import React from 'react'
import { Select } from '../Wrappers/SelectAll'
import { selectComponentStyles } from '../../services/utils/globalFunctions'

const TeamsList = ({
  handleFiltersChange,
  selectedTeam,
  teamsList,
}) => {
  return (
    <div>
      <span className="h4 text-white">Equipe</span>
          <Select
            styles={selectComponentStyles}
            placeholder={"Selecione"}
            isClearable={true}
            value={selectedTeam}
            options={teamsList}
            onChange={(e) =>
              handleFiltersChange(
                "team",
                !e
                  ? {
                      label: "Todos",
                      value: "",
                    }
                  : e
              )
            }
            isDisabled={teamsList.length === 0}
          />
    </div>
  )
}

export default TeamsList