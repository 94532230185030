import { useContextSelector } from "use-context-selector";

import { MapContext } from "../Contexts/mapContext";

const useMapOperatingRanges = () => {
  const trapsListToShowRange = useContextSelector(
    MapContext,
    (context) => context.trapsListToShowRange
  );
  const setTrapsListToShowRange = useContextSelector(
    MapContext,
    (context) => context.setTrapsListToShowRange
  );
  const trapTypesRangesRadius = useContextSelector(
    MapContext,
    (context) => context.trapTypesRangesRadius
  );
  const hadleTrapTypesRangesRadiusChange = useContextSelector(
    MapContext,
    (context) => context.hadleTrapTypesRangesRadiusChange
  );
  

  return {
    trapsListToShowRange,
    setTrapsListToShowRange,
    trapTypesRangesRadius,
    hadleTrapTypesRangesRadiusChange,
  };
};

export default useMapOperatingRanges;
