import React from "react";

import ImportTrapHistoriesPage from "./ImportTrapHistoriesPage";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import CsvDownloadButton from "react-json-to-csv";

const ImportTrapHistoriesModal = ({ isOpen, toggleModal }) => {
  const expectedFileColumnsToImport = [
    {
      ORGANIZATION_ID: "64229054-dcb0-4fcd-b617-26cde6ea3708",
      DEMAND_ID: "64229054-dcb0-4fcd-b617-26cde6ea3708",
      AGENTE_ID: "353db2e2-96ed-4c74-b9fd-56faa058fbcb",
      DATA_ATIVIDADE: "2024-09-13 13:00",
      NUMERO_ARMADILHA: "E03869",
      ARMADILHA_INSTALADA: "Não",
      ARMADILHA_LIDA: "Sim",
      ARMADILHA_DESINSTALADA: "Não",
    },
  ];

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={() => toggleModal("importTrapHistoriesTool")}
      >
        <ModalHeader toggle={() => toggleModal("importTrapHistoriesTool")}>
          <span className="h3">Importar históricos</span>
        </ModalHeader>
        <ModalBody>
          <ImportTrapHistoriesPage />
        </ModalBody>
        <ModalFooter>
          <CsvDownloadButton
            className="btn btn-primary"
            data={expectedFileColumnsToImport}
            filename={"modelo_importar_historicos"}
            style={{
              margin: 0,
              backgroundColor: "#3277F8",
              border: "0",
            }}
          >
            <i>
              <img
                src={require("../../../assets/img/icons/interface/download.svg")}
                alt=""
                style={{ width: "15px" }}
              />
            </i>
            <span style={{ color: "white" }}>Baixar modelo de importação</span>
          </CsvDownloadButton>
          <Button
            color="secondary"
            style={{ width: "100px" }}
            onClick={() => toggleModal("importTrapHistoriesTool")}
          >
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ImportTrapHistoriesModal;
