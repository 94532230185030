import { useContextSelector } from "use-context-selector";
import { OrganizationContext } from "../contexts/organizationContext";

const useTrapTypesList = () => {
  const trapTypesList = useContextSelector(
    OrganizationContext,
    (context) => context.trapTypesList
  );
  const setTrapTypesList = useContextSelector(
    OrganizationContext,
    (context) => context.setTrapTypesList
  );

  return { trapTypesList, setTrapTypesList };
};

export default useTrapTypesList;
