import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import Cookies from "js-cookie";
import {
  Map,
  TileLayer,
  FeatureGroup,
  LayersControl,
  withLeaflet,
  Polygon,
  Marker,
  Popup,
  Tooltip,
  ScaleControl,
  Circle,
} from "react-leaflet";
import FullscreenControl from "react-leaflet-fullscreen";
import PrintControlDefault from "react-leaflet-easyprint";
import "react-leaflet-fullscreen/dist/styles.css";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import HeatmapLayer from "react-leaflet-heatmap-layer";
import Control from "react-leaflet-control";
import MarkerClusterGroup from "react-leaflet-markercluster";
import "react-leaflet-markercluster/dist/styles.min.css";
import { area, pointsWithinPolygon } from "@turf/turf";
import "./CloropleticMap.css";
import PopUpInformationsComponent from "../../../components/PopUpInformationsComponent";
import moment from "moment";
import { getVersion } from "../../../constants/VersionConstant";
import { getUserData } from "../../../services/utils/auth";
import "leaflet-draw/dist/leaflet.draw.css";
import * as turf from "@turf/turf";

import { EditControl } from "react-leaflet-draw";
import { Button } from "reactstrap";
import { GOOGLE_MAPS_API_KEY } from "../../../constants/WebFormConstants";
import MarkerTrap from "../../../services/utils/MarkerTrap";

const { BaseLayer, Overlay } = LayersControl;
const PrintControl = withLeaflet(PrintControlDefault);

const L = require("leaflet");

const CloropleticMap = ({
  PolygonsGroup,
  PointsGroup,
  typeLayerSubtypePolygonsGroup,
  showCloropletic = false,
  HeatMapGroupped = false,
  HeatMap = null,
  PointsHeatMap = null,
  HeatMapGroups = null,
  HeatMapTitle = "Mapa de Calor",
  HeatMapGradient = null,
  maxClusterRadius = 80,
  buildPolygonPopup,
  dataToPopup = {},
  QuantityLegend = null,
  titles = null,
  showPopupPolygon = true,
  setTrapToCenterOnMapPosition,
  trapToCenterOnMapPosition,
  trapToCenterNumber, 
  setModal2,
  modal2,
  setTrapToUpdateData,
  trapToUpdateData
}) => {
  const [mapInitialCenter, setMapInitialCenter] = useState(null);
  const [clusterBool, setClusterBool] = useState(false);
  const [changedClusterBool, setChangedClusterBool] = useState(true);
  const [zoom, setZoom] = useState(8);
  const [activeLegend, setActiveLegend] = useState(null);
  const [isMapLoaded, setMapLoaded] = useState(false);
  const [polygonPopup, setPolygonPopup] = useState(null);
  const [loadedPoints, setLoadedPoints] = useState(false);
  const [arrayPoints, setArrayPoints] = useState([]);
  const [controlLayerInformations, setControlLayerInformations] =
    useState(false);
  const [renderedPoints, setRenderedPoints] = useState(null);
  const [markerClicked, setMarkerClicked] = useState(null);
  const [trapsToShowOperatingRange, setTrapsToShowOperatingRange] =
    React.useState(null);
  const [trapsOperatingRanges, setTrapsOperatingRanges] = React.useState({
    Adultrap: 300,
    Ovitrampa: 300,
    ADI: 12,
  });
  const [activeDrawedPolygonsData, setActiveDrawedPolygonsData] =
    React.useState([]);

  const Organization = "Admin-Organization";
  const legendsOrder = useRef([]);
  const cloropleticRefs = useRef([]);
  const layerPointRef = useRef(null);
  const mapRef = useRef(null);
  const organizationId = getUserData("organizationId");
  const webVersion = getVersion(organizationId);

  const handleActiveDrawedPolygonsChange = (polygonData) => {
    const newDrawedPolygons = [...activeDrawedPolygonsData];
    newDrawedPolygons.push(polygonData);

    setActiveDrawedPolygonsData(newDrawedPolygons);
  };

  const handleDeleteAllDrawedPolygonsData = () => {
    setActiveDrawedPolygonsData([]);
  };

  const handleClearAllOperatingRanges = () => {
    setTrapsToShowOperatingRange([]);
  };

  const handleDeleteTrapOperatingRange = (trapNumber) => {
    setTrapsToShowOperatingRange((previousTraps) => {
      const newTrapsToShowOperatingRange = [...previousTraps];
      const trapToRemoveIndex = newTrapsToShowOperatingRange.findIndex(
        (trap) => trap.trapNumber === trapNumber
      );

      newTrapsToShowOperatingRange.splice(trapToRemoveIndex, 1);

      return newTrapsToShowOperatingRange;
    });
  };

  const handleShowOperatingRangeChange = (trapData) => {
    setTrapsToShowOperatingRange((previousTraps) => {
      const newTrapsToShowOperatingRange = [...previousTraps, trapData];

      return newTrapsToShowOperatingRange;
    });
  };

  const handleTrapsOperatingRangesChange = (trapType, newRange) => {
    const newTrapsOperatingRanges = { ...trapsOperatingRanges };

    newTrapsOperatingRanges[trapType] = +newRange;

    setTrapsOperatingRanges(newTrapsOperatingRanges);
  };

  const renderPolygonsGroup = ({
    LayerTitleText = "",
    PolygonFillColor = true,
    PolygonColor = true,
    PolygonKey,
    polygonsGroupsToRender,
  }) => {
    return (
      <LayersControl position="topright">
        {LayerTitleText !== "" && (
          <BaseLayer checked name={LayerTitleText}>
            <TileLayer attribution="" url="" />
          </BaseLayer>
        )}

        {polygonsGroupsToRender && polygonsGroupsToRender.length > 0
          ? polygonsGroupsToRender.map((polygonGroup) => {
              return (
                <Overlay
                  key={`${PolygonKey}-${polygonGroup.name}`}
                  name={polygonGroup.name}
                >
                  <FeatureGroup
                    fillOpacity={PolygonFillColor ? 0.4 : 0.3}
                    name={polygonGroup.name}
                    ref={(r) =>
                      PolygonKey === "cloropletic-polygon"
                        ? addCloropleticRef(r)
                        : null
                    }
                  >
                    {polygonGroup.Polygons.map((polygon, index) => {
                      const coordinates = polygon.geometry.coordinates[0].map(
                        ([latitude, longitude]) => [longitude, latitude]
                      );

                      if (
                        polygon.territorialization.typeLayerId ===
                        "eb3d927c-2ef0-41ab-91fd-7917f32486e8"
                      ) {
                        PolygonFillColor = true;
                        polygon.color = "#fcf803";
                      }

                      if (
                        polygon.territorialization.typeLayerId ===
                        "79d665fe-ac45-4016-a709-b0a31b3019c5"
                      ) {
                        PolygonFillColor = true;
                        polygon.color = "#fc03d2";
                      }

                      return (
                        <Polygon
                          key={PolygonKey + index}
                          color={
                            PolygonColor ? polygon.color || "#03a1fc" : "#000"
                          }
                          opacity={PolygonFillColor ? 1 : 1}
                          fillColor={
                            PolygonFillColor
                              ? polygon.color || "#03a1fc"
                              : "transparent"
                          }
                          positions={coordinates}
                        >
                          {polygon.popup}
                          {polygon.name ? (
                            <Tooltip sticky>{polygon.name}</Tooltip>
                          ) : null}

                          <Popup
                            key={"pupupDataTerri-carregando131"}
                            onClose={() => setPolygonPopup(null)}
                            onOpen={() => handlePolygonClick(polygon)}
                          >
                            <div key={"popupDataPoint"} className="popUp">
                              <PopUpInformationsComponent
                                polygonPopup={polygonPopup}
                                territorializationId={
                                  polygon.territorializationId
                                    ? polygon.territorializationId
                                    : null
                                }
                                typeSinan={
                                  polygon.typeSinan ? polygon.typeSinan : null
                                }
                                filter={polygon.filter ? polygon.filter : null}
                              />
                            </div>
                          </Popup>
                        </Polygon>
                      );
                    })}
                  </FeatureGroup>
                </Overlay>
              );
            })
          : PolygonsGroup.map((Group) => {
              return (
                <Overlay
                  key={`${PolygonKey}-${Group.name}`}
                  name={Group.name}
                  checked={Group.name == "UVIS" ? true : false}
                >
                  <FeatureGroup
                    fillOpacity={PolygonFillColor ? 0.4 : 0.3}
                    name={Group.name}
                    ref={(r) =>
                      PolygonKey === "cloropletic-polygon"
                        ? addCloropleticRef(r)
                        : null
                    }
                  >
                    {Group.Polygons.map((polygon, index) => {
                      const coordinates = polygon.geometry.coordinates[0].map(
                        ([latitude, longitude]) => [longitude, latitude]
                      );
                      if (
                        Group.name == "Áreas Selecionadas Etapa 1 Ca" ||
                        Group.name == "Áreas Selecionadas Etapa 1 Co" ||
                        Group.name == "Área supressão de Aedes"
                      ) {
                        PolygonFillColor = true;
                        polygon.color = "#03a1fc";
                      }

                      if (Group.name == "Áreas Selecionadas Etapa 2") {
                        PolygonFillColor = true;
                        polygon.color = "#eb4034";
                      }

                      if (Group.name === "Escolas Participantes") {
                        PolygonFillColor = true;
                        polygon.color = "#fcf803";
                      }

                      if (Group.name === "Escolas Não Participantes") {
                        PolygonFillColor = true;
                        polygon.color = "#fc03d2";
                      }

                      return (
                        <Polygon
                          key={PolygonKey + index}
                          color={
                            PolygonColor ? polygon.color || "#03a1fc" : "#000"
                          }
                          opacity={PolygonFillColor ? 1 : 1}
                          fillColor={
                            PolygonFillColor
                              ? polygon.color || "#03a1fc"
                              : "transparent"
                          }
                          positions={coordinates}
                        >
                          {polygon.popup}
                          {polygon.name ? (
                            <Tooltip sticky>{polygon.name}</Tooltip>
                          ) : null}

                          <Popup
                            key={"pupupDataTerri-carregando131"}
                            onClose={() => setPolygonPopup(null)}
                            onOpen={() => handlePolygonClick(polygon)}
                          >
                            <div key={"popupDataPoint"} className="popUp">
                              <PopUpInformationsComponent
                                polygonPopup={polygonPopup}
                                territorializationId={
                                  polygon.territorializationId
                                    ? polygon.territorializationId
                                    : null
                                }
                                typeSinan={
                                  polygon.typeSinan ? polygon.typeSinan : null
                                }
                                filter={polygon.filter ? polygon.filter : null}
                              />
                            </div>
                          </Popup>
                        </Polygon>
                      );
                    })}
                  </FeatureGroup>
                </Overlay>
              );
            })}
      </LayersControl>
    );
  };

  useEffect(() => {
    if (trapToCenterNumber) {
      let foundIndex = -1;
      let PointFoundIndex = -1;
      PointsGroup.forEach((Group, indexPointGroup) => {
        const index = Group.points.findIndex(
          (point) => point.number === trapToCenterNumber
        );
        if (index !== -1) {
          foundIndex = index;
          PointFoundIndex = indexPointGroup;
          return;
        }
      });

      if (foundIndex !== -1) {
        setMarkerClicked([PointFoundIndex, foundIndex]);
      }
    }
  }, [trapToCenterNumber]);

  useEffect(() => {
    if (
      PointsGroup &&
      PointsGroup.length > 0 &&
      (!loadedPoints || changedClusterBool)
    ) {
      var centerLocation = [];
      var zoom = null;

      if (Cookies.get(Organization)) {
        var organization = JSON.parse(Cookies.get(Organization));
        var centerLocation = organization.stateSub
          .replace(/\s/g, "")
          .split(",");
        zoom = organization.countySub;
      }

      if (centerLocation.length > 0) {
        var validCenterLocation = validLatLong(
          centerLocation[0],
          centerLocation[1]
        );
        if (validCenterLocation) {
          setMapInitialCenter([centerLocation[0], centerLocation[1]]);
        }
      }

      //criando lógica para definir como centro do mapa o primeiro ponto do primeiro grupo de pontos
      if (!mapInitialCenter && PointsGroup && PointsGroup.length > 0) {
        if (PointsGroup[0].points.length > 0) {
          let lastIndex = PointsGroup[0].points.length - 1;
          let lastPoint = PointsGroup[0].points[lastIndex].position;
          setMapInitialCenter(lastPoint);
        }
      }

      if (zoom) {
        setZoom(parseInt(zoom));
      } else {
        setZoom(12);
      }

      let checkedLayers = [];

      if(layerPointRef && layerPointRef.current) {
        const layers = layerPointRef.current.leafletElement._layers;
  
        layerPointRef.current.leafletElement._layerControlInputs.map(
          (layerInputs, index) => {
            if (
              layerInputs.checked &&
              layers[index] &&
              layers[index].name != "Armadilhas"
            ) {
              checkedLayers.push(layers[index]);
            }
          }
        );
      }

      const newArrayPoints = PointsGroup.map((Group, indexPointsGroup) => {
        let checked = Group.checked;

        if(checkedLayers && checkedLayers.length > 0) {
          checked = checkedLayers.some(cl => cl.name == Group.name);
        }

        return (
          <Overlay
            key={`overlay-${Group.name}-${indexPointsGroup}-${checked}-${clusterBool}`}
            name={Group.name}
            
            /* checked={true} */ checked={checked}
          >
            <MarkerClusterGroup
              key={moment() + "-" + clusterBool}
              disableClusteringAtZoom={!clusterBool ? null : 10}
              removeOutsideVisibleBounds={true}
            >
              {Group.points.map(
                (
                  {
                    icon,
                    position,
                    name,
                    number,
                    nameWithoutFormat,
                    organizationId,
                  },
                  index
                ) => {
                  const myIcon = L.icon({
                    iconUrl: icon,
                    iconSize: [16, 16],
                  });

                  var linhas = 0;
                  var paragrafo = null;

                  if (!nameWithoutFormat) {
                    linhas = name ? name.split("\n").length : 0;

                    if (linhas > 0) {
                      paragrafo = name.split("\n");
                    }
                  }

                  return (
                    <MarkerTrap
                      name={name}
                      index={index}
                      myIcon={myIcon}
                      position={position}
                      paragrafo={paragrafo}
                      number={number}
                      handleShowOperatingRangeChange={handleShowOperatingRangeChange}
                      handleDeleteTrapOperatingRange={handleDeleteTrapOperatingRange}
                      organizationId={getUserData("organizationId")}
                      indexPointsGroup={indexPointsGroup}
                      setModal2={setModal2}
                      modal2={modal2}
                      setTrapToUpdateData={setTrapToUpdateData}
                      trapToUpdateData={trapToUpdateData}
                      PointsGroup={PointsGroup}
                    />
                  );
                }
              )}
            </MarkerClusterGroup>
          </Overlay>
        )
      });

      if (changedClusterBool) {
        // if (arrayPoints && arrayPoints.length > 0) {
        //   console.log("Carreguei aqui no 1")
        //   setRenderedPoints(arrayPoints);
        // }
        setChangedClusterBool(false);
      }
      setArrayPoints(newArrayPoints);
      setLoadedPoints(true);
    }
  }, [PointsGroup, clusterBool]);

  useEffect(() => {
    const map = mapRef.current.leafletElement;
    var zoom = null;

    if (Cookies.get(Organization)) {
      var organization = JSON.parse(Cookies.get(Organization));
      var centerLocation = organization.stateSub.replace(/\s/g, "").split(",");
      zoom = organization.countySub;
    }

    if (centerLocation.length > 0) {
      var validCenterLocation = validLatLong(
        centerLocation[0],
        centerLocation[1]
      );
      if (validCenterLocation) {
        setMapInitialCenter([centerLocation[0], centerLocation[1]]);
      }
    }

    //criando lógica para definir como centro do mapa o primeiro polígono
    if (!mapInitialCenter && PolygonsGroup && PolygonsGroup.length > 0) {
      for (const index in PolygonsGroup) {
        if (PolygonsGroup[index].Polygons.length > 0) {
          let firstPolygonCoordinate =
            PolygonsGroup[index].Polygons[0].geometry.coordinates[0][0];
          setMapInitialCenter([
            firstPolygonCoordinate[1],
            firstPolygonCoordinate[0],
          ]);
          break;
        }
      }
    }

    //criando lógica para definir como centro do mapa o primeiro ponto do primeiro grupo de pontos
    if (!mapInitialCenter && PointsGroup && PointsGroup.length > 0) {
      if (PointsGroup[0].points.length > 0) {
        let lastIndex = PointsGroup[0].points.length - 1;
        let lastPoint = PointsGroup[0].points[lastIndex].position;
        setMapInitialCenter(lastPoint);
      }
    }

    if (zoom) {
      setZoom(parseInt(zoom));
    } else {
      setZoom(12);
    }

    map.on("overlayadd", (e) => {
      if (PolygonsGroup && PolygonsGroup.length > 0) {
        let cloropleticLayer = cloropleticRefs.current.find(
          (l) => l._leaflet_id === e.layer._leaflet_id
        );
        if (cloropleticLayer) {
          // PolygonsGroup.find((g) => g.name === e.name).Polygons.map((polygon, index) => {
          // })
          let groupLegends = PolygonsGroup.find(
            (g) => g.name === e.name
          ).legends;
          if (groupLegends) {
            let legendObject = {
              group: e.name,
              leaflet_id: e.layer._leaflet_id,
              legends: groupLegends,
            };

            legendsOrder.current.push(legendObject);
            setActiveLegend(legendObject);
          }
        }
      }
    });

    map.on("overlayremove", (e) => {
      let removeIndex = legendsOrder.current.findIndex(
        (l) => l.leaflet_id === e.layer._leaflet_id
      );
      if (removeIndex != -1) {
        legendsOrder.current.splice(removeIndex, 1);
        let legendsRemaining = legendsOrder.current.length;
        if (legendsRemaining > 0) {
          let previousLegend =
            legendsOrder.current[legendsOrder.current.length - 1];
          setActiveLegend(previousLegend);
        } else {
          setActiveLegend(null);
        }
      }
    });

    map.on("baselayerchange", (e) => {
      if (e.name === "Normal" && setTrapToCenterOnMapPosition)
        setTrapToCenterOnMapPosition([]);
    });
  }, [PolygonsGroup, PointsGroup]);

  function validLatLong(lat, long) {
    if (
      lat === null ||
      long === null ||
      lat === undefined ||
      long === undefined
    ) {
      return false;
    }

    lat = Number(lat);
    long = Number(long);

    if (!(lat >= -90 && lat <= 90)) {
      return false;
    }

    if (!(long >= -180 && long <= 180)) {
      return false;
    }

    return true;
  }
  const addCloropleticRef = (el) => {
    if (el && !cloropleticRefs.current.includes(el.leafletElement)) {
      cloropleticRefs.current.push(el.leafletElement);
    }
  };

  const handlePolygonClick = async (polygon) => {
    const territorialization = polygon.territorialization;

    if (territorialization) {
      var dataTraps = dataToPopup;
      let checkedLayers = [];

      if (titles[0] == "Armadilhas") {
        const layers = layerPointRef.current.leafletElement._layers;

        dataTraps = {
          type: "FeatureCollection",
          features: [],
        };
        layerPointRef.current.leafletElement._layerControlInputs.map(
          (layerInputs, index) => {
            if (
              layerInputs.checked &&
              layers[index] &&
              layers[index].name != "Armadilhas"
            ) {
              checkedLayers.push(layers[index]);
            }
          }
        );

        console.log("checkedLayers", checkedLayers)

        dataToPopup.features.map((trapFeature) => {
          const encontrada = checkedLayers.some(
            (layerTrap) => layerTrap.name === trapFeature.properties.name
          );
          if (encontrada) {
            dataTraps.features.push(trapFeature);
          }
        });
      }

      const dataWithinPolygon = pointsWithinPolygon(dataTraps, polygon);
      const polygonArea = area(polygon);

      territorialization.data = dataWithinPolygon.features;
      territorialization.areaM = polygonArea;
      territorialization.areaKm = territorialization.areaM / 1000000;
      territorialization.areaHa = territorialization.areaM / 10000;
      territorialization.densityKm =
        territorialization.data.length / territorialization.areaKm;
      territorialization.densityHa =
        territorialization.data.length / territorialization.areaHa;
      territorialization.trapDensityKm =
        territorialization.areaKm / territorialization.data.length;
      territorialization.trapDensityHa =
        territorialization.areaHa / territorialization.data.length;
      territorialization.trapDensitySquareMeters =
        (territorialization.areaKm * 1000000) / territorialization.data.length;

      if (buildPolygonPopup && titles[0] == "Armadilhas") {
        setPolygonPopup(
          buildPolygonPopup(
            territorialization,
            checkedLayers,
            setTrapsToShowOperatingRange,
            handleClearAllOperatingRanges,
            dataWithinPolygon
          )
        );
      } else if (buildPolygonPopup) {
        setPolygonPopup(
          buildPolygonPopup(
            territorialization,
            null,
            setTrapsToShowOperatingRange,
            handleClearAllOperatingRanges,
            dataWithinPolygon
          )
        );
      }
    }
  };

  const buildQuantityLegend = () => {
    return (
      <Control position={"bottomleft"}>
        <div
          style={{
            maxWidth: 250,
            maxHeight: 300,
            backgroundColor: "#fff",
            paddingTop: 5,
            paddingBottom: 5,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <div
            style={{ width: "100%", textAlign: "center", fontWeight: "bold" }}
          >
            <span style={{ fontSize: "17px" }}>{QuantityLegend}</span>
          </div>
        </div>
      </Control>
    );
  };

  const buildTest = () => {
    return (
      <Control prepend position="topright">
        <Button color="inherit"></Button>
      </Control>
    );
  };

  const buildCloropleticLegend = () => {
    if (activeLegend) {
      return (
        <Control position={"bottomright"}>
          <div
            style={{
              maxWidth: 250,
              maxHeight: 300,
              zIndex: "0",
              backgroundColor: "#fff",
              paddingTop: 5,
              paddingBottom: 5,
              paddingLeft: 10,
              paddingRight: 10,
            }}
          >
            {/* title */}
            <div
              style={{ width: "100%", textAlign: "center", fontWeight: "bold" }}
            >
              {activeLegend.group}
            </div>

            {/* legends */}
            {activeLegend.legends.map((legend) => (
              <div
                style={{
                  width: "100%",
                  fontSize: 10,
                  display: "flex",
                  alignContent: "center",
                  paddingBottom: 2,
                }}
              >
                <div
                  style={{
                    width: 15,
                    height: 15,
                    backgroundColor: legend.color,
                    display: "inline-block",
                    marginRight: 5,
                  }}
                ></div>
                <span>{legend.text}</span>
              </div>
            ))}
          </div>
        </Control>
      );
    }
  };

  useEffect(() => {
    if (arrayPoints && arrayPoints.length > 0 && loadedPoints) {
      console.log("Carreguei aqui no 2")
      setRenderedPoints(arrayPoints);
    }
  }, [arrayPoints, loadedPoints]);

  useEffect(() => {
    if (trapToCenterOnMapPosition && trapToCenterOnMapPosition.length > 0) {
      const mapRefL = mapRef.current.leafletElement;
      if (mapRef.current) {
        mapRefL.flyTo(trapToCenterOnMapPosition, 21, { duration: 1 });
      }
    }
  }, [trapToCenterOnMapPosition]);

  const buildMapDrawedPolygonsLegend = () => {
    if (!activeDrawedPolygonsData || activeDrawedPolygonsData.length === 0)
      return;

    let totalAreaInSquareKilometers = 0;
    let totalAreaInSquareMeters = 0;
    let totalAreaInEquit = 0;

    activeDrawedPolygonsData.forEach(
      ({ areaInSquareKilometers, areaInSquareMeters, areaInEquit }) => {
        totalAreaInSquareKilometers += areaInSquareKilometers;
        totalAreaInSquareMeters += areaInSquareMeters;
        totalAreaInEquit += areaInEquit;
      }
    );

    return (
      <table style={{ fontSize: ".75rem", width: "100%" }}>
        <thead>
          <tr style={{ border: "1px solid black", textAlign: "center" }}>
            <td style={{ border: "1px solid black" }}>Polígono</td>
            <td style={{ border: "1px solid black" }}>m²</td>
            <td style={{ border: "1px solid black" }}>km²</td>
            <td style={{ border: "1px solid black" }}>ha</td>
          </tr>
        </thead>
        <tbody>
          {activeDrawedPolygonsData.map(
            (
              {
                areaInSquareKilometers,
                areaInSquareMeters,
                areaInEquit,
                color,
              },
              index
            ) => (
              <tr
                style={{ border: "1px solid black", textAlign: "center" }}
                key={index}
              >
                <td
                  style={{
                    border: "1px solid black",
                    backgroundColor: color,
                    color: "white",
                  }}
                ></td>
                <td style={{ border: "1px solid black" }}>
                  {areaInSquareMeters.toFixed(2)}
                </td>
                <td style={{ border: "1px solid black" }}>
                  {areaInSquareKilometers.toFixed(2)}
                </td>
                <td style={{ border: "1px solid black" }}>
                  {areaInEquit.toFixed(2)}
                </td>
              </tr>
            )
          )}
          <tr style={{ border: "1px solid black", textAlign: "center" }}>
            <td style={{ border: "1px solid black" }}>TOTAL:</td>
            <td style={{ border: "1px solid black" }}>
              {totalAreaInSquareMeters.toFixed(2)}
            </td>
            <td style={{ border: "1px solid black" }}>
              {totalAreaInSquareKilometers.toFixed(2)}
            </td>
            <td style={{ border: "1px solid black" }}>
              {totalAreaInEquit.toFixed(2)}
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  const mapDrawedPolygonsLegend = buildMapDrawedPolygonsLegend();

  return (
    <div id="mapid">
      <Map
        style={{ height: "32.3rem", borderRadius: 4 }}
        center={mapInitialCenter || [-22.848046, -43.321953]}
        zoom={zoom || 8}
        ref={mapRef}
        whenReady={() => setMapLoaded(true)}
      >
        {isMapLoaded ? (
          <>
            <Control position="topleft">
              <div className="leaflet-control-scale leaflet-control">
                <img
                  width={60}
                  src={require("../../../assets/img/control/compass_rose_2.png")}
                  alt="oi"
                />
              </div>
            </Control>

            <Control
              position="topright"
              className="leaflet-control-layers leaflet-control"
            >
              {controlLayerInformations ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                      padding: "5px 8px 0 0",
                      cursor: "pointer",
                    }}
                  >
                    <i
                      onClick={() => setControlLayerInformations(false)}
                      className="fa fa-times"
                    ></i>
                  </div>

                  {(organizationId !== "559a16ab-5da8-41ba-96b8-6e9f55feddd8" && 
                    organizationId !== "7479f4e9-1706-41d4-8402-0bae72ef71ec" && 
                    organizationId !== "b2e9a6f4-8a10-4b38-b80c-95cf0aeeed30"
                  ) && (
                    <div style={{ padding: "10px" }}>
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          console.log("target cb", e.target.checked);
                          setClusterBool(e.target.checked);
                          setChangedClusterBool(true);
                        }}
                        checked={clusterBool}
                      />
                      <span>Desabilitar agrupamento dos pontos</span>
                    </div>
                  )}
                  <div style={{ padding: "10px" }}>
                    <span style={{ fontWeight: "bold", display: "block" }}>
                      Raios de atuação das armadilhas:
                    </span>
                    <span>
                      <span style={{ fontWeight: "bold" }}>ADI</span>:{" "}
                      {trapsOperatingRanges.ADI}m
                    </span>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "5px",
                        marginTop: "10px",
                      }}
                    >
                      <span>1m</span>
                      <input
                        type="range"
                        name="adultrapRange"
                        id="adultrapRange"
                        min={1}
                        max={1000}
                        value={trapsOperatingRanges.ADI}
                        onChange={({ target }) =>
                          handleTrapsOperatingRangesChange("ADI", target.value)
                        }
                      />
                      <span>1000m</span>
                    </div>
                  </div>
                  <div style={{ padding: "0px 10px 10px 10px" }}>
                    <span>
                      <span style={{ fontWeight: "bold" }}>Adultrap</span>:{" "}
                      {trapsOperatingRanges.Adultrap}m
                    </span>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "5px",
                        marginTop: "10px",
                      }}
                    >
                      <span>1m</span>
                      <input
                        type="range"
                        name="adultrapRange"
                        id="adultrapRange"
                        min={1}
                        max={1000}
                        value={trapsOperatingRanges.Adultrap}
                        onChange={({ target }) =>
                          handleTrapsOperatingRangesChange(
                            "Adultrap",
                            target.value
                          )
                        }
                      />
                      <span>1000m</span>
                    </div>
                  </div>
                  <div style={{ padding: "0px 10px 10px 10px" }}>
                    <span>
                      <span style={{ fontWeight: "bold" }}>Ovitrampa</span>:{" "}
                      {trapsOperatingRanges.Ovitrampa}m
                    </span>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "5px",
                        marginTop: "10px",
                      }}
                    >
                      <span>1m</span>
                      <input
                        type="range"
                        name="adultrapRange"
                        id="adultrapRange"
                        min={1}
                        max={1000}
                        value={trapsOperatingRanges.Ovitrampa}
                        onChange={({ target }) =>
                          handleTrapsOperatingRangesChange(
                            "Ovitrampa",
                            target.value
                          )
                        }
                      />
                      <span>1000m</span>
                    </div>
                  </div>
                  <div style={{ padding: "10px" }}>
                    <span style={{ marginBottom: "15px", fontWeight: "bold" }}>
                      Polígonos desenhados:
                    </span>
                    {buildMapDrawedPolygonsLegend()}
                  </div>
                  <div style={{ padding: "10px" }}>
                    <Button
                      color="danger"
                      style={{
                        margin: "16px 0px 0px 0px",
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                      onClick={() => handleClearAllOperatingRanges()}
                    >
                      <i
                        className="ni ni-fat-remove"
                        style={{ color: "white" }}
                      ></i>{" "}
                      Limpar áreas de atuação
                    </Button>
                  </div>
                </>
              ) : (
                <div
                  onClick={() => setControlLayerInformations(true)}
                  style={{
                    padding: "10px",
                    width: "44px",
                    height: "44px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <i className="fa fa-cog fa-2x"></i>
                </div>
              )}
            </Control>

            <ScaleControl
              position="bottomright"
              imperial={false}
              updateWhenIdle={false}
            />

            <FullscreenControl
              position="topleft"
              title="Tela Cheia"
              titleCancel="Sair da Tela Cheia"
            />
            {/* <PrintControl
              position="topleft"
              sizeModes={["Current", "A4Portrait", "A4Landscape"]}
              hideControlContainer={false}
              title="Export as PNG"
              exportOnly
            /> */}

            <LayersControl position="topright">
              <BaseLayer checked name="Normal">
                <TileLayer
                  attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
              </BaseLayer>
              {/* <BaseLayer name="Preto e Branco">
                        <TileLayer
                            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
                        />
                    </BaseLayer> */}

              <BaseLayer name="Satélite">
                <ReactLeafletGoogleLayer
                  googleMapsLoaderConf={{ KEY: GOOGLE_MAPS_API_KEY }}
                  type={"hybrid"}
                />
              </BaseLayer>

              {HeatMapGroupped && HeatMapGroups ? (
                HeatMapGroups.map((group, index) => (
                  <Overlay
                    key={group.heatMapTitle + index}
                    name={group.heatMapTitle}
                    checked={index === 0}
                  >
                    <HeatmapLayer
                      points={group.points}
                      longitudeExtractor={(m) => m.position[1]}
                      latitudeExtractor={(m) => m.position[0]}
                      intensityExtractor={(m) => parseFloat(m.intensity)}
                      gradient={
                        group.gradient ? group.gradient : HeatMapGradient
                      }
                    />
                  </Overlay>
                ))
              ) : HeatMap && PointsHeatMap ? (
                <Overlay name={HeatMapTitle} checked>
                  <HeatmapLayer
                    points={PointsHeatMap}
                    longitudeExtractor={(m) => m.position[1]}
                    latitudeExtractor={(m) => m.position[0]}
                    intensityExtractor={(m) => parseFloat(m.intensity)}
                    gradient={HeatMapGradient}
                  />
                </Overlay>
              ) : null}
            </LayersControl>

            {renderedPoints && (
              <LayersControl ref={layerPointRef} position={"topright"}>
                {titles ? (
                  <BaseLayer checked name={titles[0]}>
                    <TileLayer attribution="" url="" />
                  </BaseLayer>
                ) : null}
                {renderedPoints}
              </LayersControl>
            )}

            {/* Colocando layer cloroplético */}
            {webVersion &&
              webVersion == "normal_version" &&
              showCloropletic &&
              PolygonsGroup &&
              renderPolygonsGroup({
                LayerTitleText: "Opções Coropléticas",
                PolygonKey: "cloropletic-polygon",
              })}

            {/* Colocando layer delimitador */}
            {PolygonsGroup &&
              renderPolygonsGroup({
                LayerTitleText: "Delimitador de Áreas",
                PolygonKey: "delimiter-area-polygon",
                PolygonFillColor: false,
                PolygonColor: false,
                Delimiter: true,
              })}

            {typeLayerSubtypePolygonsGroup &&
              getUserData("organizationId") ===
                "559a16ab-5da8-41ba-96b8-6e9f55feddd8" &&
              renderPolygonsGroup({
                LayerTitleText: "Tipo de escola",
                PolygonKey: "delimiter-area-polygon-schools",
                PolygonFillColor: false,
                PolygonColor: false,
                Delimiter: true,
                polygonsGroupsToRender: typeLayerSubtypePolygonsGroup,
              })}

            {showCloropletic && buildCloropleticLegend()}

            {QuantityLegend && buildQuantityLegend()}

            <MapDrawTools
              handleActiveDrawedPolygonsChange={
                handleActiveDrawedPolygonsChange
              }
              handleDeleteAllDrawedPolygonsData={
                handleDeleteAllDrawedPolygonsData
              }
            />

            {trapsToShowOperatingRange &&
              trapsToShowOperatingRange.features &&
              trapsToShowOperatingRange.features.length > 0 &&
              trapsToShowOperatingRange.features.map(
                ({ properties, geometry }, index) => (
                  <TrapOperatingRangeCircleWrapper
                    trapNumber={index}
                    trapType={properties.name}
                    trapPosition={geometry.coordinates}
                    situationColor={properties.situationColor}
                    trapsOperatingRanges={trapsOperatingRanges}
                  />
                )
              )}
          </>
        ) : (
          <></>
        )}
      </Map>
    </div>
  );
};

const TrapOperatingRangeCircleWrapper = ({
  trapNumber,
  trapType,
  trapPosition,
  situationColor,
  trapsOperatingRanges,
}) => {
  const COVERED_AREAS__COLORS_CIRCLE_OBJECT = {
    fillColor: "green",
    borderColor: "green",
  };
  
  const RISK_AREAS__COLORS_CIRCLE_OBJECT = {
    fillColor: "red",
    borderColor: "red",
  };

  const colorsVariants = {
    ADI: {
      green: COVERED_AREAS__COLORS_CIRCLE_OBJECT,
      yellow: COVERED_AREAS__COLORS_CIRCLE_OBJECT,
      red: RISK_AREAS__COLORS_CIRCLE_OBJECT,
    },
    Adultrap: {
      green: COVERED_AREAS__COLORS_CIRCLE_OBJECT,
      yellow: COVERED_AREAS__COLORS_CIRCLE_OBJECT,
      red: RISK_AREAS__COLORS_CIRCLE_OBJECT,
    },
    Ovitrampa: {
      green: COVERED_AREAS__COLORS_CIRCLE_OBJECT,
      yellow: COVERED_AREAS__COLORS_CIRCLE_OBJECT,
      red: RISK_AREAS__COLORS_CIRCLE_OBJECT,
    },
    "BG-GAT": {
      green: COVERED_AREAS__COLORS_CIRCLE_OBJECT,
      yellow: COVERED_AREAS__COLORS_CIRCLE_OBJECT,
      red: RISK_AREAS__COLORS_CIRCLE_OBJECT,
    },
  };

  const trapTypeName = trapType.split(" ")[0];
  const trapCircleColors = colorsVariants[trapTypeName][situationColor];
  const trapOperatingRange = trapsOperatingRanges[trapTypeName];

  return (
    <Circle
      key={trapType + trapNumber}
      center={[trapPosition[1], trapPosition[0]]}
      color={!trapCircleColors ? "darkblue" : trapCircleColors.borderColor}
      fillColor={!trapCircleColors ? "darkblue" : trapCircleColors.fillColor}
      radius={!trapOperatingRange ? 100 : trapOperatingRange}
    />
  );
};

const MapLegend = ({ legend }) => {
  return (
    <Control position={"bottomleft"}>
      {legend && legend !== "" && (
        <div
          style={{
            maxHeight: 300,
            backgroundColor: "#fff",
            paddingTop: 5,
            paddingBottom: 5,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <div
            style={{ width: "100%", textAlign: "center", fontWeight: "bold" }}
          >
            <span style={{ fontSize: ".75rem" }}>{legend}</span>
          </div>
        </div>
      )}
    </Control>
  );
};

const MapDrawTools = ({
  handleActiveDrawedPolygonsChange,
  handleDeleteAllDrawedPolygonsData,
}) => {
  const [polygonColorIndex, setPolygonColorIndex] = React.useState(0);

  const polygonColors = [
    "yellow",
    "red",
    "blue",
    "orange",
    "green",
    "purple",
    "gray",
    "pink",
    "black",
    "white",
    "aqua",
    "olive",
  ];

  const onPolygonCreated = (e) => {
    const layer = e.layer;
    if (layer instanceof L.Polygon) {
      const geojson = layer.toGeoJSON();
      const polygonArea = turf.area(geojson);
      const polygonAreaInSquareMeteres = polygonArea;
      const polygonAreaInSquareKilometers = polygonArea / 1000000;
      const polygonAreaInEquit = polygonArea / 10000;

      handleActiveDrawedPolygonsChange({
        areaInSquareKilometers: polygonAreaInSquareKilometers,
        areaInSquareMeters: polygonAreaInSquareMeteres,
        areaInEquit: polygonAreaInEquit,
        color: polygonColors[polygonColorIndex],
      });

      const popupContent = `
        <div>
          Área do polígono:
          <br />
          <br />
          ・${polygonAreaInSquareMeteres.toFixed(2)} m²
          <br />
          ・${polygonAreaInSquareKilometers.toFixed(2)} km²
          <br />
          ・${polygonAreaInEquit.toFixed(2)} ha
        </div>
      `;

      layer.bindPopup(popupContent).openPopup();
      setPolygonColorIndex((previousValue) => previousValue + 1);

      /* layer.on('click', function() {
        map.on('draw:deleted', (e) => {
          e.layers.eachLayer((deletedLayer) => {
            if (deletedLayer === layer) {
              handlePolygonDeleted(geojson);
            }
          });
        });
      }); */
    }
  };

  const onPolygonsDeleted = () => {
    handleDeleteAllDrawedPolygonsData();
  };

  return (
    <FeatureGroup>
      <EditControl
        position="topleft"
        onCreated={onPolygonCreated}
        onDeleted={onPolygonsDeleted}
        draw={{
          polyline: {
            icon: new L.DivIcon({
              iconSize: new L.Point(8, 8),
              className: "leaflet-div-icon leaflet-editing-icon",
            }),
            shapeOptions: {
              guidelineDistance: 10,
              color: "navy",
              weight: 3,
            },
          },
          marker: false,
          rectangle: false,
          circlemarker: false,
          circle: false,
          polygon: {
            shapeOptions: {
              color: polygonColors[polygonColorIndex],
              weight: 7,
              fillColor: "transparent",
              fillOpacity: 0,
            },
          },
        }}
      />
    </FeatureGroup>
  );
};

export default CloropleticMap;
