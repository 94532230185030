import { useContextSelector } from "use-context-selector";
import { FieldResultsPageContext } from "../Contexts/FieldResultsPageContext";

const useGraphicsData = () => {
  const fieldResultsList = useContextSelector(
    FieldResultsPageContext,
    (context) => context.fieldResultsList
  );
  const setFieldResultsList = useContextSelector(
    FieldResultsPageContext,
    (context) => context.setFieldResultsList
  );

  return {
    fieldResultsList,
    setFieldResultsList,
  };
};

export default useGraphicsData;
