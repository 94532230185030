import React from "react";

import { createContext } from "use-context-selector";

const OrganizationContext = createContext(null);

const useOrganizationContext = () => {
  const context = React.useContext(OrganizationContext);

  if (!context)
    throw new Error("useContext de Organization deve estar dentro do Provider");

  return context;
};

const OrganizationContextProvider = ({ children }) => {
  const [demandsList, setDemandsList] = React.useState([]);
  const [employeesList, setEmployeesList] = React.useState([]);
  const [teamsList, setTeamsList] = React.useState([]);
  const [territorializationsList, setTerritorializationsList] = React.useState(
    []
  );
  const [trapsList, setTrapsList] = React.useState([]);
  const [trapTypesList, setTrapTypesList] = React.useState([]);
  const [typeLayersList, setTypeLayersList] = React.useState([]);

  return (
    <OrganizationContext.Provider
      value={{
        demandsList,
        employeesList,
        teamsList,
        territorializationsList,
        trapsList,
        trapTypesList,
        typeLayersList,
        setDemandsList,
        setEmployeesList,
        setTeamsList,
        setTerritorializationsList,
        setTrapsList,
        setTrapTypesList,
        setTypeLayersList,
      }}
    >
      {children}
    </OrganizationContext.Provider>
  );
};

export { OrganizationContextProvider, useOrganizationContext, OrganizationContext };
