import React from "react";

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
  getTrapByNumber,
  postTrapHistory,
} from "../../../../services/api/Trap";
import { getUserData } from "../../../../services/utils/auth";
import moment from "moment";
import { toast } from "react-toastify";

const useUninstallTrapModal = (trapNumber, isOpen) => {
  const [trapData, setTrapData] = React.useState(null);

  React.useEffect(() => {
    if (!isOpen || !trapNumber || trapNumber === "") return;

    fetchTrapByNumberData(trapNumber);
  }, [isOpen, trapNumber]);

  const fetchTrapByNumberData = async (trapNumber) => {
    if (!trapNumber || trapNumber === "") return;

    try {
      const { data, status } = await getTrapByNumber(trapNumber);
      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao buscap pelos dados da armadilha. Verifique sua conexão com internet e tente novamente. Caso o problema persista, entre em contato com nossa equipe."
        );

      setTrapData(data);
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
        console.error(error.message);
      }
    }
  };

  const handleUninstallTrap = async () => {
    if (!trapData) return;

    const organizationId = getUserData("organizationId");
    const userId = getUserData("userId");

    const { demandId, id, latitude, longitude, number } = trapData;

    const uninstallHistory = {
      demandId,
      trapId: id,
      userId,
      statusDate: moment().toISOString(),
      organizationId,
      wasUnableToRead: false,
      trapWasInstalled: false,
      trapWasRead: false,
      trapWasRemoved: true,
      trapNumber: number,
      wasCollectedSample: false,
      wasInviable: false,
      manuallyChanged: false,
      latitude,
      longitude,
      visitId: "000000000000000000000000",
      georeferencedByAddress: false,
    };

    try {
      const { status } = await postTrapHistory(uninstallHistory);
      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao atualizar os dados da armadilha. Verifique sua conexão com internet e tente novamente. Caso o problema persista, entre em contato com nossa equipe."
        );

      toast.success("Armadilha desinstalada com sucesso!");
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
        console.error(error.message);
      }
    }
  };

  return { handleUninstallTrap };
};

const UninstallTrapModal = ({ isOpen, toggle, trapNumber }) => {
  const { handleUninstallTrap } = useUninstallTrapModal(trapNumber, isOpen);

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader className="pb-0"></ModalHeader>
      <ModalBody className="pt-0 pb-4">
        <span>
          Você tem certeza que deseja desinstalar a armadilha (
          <span style={{ color: "#3b6bca", fontWeight: "bold" }}>
            {trapNumber}
          </span>
          )?
        </span>
      </ModalBody>
      <ModalFooter style={{ borderTop: "1px solid #f0f1f3" }}>
        <Button style={{ width: "130px" }} color="secondary" onClick={toggle}>
          Fechar
        </Button>
        <Button
          style={{ width: "130px" }}
          color="warning"
          onClick={handleUninstallTrap}
        >
          Desinstalar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default UninstallTrapModal;
