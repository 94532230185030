import request from '../utils/request';
//import request from "../utils/requestTests/requestTestSample"

export function fetchAnimalTypeGroups() {
    return request({
      url: 'IdentificationGroups/TypeIdentificationGroup',
      method: 'get',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
    });
  }

export function fetchAnimalGroups(typeGroup) {
  return request({
    url: '/IdentificationGroups/'+ typeGroup,
    method: 'get',
    headers: new Headers({
      'Content-Type': 'application/json'
    }),
  });
}

export function fetchAllAnimalGenres() {
  return request({
    url: '/IdentificationGroups/Genres',
    method: 'get',
    headers: new Headers({
      'Content-Type': 'application/json'
    }),
  });
}

export function fetchAnimalGenres(group) {
    return request({
      url: '/IdentificationGroups/Genres/'+group,
      method: 'get',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
    });
}

export function fetchAnimalSpecies(genre) {
  const cachedData = localStorage.getItem('animalSpeciesCache');
  
  const animalSpeciesCache = cachedData ? JSON.parse(cachedData) : {};

  if (animalSpeciesCache[genre]) {
    return Promise.resolve(animalSpeciesCache[genre]);
  }

  return request({
    url: '/IdentificationGroups/Species/' + genre,
    method: 'get',
    headers: new Headers({
      'Content-Type': 'application/json'
    }),
  }).then(response => {
    animalSpeciesCache[genre] = response;
    
    localStorage.setItem('animalSpeciesCache', JSON.stringify(animalSpeciesCache));

    return response;
  });
}

export function fetchAllAnimalSpecies() {
  return request({
      url: '/IdentificationGroups/Species',
      method: 'get',
      headers: new Headers({
      'Content-Type': 'application/json'
      }),
  });
}

export function fetchGenreVirusType(genre) {
  return request({
      url: '/IdentificationGroups/Virus/'+genre,
      method: 'get',
      headers: new Headers({
      'Content-Type': 'application/json'
      }),
  });
}
