import React from "react";
import { Link } from "react-router-dom";
import "./../../assets/css/navbar.css";

// reactstrap components
import {
    Container,
} from "reactstrap";
import { fetchOrigins } from "../../services/api/Origins";
import { fetchPrograms } from "../../services/api/Program";
import { checkPermissionComponent } from "../../services/utils/permission";
import "./FiltroDemandas.css"
import { fetchEmployees } from "../../services/api/Employee";
import { Select } from "../Wrappers/SelectAll";
import CsvDownloadButton from 'react-json-to-csv'
import { getTeamById } from "../../services/api/Team";

const INITIAL_STATE = {
    programs: [],
    origins: [],
    employees: [],  
    priorities: [
        {label: 'Imediato', value: 1},
        {label: 'Até 24 horas', value: 2},
        {label: 'Até 48 horas', value: 3},
        {label: 'Até 72 horas', value: 4},
        {label: 'Acima 72 horas', value: 5},
        {label: 'Todas as prioridades', value: 6}
    ],
    loadingCsvInfo: false,
    situations: [
        {label: 'Aguardando validação', value: 'Aguardando validação'},
        {label: 'Aguardando Execução', value: 'Aguardando Execução'},
        {label: 'Aguardando finalização', value: 'Aguardando finalização'},
        {label: 'Descartada', value: 'Descartada'},
        {label: 'Finalizada', value: 'Finalizada'}
    ],
    filterOs:"",
    filterSolicitor:"",
    filterNameEmployee:"",
    filterProgram:"",
    filterOrigin:"",
    filterPriority:6,
    filterSituation: ""
}
class FiltroDemandas extends React.Component {

    state = {
        ...INITIAL_STATE,
        dataDemand: [],
    }

    resetFilter = () => { 
        this.setState({...INITIAL_STATE});
    }

    componentDidMount() {
        //select de origens        
        fetchOrigins().then(({data}) => {
            this.setState({origins: data.map(({name}) => ({label: name, value: name}))});
        });

        //select de programas
        fetchPrograms().then(({data}) => {
            this.setState({programs: data.map(({id, name}) => ({label: name, value: id}))})
        })

        //select de employees
        fetchEmployees().then(({data}) => {
            this.setState({employees: data.map(({name}) => ({label: name, value: name}))})
        })
        
    }

    async componentDidUpdate(prevProps){
        if(prevProps.Demands != this.props.Demands){
            this.setState({loadingCsvInfo: true})
            this.criarObj(this.props.Demands).then((response)=> {
                this.setState({loadingCsvInfo: false, dataDemand: response})
            });
        }
    }

    handleChange = e => {
        let {name,value} = e.target;
        this.setState({
            [name]: value
        });
    };

    filterProgram= Demand =>{
        var test = false;
        Demand.problems.map((problem) => {
            var test2 = problem.program.id.indexOf(this.state.filterProgram) !== -1;
            if(test2 !== test && !test){
                test = !test;
            }
        });
        return test;
    }

    criarObj = async (demands) => {
        const demandsReturn = await Promise.all(demands.map(async (demandObj) => {
            const territorializacaoString = demandObj.territorializations.map((terr) => terr.name).join(', ');
    
            const programs = demandObj.problems.map((pgm) => ({
                program: pgm.program.name,
                service: pgm.service.name
            }));
    
            var executor = [];
            var coordinator = [];
            var comissioner = [];
            const teamsString = await Promise.all(demandObj.teams.map(async (tm) => {
                try {
                    const response = await getTeamById(tm.id);
                    response.data.result.members.map((member) => {
                        if(member.isExecutor == true){
                            executor.push(member.name)
                        }else if(member.isCoordinator == true){
                            coordinator.push(member.name)
                        }else if(member.isComissioner == true){
                            comissioner.push(member.name)
                        }
                    })
                    return tm.name;
                } catch (error) {
                    console.error("Error fetching team:", error);
                    return "Erro na obtenção de dados da equipe";
                }
            }));
    
            const demandObject = {
                "Data Criação Demanda": demandObj.createDate,
                "Tipo demanda": demandObj.type,
                "Número demanda": demandObj.serviceOrder,
                "Descrição": demandObj.description,
                "Territorializações": territorializacaoString,
                "Origem": demandObj.originName,
                "Programas": programs.map(p => p.program).join(', '),
                "Necessidade": demandObj.name,
                "Serviço": programs.map(p => p.service).join(', '), // Adicionei esta linha para criar a string de serviços
                "Início": demandObj.startDate,
                "Equipe responsável": teamsString.join(', '),
                "Executores": executor.join(', '),
                "Encarregado da equipe": comissioner.join(', '),
                "Coordenador da equipe": coordinator.join(', '),
                "Pessoa que validou a demanda." : demandObj.attendant,
                "Data da validação." : demandObj.aprovedDate,
            };
            return demandObject;
        }));
    
        return demandsReturn;
    };
    

    filterPriority=Demand =>{
        var test = false;
        Demand.problems.map((problem) => {
            var test2 = problem.priority == this.state.filterPriority || this.state.filterPriority == 6;
            if(test2 !== test && !test){
                test = !test;
            }
        });

        return test;
    }
    render() {
        let filteredDemands2 = this.props.Demands;
        let filteredDemands = filteredDemands2.filter(
            (Demand)=>{
                return Demand.situation.indexOf(this.state.filterSituation) !== -1 &&               
                Demand.serviceOrder.indexOf(this.state.filterOs) !== -1 &&
                Demand.originName.indexOf(this.state.filterOrigin) !== -1 &&
                Demand.attendant.toLowerCase().indexOf(this.state.filterNameEmployee.toLowerCase()) !== -1 &&
                this.filterProgram(Demand) &&
                this.filterPriority(Demand);
            }
        );

        return (
            <div className="header bg-gradient-info pb-8 pt-5 pt-md-8" >
                <Container fluid>
                    <div className="header-body">

                        <div className="row linha-form">
                            <div className="col-4 responsive-filter-demanda">
                                <input type="number" name={"filterOs"} onChange={this.handleChange} className="form-control" placeholder="N&uacute;mero OS" />
                            </div>
                            <div className="col-4 responsive-filter-demanda">
                                <input type="text"name={"filterSolicitor"} onChange={this.handleChange} className="form-control" placeholder="Solicitante" />
                            </div>
                            <div className="col-4 responsive-filter-demanda">
                                <Select
                                    options={this.state.employees}
                                    value={this.state.filterNameEmployee ? this.state.employees.filter(({value}) => value === this.state.filterNameEmployee) : []}
                                    onChange={(employee) => {this.setState({filterNameEmployee: employee !== null ? employee.value : ''})}}
                                    isClearable
                                    placeholder={'Responsável'}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-4 responsive-filter-demanda">
                                <Select
                                    options={this.state.programs}
                                    value={this.state.filterProgram ? this.state.programs.filter(({value}) => value === this.state.filterProgram) : []}
                                    onChange={(program) => {this.setState({filterProgram: program !== null ? program.value : ''})}}
                                    isClearable
                                    placeholder={'Todos os programas'}
                                />
                            </div>
                            <div className="col-4 responsive-filter-demanda">
                                <Select
                                    options={this.state.origins}
                                    value={this.state.filterOrigin ? this.state.origins.filter(({value}) => value === this.state.filterOrigin) : []}
                                    onChange={(origin) => {this.setState({filterOrigin: origin !== null ? origin.value : ''})}}
                                    isClearable
                                    placeholder={'Todos as origens'}
                                />
                            </div>

                            <div className="linha-form col-4 responsive-filter-demanda">
                                <Select
                                    options={this.state.priorities}
                                    value={this.state.filterPriority ? this.state.priorities.filter(({value}) => value === this.state.filterPriority) : []}
                                    onChange={(priority) => {this.setState({filterPriority: priority !== null ? priority.value : 6})}}
                                />
                            </div>

                            <div className="linha-form col-4 responsive-filter-demanda">
                                <Select
                                    options={this.state.situations}
                                    value={this.state.filterSituation ? this.state.situations.filter(({value}) => value === this.state.filterSituation) : []}
                                    onChange={(situation) => {this.setState({filterSituation: situation !== null ? situation.value : 6})}}
                                    placeholder="Todas as situações"
                                />
                            </div>
                        </div>

                        <div className="row row-gap responsive-container-buttons">
                            <div className="col-4 responsive-filter-button">
                                <button className="btn btn-primary" onClick={()=> this.props.parentCallback(filteredDemands)}> <i className="fa fa-search" /> Pesquisar</button>
                            </div>
                            <div className="col-4 responsive-filter-button">
                                <button onClick={()=>this.resetFilter()} className="form-control"> <i className="fa fa-eraser" /> Limpar filtros</button>
                            </div>
                            {checkPermissionComponent("da149579-6cd3-425c-bdfa-04e968d44a69", "create") ? 
                                <div className="col-4 responsive-filter-demanda">
                                    <Link to="/admin/AddDemand">
                                        <button className="float-right btn btn-default"> <i className="fa fa-calendar" /> Cadastrar demanda</button>
                                    </Link>
                                </div>
                            :null}
                            <div className="linha-form col-4 responsive-filter-button"></div>
                            <div className="linha-form col-4 responsive-filter-button">
                                {
                                this.state.loadingCsvInfo
                                ?
                                <button className="float-right btn btn-default">Carregando...</button>
                                :
                                <CsvDownloadButton className="btn btn-primary" data={this.state.dataDemand} filename={'demandas_arquivo'}> <i className="fa fa-download" /> Baixar CSV de Demandas</CsvDownloadButton>
                                }
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        );

    }

}

export default FiltroDemandas;
