import React from "react";

import ReactTooltip from "react-tooltip";
import { Line } from "recharts";
import LineChartGraphic from "../../../../../components/Graphics/Line/LineChartGraphic";
import { Button } from "reactstrap";
import { ARMADILHA_INFO } from "../../../../../constants/ArmadilhaConstant";

export const PositivityIndexLineByWeekGraphic = ({
  data,
  headerText,
  positiveFor,
  positivityIndexName,
  setGraphicType,
  tooltipPositivityPropertyName,
  trapTypeName,
}) => {
  if (!data || data.length === 0 || !trapTypeName || trapTypeName === "")
    return null;

  return (
    <LineChartGraphic
      brushBar={true}
      customTooltip={
        <PositivityGraphicTooltip
          positivityIndexName={positivityIndexName}
          tooltipPositivityPropertyName={tooltipPositivityPropertyName}
        />
      }
      customYAxisDomain={[0, 100]}
      data={data}
      footerText={
        <span style={{ fontSize: "12px", opacity: "0.8" }}>
          Cálculo do {positivityIndexName}: (
          <span
            data-tip
            data-for="trapsWithExuvia"
            style={{ textDecoration: "underline", fontWeight: "bold" }}
          >
            Nº Armadilhas com {positiveFor}
          </span>
          <ReactTooltip effect="solid" type="info" id="trapsWithExuvia">
            Armadilhas {ARMADILHA_INFO[trapTypeName].apelido}'s lidas com Nº com
            {positiveFor} DIFERENTE de 0 (zero)
          </ReactTooltip>
          {" / "}
          <span
            data-tip
            data-for="vistoriedTrapsExuvia"
            style={{ textDecoration: "underline", fontWeight: "bold" }}
          >
            Nº Armadilhas Diagnosticadas
          </span>
          <ReactTooltip effect="solid" type="info" id="vistoriedTrapsExuvia">
            Armadilhas {ARMADILHA_INFO[trapTypeName].apelido}'s lidas
          </ReactTooltip>
          ) * 100
        </span>
      }
      headerText={headerText}
      headerContent={
        <>
          <Button
            onClick={() => setGraphicType("line")}
            color="primary"
            data-tip
            data-for="exchangeGraphicType2"
            style={{ width: "55px", marginRight: "0px" }}
          >
            <i>
              <img
                src={require("../../../../../assets/img/icons/interface/exchange-alt.png")}
                style={{ width: "13px", height: "13px" }}
                alt="icone"
              />
            </i>
          </Button>
          <ReactTooltip
            id="exchangeGraphicType2"
            type="info"
            place="bottom"
            effect="solid"
          >
            Trocar visualização
          </ReactTooltip>
        </>
      }
      hideDownloadButton={true}
      lines={[
        <Line type="monotone" dataKey={positivityIndexName} stroke="#ff0000" />,
      ]}
      xAxisDataKey={"periodLabel"}
    />
  );
};

const PositivityGraphicTooltip = ({
  active,
  payload,
  positivityIndexName,
  tooltipPositivityPropertyName,
}) => {
  if (active && payload && payload.length) {
    const payloadData = payload[0].payload;

    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#fff",
          border: "1px solid #ccc",
          padding: "10px",
        }}
      >
        <p className="label">{payloadData["periodLabel"]}</p>
        <p className="label" style={{ fontWeight: "bold" }}>
          {positivityIndexName}: {payloadData[positivityIndexName]}
        </p>
        <p className="label">
          Armadilhas diagnosticadas: {payloadData.diagnosedTraps}
        </p>
        <p className="label">
          Armadilhas positivas: {payloadData[tooltipPositivityPropertyName]}
        </p>
        <p className="label">
          Armadilhas negativas:{" "}       
          {payloadData.diagnosedTraps -
            payloadData[tooltipPositivityPropertyName]}
        </p>
      </div>
    );
  }

  return null;
};
