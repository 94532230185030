import React from "react";

import DataTable from "react-data-table-component";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardText,
  Button,
  CardBody,
} from "reactstrap";
import CsvDownloadButton from "react-json-to-csv";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import { toPng } from "html-to-image";
import { toast } from "react-toastify";
import { calculateMinutesDifference } from "../../../../services/utils/format";

const ProductivityVisitsTable = ({
  visitsData,
  employees,
  productivityFilters,
}) => {
  const [formatedVisits, setFormatedVisits] = React.useState([]);
  const [generatedAt, setGeneratedAt] = React.useState("");

  const contentToPrintRef = React.useRef(null);

  React.useEffect(() => {
    if (!visitsData || visitsData.length === 0) return;

    const splitedActualDate = moment().format("DD/MM/YYYY HH:mm").split(" ");

    setGeneratedAt(`${splitedActualDate[0]} às ${splitedActualDate[1]}`);

    const visits = visitsData;
    const formatedVisitsResult = [];

    visits.forEach(({ situation, visitsData }) => {
      visitsData.forEach(
        ({
          activityDate,
          address,
          collectorId,
          finalTime,
          initialTime,
          installedTrapsNumbers,
          latitude,
          longitude,
          readTrapsNumbers,
          uninstalledTrapsNumbers,
        }) => {
          const result = {
            SITUACAO_VISITA: situation,
            DATA_VISITA: formatDateInUTC(activityDate).split(" ")[0],
            FUNCIONARIO: getUserName(collectorId),
            TIPO_IMOVEL: address.tipoImovel,
            ENDERECO: `${address.localDoImovel} ${address.logradouro}, ${address.numero}`,
            LATITUDE: latitude,
            LONGITIDE: longitude,
            HORA_INICIO: formatDateInUTC(initialTime, "HH:mm"),
            HORA_TERMINO: formatDateInUTC(finalTime, "HH:mm"),
            TEMPO_GASTO: calculateMinutesDifference(initialTime, finalTime),
            NUMERO_INSTALACOES: !installedTrapsNumbers
              ? 0
              : installedTrapsNumbers.length,
            NUMERACAO_DAS_ARMADILHAS_INSTALADAS:
              !installedTrapsNumbers || installedTrapsNumbers.length === 0
                ? "Não foram instaladas armadilhas"
                : installedTrapsNumbers.join(", "),
            NUMERO_MANUTENCOES: !readTrapsNumbers ? 0 : readTrapsNumbers.length,
            NUMERACAO_DAS_ARMADILHAS_LIDAS:
              !readTrapsNumbers || readTrapsNumbers.length === 0
                ? "Não foram lidas armadilhas"
                : readTrapsNumbers.join(", "),
            NUMERO_DESINSTALACOES: !uninstalledTrapsNumbers
              ? 0
              : uninstalledTrapsNumbers.length,
            NUMERACAO_DAS_ARMADILHAS_DESINSTALADAS:
              !uninstalledTrapsNumbers || uninstalledTrapsNumbers.length === 0
                ? "Não foram desinstaladas armadilhas"
                : uninstalledTrapsNumbers.join(", "),
          };

          formatedVisitsResult.push(result);
        }
      );
    });

    setFormatedVisits(formatedVisitsResult);
  }, [visitsData]);

  const formatDateInUTC = (date, format = "DD/MM/YYYY HH:mm") => {
    return date ? moment(date).add(3, "hours").format(format) : "-";
  };

  const getUserName = (id) => {
    if (
      id === "00000000-0000-0000-0000-000000000000" ||
      id === null ||
      id === undefined ||
      !employees ||
      employees.length === 0
    )
      return "Usuário não encontrado";

    if (id === "5a868c02-7a8d-47a0-b1b1-bb55383ad55d") return "Não informado";

    const userData = employees.find(
      (employee) => employee.employeeId === id || employee.userId === id
    );

    return userData ? userData.employeeName : "Usuário não encontrado";
  };

  const handleDownloadGraphicImage = async () => {
    try {
      if (contentToPrintRef.current === null) return;

      const imageToDownloadString = await toPng(contentToPrintRef.current, {
        quality: 1,
        pixelRatio: 2,
      });

      if (!imageToDownloadString || imageToDownloadString === "")
        throw new Error("Ocorreu um erro ao gerar a imagem do gráfico.");

      const link = document.createElement("a");
      link.download = `VisitasGeneratedAt${generatedAt}.png`;
      link.href = imageToDownloadString;
      link.click();
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
        toast.error(error.message);
      }
    }
  };

  const tableColumns = [
    {
      name: "Situação",
      selector: "SITUACAO_VISITA",
      sortable: true,
    },
    {
      name: "Data da visita",
      selector: "DATA_VISITA",
      sortable: true,
    },
    {
      name: "Funcionário",
      selector: "FUNCIONARIO",
      sortable: true,
    },
    {
      name: "Tipo de imóvel",
      selector: "TIPO_IMOVEL",
      sortable: true,
    },
    {
      name: "Endereço",
      selector: "ENDERECO",
      sortable: true,
      grow: 2,
    },
    {
      name: "Hora de início",
      selector: "HORA_INICIO",
      sortable: true,
    },
    {
      name: "Hora de término",
      selector: "HORA_TERMINO",
      sortable: true,
    },
    {
      name: "Tempo gasto (Minutos)",
      selector: "TEMPO_GASTO",
      sortable: true,
    },
    {
      name: "Número de instalações",
      selector: "NUMERO_INSTALACOES",
      sortable: true,
    },
    {
      name: "Número de manutenções",
      selector: "NUMERO_MANUTENCOES",
      sortable: true,
    },
    {
      name: "Número de desinstalações",
      selector: "NUMERO_DESINSTALACOES",
      sortable: true,
    },
  ];

  const style = {
    col: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "25rem",
      marginTop: "0.7rem",
    },
    cardBody: {
      padding: "1rem",
    },
    cardHeaderText: {
      color: "black",
      fontWeight: "bold",
      fontSize: "0.97rem",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  };

  const beginDateFormated =
    productivityFilters &&
    productivityFilters.beginDate &&
    moment(productivityFilters.beginDate).format("DD/MM/YYYY");
  const finalDateFormated =
    productivityFilters &&
    productivityFilters.finalDate &&
    moment(productivityFilters.finalDate).format("DD/MM/YYYY");

  return (
    <div ref={contentToPrintRef}>
      <Row className="mt-12" style={{ marginBottom: "1.5rem" }}>
        <Col className="mb-12 mb-xl-0" xl="12">
          <Card className="shadow">
            <CardHeader>
              <CardText className="mb-0 mt-0" style={style.cardHeaderText}>
                <div>
                  <span>
                    Visitas em {beginDateFormated} a {finalDateFormated}
                  </span>
                </div>
                <div
                  style={{ display: "flex", gap: "5px", alignItems: "center" }}
                >
                  <span style={{ fontSize: "13px", fontWeight: "normal" }}>
                    Gerado em {generatedAt}
                  </span>
                  <span>
                    <CsvDownloadButton
                      className="btn btn-primary"
                      data={formatedVisits}
                      filename={`VisitasGeneratedAt${generatedAt}`}
                      style={{ width: "55px", marginRight: "0px" }}
                      data-tip
                      data-for="dataDownload"
                    >
                      <i className="fa fa-download" />
                    </CsvDownloadButton>
                    <ReactTooltip effect="solid" type="info" id="dataDownload">
                      Baixar dados
                    </ReactTooltip>
                  </span>
                  <span>
                    <Button
                      onClick={() => handleDownloadGraphicImage()}
                      color="primary"
                      data-tip
                      data-for="viewDownload"
                      style={{ width: "55px", marginRight: "0px" }}
                    >
                      <i className={"fa fa-image"} />
                    </Button>
                    <ReactTooltip effect="solid" type="info" id="viewDownload">
                      Baixar visualização
                    </ReactTooltip>
                  </span>
                </div>
              </CardText>
            </CardHeader>
            <CardBody style={style.cardBody}>
              <Row>
                <Col>
                  <DataTable
                    noHeader
                    defaultSortField="name"
                    defaultSortAsc={true}
                    pagination
                    highlightOnHover
                    responsive
                    columns={tableColumns}
                    data={formatedVisits}
                    noDataComponent={"Nenhum registro encontrado."}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ProductivityVisitsTable;
