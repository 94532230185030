import React from "react";
import { Select } from "../Wrappers/SelectAll";
import { selectComponentStyles } from "../../services/utils/globalFunctions";

const TrapTypesFilter = ({
  selectedTrapType,
  handleFiltersChange,
  trapTypesList,
}) => {
  return (
    <div>
      <span className="h4 text-white">Tipo de armadilha</span>
      <Select
        styles={selectComponentStyles}
        placeholder={"Selecione"}
        isClearable={true}
        value={selectedTrapType}
        options={trapTypesList}
        onChange={(e) =>
          handleFiltersChange(
            "trapType",
            !e
              ? {
                  label: "Selecione",
                  value: "",
                }
              : e
          )
        }
        isDisabled={trapTypesList.length === 0}
      />
    </div>
  );
};

export default TrapTypesFilter;
