import React from "react";

import { createContext } from "use-context-selector";
import {
  ACTIVE_TRAP_STATUS,
  generateTrapGroupsPointsList,
  GREEN_TRAP_STATUS,
  RED_TRAP_STATUS,
  YELLOW_TRAP_STATUS,
} from "../../../../services/utils/globalFunctions";
import moment from "moment";
import { getUserData } from "../../../../services/utils/auth";
import { fetchFilteredTraps } from "../../../../services/api/Trap";
import { toast } from "react-toastify";
import { getTrapRange } from "../../../../constants/RangeTrapConstant";

const MapContext = createContext(null);

const DEFAULT_TRAPTYPES_RANGE_RADIUS_IN_METERS = {
  //ADI
  armadilhaDisseminadoraInseticida: 12,
  //ADULTRAP
  armadilhaMosquitoAdulto: 200,
  //BG-GAT
  armadilhaMosquitoAdulto2: 12,
  //OVITRAMPA
  armadilhaOvos: 200,
};

const MapContextProvider = ({ children }) => {
  const [filters, setFilters] = React.useState({
    trapNumber: "",
    pointsCluster: true,
  });
  const [filteredGroupsPointsList, setFilteredgroupsPointsList] =
    React.useState([]);
  const [isPolygonPopupOpen, setIsPolygonPopupOpen] = React.useState(false);
  const [polygonPopupData, setPolygonPopupData] = React.useState(null);
  const [trapsListToShowRange, setTrapsListToShowRange] = React.useState([]);
  const [removePointsCluster, setRemovePointsCluster] = React.useState(false);
  const [trapTypesRangesRadius, setTrapTypesRangesRadius] = React.useState(
    DEFAULT_TRAPTYPES_RANGE_RADIUS_IN_METERS
  );

  const fetchTrapsListData = React.useCallback(
    async (filters, trapTypesList) => {
      {
        if (
          filters.trapNumber === "" &&
          filters.green &&
          filters.yellow &&
          filters.red &&
          filteredGroupsPointsList.length > 0
        ) {
          setFilteredgroupsPointsList([]);

          return;
        }

        const trapsRangesToSend = trapTypesList.map(
          ({ trapTypeName, value }) => {
            const { quantityRange, data } = getTrapRange(trapTypeName);

            return {
              trapTypeId: value,
              quantity: quantityRange,
              begin: data.beggining,
              middle: !data.middle ? null : data.middle,
              end: data.end,
            };
          }
        );

        const filtersToSend = {
          colorStatuses: [
            GREEN_TRAP_STATUS,
            YELLOW_TRAP_STATUS,
            RED_TRAP_STATUS,
          ],
          demandsIds: [],
          finalDate: moment
            .utc()
            .set({ hour: 23, minute: 59, second: 59 })
            .toISOString(),
          organizationId: getUserData("organizationId"),
          ranges: trapsRangesToSend,
          statuses: [ACTIVE_TRAP_STATUS],
          territorializationsIds: [],
          trapsNumbers: [filters.trapNumber],
          trapTypesIds: [],
          usersIds: [],
        };

        try {
          const { data, status } = await fetchFilteredTraps(filtersToSend);

          if (status !== 200)
            throw new Error(
              "Ocorreu um erro ao buscar pelas armadilhas. Verifique sua conexão com internet e tente novamente. Caso o problema persista, entre em contato com nossa equipe."
            );

          const groupsPoints = generateTrapGroupsPointsList(
            trapTypesList,
            data
          );

          setFilteredgroupsPointsList(groupsPoints);
        } catch (error) {
          if (error instanceof Error) {
            toast.error(error.message);
            console.error(error.message);
          }
        } finally {
        }
      }
    },
    []
  );

  const handleFiltersChange = React.useCallback((filterName, newValue) => {
    setFilters((previousValue) => {
      return {
        ...previousValue,
        [filterName]: newValue,
      };
    });
  }, []);

  const hadleTrapTypesRangesRadiusChange = React.useCallback((trapTypeName, value) => {
    setTrapTypesRangesRadius((previousValue) => {
      return {
        ...previousValue,
        [trapTypeName]: value,
      };
    });
  }, []);

  const togglePolygonPopup = React.useCallback((polygon) => {
    setIsPolygonPopupOpen((previousValue) => {
      if (!previousValue) setPolygonPopupData(polygon);

      return !previousValue;
    });
  }, []);


  return (
    <MapContext.Provider
      value={{
        removePointsCluster,
        setRemovePointsCluster,
        filters,
        setFilters,
        handleFiltersChange,
        fetchTrapsListData,
        filteredGroupsPointsList,
        isPolygonPopupOpen,
        polygonPopupData,
        togglePolygonPopup,
        trapsListToShowRange,
        setTrapsListToShowRange,
        trapTypesRangesRadius,
        hadleTrapTypesRangesRadiusChange,
      }}
    >
      {children}
    </MapContext.Provider>
  );
};

export { MapContextProvider, MapContext };
