
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { isAuth } from './services/utils/auth';

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

import AdminLayout from "layouts/Admin.jsx";
import AuthLayout from "layouts/Auth.jsx";

import routes from "routes.js";
import { OrganizationContextProvider } from "./contexts/organizationContext";

const PrivateRoute = ({ component: Component, ...rest }) => (

  <Route {...rest} render={props => isAuth() ? (
    <AdminLayout {...props} />
  ) : (
       <Redirect to={{ pathname: "/auth/login", state: { from: props.location } }} />
    )}
  />
);

const getRoutes = routes => {
  return routes.map((prop, key) => {
    if (prop.layout === "/admin" ) {
      return (
        <PrivateRoute key={key} path={prop.layout + prop.path} component={prop.component} />
      );
    } else {
      return null;
    }
  });
};

ReactDOM.render(

  <BrowserRouter>
    <Switch>
      {/* <Route path="/admin" render={props => <AdminLayout {...props} />} />
      <Route path="/auth" render={props => <AuthLayout {...props} />} />
      <Redirect from="/" to="/admin/index" /> */}

      <OrganizationContextProvider>
        <Route path="/auth/login" render={props => <AuthLayout {...props} />} />
        <Route path="/auth/changePassword" render={props => <AuthLayout {...props} />} />
        <Route path="/auth/acesso" render={props => <AuthLayout {...props} />} />
        {getRoutes(routes)}
      </OrganizationContextProvider>
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")

);
