import { useContextSelector } from "use-context-selector";
import { OrganizationContext } from "../contexts/organizationContext";

const useEmployeesList = () => {
  const employeesList = useContextSelector(
    OrganizationContext,
    (context) => context.employeesList
  );
  const setEmployeesList = useContextSelector(
    OrganizationContext,
    (context) => context.setEmployeesList
  );

  return { employeesList, setEmployeesList };
};

export default useEmployeesList;
