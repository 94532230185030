import React from "react";

import { ToastContainer } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Row,
} from "reactstrap";
import ImportTrapHistoriesModal from "./ImportTrapHistories/ImportTrapHistoriesModal";
import SincronizationLogsModal from "./SincronizationLogs/SincronizationLogsModal";

const ToolsPage = () => {
  const [openedModals, setOpenedModals] = React.useState({
    importTrapHistoriesTool: false,
    installTrapsTool: false,
    importUsersTool: false,
    createReplicaTool: false,
    verifySyncLogsTool: false,
  });

  const handleOpenModal = (pageName) => {
    setOpenedModals((previousValue) => {
      const newValues = Object.keys(previousValue).reduce((acc, key) => {
        acc[key] = false;

        return acc;
      }, {});

      newValues[pageName] = true;

      return newValues;
    });
  };

  const handleToggleModal = (pageName) => {
    setOpenedModals((previousValue) => {
      const newValues = { ...previousValue };

      newValues[pageName] = !newValues[pageName];

      return newValues;
    });
  };

  const toolsToRenderInformations = [
    /* {
      title: "Criação de réplica",
      description: "Ferramenta para criar réplicas.",
      toolName: "createReplicaTool",
    },
    {
      title: "Instalar armadilhas",
      description: "Ferramenta para importação de instalações de armadilhas.",
      toolName: "installTrapsTool",
    },
    {
      title: "Importar usuários",
      description: "Ferramenta para importação de usuários.",
      toolName: "importUsersTool",
    }, */
    {
      title: "Importar históricos",
      description:
        "Ferramenta para importação de históricos de armadilhas. Pode ser utilizada, por exemplo, em casos de problemas de sincronização com o app. Somente funciona com armadilhas previamente instaldas.",
      toolName: "importTrapHistoriesTool",
    },
    {
      title: "Verificar logs de sincronização",
      description:
        "Ferramenta para verificar logs de sincronização do aplicativo.",
      toolName: "verifySyncLogsTool",
    },
  ];

  const styles = {
    cardBody: {
      height: "190px",
      backgroundColor: "#c4c4c4",
      borderTopLeftRadius: "5px",
      borderTopRightRadius: "5px",
    },
    cardFooter: {
      display: "flex",
      justifyContent: "end",
      borderTop: "0px",
      paddingTop: "0px",
    },
  };

  return (
    <>
      <ImportTrapHistoriesModal
        isOpen={openedModals.importTrapHistoriesTool}
        toggleModal={handleToggleModal}
      />
      {/* Está quebrando a tela por que não foi atualizado o GetAll de Organizations. Comentado para evitar a fadiga */}
      {/* <SincronizationLogsModal
        isOpen={openedModals.verifySyncLogsTool}
        toggleModal={handleToggleModal}
      /> */}
      <main>
        <ToastContainer />
        <Container fluid className="bg-gradient-info pt-5 pb-2 mb-5">
          <div style={{ paddingTop: "10px !important" }}>&nbsp;</div>
        </Container>
        <Container fluid className="pb-2 mt-2">
          <Row className="mt-12 mb-12">
            {toolsToRenderInformations &&
              toolsToRenderInformations.length > 0 &&
              toolsToRenderInformations.map(
                ({ title, description, toolName }) => (
                  <Col className="mb-6 mb-xl-0" xl="3">
                    <Card style={{ height: "100%" }}>
                      <CardBody style={{ padding: "0px" }}>
                        <div style={styles.cardBody}></div>
                        <div style={{ padding: "1.25rem 1.5rem" }}>
                          <p className="h2">{title}</p>
                          <span style={{ fontSize: "14px" }}>
                            {description}
                          </span>
                        </div>
                      </CardBody>
                      <CardFooter style={styles.cardFooter}>
                        <Button
                          color="primary"
                          style={{ width: "130px" }}
                          onClick={() => handleOpenModal(toolName)}
                        >
                          Utilizar
                        </Button>
                      </CardFooter>
                    </Card>
                  </Col>
                )
              )}
          </Row>
        </Container>
      </main>
    </>
  );
};

export default ToolsPage;
