import React from "react";
import { Select } from "../Wrappers/SelectAll";
import { selectComponentStyles } from "../../services/utils/globalFunctions";

const UsersFilter = ({ handleFiltersChange, selectedUsers, usersList }) => {
  return (
    <div>
      <span className="h4 text-white">Usuários</span>
      <Select
        styles={selectComponentStyles}
        isMulti={true}
        placeholder={"Selecione"}
        isClearable={true}
        options={usersList}
        value={selectedUsers}
        onChange={(e) =>
          handleFiltersChange("employees", !e || e.length === 0 ? [] : e)
        }
        blurInputOnSelect={false}
        closeMenuOnSelect={false}
        menuPortalTarget={document.body}
        isDisabled={usersList.length === 0}
      />
    </div>
  );
};

export default UsersFilter;
