import React from "react";

import { Button, Col, Row } from "reactstrap";
import useDemandsList from "../../../../hooks/useDemandsList";
import useTeamsList from "../../../../hooks/useTeamsList";
import useEmployeesList from "../../../../hooks/useEmployeesList";
import useTerritorializationsList from "../../../../hooks/useTerritorializationsList";
import useTypeLayersList from "../../../../hooks/useTypeLayersList";
import moment from "moment";
import useTrapTypesList from "../../../../hooks/useTrapTypesList";
import { getTodayEpidemiologicalWeek } from "../../../../services/utils/todayEpidemiologicalWeek";
import DemandsFilter from "../../../../components/Filters/DemandsFilter";
import TeamsList from "../../../../components/Filters/TeamsList";
import UsersFilter from "../../../../components/Filters/UsersFilter";
import TrapTypesFilter from "../../../../components/Filters/TrapTypesFilter";
import TerritorializationsFilter from "../../../../components/Filters/TerritorializationsFilter";
import TypeLayersFilter from "../../../../components/Filters/TypeLayersFilter";
import EpidemiologicWeeksPeriodsFilter from "../../../../components/Filters/EpidemiologicWeeksPeriodsFilter";
import YearsFilter from "../../../../components/Filters/YearsFilter";
import ManualEpidemiologicWeeksFilter from "../../../../components/Filters/ManualEpidemiologicWeeksFilter";
import { getUserData } from "../../../../services/utils/auth";
import { getEntomologicFieldResultsByFilters } from "../../../../services/api/VigilanciaEntomologica";
import { toast } from "react-toastify";
import LoadingSpin from "react-loading-spin";
import useGraphicsData from "../Hooks/useGraphicsData";
import useAppliedFilters from "../Hooks/useAppliedFilters";
import getWeeks from "../../../../services/utils/epidemiologicalWeek";

const DEFAULT_FILTERS = {
  beginDate: moment().subtract(30, "days").format("YYYY-MM-DD"),
  demand: {
    label: "Todas",
    value: "",
  },
  epidemiologicalWeeks: [],
  epidemiologicalWeekBegin: {
    label: "Selecione",
    value: "",
  },
  epidemiologicalWeekEnd: {
    label: "Selecione",
    value: "",
  },
  employees: [],
  endDate: moment().format("YYYY-MM-DD"),
  team: {
    label: "Todos",
    members: [],
    value: "",
  },
  territorializations: [],
  trapType: {
    label: "Selecione",
    trapTypeName: "",
    value: "",
  },
  typeLayers: [],
  year: {
    label: "Selecione",
    value: "",
  },
};

const useFieldResulstPageFilters = () => {
  const [filters, setFilters] = React.useState(DEFAULT_FILTERS);
  const [isFieldResultsLoading, setIsFieldResultsLoading] =
    React.useState(false);
  const { demandsList } = useDemandsList();
  const { employeesList } = useEmployeesList();
  const { setAppliedFilters } = useAppliedFilters();
  const { setFieldResultsList } = useGraphicsData();
  const { teamsList } = useTeamsList();
  const { territorializationsList } = useTerritorializationsList();
  const { trapTypesList } = useTrapTypesList();
  const { typeLayersList } = useTypeLayersList();

  const fetchAllComponentsData = async () => {
    if (filters.trapType.value === "") {
      toast.error("Selecione um tipo de armadilha.");

      return;
    }

    if (filters.year.value === "") {
      toast.error("Selecione um ano.");
      return;
    }

    if (filters.epidemiologicalWeeks.length === 0) {
      toast.error("Selecione ao menos uma semana epidemiológica.");
      return;
    }

    setIsFieldResultsLoading(true);
    setFieldResultsList([]);
    setAppliedFilters(filters);

    const DEFAULT_ERROR_MESSAGE =
      "Ocorreu um erro ao buscar pelos resultados de campo. Verifique sua conexão com a internet e tente novamente. Caso o problema persista, entre em contato com a nossa equipe.";

    const filtersToSend = {
      demandsIds: [],
      organizationId: getUserData("organizationId"),
      periods: filters.epidemiologicalWeeks.map(
        ({ beginDate, endDate, label }) => ({
          beginDate: moment(beginDate, "DD/MM/YYYY")
            .utc()
            .set({ hour: 0, minute: 0, second: 0 })
            .toISOString(),
          finalDate: moment(endDate, "DD/MM/YYYY")
            .utc()
            .set({ hour: 23, minute: 59, second: 59 })
            .toISOString(),
          label,
        })
      ),
      territorializationsIds: [],
      trapTypeName: filters.trapType.trapTypeName,
      usersIds: [],
    };

    try {
      const { data, status } = await getEntomologicFieldResultsByFilters(
        filtersToSend
      );

      if (status !== 200) {
        toast.error(DEFAULT_ERROR_MESSAGE);

        return;
      }

      setFieldResultsList(data);
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
      }
    } finally {
      setIsFieldResultsLoading(false);
    }
  };

  const handleFiltersChange = React.useCallback((filterName, newValue) => {
    setFilters((previousValues) => {
      const newValues = { ...previousValues };
      newValues[filterName] = newValue;

      return newValues;
    });
  }, []);

  const handleEpidemiologicWeeksPeriodsFilterChange = React.useCallback(
    (filterName, newValue) => {
      setFilters((previousValues) => {
        const newValues = { ...previousValues };

        if(filterName === "epidemiologicalWeekBegin" && newValue.value === "") {
          newValues.epidemiologicalWeekBegin = newValue;
          newValues.epidemiologicalWeekEnd = newValue;

          return newValues;
        }

        if (
          filterName === "epidemiologicalWeekEnd" &&
          newValue.value !== "" &&
          newValues.epidemiologicalWeekBegin.value !== ""
        ) {
          const beginWeekNumber =
            newValues.epidemiologicalWeekBegin.value !== ""
              ? +newValues.epidemiologicalWeekBegin.label
                  .replace("Semana", "")
                  .trim()
              : "";

          const endWeekNumber =
            newValue.value !== ""
              ? +newValue.label.replace("Semana", "").trim()
              : "";

          if (endWeekNumber < beginWeekNumber) {
            toast.error(
              "A semana epidemiológica final deve ser igual ou maior que a inicial."
            );

            return newValues;
          }

          const epidemiologicalWeeksFromYear = getWeeks().find(
            ({ year }) => year === filters.year.label
          ).weeks;

          const weeksInBetween = epidemiologicalWeeksFromYear.slice(
            beginWeekNumber - 1,
            endWeekNumber
          ).map(({endDate, ...rest}) => ({
            ...rest,
            endDate,
            value: endDate,
          }));

          newValues.epidemiologicalWeeks = weeksInBetween;
          newValues[filterName] = newValue;

          console.log(newValues);


          return newValues;
        }

        newValues[filterName] = newValue;

        return newValues;
      });
    },
    [filters]
  );

  const handleTypeLayerChange = React.useCallback((value) => {
    handleFiltersChange(
      "typeLayers",
      !value || value.length === 0 ? [] : value
    );

    if (!value || value.length === 0)
      handleFiltersChange("territorializations", []);
    else
      handleFiltersChange("territorializations", value[0].territorializations);
  }, []);

  return {
    demandsList,
    employeesList,
    fetchAllComponentsData,
    filters,
    handleEpidemiologicWeeksPeriodsFilterChange,
    handleFiltersChange,
    handleTypeLayerChange,
    isFieldResultsLoading,
    teamsList,
    territorializationsList,
    trapTypesList,
    typeLayersList,
  };
};

const FieldResulstPageFilters = () => {
  const {
    demandsList,
    employeesList,
    fetchAllComponentsData,
    filters,
    handleEpidemiologicWeeksPeriodsFilterChange,
    handleFiltersChange,
    handleTypeLayerChange,
    isFieldResultsLoading,
    teamsList,
    territorializationsList,
    trapTypesList,
    typeLayersList,
  } = useFieldResulstPageFilters();

  return (
    <section>
      <Row className="mb-4">
        <Col className="mb-3 col-md-4 visit-responsive-filter">
          <DemandsFilter
            demandsList={demandsList}
            handleFiltersChange={handleFiltersChange}
            selectedDemands={filters.demand}
          />
        </Col>
        <Col className="mb-3 col-md-4 visit-responsive-filter">
          <TeamsList
            handleFiltersChange={handleFiltersChange}
            selectedTeam={filters.team}
            teamsList={teamsList}
          />
        </Col>
        <Col className="mb-3 col-md-4 visit-responsive-filter">
          <UsersFilter
            handleFiltersChange={handleFiltersChange}
            selectedUsers={filters.employees}
            usersList={employeesList}
          />
        </Col>
        <Col className="mb-3 col-md-4 visit-responsive-filter">
          <TrapTypesFilter
            handleFiltersChange={handleFiltersChange}
            selectedTrapType={filters.trapType}
            trapTypesList={trapTypesList}
          />
        </Col>
        <Col className="mb-3 col-md-4 visit-responsive-filter">
          <TypeLayersFilter
            handleTypeLayerChange={handleTypeLayerChange}
            selectedTypeLayers={filters.typeLayers}
            typeLayersList={typeLayersList}
          />
        </Col>
        <Col className="mb-3 col-md-4 visit-responsive-filter">
          <TerritorializationsFilter
            handleFiltersChange={handleFiltersChange}
            selectedTerritorializations={filters.territorializations}
            territorializationsList={territorializationsList}
          />
        </Col>
        <Col className="mb-3 col-md-4 visit-responsive-filter">
          <YearsFilter handleFiltersChange={handleFiltersChange} />
        </Col>
        <Col className="mb-3 col-md-4 visit-responsive-filter">
          <EpidemiologicWeeksPeriodsFilter
            epidemiologicalWeekBegin={filters.epidemiologicalWeekBegin}
            epidemiologicalWeekEnd={filters.epidemiologicalWeekEnd}
            filters={filters}
            handleFiltersChange={handleEpidemiologicWeeksPeriodsFilterChange}
          />
        </Col>
        <Col className="mb-3 col-md-4 visit-responsive-filter">
          <ManualEpidemiologicWeeksFilter
            filters={filters}
            handleFiltersChange={handleFiltersChange}
          />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col className="col-md-4 visit-responsive-filter"></Col>
        <Col
          className="col-md-4 visit-responsive-filter"
          style={{ textAlign: "center" }}
        >
          <span
            className="text-white"
            style={{ fontSize: "14px", fontWeight: "bold" }}
          >
            Semana epidemiológica atual: {getTodayEpidemiologicalWeek()}
          </span>
        </Col>
        <Col className="col-md-4 visit-responsive-filter"></Col>
      </Row>
      <Row className="mb-2">
        <Col className="col-md-4 visit-responsive-filter"></Col>
        <Col className="col-md-4 visit-responsive-filter">
          {isFieldResultsLoading ? (
            <Button disabled={true} color="primary">
              <LoadingSpin color="#fff" size={17} />
            </Button>
          ) : (
            <Button color="primary" onClick={() => fetchAllComponentsData()}>
              Filtrar
            </Button>
          )}
        </Col>
        <Col className="col-md-4 visit-responsive-filter"></Col>
      </Row>
    </section>
  );
};

export default FieldResulstPageFilters;
