import React from "react";

import useAppliedFilters from "./Hooks/useAppliedFilters";
import useGraphicsData from "./Hooks/useGraphicsData";
import { Col, Row } from "reactstrap";
import { PositivityIndexMapContextProvider } from "./Contexts/PositivityIndexMapContext";
import PositivityIndexMap from "./Maps/PositivityIndexMap";
import { IPLGraphic } from "./Graphics/IPL/IPLGraphic";
import { IPEGraphic } from "./Graphics/IPE/IPEGraphic";
import { groupPositiveTrapsEstimatesByWeek } from "./Utils/functions";
import { IPOGraphic } from "./Graphics/IPO/IPOGraphic";
import { IPAGraphic } from "./Graphics/IPA/IPAGraphic";

const DEFAULT_GRAPHICS_DATA = {
  IPA: {
    estimatesCategories: [],
    barEstimates: [],
    lineEstimates: [],
  },
  IPL: {
    estimatesCategories: [],
    barEstimates: [],
    lineEstimates: [],
  },
  IPO: {
    estimatesCategories: [],
    barEstimates: [],
    lineEstimates: [],
  },
  IPE: {
    estimatesCategories: [],
    barEstimates: [],
    lineEstimates: [],
  },
};

const useFieldResultsPageGraphicsWrapper = () => {
  const [graphicsData, setGraphicsData] = React.useState(DEFAULT_GRAPHICS_DATA);
  const [positivityIndexGraphicsData, setPositivityIndexGraphicsData] =
    React.useState([]);
  const { appliedFilters } = useAppliedFilters();
  const { fieldResultsList } = useGraphicsData();

  React.useEffect(() => {
    const formatedDataForPositivityIndexGraphics =
      formatDataForPositivityIndexGraphics(fieldResultsList);

    const groupedPositiveTrapsByWeek = groupPositiveTrapsEstimatesByWeek(
      fieldResultsList,
      appliedFilters.trapType.trapTypeName
    );

    const { aedesAegypti, egg, exuvia, larvaOrPupa } =
      formatPositiveTrapsForGraphics(groupedPositiveTrapsByWeek);

    const appliedTrapTypeNameFilter = appliedFilters.trapType.trapTypeName;

    if (appliedTrapTypeNameFilter === "armadilhaDisseminadoraInseticida") {
      setGraphicsData((previousValues) => {
        const newValues = {
          ...previousValues,
          IPL: {
            estimatesCategories: larvaOrPupa.estimatesCategories,
            barEstimates: larvaOrPupa.percentageEstimatesValuesByWeek,
            lineEstimates: larvaOrPupa.absoluteEstimatesValuesByWeek,
          },
          IPE: {
            estimatesCategories: exuvia.estimatesCategories,
            barEstimates: exuvia.percentageEstimatesValuesByWeek,
            lineEstimates: exuvia.absoluteEstimatesValuesByWeek,
          },
        };

        return newValues;
      });
    }

    if (
      appliedTrapTypeNameFilter === "armadilhaMosquitoAdulto" ||
      appliedTrapTypeNameFilter === "armadilhaMosquitoAdulto2"
    ) {
      setGraphicsData((previousValues) => {
        const newValues = {
          ...previousValues,
          IPL: {
            estimatesCategories: larvaOrPupa.estimatesCategories,
            barEstimates: larvaOrPupa.percentageEstimatesValuesByWeek,
            lineEstimates: larvaOrPupa.absoluteEstimatesValuesByWeek,
          },
          IPA: {
            estimatesCategories: aedesAegypti.estimatesCategories,
            barEstimates: aedesAegypti.percentageEstimatesValuesByWeek,
            lineEstimates: aedesAegypti.absoluteEstimatesValuesByWeek,
          },
        };

        return newValues;
      });
    }

    if (appliedTrapTypeNameFilter === "armadilhaOvos") {
      setGraphicsData((previousValues) => {
        const newValues = {
          ...previousValues,
          IPO: {
            estimatesCategories: egg.estimatesCategories,
            barEstimates: egg.percentageEstimatesValuesByWeek,
            lineEstimates: egg.absoluteEstimatesValuesByWeek,
          },
          IPL: {
            estimatesCategories: larvaOrPupa.estimatesCategories,
            barEstimates: larvaOrPupa.percentageEstimatesValuesByWeek,
            lineEstimates: larvaOrPupa.absoluteEstimatesValuesByWeek,
          },
          IPE: {
            estimatesCategories: exuvia.estimatesCategories,
            barEstimates: exuvia.percentageEstimatesValuesByWeek,
            lineEstimates: exuvia.absoluteEstimatesValuesByWeek,
          },
        };

        return newValues;
      });
    }

    setPositivityIndexGraphicsData(formatedDataForPositivityIndexGraphics);
  }, [fieldResultsList]);

  const formatDataForPositivityIndexGraphics = (fieldResultsList) => {
    if (!fieldResultsList || fieldResultsList.length === 0) return [];

    const formatedDataForPositivityIndexGraphics = fieldResultsList.map(
      ({
        diagnosedTraps,
        period,
        positiveTraps,
        negativeTrapsForEggsLarvaesPupasOrMosquitoes,
      }) => {
        const ipaIndex = (
          (positiveTraps.forAedesAegypti.length / diagnosedTraps) *
          100
        ).toFixed(2);
        const ipeIndex = (
          (positiveTraps.forExuvia.length / diagnosedTraps) *
          100
        ).toFixed(2);
        const iplIndex = (
          (positiveTraps.forLarvaOrPupa.length / diagnosedTraps) *
          100
        ).toFixed(2);
        const ipoIndex = (
          (positiveTraps.forEgg.length / diagnosedTraps) *
          100
        ).toFixed(2);

        const result = {
          diagnosedTraps,
          negativeTraps: negativeTrapsForEggsLarvaesPupasOrMosquitoes.length,
          positiveForAedesAegypti: positiveTraps.forAedesAegypti.length,
          positiveForEgg: positiveTraps.forEgg.length,
          positiveForExuvia: positiveTraps.forExuvia.length,
          positiveForLarvaOrPupa: positiveTraps.forLarvaOrPupa.length,
          IPA: ipaIndex === "NaN" ? "0.00" : ipaIndex,
          IPE: ipeIndex === "NaN" ? "0.00" : ipeIndex,
          IPL: iplIndex === "NaN" ? "0.00" : iplIndex,
          IPO: ipoIndex === "NaN" ? "0.00" : ipoIndex,
          trapTypeName: appliedFilters.trapType.trapTypeName,
          periodLabel: period.label,
        };

        return result;
      }
    );

    return formatedDataForPositivityIndexGraphics;
  };

  const formatPositiveTrapsForGraphics = (groupedPositiveTraps) => {
    if (Object.keys(groupedPositiveTraps).length === 0)
      return {
        larvaOrPupa: {
          absoluteEstimatesValuesByWeek: [],
          percentageEstimatesValuesByWeek: [],
          estimatesCategories: [],
        },
        egg: {
          absoluteEstimatesValuesByWeek: [],
          percentageEstimatesValuesByWeek: [],
          estimatesCategories: [],
        },
        aedesAegypti: {
          absoluteEstimatesValuesByWeek: [],
          percentageEstimatesValuesByWeek: [],
          estimatesCategories: [],
        },
        exuvia: {
          absoluteEstimatesValuesByWeek: [],
          percentageEstimatesValuesByWeek: [],
          estimatesCategories: [],
        },
      };

    const {
      aedesAegyptiEstimatesByWeek,
      eggEstimatesByWeek,
      exuviaEstimatesByWeek,
      larvaOrPupaEstimatesByWeek,
    } = groupedPositiveTraps;

    const {
      larvaOrPupaAbosluteEstimatesValuesByWeek,
      larvaOrPupaPercentageEstimatesValuesByWeek,
      larvaOrPupaEstimatesCategories,
    } = getLarvaOrPupaEstimatesForGraphics(larvaOrPupaEstimatesByWeek);

    const {
      eggAbosluteEstimatesValuesByWeek,
      eggPercentageEstimatesValuesByWeek,
      eggEstimatesCategories,
    } = getEggEstimatesForGraphics(eggEstimatesByWeek);

    const {
      aedesAegyptiAbosluteEstimatesValuesByWeek,
      aedesAegyptiPercentageEstimatesValuesByWeek,
      aedesAegyptiEstimatesCategories,
    } = getAedesAegyptiEstimatesForGraphics(aedesAegyptiEstimatesByWeek);

    const {
      exuviaAbosluteEstimatesValuesByWeek,
      exuviaPercentageEstimatesValuesByWeek,
      exuviaEstimatesCategories,
    } = getExuviaEstimatesForGraphics(exuviaEstimatesByWeek);

    return {
      larvaOrPupa: {
        absoluteEstimatesValuesByWeek: larvaOrPupaAbosluteEstimatesValuesByWeek,
        percentageEstimatesValuesByWeek:
          larvaOrPupaPercentageEstimatesValuesByWeek,
        estimatesCategories: larvaOrPupaEstimatesCategories,
      },
      egg: {
        absoluteEstimatesValuesByWeek: eggAbosluteEstimatesValuesByWeek,
        percentageEstimatesValuesByWeek: eggPercentageEstimatesValuesByWeek,
        estimatesCategories: eggEstimatesCategories,
      },
      aedesAegypti: {
        absoluteEstimatesValuesByWeek:
          aedesAegyptiAbosluteEstimatesValuesByWeek,
        percentageEstimatesValuesByWeek:
          aedesAegyptiPercentageEstimatesValuesByWeek,
        estimatesCategories: aedesAegyptiEstimatesCategories,
      },
      exuvia: {
        absoluteEstimatesValuesByWeek: exuviaAbosluteEstimatesValuesByWeek,
        percentageEstimatesValuesByWeek: exuviaPercentageEstimatesValuesByWeek,
        estimatesCategories: exuviaEstimatesCategories,
      },
    };
  };

  const getLarvaOrPupaEstimatesForGraphics = (larvaOrPupaEstimatesByWeek) => {
    if (
      !larvaOrPupaEstimatesByWeek ||
      Object.keys(larvaOrPupaEstimatesByWeek).length === 0
    )
      return {
        larvaOrPupaAbosluteEstimatesValuesByWeek: [],
        larvaOrPupaPercentageEstimatesValuesByWeek: [],
        larvaOrPupaEstimatesCategories: [],
      };

    const larvaOrPupaAbosluteEstimatesValuesByWeek = Object.keys(
      larvaOrPupaEstimatesByWeek
    ).map((week) => {
      const estimates = larvaOrPupaEstimatesByWeek[week];

      const newEstimates = {
        "1 a 20": estimates["1 a 20"] ? estimates["1 a 20"] : 0,
        "21 a 100":
          (estimates["21 a 100"] ? estimates["21 a 100"] : 0) +
          (estimates["21 a 50"] ? estimates["21 a 50"] : 0) +
          (estimates["51 a 100"] ? estimates["51 a 100"] : 0),
        "Mais de 100": estimates["Mais de 100"]
          ? estimates["Mais de 100"]
          : estimates["mais de 100"]
          ? estimates["mais de 100"]
          : 0,
      };

      return {
        week,
        ...newEstimates,
      };
    });

    const larvaOrPupaPercentageEstimatesValuesByWeek =
      larvaOrPupaAbosluteEstimatesValuesByWeek.map((estimate) => {
        const totalEstimatesValue =
          estimate["1 a 20"] + estimate["21 a 100"] + estimate["Mais de 100"];

        const firstPeriod = +(
          (estimate["1 a 20"] / totalEstimatesValue) *
          100
        ).toFixed(2);

        const secondPeriod = +(
          (estimate["21 a 100"] / totalEstimatesValue) *
          100
        ).toFixed(2);

        const thirdPeriod = +(
          (estimate["Mais de 100"] / totalEstimatesValue) *
          100
        ).toFixed(2);

        const newEstimatesPercentage = {
          "1 a 20": isNaN(firstPeriod) ? 0 : firstPeriod,
          "21 a 100": isNaN(secondPeriod) ? 0 : secondPeriod,
          "Mais de 100": isNaN(thirdPeriod) ? 0 : thirdPeriod,
        };

        return {
          ...estimate,
          ...newEstimatesPercentage,
        };
      });

    const larvaOrPupaEstimatesCategories =
      !larvaOrPupaPercentageEstimatesValuesByWeek ||
      larvaOrPupaPercentageEstimatesValuesByWeek.length === 0
        ? []
        : Object.keys(larvaOrPupaPercentageEstimatesValuesByWeek[0]).filter(
            (key) => key !== "week"
          );

    return {
      larvaOrPupaAbosluteEstimatesValuesByWeek,
      larvaOrPupaPercentageEstimatesValuesByWeek,
      larvaOrPupaEstimatesCategories,
    };
  };

  const getEggEstimatesForGraphics = (eggEstimatesByWeek) => {
    if (!eggEstimatesByWeek || Object.keys(eggEstimatesByWeek).length === 0)
      return {
        eggAbosluteEstimatesValuesByWeek: [],
        eggPercentageEstimatesValuesByWeek: [],
        eggEstimatesCategories: [],
      };

    const eggAbosluteEstimatesValuesByWeek = Object.keys(
      eggEstimatesByWeek
    ).map((week) => {
      const estimates = eggEstimatesByWeek[week];

      const newEstimates = {
        "1 a 100": estimates["1 a 100"] ? estimates["1 a 100"] : 0,
        "101 a 200": estimates["101 a 200"] ? estimates["101 a 200"] : 0,
        "Mais de 200": estimates["Mais de 200"],
      };

      return {
        week,
        ...newEstimates,
      };
    });

    const eggPercentageEstimatesValuesByWeek =
      eggAbosluteEstimatesValuesByWeek.map((estimate) => {
        const totalEstimatesValue =
          estimate["1 a 100"] + estimate["101 a 200"] + estimate["Mais de 200"];

        const firstPeriod = +(
          (estimate["1 a 100"] / totalEstimatesValue) *
          100
        ).toFixed(2);

        const secondPeriod = +(
          (estimate["101 a 200"] / totalEstimatesValue) *
          100
        ).toFixed(2);

        const thirdPeriod = +(
          (estimate["Mais de 200"] / totalEstimatesValue) *
          100
        ).toFixed(2);

        const newEstimatesPercentage = {
          "1 a 100": isNaN(firstPeriod) ? 0 : firstPeriod,
          "101 a 200": isNaN(secondPeriod) ? 0 : secondPeriod,
          "Mais de 200": isNaN(thirdPeriod) ? 0 : thirdPeriod,
        };

        return {
          ...estimate,
          ...newEstimatesPercentage,
        };
      });

    const eggEstimatesCategories =
      !eggPercentageEstimatesValuesByWeek ||
      eggPercentageEstimatesValuesByWeek.length === 0
        ? []
        : Object.keys(eggPercentageEstimatesValuesByWeek[0]).filter(
            (key) => key !== "week"
          );

    return {
      eggAbosluteEstimatesValuesByWeek,
      eggPercentageEstimatesValuesByWeek,
      eggEstimatesCategories,
    };
  };

  const getAedesAegyptiEstimatesForGraphics = (aedesAegyptiEstimatesByWeek) => {
    if (
      !aedesAegyptiEstimatesByWeek ||
      Object.keys(aedesAegyptiEstimatesByWeek).length === 0
    )
      return {
        aedesAegyptiAbosluteEstimatesValuesByWeek: [],
        aedesAegyptiPercentageEstimatesValuesByWeek: [],
        aedesAegyptiEstimatesCategories: [],
      };

    const aedesAegyptiAbosluteEstimatesValuesByWeek = Object.keys(
      aedesAegyptiEstimatesByWeek
    ).map((week) => {
      const estimates = aedesAegyptiEstimatesByWeek[week];

      const newEstimates = {
        1: estimates["1"] ? estimates["1"] : 0,
        "2 a 3": estimates["2 a 3"] ? estimates["2 a 3"] : 0,
        "Mais de 3": estimates["Mais de 3"] ? estimates["Mais de 3"] : 0,
      };

      return {
        week,
        ...newEstimates,
      };
    });

    const aedesAegyptiPercentageEstimatesValuesByWeek =
      aedesAegyptiAbosluteEstimatesValuesByWeek.map((estimate) => {
        const totalEstimatesValue =
          estimate["1"] + estimate["2 a 3"] + estimate["Mais de 3"];

        const firstPeriod = +(
          (estimate["1"] / totalEstimatesValue) *
          100
        ).toFixed(2);

        const secondPeriod = +(
          (estimate["2 a 3"] / totalEstimatesValue) *
          100
        ).toFixed(2);

        const thirdPeriod = +(
          (estimate["Mais de 3"] / totalEstimatesValue) *
          100
        ).toFixed(2);

        const newEstimatesPercentage = {
          1: isNaN(firstPeriod) ? 0 : firstPeriod,
          "2 a 3": isNaN(secondPeriod) ? 0 : secondPeriod,
          "Mais de 3": isNaN(thirdPeriod) ? 0 : thirdPeriod,
        };

        return {
          ...estimate,
          ...newEstimatesPercentage,
        };
      });

    const aedesAegyptiEstimatesCategories =
      !aedesAegyptiPercentageEstimatesValuesByWeek ||
      aedesAegyptiPercentageEstimatesValuesByWeek.length === 0
        ? []
        : Object.keys(aedesAegyptiPercentageEstimatesValuesByWeek[0]).filter(
            (key) => key !== "week"
          );

    return {
      aedesAegyptiAbosluteEstimatesValuesByWeek,
      aedesAegyptiPercentageEstimatesValuesByWeek,
      aedesAegyptiEstimatesCategories,
    };
  };

  const getExuviaEstimatesForGraphics = (exuviaEstimatesByWeek) => {
    if (
      !exuviaEstimatesByWeek ||
      Object.keys(exuviaEstimatesByWeek).length === 0
    )
      return {
        exuviaAbosluteEstimatesValuesByWeek: [],
        exuviaPercentageEstimatesValuesByWeek: [],
        exuviaEstimatesCategories: [],
      };

    const exuviaAbosluteEstimatesValuesByWeek = Object.keys(
      exuviaEstimatesByWeek
    ).map((week) => {
      const estimates = exuviaEstimatesByWeek[week];

      const newEstimates = {
        "1 a 20": estimates["1 a 20"] ? estimates["1 a 20"] : 0,
        "21 a 100": estimates["21 a 100"] ? estimates["21 a 100"] : 0,
        "Mais de 100": estimates["Mais de 100"],
      };

      return {
        week,
        ...newEstimates,
      };
    });

    const exuviaPercentageEstimatesValuesByWeek =
      exuviaAbosluteEstimatesValuesByWeek.map((estimate) => {
        const totalEstimatesValue =
          estimate["1 a 20"] + estimate["21 a 100"] + estimate["Mais de 100"];

        const firstPeriod = +(
          (estimate["1 a 20"] / totalEstimatesValue) *
          100
        ).toFixed(2);

        const secondPeriod = +(
          (estimate["21 a 100"] / totalEstimatesValue) *
          100
        ).toFixed(2);

        const thirdPeriod = +(
          (estimate["Mais de 100"] / totalEstimatesValue) *
          100
        ).toFixed(2);

        const newEstimatesPercentage = {
          "1 a 20": isNaN(firstPeriod) ? 0 : firstPeriod,
          "21 a 100": isNaN(secondPeriod) ? 0 : secondPeriod,
          "Mais de 100": isNaN(thirdPeriod) ? 0 : thirdPeriod,
        };

        return {
          ...estimate,
          ...newEstimatesPercentage,
        };
      });

    const exuviaEstimatesCategories =
      !exuviaPercentageEstimatesValuesByWeek ||
      exuviaPercentageEstimatesValuesByWeek.length === 0
        ? []
        : Object.keys(exuviaPercentageEstimatesValuesByWeek[0]).filter(
            (key) => key !== "week"
          );

    return {
      exuviaAbosluteEstimatesValuesByWeek,
      exuviaPercentageEstimatesValuesByWeek,
      exuviaEstimatesCategories,
    };
  };

  return { appliedFilters, graphicsData, positivityIndexGraphicsData };
};

const FieldResultsPageGraphicsWrapper = () => {
  const {
    appliedFilters,
    graphicsData: { IPA, IPE, IPL, IPO },
    positivityIndexGraphicsData,
  } = useFieldResultsPageGraphicsWrapper();

  return (
    <PositivityIndexMapContextProvider>
      <section>
        {appliedFilters.trapType.trapTypeName ===
        "armadilhaDisseminadoraInseticida" ? (
          <>
            <Row className="mb-2">
              <Col xl={6}>
                <IPLGraphic
                  estimatesList={IPL.estimatesCategories}
                  lineData={IPL.lineEstimates}
                  positivityIndex={positivityIndexGraphicsData}
                  trapTypeName={appliedFilters.trapType.trapTypeName}
                  verticalBarData={IPL.barEstimates}
                />
              </Col>
              <Col xl={6}>
                <PositivityIndexMap estimateObjectName="larvaOrPupaPointsEstimatesByWeek" />
              </Col>
            </Row>
            <Row className="mb-4">
              <Col xl={6}>
                <IPEGraphic
                  estimatesList={IPE.estimatesCategories}
                  lineData={IPE.lineEstimates}
                  positivityIndex={positivityIndexGraphicsData}
                  trapTypeName={appliedFilters.trapType.trapTypeName}
                  verticalBarData={IPE.barEstimates}
                />
              </Col>
              <Col xl={6}>
                <PositivityIndexMap estimateObjectName="exuviaPointsEstimatesByWeek" />
              </Col>
            </Row>
          </>
        ) : appliedFilters.trapType.trapTypeName ===
            "armadilhaMosquitoAdulto" ||
          appliedFilters.trapType.trapTypeName ===
            "armadilhaMosquitoAdulto2" ? (
          <>
            <Row className="mb-2">
              <Col xl={6}>
                <IPLGraphic
                  estimatesList={IPL.estimatesCategories}
                  lineData={IPL.lineEstimates}
                  positivityIndex={positivityIndexGraphicsData}
                  trapTypeName={appliedFilters.trapType.trapTypeName}
                  verticalBarData={IPL.barEstimates}
                />
              </Col>
              <Col xl={6}>
                <PositivityIndexMap estimateObjectName="larvaOrPupaPointsEstimatesByWeek" />
              </Col>
            </Row>
            <Row className="mb-4">
              <Col xl={6}>
                <IPAGraphic
                  estimatesList={IPA.estimatesCategories}
                  lineData={IPA.lineEstimates}
                  positivityIndex={positivityIndexGraphicsData}
                  trapTypeName={appliedFilters.trapType.trapTypeName}
                  verticalBarData={IPA.barEstimates}
                />
              </Col>
              <Col xl={6}>
                <PositivityIndexMap estimateObjectName="aedesAegyptiPointsEstimatesByWeek" />
              </Col>
            </Row>
          </>
        ) : appliedFilters.trapType.trapTypeName === "armadilhaOvos" ? (
          <>
            <Row className="mb-2">
              <Col xl={6}>
                <IPOGraphic
                  estimatesList={IPO.estimatesCategories}
                  lineData={IPO.lineEstimates}
                  positivityIndex={positivityIndexGraphicsData}
                  trapTypeName={appliedFilters.trapType.trapTypeName}
                  verticalBarData={IPO.barEstimates}
                />
              </Col>
              <Col xl={6}>
                <PositivityIndexMap estimateObjectName="eggPointsEstimatesByWeek" />
              </Col>
            </Row>
            <Row className="mb-2">
              <Col xl={6}>
                <IPLGraphic
                  estimatesList={IPL.estimatesCategories}
                  lineData={IPL.lineEstimates}
                  positivityIndex={positivityIndexGraphicsData}
                  trapTypeName={appliedFilters.trapType.trapTypeName}
                  verticalBarData={IPL.barEstimates}
                />
              </Col>
              <Col xl={6}>
                <PositivityIndexMap estimateObjectName="larvaOrPupaPointsEstimatesByWeek" />
              </Col>
            </Row>
            <Row className="mb-4">
              <Col xl={6}>
                <IPEGraphic
                  estimatesList={IPE.estimatesCategories}
                  lineData={IPE.lineEstimates}
                  positivityIndex={positivityIndexGraphicsData}
                  trapTypeName={appliedFilters.trapType.trapTypeName}
                  verticalBarData={IPE.barEstimates}
                />
              </Col>
              <Col xl={6}>
                <PositivityIndexMap estimateObjectName="exuviaPointsEstimatesByWeek" />
              </Col>
            </Row>
          </>
        ) : null}
      </section>
    </PositivityIndexMapContextProvider>
  );
};

export default FieldResultsPageGraphicsWrapper;
