import { useContextSelector } from "use-context-selector";
import { OrganizationContext } from "../contexts/organizationContext";

const useDemandsList = () => {
  const demandsList = useContextSelector(
    OrganizationContext,
    (context) => context.demandsList
  );
  const setDemandsList = useContextSelector(
    OrganizationContext,
    (context) => context.setDemandsList
  );

  return { demandsList, setDemandsList };
};

export default useDemandsList;
