import { useContextSelector } from "use-context-selector";

import { MapContext } from "../Contexts/mapContext";

const useMapPoints = () => {
  const fetchTrapsListData = useContextSelector(
    MapContext,
    (context) => context.fetchTrapsListData
  );
  const filteredGroupsPointsList = useContextSelector(
    MapContext,
    (context) => context.filteredGroupsPointsList
  );
  const removePointsCluster = useContextSelector(
    MapContext,
    (context) => context.removePointsCluster
  );
  const setRemovePointsCluster = useContextSelector(
    MapContext,
    (context) => context.setRemovePointsCluster
  );
  

  return {
    fetchTrapsListData,
    filteredGroupsPointsList,
    removePointsCluster,
    setRemovePointsCluster,
  };
};

export default useMapPoints;
