import React from "react";
import { Select } from "../Wrappers/SelectAll";
import { selectComponentStyles } from "../../services/utils/globalFunctions";

const TerritorializationsFilter = ({
  selectedTerritorializations,
  handleFiltersChange,
  territorializationsList,
}) => {
  return (
    <div>
      <span className="h4 text-white">Territorialização</span>
      <Select
        styles={selectComponentStyles}
        placeholder={"Selecione"}
        isClearable={true}
        options={territorializationsList}
        value={selectedTerritorializations}
        isMulti={true}
        blurInputOnSelect={false}
        closeMenuOnSelect={false}
        onChange={(e) =>
          handleFiltersChange(
            "territorializations",
            !e || e.length === 0 ? [] : e
          )
        }
        menuPortalTarget={document.body}
        isDisabled={territorializationsList.length === 0}
      />
    </div>
  );
};

export default TerritorializationsFilter;
