import React from "react";

import { Button } from "reactstrap";
import { Bar, Rectangle } from "recharts";
import VerticalChartGraphic from "../../../../../components/Graphics/Composed/VerticalChartGraphic";
import { getColorByIndex } from "../../../../../services/utils/globalFunctions";
import ReactTooltip from "react-tooltip";
import { ARMADILHA_INFO } from "../../../../../constants/ArmadilhaConstant";

export const PositiveBarPercentageEstimatesGraphic = ({
  data,
  estimatesList,
  positiveFor,
  setGraphicType,
  trapTypeName,
}) => {
  return (
    <VerticalChartGraphic
      chartElements={estimatesList.map((category, index) => (
        <Bar
          key={category}
          dataKey={category}
          fill={getColorByIndex(index)}
          activeBar={<Rectangle fill="pink" stroke="blue" />}
          stackId="a"
          barSize={20}
        />
      ))}
      data={data}
      headerContent={
        <>
          <Button
            onClick={() => setGraphicType("default")}
            color="primary"
            data-tip
            data-for="exchangeGraphicType3"
            style={{ width: "55px", marginRight: "0px" }}
          >
            <i>
              <img
                src={require("../../../../../assets/img/icons/interface/exchange-alt.png")}
                style={{ width: "13px", height: "13px" }}
                alt="icone"
              />
            </i>
          </Button>
          <ReactTooltip
            id="exchangeGraphicType3"
            type="info"
            place="bottom"
            effect="solid"
          >
            Trocar visualização
          </ReactTooltip>
        </>
      }
      headerText={`Estimativas em porcentagem de ${ARMADILHA_INFO[trapTypeName].apelido}'s positivas para ${positiveFor}`}
      hideDownloadButton={true}
      xAxisTickFormatter="%"
      xAxisDomain={[0, 100]}
      yAxisDataKey="week"
    />
  );
};
