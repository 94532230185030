import { useContextSelector } from "use-context-selector";
import { OrganizationContext } from "../contexts/organizationContext";

const useTerritorializationsList = () => {
  const territorializationsList = useContextSelector(
    OrganizationContext,
    (context) => context.territorializationsList
  );
  const setTerritorializationsList = useContextSelector(
    OrganizationContext,
    (context) => context.setTerritorializationsList
  );

  return { territorializationsList, setTerritorializationsList };
};

export default useTerritorializationsList;
