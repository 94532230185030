import React from "react";

import moment from "moment";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardText,
} from "reactstrap";
import CsvDownloadButton from "react-json-to-csv";
import ReactTooltip from "react-tooltip";
import { toPng } from "html-to-image";
import { toast } from "react-toastify";

const CardWrapper = ({ children, ...props }) => {
  const contentToPrintRef = React.useRef(null);

  const handleDownloadGraphicImage = async () => {
    try {
      if (contentToPrintRef.current === null) return;

      const imageToDownloadString = await toPng(contentToPrintRef.current, {
        quality: 1,
        pixelRatio: 2,
      });

      if (!imageToDownloadString || imageToDownloadString === "")
        throw new Error("Ocorreu um erro ao gerar a imagem do gráfico.");

      const link = document.createElement("a");
      link.download = `vitecImage.png`;
      link.href = imageToDownloadString;
      link.click();
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
        toast.error(error.message);
      }
    }
  };

  return (
    <>
      <div ref={contentToPrintRef}>
        <Card {...props}>
          {React.Children.map(children, (child) =>
            React.cloneElement(child, {
              handleDownloadGraphicImage,
            })
          )}
        </Card>
      </div>
    </>
  );
};

const CardHeaderWrapper = ({
  children,
  dataToDownload,
  showExpandButton = true,
  showDownloadDataButton = true,
  showDownloadImageButton = true,
  showButtonsTooltips = true,
  handleExpandButtonClick,
  handleDownloadGraphicImage,
  headerText,
  ...props
}) => {
  const [generatedAt, setGeneratedAt] = React.useState("");

  React.useEffect(() => {
    const splitedActualDate = moment().format("DD/MM/YYYY HH:mm").split(" ");

    setGeneratedAt(`${splitedActualDate[0]} às ${splitedActualDate[1]}`);
  }, [dataToDownload]);

  return (
    <CardHeader>
      <CardText className="mb-0 mt-0" style={style.cardHeaderText}>
        <div>
          <span>{headerText}</span>
        </div>
        <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
          <span style={{ fontSize: "13px", fontWeight: "normal" }}>
            Gerado em: <br />{generatedAt}
          </span>
          <span>
            {showDownloadDataButton && (
              <CsvDownloadButton
                className="btn btn-primary"
                data={dataToDownload}
                filename={generatedAt}
                style={{ width: "55px", marginRight: "0px" }}
                data-tip
                data-for="dataDownload"
              >
                <i className="fa fa-download" />
              </CsvDownloadButton>
            )}
            {showButtonsTooltips && (
              <ReactTooltip effect="solid" type="info" id="dataDownload">
                Baixar dados
              </ReactTooltip>
            )}
          </span>
          <span>
            {showDownloadImageButton && (
              <Button
                onClick={() => handleDownloadGraphicImage()}
                color="primary"
                data-tip
                data-for="viewDownload"
                style={{ width: "55px", marginRight: "0px" }}
              >
                <i className={"fa fa-image"} />
              </Button>
            )}
            {showButtonsTooltips && (
              <ReactTooltip effect="solid" type="info" id="viewDownload">
                Baixar visualização
              </ReactTooltip>
            )}
          </span>
          {showExpandButton && (
            <div>
              <Button
                onClick={() => handleExpandButtonClick()}
                color="primary"
                data-tip
                data-for="expand"
                style={{ width: "55px", marginRight: "0px" }}
              >
                <i className="fas fa-expand-arrows-alt" />
              </Button>
              {showButtonsTooltips && (
                <ReactTooltip effect="solid" type="info" id="expand">
                  Expandir
                </ReactTooltip>
              )}
            </div>
          )}
          {children}
        </div>
      </CardText>
    </CardHeader>
  );
};

const CardContentWrapper = ({ children, ...props }) => {
  return (
    <CardBody style={style.cardBody} {...props}>
      {children}
    </CardBody>
  );
};

const CardFooterWrapper = ({ children, ...props }) => {
  return (
    <CardFooter style={style.cardFooter} {...props}>
      {children}
    </CardFooter>
  );
};

const style = {
  col: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "25rem",
    marginTop: "0.7rem",
  },
  cardBody: {
    padding: "1rem",
  },
  cardHeaderText: {
    color: "black",
    fontWeight: "bold",
    fontSize: "0.97rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  cardFooter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "16px",
  },
};

export {
  CardWrapper as Card,
  CardHeaderWrapper as CardHeader,
  CardContentWrapper as CardContent,
  CardFooterWrapper as CardFooter,
};
