import { useContextSelector } from "use-context-selector";
import { OrganizationContext } from "../contexts/organizationContext";

const useTypeLayersList = () => {
  const typeLayersList = useContextSelector(
    OrganizationContext,
    (context) => context.typeLayersList
  );
  const setTypeLayersList = useContextSelector(
    OrganizationContext,
    (context) => context.setTypeLayersList
  );

  return { typeLayersList, setTypeLayersList };
};

export default useTypeLayersList;
