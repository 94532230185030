import React, { useEffect, useState, useRef } from "react";
import Chart from "chart.js";
import {
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  CardHeader,
} from "reactstrap";
import { chartOptions, parseOptions } from "variables/charts.jsx";
import EntomologicoFilterPresentational from "../components/Presentational/Entomologico/EntomologicoFilterPresentational";
import {
  getTrapsTypes,
  getActiveTrapsToMap,
  getTrapStatusCard,
  getActiveTraps,
  fetchTrapsDensity,
} from "../services/api/Trap";
import { getUserData } from "../services/utils/auth";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import { buildEpidemiologicalLabels } from "../services/utils/Entomologico/Labels";
import { getEpidemiologicalPeriod } from "../services/utils/Entomologico/Period";
import ActiveMapContainer from "../components/Containers/Entomologico/Maps/ActiveMapContainer";
import MainPeriodComponent from "../components/Inputs/MainPeriodComponent";
import getWeeks from "../services/utils/epidemiologicalWeek";
import VisitOperacionalFilterPresentational from "../components/Presentational/Operacional/VisitOperacionalFilterPresentational";
import GeographicFilterPresentational from "../components/Presentational/Filters/GeographicFilterPresentational";
import { ARMADILHA_INFO } from "../constants/ArmadilhaConstant";
import {
  fetchAnimalGenres,
  fetchAnimalSpecies,
} from "../services/api/AnimalGroups";
import "./Traps.css";
import { getTodayEpidemiologicalWeek } from "../services/utils/todayEpidemiologicalWeek";
import TodayEpidemiologicalWeekComponent from "../components/Containers/Entomologico/todayEpidemiologicalWeek/TodayEpidemiologicalWeekComponent";
import DashboardTrapsTable from "../components/Tables/DashboardTrapsTable";
import TotalTrapsNumberCardsWrapper from "../components/Wrappers/DashboardTraps/TotalTrapsNumberCards/TotalTrapsNumberCardsWrapper";
import StatusColorTrapsCardsWrapper from "../components/Wrappers/DashboardTraps/StatusColorTrapsCards/StatusColorTrapsCardsWrapper";
import CardLoadingSkeleton from "../components/ui/Loading/CardLoadingSkeleton";
import MapLoadingSkeleton from "../components/ui/Loading/MapLoadingSkeleton";
import TableLoadingSkeleton from "../components/ui/Loading/TableLoadingSkeleton";
import { getVersion } from "../constants/VersionConstant";
import { Select } from "../components/Wrappers/SelectAll";
import { getAllTypeTerritorializations } from "../services/api/territorialization";
import DataTable from "react-data-table-component";
import CsvDownloadButton from "react-json-to-csv";
//import TrapStatusCards from "../components/Wrappers/DashboardTraps/TrapStatusCards/TrapStatusCards";
import {
  NOT_PARTICIPANTS_SCHOOLS_DATA,
  PARTICIPANTS_SCHOOLS_DATA,
} from "../constants/SchoolsConstant";
import { getProjects } from "../services/api/project";

const Traps = ({
  demandsList,
  employeesList,
  teamsList,
  territorializationsList,
  typeLayerSubtypeTerritorializationsList,
}) => {
  const organizationId = getUserData("organizationId");
  const webVersion = getVersion(organizationId);
  const [pgs, setPgs] = useState(null);
  const [trapToUpdateData, setTrapToUpdateData] = useState(null);
  const [modal2, setModal2] = useState(false);
  const [isActiveTrapMapLoading, setActiveTrapMapLoading] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState("");
  const [trapTypeAUX, setTrapTypeAUX] = useState({
    trapTypeId: "00000000-0000-0000-0000-000000000000",
    trapTypeName: "Ovitrampa",
    trapName: "armadilhaOvos",
  });
  const [employeeState, setEmployee] = useState(
    "00000000-0000-0000-0000-000000000000"
  );
  const [trapType, setTrapType] = useState({
    trapTypeId: "00000000-0000-0000-0000-000000000000",
    trapTypeName: "Ovitrampa",
    trapName: "armadilhaOvos",
  });
  const [trapTypes, setTrapTypes] = useState([
    {
      trapTypeId: "00000000-0000-0000-0000-000000000000",
      trapTypeName: "Ovitrampa",
      trapName: "armadilhaOvos",
    },
  ]);
  const [trapTypeOptions, setTrapTypeOptions] = useState([]);
  const [speciesOptions, setSpeciesOptions] = useState([]);
  const [selectedSpecie, setSelectedSpecie] = useState(null);
  const [ActiveTrapMapInfo, setActiveTrapMapInfo] = useState(null);
  const [chartLabels, setChartLabels] = useState([]);
  const [chartPeriods, setChartPeriods] = useState([]);
  const [isFilterVisible, setFilterVisibility] = useState(true);
  const [applyFilterButton, setApplyFilterButton] = useState(false);
  const [months, setFilterMonths] = useState([]);
  const [year, setFilterYear] = useState([]);
  const [weeks, setFilterWeeks] = useState([]);
  const [weekValue1, setWeekValue1] = useState([]);
  const [weekValue2, setWeekValue2] = useState([]);
  const [demand, setFilterDemand] = useState(
    "00000000-0000-0000-0000-000000000000"
  );
  const [employee, setFilterEmployee] = useState(
    "00000000-0000-0000-0000-000000000000"
  );
  const [team, setFilterTeam] = useState(
    "00000000-0000-0000-0000-000000000000"
  );
  const [selectedTerritorializations, setSelectedTerritorializations] =
    useState([]);
  const teamEmployees = useRef([]);
  const [todayEpidemiologicalWeek, setTodayEpidemiologicalWeek] = useState("1");
  const [trapsList, setTrapsList] = React.useState(null);
  const [trapStatus, setTrapStatus] = React.useState(null);
  const [isTrapsListLoading, setIsTrapsListLoading] = React.useState(false);
  const [updatedData, setUpdatedData] = useState(false);
  const [trapToCenterOnMapPosition, setTrapToCenterOnMapPosition] =
    React.useState([]);
  const [trapToCenterNumber, setTrapToCenterNumber] = React.useState([]);
  const [isConsolidatedModalOpen, setIsConsolidatedModalOpen] =
    React.useState(false);
  const [consolidatedReportData, setConsolidatedReportData] =
    React.useState(null);
  const [isConsolidatedReportLoading, setIsConsolidatedReportLoading] =
    React.useState(false);
  const [consolidatedTypeLayersOptions, setConsolidatedTypeLayersOptions] =
    React.useState([]);
  const [consolidatedTrapTypeOptions, setConsolidatedTrapTypeOptions] =
    React.useState([]);
  const [selectedTypeLayerOption, setSelectedTypeLayerOption] = React.useState({
    label: "Selecione uma camada",
    value: "",
  });
  const [selectedTrapTypeOption, setSelectedTrapTypeOption] = React.useState({
    label: "Selecione um tipo armadilha",
    value: "",
  });
  const [totalConsolidatedInstalledTraps, setTotalConsolidatedInstalledTraps] =
    React.useState(0);
  const [trapsPageFilters, setTrapsPageFilters] = React.useState({
    selectedTypeLayerSubtypeOption: {
      label: "Selecione",
      value: "00000000-0000-0000-0000-000000000000",
    },
    selectedSchoolType: {
      label: "Selecione",
      value: "00000000-0000-0000-0000-000000000000",
    },
    selectedProjects: [],
  });
  const [projectsList, setProjectsList] = React.useState([]);

  React.useEffect(() => {
    getAllTypeLayers();
    getAllTrapTypes();
    getAllProjectsList();
  }, []);

  const getAllProjectsList = async () => {
    const DEFAULT_ERROR_MESSAGE =
      "Ocorreu um erro ao buscar pela lista de projetos. Verifique sua conexão com a internet e tente novamente mais tarde. Caso o erro persista, entre em contato com a nossa equipe.";

    try {
      const { data, status } = await getProjects();

      if (status !== 200) throw new Error(DEFAULT_ERROR_MESSAGE);

      const formatedProjectsList = data.map(({ name, id }) => {
        return {
          label: name,
          value: id,
        };
      });

      setProjectsList(formatedProjectsList);
    } catch (error) {
      if (error instanceof Error) {
        toast.error(DEFAULT_ERROR_MESSAGE);
        console.error(error.message);
      }
    }
  };

  const handleFiltersChange = (filterName, filterValue) => {
    setTrapsPageFilters((previousValues) => {
      const newFiltersValues = { ...previousValues };
      newFiltersValues[filterName] = filterValue;

      return newFiltersValues;
    });
  };

  const getAllTypeLayers = async () => {
    try {
      const { data, status } = await getAllTypeTerritorializations();
      if (status !== 200)
        throw new Error("Ocorreu um erro ao buscar pelos tipos de camadas");

      const formatedTypeLayers = data.map(({ name, id }) => {
        return {
          label: name,
          value: id,
        };
      });

      setConsolidatedTypeLayersOptions(formatedTypeLayers);
    } catch (error) {
      console.error(error);
    }
  };

  const getAllTrapTypes = async () => {
    try {
      const { data, status } = await getTrapsTypes();
      if (status !== 200)
        throw new Error("Ocorreu um erro ao buscar pelos tipos de armadilhas");

      const formatedTrapTypes = data.map(({ id, name }) => {
        return {
          label: ARMADILHA_INFO[name].apelido,
          value: id,
        };
      });

      setConsolidatedTrapTypeOptions(formatedTrapTypes);
    } catch (error) {
      console.error(error);
    }
  };

  const handleConsolidatedReportButtonClick = async () => {
    if (
      selectedTypeLayerOption.value === "" ||
      selectedTrapTypeOption.value === ""
    ) {
      toast.error(
        "É necessário informar a Camada e o Tipo de armadilha para gerar o Relatório Consolidado"
      );

      return;
    }

    const filters = {
      typeLayerId: selectedTypeLayerOption.value,
      trapTypeId: selectedTrapTypeOption.value,
    };

    try {
      setIsConsolidatedReportLoading(true);

      const { data, status } = await fetchTrapsDensity(filters);
      if (status !== 200)
        throw new Error("Ocorreu um erro ao buscar pelos dados do consolidado");

      let totalTrapsNumber = 0;

      const formatedConsolidatedReport = data.map(({ name, quantity }) => {
        totalTrapsNumber += quantity;

        return {
          name,
          quantity,
        };
      });

      setTotalConsolidatedInstalledTraps(totalTrapsNumber);
      setConsolidatedReportData(formatedConsolidatedReport);
    } catch (error) {
      console.error(error);
    } finally {
      setIsConsolidatedReportLoading(false);
    }
  };

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const getStartDate = () => {
    const date = new Date();

    date.setMonth(date.getMonth() - 1);

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return year + "-" + month + "-" + day;
  };

  const getEndDate = (isFilter = false) => {
    let date = new Date();

    if (!isFilter) date.setDate(date.getDate() + 1);

    const endDateString =
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1) +
      "-" +
      (date.getDate() < 10 ? "0" + date.getDate() : date.getDate());

    return endDateString;
  };

  useEffect(() => {
    const Pgs = {
      showRemovedTrapsInTable: true,
    };

    setPgs(Pgs);

    setStartDate(getStartDate());

    setTodayEpidemiologicalWeek(getTodayEpidemiologicalWeek());

    setEndDate(getEndDate(true));

    const reqTrapTypes = getTrapsTypes();
    reqTrapTypes.then((response) => {
      setTrapTypeOptions(response.data);
      if (response.data.length > 0) {
        let index;

        const organizationId = getUserData("organizationId");

        if (
          organizationId === "24d125ef-15e6-4bd9-9111-c0b3d50a8009" ||
          organizationId === "559a16ab-5da8-41ba-96b8-6e9f55feddd8" ||
          organizationId === "b2e9a6f4-8a10-4b38-b80c-95cf0aeeed30" ||
          organizationId === "4d4b0460-c8de-474a-9bb9-44e2ffa272b6" ||
          organizationId === "d67ef2b0-92b1-40bf-aba6-0e06070e6091" ||
          organizationId === "42a4ea68-1e69-428c-9498-e4727d30dd20" ||
          organizationId === "24a480de-51af-45a0-93c9-12499d329310" ||
          organizationId === "ed13d1fc-5804-48f1-a695-cdacfdcbd2ad"
        )
          index = response.data.findIndex(
            (tpType) => tpType.name === "armadilhaDisseminadoraInseticida"
          );
        else {
          index = response.data.findIndex(
            (tpType) => tpType.name === "armadilhaOvos"
          );
        }

        var trap = {
          trapTypeId: response.data[index].id,
          trapTypeName: ARMADILHA_INFO[response.data[index].name].apelido,
          trapName: response.data[index].name,
        };
        setTrapType(trap);

        let traps = [];
        response.data.map((trap) => {
          if (trap.name) {
            traps.push({
              trapTypeId: trap.id,
              trapTypeName: ARMADILHA_INFO[trap.name].apelido,
              trapName: trap.name,
            });
          }
        });
        setTrapTypes(traps);
      }

      applyFilter();
    });

    const species = [];
    fetchAnimalGenres("178886c4-a41c-4d15-ac3c-a58806bb8df9").then(
      async (response) => {
        for (const genre of response.data) {
          let speciesRequest = await fetchAnimalSpecies(genre.id);
          for (const specie of speciesRequest.data) {
            species.push({
              label: specie.name,
              value: specie.id,
            });
          }
        }

        setSpeciesOptions(species);
      }
    );
  }, []);

  const getUserUvisName = (id) => {
    if (organizationId !== "559a16ab-5da8-41ba-96b8-6e9f55feddd8") return "";
    if (!id) return "Não encontrado";
    if (!employeesList || employeesList.length === 0) return;

    const employeeData = employeesList.find(
      (employee) => employee.userId === id
    );

    if (!employeeData || !employeeData.uvis) return "Não encontrado";

    return employeeData.uvis;
  };

  const fetchActiveTrapsData = async (endDateFunctional) => {
    const organizationId = getUserData("organizationId");
    const userIds = [];

    if (team && team !== "00000000-0000-0000-0000-000000000000") {
      team.members.map((emp) => {
        if (emp.userId !== "00000000-0000-0000-0000-000000000000") {
          userIds.push(emp.userId);
        }
      });
    } else if (
      employee &&
      employee !== "00000000-0000-0000-0000-000000000000"
    ) {
      userIds.push(employee);
    }

    const filter = {
      organizationId,
      endDate: endDateFunctional.toISOString(),
      showRemovedTraps: pgs.showRemovedTrapsInTable,
      usersIds: userIds,
      demandId: "00000000-0000-0000-0000-000000000000",
      territorializations: selectedTerritorializations.map(
        ({ value }) => value
      ),
      typeLayerId: trapsPageFilters.selectedSchoolType.value,
      typeLayerSubtypeId: trapsPageFilters.selectedTypeLayerSubtypeOption.value,
      typeLayerSubtypesIds:
        trapsPageFilters.selectedSchoolType.value ===
          "eb3d927c-2ef0-41ab-91fd-7917f32486e8" &&
        trapsPageFilters.selectedTypeLayerSubtypeOption.value ===
          "00000000-0000-0000-0000-000000000000"
          ? PARTICIPANTS_SCHOOLS_DATA.map(({ value }) => value)
          : trapsPageFilters.selectedSchoolType.value ===
              "79d665fe-ac45-4016-a709-b0a31b3019c5" &&
            trapsPageFilters.selectedTypeLayerSubtypeOption.value ===
              "00000000-0000-0000-0000-000000000000"
          ? NOT_PARTICIPANTS_SCHOOLS_DATA.map(({ value }) => value)
          : [],
      projectsId:  trapsPageFilters.selectedProjects.map(({value}) => value),
    };

    
    try {
      setIsTrapsListLoading(true);
      
      const { data, status } = await getActiveTraps(filter);
      if (status !== 200)
        throw new Error("Ocorreu um erro ao buscar pela lista de armadilhas");

      // console.log(data);

      if (organizationId === "559a16ab-5da8-41ba-96b8-6e9f55feddd8") {
        const formatedTraps = data.map((trap) => {
          return {
            ...trap,
            trapName: trap.trapTypeName,
            name: ARMADILHA_INFO[trap.trapTypeName].apelido,
            address: `${
              trap.realty.subpremisse
                ? trap.realty.subpremisse
                : trap.realty.subpremise
            }, ${trap.realty.number}`,
            installDate: trap.dateInstalled,
            installTime: trap.dateInstalled,
            lastReadDate: trap.lastReadDate,
            lastReadTime: trap.lastReadDate,
            installTypingDate: trap.typingDate,
            installTypingTime: trap.typingDate,
            lastReadTypingDate: trap.lastReadTypingDate,
            lastReadTypingTime: trap.lastReadTypingDate,
            typistId: trap.typistId
              ? trap.typistId
              : "00000000-0000-0000-0000-000000000000",
            installUvis: getUserUvisName(trap.whoInstallId),
            maintenanceUvis: getUserUvisName(trap.whoLastReadId),
          };
        });

        return formatedTraps;
      }

      const formatedTraps = data.map((trap) => {
        return {
          ...trap,
          trapName: trap.trapTypeName,
          name: ARMADILHA_INFO[trap.trapTypeName].apelido,
          address: `${
            trap.realty.subpremisse
              ? trap.realty.subpremisse
              : trap.realty.subpremise
          }, ${trap.realty.number}`,
          installDate: trap.dateInstalled,
          installTime: trap.dateInstalled,
          lastReadDate: trap.lastReadDate,
          lastReadTime: trap.lastReadDate,
          installTypingDate: trap.typingDate,
          installTypingTime: trap.typingDate,
          lastReadTypingDate: trap.lastReadTypingDate,
          lastReadTypingTime: trap.lastReadTypingDate,
          typistId: trap.typistId
            ? trap.typistId
            : "00000000-0000-0000-0000-000000000000",
          projects: trap.projects.length === 0 ? "" : trap.projects.map(({ name }) => name).join(", "),
        };
      });

      return formatedTraps;
    } catch (error) {
      console.error(error);
    } finally {
      setIsTrapsListLoading(false);
    }
  };

  useEffect(() => {
    if (chartLabels.length > 0) {
      fetchData();
    }
  }, [chartLabels]);

  async function applyFilter() {
    if (year.length > 0) {
      setActiveTrapMapLoading(true);
      buildYearsLabelsAndPeriods(year);
    } else {
      setActiveTrapMapLoading(true);
      var startDateAux = getStartDate();
      var endDateAux = getEndDate();
      buildLabelsAndPeriods(startDateAux, endDateAux);
    }
  }

  function changePeriod(e, field) {
    switch (field) {
      case "Year":
        if (e === null) {
          setFilterYear([]);
          setFilterMonths([]);
          setFilterWeeks([]);
          break;
        } else {
          e = e.sort((a, b) => parseInt(a.value) - parseInt(b.value));
        }

        setFilterYear(e !== null ? e : []);
        break;

      case "Month":
        if (e !== null) {
          setFilterWeeks([]);
          e = e.sort((a, b) => parseInt(a.value) - parseInt(b.value));
        }

        setFilterMonths(e !== null ? e : []);
        break;

      case "EpiWeek":
        if (e !== null) {
          setFilterMonths([]);
          e = e.sort((a, b) => parseInt(a.value) - parseInt(b.value));
        }

        setFilterWeeks(e !== null ? e : []);
        break;

      case "Week1":
        if (e !== null) {
          setFilterMonths([]);
        }
        setWeekValue1(e !== null ? e : []);
        break;

      case "Week2":
        if (e !== null) {
          setFilterMonths([]);
        }
        setWeekValue2(e !== null ? e : []);
        break;
    }
  }

  function handleChangeFilter(field, value) {
    switch (field) {
      case "startDate":
        if (moment(value).isAfter(endDate)) {
          toast.error("Período de data inválido!");
        } else {
          setStartDate(value);
        }
        break;
      case "endDate":
        if (moment(value).isBefore(startDate)) {
          toast.error("Período de data inválido!");
        } else {
          setEndDate(value);
        }
        break;
      case "trapType":
        const selectedTrapType = trapTypeOptions.filter((tp) => tp.id == value);

        //setando a especie para aedes aegypti se marcar adultrap
        if (ARMADILHA_INFO[selectedTrapType[0].name].apelido == "Adultrap") {
          setSelectedSpecie("3ce3a48f-9555-4b58-8bb9-18c8a4c7549c");
        } else {
          setSelectedSpecie(null);
        }

        setTrapTypeAUX({
          trapTypeId: selectedTrapType[0].id,
          trapTypeName: ARMADILHA_INFO[selectedTrapType[0].name].apelido,
          trapName: selectedTrapType[0].name,
        });
        toast.info(
          "Tipo de armadilha alterado! Clique no botão para filtrar novamente."
        );
        break;
      case "demandId":
        if (value) {
          setFilterDemand(value.value);
        } else {
          setFilterDemand("00000000-0000-0000-0000-000000000000");
        }
        break;
      case "teamId":
        if (value) {
          let employeeUserIds = new Array();
          for (const member of value.members) {
            if (member.userId) {
              employeeUserIds.push(member.userId);
            }
          }
          teamEmployees.current = employeeUserIds;
        } else {
          teamEmployees.current = new Array();
        }

        setFilterTeam(value);
        break;
      case "employeeId":
        if (value) {
          setFilterEmployee(value.value);
          toast.info(
            "Funcionário alterado! Clique no botão para filtrar novamente."
          );
        } else {
          setFilterEmployee("00000000-0000-0000-0000-000000000000");
        }
        break;
      case "territorialization":
        if (value !== null) {
          //colocando as territorializações para ficarem selecionadas no filtro.
          setSelectedTerritorializations(value);
        } else {
          setSelectedTerritorializations([]);
        }
        break;
      case "specie":
        setSelectedSpecie(value);
        break;
    }
  }

  const buildPeriods = async () => {
    let filterPeriod = null;
    let previousFilterPeriod = null;

    let currentDate = moment.utc(endDate);
    for (let i = chartPeriods.length - 1; i >= 0; i--) {
      if (filterPeriod === null) {
        const periods = chartPeriods[i].periods;
        for (let j = periods.length - 1; j >= 0; j--) {
          if (
            currentDate.isBetween(
              moment(periods[j].beginDate),
              moment(periods[j].finalDate)
            ) ||
            currentDate.isSameOrAfter(moment(periods[j].finalDate))
          ) {
            filterPeriod = periods[j];
            previousFilterPeriod = periods[j - 1];
            break;
          }
        }
      } else {
        break;
      }

      // aqui
      if (filterPeriod === null && i === 0) {
        filterPeriod = {
          beginDate: currentDate.format("YYYY-MM-DD"),
          finalDate: currentDate.format("YYYY-MM-DD"),
        };
        break;
      }
    }

    return filterPeriod;
  };

  const getUser = async () => {
    var userIds = [];

    if (team && team != "00000000-0000-0000-0000-000000000000") {
      team.members.map((emp) => {
        if (emp.userId != "00000000-0000-0000-0000-000000000000") {
          userIds.push(emp.userId);
        }
      });
    } else if (employee && employee != "00000000-0000-0000-0000-000000000000") {
      userIds.push(employee);
    }

    return userIds;
  };

  async function fetchTrapStatus() {
    var todayMinusFifteen = new Date();
    todayMinusFifteen.setUTCDate(todayMinusFifteen.getUTCDate() - 15);

    var beginDate = new Date(startDate).toISOString().slice(0, 10);
    var finalDate = new Date(endDate).toISOString().slice(0, 10);

    if (!applyFilterButton) {
      beginDate = todayMinusFifteen.toISOString().slice(0, 10);
      finalDate = new Date().toISOString().slice(0, 10);
    }

    let filterPeriod = [
      {
        finalDate: finalDate,
        beginDate: beginDate,
      },
    ];

    let userIds = await getUser();

    const filter = [
      {
        organizationId,
        periods: filterPeriod,
        usersIds: userIds,
        demandId: demand,
        trapTypeId: trapType.trapTypeId,
        territorializations: selectedTerritorializations.map(
          ({ value }) => value
        ),
      },
    ];

    try {
      const response = await getTrapStatusCard(filter);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async function fetchActiveTrapToMap(employees, endDate) {
    let filterPeriod = await buildPeriods();

    const activeTrapPromises = trapTypes.map(async (thisTrapType) => {
      const organizationId = getUserData("organizationId");
      const userIds = [];

      if (team && team !== "00000000-0000-0000-0000-000000000000") {
        team.members.map((emp) => {
          if (emp.userId !== "00000000-0000-0000-0000-000000000000") {
            userIds.push(emp.userId);
          }
        });
      } else if (
        employee &&
        employee !== "00000000-0000-0000-0000-000000000000"
      ) {
        userIds.push(employee);
      }

      const filterMap = {
        organizationId,
        period: filterPeriod,
        trapTypeId: thisTrapType.trapTypeId,
        usersIds: userIds,
        demandId: demand,
        territorializations: selectedTerritorializations.map(
          ({ value }) => value
        ),
        typeLayerId: trapsPageFilters.selectedSchoolType.value,
        typeLayerSubtypeId:
          trapsPageFilters.selectedTypeLayerSubtypeOption.value,
        typeLayerSubtypesIds:
          trapsPageFilters.selectedSchoolType.value ===
            "eb3d927c-2ef0-41ab-91fd-7917f32486e8" &&
          trapsPageFilters.selectedTypeLayerSubtypeOption.value ===
            "00000000-0000-0000-0000-000000000000"
            ? PARTICIPANTS_SCHOOLS_DATA.map(({ value }) => value)
            : trapsPageFilters.selectedSchoolType.value ===
                "79d665fe-ac45-4016-a709-b0a31b3019c5" &&
              trapsPageFilters.selectedTypeLayerSubtypeOption.value ===
                "00000000-0000-0000-0000-000000000000"
            ? NOT_PARTICIPANTS_SCHOOLS_DATA.map(({ value }) => value)
            : [],
        projectsId: trapsPageFilters.selectedProjects.map(({value}) => value)
      };

      try {
        const response = await getActiveTrapsToMap(filterMap);
        return { data: response.data, trapType: thisTrapType.trapTypeName };
      } catch (error) {
        console.error(error);
        throw error;
      }
    });

    const activeTrap = await Promise.all(activeTrapPromises);

    setActiveTrapMapInfo(activeTrap);
    setActiveTrapMapLoading(false);
  }

  const fetchAllComponentData = async () => {
    const employees = teamEmployees.current;

    const endDateFunctional = new Date(
      Date.UTC(
        parseInt(endDate.substring(0, 4)),
        parseInt(endDate.substring(5, 7)) - 1,
        parseInt(endDate.substring(8, 10))
      )
    );
    endDateFunctional.setDate(endDateFunctional.getDate() + 1);

    if (
      employee != "00000000-0000-0000-0000-000000000000" &&
      !employees.includes(employee)
    ) {
      employees.push(employee);
    }

    const formatedTrapsList = fetchActiveTrapsData(endDateFunctional);
    const activeTrapsToMap = fetchActiveTrapToMap(
      employees,
      endDateFunctional.toISOString().slice(0, 10)
    );
    const trapStatusPromise = fetchTrapStatus();

    const promises = await Promise.all([formatedTrapsList, activeTrapsToMap]);

    const trapStatus = await trapStatusPromise;

    return { trapData: promises, trapStatus: trapStatus };
  };

  const fetchData = async () => {
    const formatedTrapsList = await fetchAllComponentData();

    const [trapData] = formatedTrapsList.trapData;
    const [trapStatus] = formatedTrapsList.trapStatus;

    setTrapsList(trapData);
    setTrapStatus(trapStatus);

    setUpdatedData(true);
  };

  async function buildLabelsAndPeriods(startDate, endDate) {
    const labels = await buildEpidemiologicalLabels({ startDate, endDate }); //semana epidemiológica
    const periods = new Array({ year: "", periods: [] });
    let labelsToCharts = new Array();
    for (let i = 0; i < labels.length; i++) {
      for (
        let labelIndex = 0;
        labelIndex < labels[i].labels.length;
        labelIndex++
      ) {
        const { finalDate, beginDate } = await getEpidemiologicalPeriod({
          labels: labels[i].labels,
          labelIndex,
          startDate,
          endDate,
          year: labels[i].year,
          lastYear: labels[labels.length - 1].year,
          firstYear: labels[0].year,
        }); //semana epidemiológica
        labelsToCharts.push(
          labels[i].labels[labelIndex] + "/" + labels[i].year
        );
        periods[0].periods.push({ finalDate, beginDate });
      }
    }

    setChartPeriods(periods);
    setChartLabels(labelsToCharts);
  }

  async function buildYearsLabelsAndPeriods(years = new Array()) {
    const labels = new Array();
    const periods = new Array();

    if (months.length === 0 && weeks.length === 0) {
      for (let i = 1; i <= 53; i++) {
        labels.push(i);
      }

      for (let i = 0; i < years.length; i++) {
        const epidemiologicalWeek = getWeeks().filter(
          (ew) => ew.year === years[i].value
        );
        periods.push({
          year: years[i].value,
          periods: epidemiologicalWeek[0].weeks.map((ew) => {
            return {
              finalDate: moment(ew.endDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
              beginDate: moment(ew.beginDate, "DD/MM/YYYY").format(
                "YYYY-MM-DD"
              ),
            };
          }),
        });
      }
    } else if (weeks.length === 0) {
      months.forEach((month) => {
        labels.push(month.label);
      });

      for (let i = 0; i < years.length; i++) {
        const monthPeriods = [];

        for (let j = 0; j < months.length; j++) {
          const beginDate = moment(
            new Date(years[i].value, months[j].value, 1)
          ).format("YYYY-MM-DD");
          const finalDate = moment(beginDate)
            .add(1, "month")
            .subtract(1, "day")
            .format("YYYY-MM-DD");
          monthPeriods.push({ beginDate, finalDate });
        }

        periods.push({ year: years[i].value, periods: monthPeriods });
      }
    } else {
      weeks.forEach((weeks) => {
        labels.push(weeks.label);
      });

      for (let i = 0; i < years.length; i++) {
        const weeksPeriods = [];
        const epiWeeks = getWeeks().filter((ew) => ew.year === years[i].value);

        for (let j = 0; j < weeks.length; j++) {
          if (epiWeeks[0].weeks[weeks[j].value] !== undefined) {
            const beginDate = moment(
              epiWeeks[0].weeks[weeks[j].value].beginDate,
              "DD/MM/YYYY"
            ).format("YYYY-MM-DD");
            const finalDate = moment(
              epiWeeks[0].weeks[weeks[j].value].endDate,
              "DD/MM/YYYY"
            ).format("YYYY-MM-DD");
            weeksPeriods.push({ beginDate, finalDate });
          }
        }
        periods.push({ year: years[i].value, periods: weeksPeriods });
      }
    }

    setChartPeriods(periods);
    setChartLabels(labels);
  }

  const styles = {
    grid: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    row: {
      marginLeft: 0,
      marginRight: 0,
    },
    col: {
      paddingLeft: 0,
      paddingRight: "0.2vw",
    },
  };

  const selectComponentStyles = {
    control: (styles) => ({
      ...styles,
      minHeight: "2.90rem",
      borderRadius: "0.370rem",
    }),
    option: (styles) => ({ ...styles, fontSize: "0.875rem" }),
    valueContainer: (styles) => ({
      ...styles,
      fontSize: "0.875rem",
      color: "#8898aa",
    }),
    singleValue: (styles) => ({
      ...styles,
      fontSize: "0.875rem",
      color: "#8898aa",
    }),
    placeholder: (styles) => ({
      ...styles,
      fontSize: "0.875rem",
      color: "#8898aa",
    }),
  };

  const consolidatedReportTableColumns = [
    {
      name: "Nome da territorialização",
      selector: "name",
      sortable: true,
    },
    {
      name: "Quantidade de armadilhas",
      selector: "quantity",
      sortable: true,
    },
  ];

  return (
    <>
      <Modal
        size="lg"
        fade
        isOpen={isConsolidatedModalOpen}
        toggle={() => setIsConsolidatedModalOpen(!isConsolidatedModalOpen)}
      >
        <ModalHeader>
          <h2>Relatório consolidado</h2>
        </ModalHeader>
        <ModalBody>
          <Row style={{ marginBottom: "2rem" }}>
            <Col xl="6">
              <span style={{ fontWeight: "bold" }}>Camada</span>
              <Select
                styles={selectComponentStyles}
                placeholder={"Selecione"}
                isClearable={false}
                options={consolidatedTypeLayersOptions}
                value={selectedTypeLayerOption}
                onChange={(selectedOptionObject) =>
                  setSelectedTypeLayerOption(selectedOptionObject)
                }
              />
            </Col>
            <Col xl="6">
              <span style={{ fontWeight: "bold" }}>Tipo de armadilha</span>
              <Select
                styles={selectComponentStyles}
                placeholder={"Selecione"}
                isClearable={false}
                options={consolidatedTrapTypeOptions}
                value={selectedTrapTypeOption}
                onChange={(selectedOptionObject) =>
                  setSelectedTrapTypeOption(selectedOptionObject)
                }
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: "2rem" }}>
            <Col>
              <button
                className="btn btn-primary"
                onClick={() => handleConsolidatedReportButtonClick()}
              >
                Gerar relatório
              </button>
            </Col>
          </Row>
          <Row>
            <Col>
              {isConsolidatedReportLoading ? (
                <TableLoadingSkeleton />
              ) : consolidatedReportData ? (
                <>
                  <Card className="shadow">
                    <CardHeader
                      className="border-0"
                      style={{ paddingBottom: "0px" }}
                    >
                      <Row
                        className="align-items-center"
                        style={{ marginBottom: ".5rem" }}
                      >
                        <div className="col">
                          <h2 className="mb-0">Consolidado</h2>
                          <br />
                          <p style={{ fontWeight: "bolder" }}>
                            Total de armadilhas:{" "}
                            {totalConsolidatedInstalledTraps}
                          </p>
                        </div>
                      </Row>
                    </CardHeader>
                    <Row style={{ textAlign: "center", marginBottom: "1rem" }}>
                      <Col>
                        <CsvDownloadButton
                          className="btn btn-primary"
                          data={consolidatedReportData}
                          filename={`consolidado${moment().format(
                            "YYYY-MM-DD"
                          )}`}
                          style={{ width: "330px" }}
                        >
                          <i className="fa fa-download" /> Baixar CSV do
                          consolidado
                        </CsvDownloadButton>
                      </Col>
                    </Row>
                    <DataTable
                      noHeader
                      defaultSortField="name"
                      defaultSortAsc={true}
                      pagination
                      highlightOnHover
                      responsive
                      columns={consolidatedReportTableColumns}
                      data={consolidatedReportData}
                      noDataComponent={"Nenhum registro encontrado."}
                    />
                  </Card>
                </>
              ) : null}
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Row>
            <Col>
              <button
                className="btn btn-primary"
                onClick={() =>
                  setIsConsolidatedModalOpen(!isConsolidatedModalOpen)
                }
              >
                FECHAR
              </button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
      <ToastContainer />
      <Container fluid className="bg-gradient-info pt-5 pb-6 pt-md-8 mb-5">
        <Container className="filter-container">
          <button
            className="btn btn-primary hide-btn"
            onClick={() => {
              setFilterVisibility(!isFilterVisible);
            }}
          >
            {isFilterVisible ? "Esconder Filtro" : "Mostrar Filtro"}
          </button>

          <button
            className="btn btn-primary hide-btn"
            onClick={() => {
              setIsConsolidatedModalOpen(true);
            }}
          >
            Relatório Consolidado
          </button>

          {isFilterVisible ? (
            <button
              className="btn btn-primary hide-btn"
              onClick={() => {
                setApplyFilterButton(true);
                if (
                  trapTypeAUX.trapTypeId !=
                  "00000000-0000-0000-0000-000000000000"
                ) {
                  setTrapType(trapTypeAUX);
                  applyFilter();
                } else {
                  applyFilter();
                }
              }}
            >
              Filtrar
            </button>
          ) : null}
        </Container>

        {isFilterVisible ? (
          <>
            <VisitOperacionalFilterPresentational
              handleChangeFilter={handleChangeFilter}
              employeeId={employee}
              employeeIdList={employeesList}
              setEmployee={setEmployee}
              demandId={demand}
              demandIdList={demandsList}
              teamId={team}
              teamIdList={teamsList}
            />
            <Row style={{ display: "flex", gapTop: "5px" }}>
              <MainPeriodComponent
                months={months}
                years={year}
                weeks={weeks}
                weekValue1={weekValue1}
                weekValue2={weekValue2}
                showWeekToWeek
                changePeriod={changePeriod}
              />
              <EntomologicoFilterPresentational
                startDate={startDate}
                endDate={endDate}
                trapTypeId={trapType.trapTypeId}
                trapTypeIdAUX={trapTypeAUX.trapTypeId}
                trapTypeOptions={trapTypeOptions}
                handleChangeFilter={handleChangeFilter}
                speciesOptions={speciesOptions}
                selectedSpecie={selectedSpecie}
                applyFilter={applyFilter}
              />
              <GeographicFilterPresentational
                Territorializations={territorializationsList}
                handleChangeFilter={handleChangeFilter}
                projectsList={projectsList}
                trapsPageFilters={trapsPageFilters}
                handleFiltersChange={handleFiltersChange}
                SelectedTerritorializations={selectedTerritorializations}
              />
            </Row>
            <Row>
              <Col className="col-4 col-md-4 col"></Col>
              <TodayEpidemiologicalWeekComponent
                todayEpidemiologicalWeek={todayEpidemiologicalWeek}
              />
              <Col className="col-4 col-md-4 col"></Col>
            </Row>
            <br />
          </>
        ) : null}

        {trapType.trapTypeId !== "00000000-0000-0000-0000-000000000000" &&
          webVersion !== "normal_version" && (
            <TotalTrapsNumberCardsWrapper
              selectedTrapTypeData={trapType}
              traps={ActiveTrapMapInfo}
              organizationId={getUserData("organizationId")}
            />
          )}

        {/* <Row style={{ ...styles.row, justifyContent: "center" }}>
          {isTrapsListLoading ? (
            <>
              <Col style={styles.col} xl={3}>
                <CardLoadingSkeleton />
              </Col>
              <Col style={styles.col} xl={3}>
                <CardLoadingSkeleton />
              </Col>
              <Col style={styles.col} xl={3}>
                <CardLoadingSkeleton />
              </Col>
            </>
          ) : (
            trapStatus && (
              <TrapStatusCards
                trapStatus={trapStatus}
                startDate={startDate}
                endDate={endDate}
                applyFilterButton={applyFilterButton}
              />
            )
          )}
        </Row> */}

        {organizationId !== "559a16ab-5da8-41ba-96b8-6e9f55feddd8" && (
          <Row style={{ ...styles.row, justifyContent: "center" }}>
            {isTrapsListLoading ? (
              <>
                <Col style={styles.col} xl={3}>
                  <CardLoadingSkeleton />
                </Col>
                <Col style={styles.col} xl={3}>
                  <CardLoadingSkeleton />
                </Col>
                <Col style={styles.col} xl={3}>
                  <CardLoadingSkeleton />
                </Col>
              </>
            ) : (
              trapsList &&
              trapType.trapTypeId !==
                "00000000-0000-0000-0000-000000000000" && (
                <StatusColorTrapsCardsWrapper
                  variant={trapType.trapName}
                  trapsList={trapsList}
                  selectedTrapTypeData={trapType}
                />
              )
            )}
          </Row>
        )}
      </Container>
      <Container fluid className="mt--7">
        <Row className="mb-5">
          <Col xl="12" className="mb-5 mb-xl-0">
            {isActiveTrapMapLoading ? (
              <MapLoadingSkeleton />
            ) : (
              <ActiveMapContainer
                trapMapInfo={ActiveTrapMapInfo}
                territorializationsList={territorializationsList}
                typeLayerSubtypeTerritorializationsList={
                  typeLayerSubtypeTerritorializationsList
                }
                territorializationFiltered={selectedTerritorializations}
                trapTypeName={trapType.trapTypeName}
                trapToCenterOnMapPosition={trapToCenterOnMapPosition}
                setTrapToCenterOnMapPosition={setTrapToCenterOnMapPosition}
                trapToCenterNumber={trapToCenterNumber}
                setTrapToCenterNumber={setTrapToCenterNumber}
                setModal2={setModal2}
                modal2={modal2}
                setTrapToUpdateData={setTrapToUpdateData}
                trapToUpdateData={trapToUpdateData}
              />
            )}
          </Col>
        </Row>
        <Row className="mb-5">
          <Col xl="12" className="mb-5 mb-xl-0">
            {isTrapsListLoading || !trapsList ? (
              <TableLoadingSkeleton />
            ) : (
              <DashboardTrapsTable
                consolidatedTrapTypeOptions={consolidatedTrapTypeOptions}
                setUpdatedData={setUpdatedData}
                updatedData={updatedData}
                employeesId={employeeState}
                traps={trapsList}
                selectedTrapType={trapType}
                trapTypeOptions={trapTypeOptions}
                employeeList={employeesList}
                setTrapToCenterOnMapPosition={setTrapToCenterOnMapPosition}
                setTrapToCenterNumber={setTrapToCenterNumber}
                territorializationsList={territorializationsList}
                setModal2={setModal2}
                modal2={modal2}
                setTrapToUpdateData={setTrapToUpdateData}
                trapToUpdateData={trapToUpdateData}
              />
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Traps;
