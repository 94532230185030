import { useContextSelector } from "use-context-selector";
import { PositivityIndexMapContext } from "../Contexts/PositivityIndexMapContext";

const usePositivityIndexMap = () => {
  const positivityIndexGroupPointsList = useContextSelector(
    PositivityIndexMapContext,
    (context) => context.positivityIndexGroupPointsList
  );
  const setPositivityIndexGroupPointsList = useContextSelector(
    PositivityIndexMapContext,
    (context) => context.setPositivityIndexGroupPointsList
  );
  
  const positivityIndexActualWeekArrayIndex = useContextSelector(
    PositivityIndexMapContext,
    (context) => context.positivityIndexActualWeekArrayIndex
  );
  const setPositivityIndexActualWeekArrayIndex = useContextSelector(
    PositivityIndexMapContext,
    (context) => context.setPositivityIndexActualWeekArrayIndex
  );

  return {
    positivityIndexGroupPointsList,
    positivityIndexActualWeekArrayIndex,
    setPositivityIndexGroupPointsList,
    setPositivityIndexActualWeekArrayIndex,
  };
};

export default usePositivityIndexMap;
