import React from "react";

import { Col, Row } from "reactstrap";
import {
  GREEN_TRAP_COLOR,
  GREEN_TRAP_STATUS,
  INACTIVE_TRAP_STATUS,
  RED_TRAP_COLOR,
  RED_TRAP_STATUS,
  YELLOW_TRAP_STATUS,
} from "../../../../services/utils/globalFunctions";
import useAppliedFilters from "../Hooks/useAppliedFilters";
import {
  Bar,
} from "recharts";
import BarChartGraphic from "../../../../components/Graphics/Bar/BarChartGraphic";
import { Rectangle } from "@react-google-maps/api";
import moment from "moment";

const useHistogramTrapsSituationsByColorsGraphics = (trapsList) => {
  const [trapsSituationsByColors, setTrapsSituationsByColors] = React.useState({
    green: {},
    yellow: {},
    red: {},
  });

  const { appliedFilters } = useAppliedFilters();

  React.useEffect(() => {
    if (
      !trapsList ||
      appliedFilters.trapType.trapTypeName !==
        "armadilhaDisseminadoraInseticida"
    )
      return;

    const trapsSituationsByColors = [
      {
        situation: "Verde",
        "0 a 10": 0,
        "11 a 20": 0,
        "21 a 30": 0,
      },
      {
        situation: "Amarelo",
        "31 a 40": 0,
        "41 a 50": 0,
        "51 a 60": 0,
      },
      {
        situation: "Vermelho",
        "61 a 90": 0,
        "91 a 120": 0,
        "Mais de 120": 0,
      },
    ];

    const greenTraps = trapsSituationsByColors[0];
    const yellowTraps = trapsSituationsByColors[1];
    const redTraps = trapsSituationsByColors[2];

    for (let i = 0; i < trapsList.length; i++) {
      const {
        colorStatus,
        daysSinceLastActivity,
        status,
        trapType: { name },
      } = trapsList[i];

      if (
        status === INACTIVE_TRAP_STATUS ||
        name !== appliedFilters.trapType.trapTypeName
      )
        continue;

      if (colorStatus === GREEN_TRAP_STATUS) {
        if (daysSinceLastActivity <= 10) {
          greenTraps["0 a 10"]++;
          continue;
        }

        if (daysSinceLastActivity <= 20) {
          greenTraps["11 a 20"]++;
          continue;
        }

        if (daysSinceLastActivity <= 30) {
          greenTraps["21 a 30"]++;
          continue;
        }

        continue;
      }

      if (colorStatus === YELLOW_TRAP_STATUS) {
        if (daysSinceLastActivity <= 40) {
          yellowTraps["31 a 40"]++;
          continue;
        }

        if (daysSinceLastActivity <= 50) {
          yellowTraps["41 a 50"]++;
          continue;
        }

        if (daysSinceLastActivity <= 60) {
          yellowTraps["51 a 60"]++;
          continue;
        }

        continue;
      }

      if (colorStatus === RED_TRAP_STATUS) {
        if (daysSinceLastActivity <= 80) {
          redTraps["61 a 90"]++;
          continue;
        }

        if (daysSinceLastActivity <= 120) {
          redTraps["91 a 120"]++;
          continue;
        }

        if (daysSinceLastActivity > 120) {
          redTraps["Mais de 120"]++;
          continue;
        }

        continue;
      }
    }

    const greenHistogramData = Object.keys(greenTraps)
      .filter((key) => key !== "situation")
      .map((key) => ({
        situation: key,
        Quantidade: greenTraps[key],
      }));

    const yellowHistogramData = Object.keys(yellowTraps)
      .filter((key) => key !== "situation")
      .map((key) => ({
        situation: key,
        Quantidade: yellowTraps[key],
      }));

    const redHistogramData = Object.keys(redTraps)
      .filter((key) => key !== "situation")
      .map((key) => ({
        situation: key,
        Quantidade: redTraps[key],
      }));

    setTrapsSituationsByColors({
      green: greenHistogramData,
      yellow: yellowHistogramData,
      red: redHistogramData,
    });
  }, [trapsList]);

  return { appliedFilters, trapsSituationsByColors };
};

export const HistogramTrapsSituationsByColorsGraphics = ({ trapsList }) => {
  const { appliedFilters, trapsSituationsByColors } =
    useHistogramTrapsSituationsByColorsGraphics(trapsList);

  if (
    appliedFilters.trapType.trapTypeName !== "armadilhaDisseminadoraInseticida"
  )
    return null;

  return (
    <Row className="mb-4">
      <Col xl={4}>
        <BarChartGraphic
          bars={[
            <Bar
              dataKey="Quantidade"
              fill={GREEN_TRAP_COLOR}
              activeBar={<Rectangle fill="pink" stroke="blue" />}
            />,
          ]}
          data={trapsSituationsByColors.green}
          xAxisDataKey="situation"
          headerText={
            <span>
              Histograma das armadilhas <br /> verdes em <br />{" "}
              {moment(appliedFilters.endDate).format("DD/MM/YYYY")}
            </span>
          }
          hideDownloadButton={true}
          hideExpandButton={true}
        />
      </Col>
      <Col xl={4}>
        <BarChartGraphic
          bars={[
            <Bar
              dataKey="Quantidade"
              fill={"#ccc20a"}
              activeBar={<Rectangle fill="pink" stroke="blue" />}
            />,
          ]}
          data={trapsSituationsByColors.yellow}
          headerText={
            <span>
              Histograma das armadilhas <br /> amarelas em <br />{" "}
              {moment(appliedFilters.endDate).format("DD/MM/YYYY")}
            </span>
          }
          hideDownloadButton={true}
          hideExpandButton={true}
          xAxisDataKey="situation"
        />
      </Col>
      <Col xl={4}>
        <BarChartGraphic
          bars={[
            <Bar
              dataKey="Quantidade"
              fill={RED_TRAP_COLOR}
              activeBar={<Rectangle fill="pink" stroke="blue" />}
            />,
          ]}
          data={trapsSituationsByColors.red}
          xAxisDataKey="situation"
          headerText={
            <span>
              Histograma das armadilhas <br /> vermelhas em <br />{" "}
              {moment(appliedFilters.endDate).format("DD/MM/YYYY")}
            </span>
          }
          hideDownloadButton={true}
          hideExpandButton={true}
        />
      </Col>
    </Row>
  );
};
