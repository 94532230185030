import React from "react";

import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Card, CardContent, CardFooter, CardHeader } from "../../Cards/Card";
import ReactTooltip from "react-tooltip";

const CardPresentational = (props) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [screenHeight, setScreenHeight] = React.useState(window.innerHeight);

  React.useEffect(() => {
    const handleResize = () => {
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleModal = () => {
    setIsModalOpen((previousValue) => !previousValue);
  };

  const styles = {
    modalCol: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "0.7rem",
      height: `${screenHeight - 300}px`,
    },
    fullScreenStyle: {
      maxWidth: "100%",
      width: "100vw",
      height: "100vh",
      margin: "0",
      top: "0",
    },
    col: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "25rem",
      marginTop: "0.7rem",
    },
  };

  const headerText = `${props.textCardHeader} ${
    props.typeContainer === "serieHistorica" ? "(Série Histórica)" : ""
  }`;

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        toggle={() => toggleModal()}
        style={styles.fullScreenStyle}
      >
        <ModalHeader toggle={() => toggleModal()}>
          Visualização em tela cheia
        </ModalHeader>
        <ModalBody style={{ height: `${screenHeight - 150}px` }}>
          <Card>
            <CardHeader
              headerText={headerText}
              handleExpandButtonClick={toggleModal}
              showExpandButton={true}
              showButtonsTooltips={false}
              showDownloadDataButton={false}
            />
            <CardContent>
              <Row>
                <Col style={styles.modalCol}>{props.element}</Col>
              </Row>
            </CardContent>
            <CardFooter style={styles.cardFooter}>
              <div></div>
              <img
                style={{ maxWidth: "100px", opacity: 0.5 }}
                src="/static/media/vitec.9bd71d52.png"
                alt="Logo do Vitec"
              />
            </CardFooter>
          </Card>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => toggleModal()}>
            Fechar
          </Button>
        </ModalFooter>
      </Modal>
      <Card>
        <CardHeader
          headerText={headerText}
          handleExpandButtonClick={toggleModal}
          showButtonsTooltips={true}
          showDownloadDataButton={false}
          showExpandButton={true}
        >
          {props.typeContainer === "serieHistorica" ? (
            <>
              <Button
                onClick={() => {
                  props.setTypeContainer("comparativo");
                }}
                color="primary"
                data-tip
                data-for="exchangeComparative"
                style={{ width: "55px", marginRight: "0px" }}
              >
                <i>
                  <img
                    src={require("../../../assets/img/icons/interface/exchange-alt.png")}
                    style={{ width: "13px", height: "13px" }}
                    alt="icone"
                  />
                </i>
              </Button>
              <ReactTooltip effect="solid" type="info" id="exchangeComparative">
                Visualizar comparativo por ano
              </ReactTooltip>
            </>
          ) : props.typeContainer ? (
            <>
              <Button
                onClick={() => {
                  props.setTypeContainer("serieHistorica");
                }}
                color="primary"
                data-tip
                data-for="exchangeHistoricSeries"
                style={{ width: "55px", marginRight: "0px" }}
              >
                <i>
                  <img
                    src={require("../../../assets/img/icons/interface/exchange-alt.png")}
                    style={{ width: "13px", height: "13px" }}
                    alt="icone"
                  />
                </i>
              </Button>
              <ReactTooltip
                effect="solid"
                type="info"
                id="exchangeHistoricSeries"
              >
                Visualizar série histórica
              </ReactTooltip>
            </>
          ) : (
            <></>
          )}
        </CardHeader>
        <CardContent>
          <Row>
            <Col style={styles.col}>{props.element}</Col>
          </Row>
        </CardContent>
        <CardFooter>
          <div></div>
          <img
            style={{ maxWidth: "100px", opacity: 0.5 }}
            src="/static/media/vitec.9bd71d52.png"
            alt="Logo do Vitec"
          />
        </CardFooter>
      </Card>
    </>
  );
};

export default CardPresentational;
