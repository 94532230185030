import React from "react";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "../../../../components/Cards/Card";
import { Map } from "../../../../components/Maps/Map";
import {
  FeatureGroup,
  LayersControl,
  Marker,
  Polygon,
  Popup,
  TileLayer,
  Tooltip,
} from "react-leaflet";
import useAppliedFilters from "../Hooks/useAppliedFilters";
import useGraphicsData from "../Hooks/useGraphicsData";
import { ARMADILHA_INFO } from "../../../../constants/ArmadilhaConstant";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { Button } from "reactstrap";
import ReactTooltip from "react-tooltip";
import Control from "react-leaflet-control";
import { polygon } from "@turf/turf";
import useTerritorializationsList from "../../../../hooks/useTerritorializationsList";
import { PositivityIndexMapContext } from "../Contexts/PositivityIndexMapContext";
import usePositivityIndexMap from "../Hooks/usePositivityIndexMap";
import { useContextSelector } from "use-context-selector";

const Leaflet = require("leaflet");
const { BaseLayer, Overlay } = LayersControl;

const usePositivityIndex = () => {
  const { fieldResultsList } = useGraphicsData();
  const handlePositivityWeekArrayIndexChange = useContextSelector(
    PositivityIndexMapContext,
    (context) => context.handlePositivityWeekArrayIndexChange
  );

  return {
    fieldResultsList,
    handlePositivityWeekArrayIndexChange,
  };
};

const PositivityIndexMap = ({ estimateObjectName }) => {
  const { fieldResultsList, handlePositivityWeekArrayIndexChange } =
    usePositivityIndex();

  if (!fieldResultsList || fieldResultsList.length === 0) return null;

  return (
    <Card className="mb-4">
      <CardHeader
        headerText={`Mapa de armadilhas`}
        showExpandButton={false}
        showDownloadDataButton={false}
        showDownloadImageButton={false}
      >
        <Button
          onClick={() => handlePositivityWeekArrayIndexChange()}
          color="primary"
          data-tip
          data-for="exchangeWeek"
          style={{ width: "55px", marginRight: "0px" }}
        >
          <i>
            <img
              src={require("../../../../assets/img/icons/interface/exchange-alt.png")}
              style={{ width: "13px", height: "13px" }}
              alt="icone"
            />
          </i>
        </Button>
        <ReactTooltip
          id="exchangeWeek"
          type="info"
          place="bottom"
          effect="solid"
        >
          Trocar semana
        </ReactTooltip>
      </CardHeader>
      <CardContent>
        <Map style={{ height: "25.7rem" }}>
          <MapPointsLayersControl estimateObjectName={estimateObjectName} />
          <MapPolygonsLayersControl />
          <Legend />
        </Map>
      </CardContent>
      <CardFooter>
        <div></div>
        <img
          style={{ maxWidth: "100px", opacity: 0.5 }}
          src="/static/media/vitec.9bd71d52.png"
          alt="Logo do Vitec"
        />
      </CardFooter>
    </Card>
  );
};

const useMapPointsLayersControl = () => {
  const {
    positivityIndexActualWeekArrayIndex,
    positivityIndexGroupPointsList,
    setPositivityIndexGroupPointsList,
  } = usePositivityIndexMap();
  const { appliedFilters } = useAppliedFilters();
  const { fieldResultsList } = useGraphicsData();

  React.useEffect(() => {
    if (!fieldResultsList || fieldResultsList.length === 0) return;

    const positivePointsByWeek = groupPositiveTrapsPointsEstimatesByWeek(
      fieldResultsList,
      appliedFilters.trapType.trapTypeName
    );

    setPositivityIndexGroupPointsList(positivePointsByWeek);
  }, [fieldResultsList]);

  const groupPositiveTrapsPointsEstimatesByWeek = (
    fieldResultsList,
    trapTypeName
  ) => {
    const aedesAegyptiPointsEstimatesByWeek = [];
    const eggPointsEstimatesByWeek = [];
    const exuviaPointsEstimatesByWeek = [];
    const larvaOrPupaPointsEstimatesByWeek = [];

    if (!fieldResultsList || fieldResultsList.length === 0) {
      return {
        aedesAegyptiPointsEstimatesByWeek,
        eggPointsEstimatesByWeek,
        exuviaPointsEstimatesByWeek,
        larvaOrPupaPointsEstimatesByWeek,
      };
    }

    fieldResultsList.forEach(({ positiveTraps, period: { label } }) => {
      if (trapTypeName === "armadilhaDisseminadoraInseticida") {
        fillInPointsEstimatesByWeek(
          "Larvas",
          "forLarvaOrPupa",
          larvaOrPupaPointsEstimatesByWeek,
          positiveTraps,
          label
        );

        fillInPointsEstimatesByWeek(
          "Exuvia",
          "forExuvia",
          exuviaPointsEstimatesByWeek,
          positiveTraps,
          label
        );
      }

      if (
        trapTypeName === "armadilhaMosquitoAdulto" ||
        trapTypeName === "armadilhaMosquitoAdulto2"
      ) {
        fillInPointsEstimatesByWeek(
          "Larvas",
          "forLarvaOrPupa",
          larvaOrPupaPointsEstimatesByWeek,
          positiveTraps,
          label
        );

        fillInPointsEstimatesByWeek(
          "Aedes Aegypti",
          "forAedesAegypti",
          aedesAegyptiPointsEstimatesByWeek,
          positiveTraps,
          label
        );
      }

      if (trapTypeName === "armadilhaOvos") {
        fillInPointsEstimatesByWeek(
          "Larvas",
          "forLarvaOrPupa",
          larvaOrPupaPointsEstimatesByWeek,
          positiveTraps,
          label
        );

        fillInPointsEstimatesByWeek(
          "Exuvia",
          "forExuvia",
          exuviaPointsEstimatesByWeek,
          positiveTraps,
          label
        );

        fillInPointsEstimatesByWeek(
          "Ovos",
          "forEgg",
          eggPointsEstimatesByWeek,
          positiveTraps,
          label
        );
      }
    });

    return {
      aedesAegyptiPointsEstimatesByWeek: Object.entries(
        aedesAegyptiPointsEstimatesByWeek
      ).map(([week, ranges]) => {
        const pointsGroups = Object.entries(ranges).map(
          ([rangeName, points]) => ({
            name: `Positivas - ${rangeName}`,
            points: points,
          })
        );

        return {
          label: week,
          pointsGroups: pointsGroups,
        };
      }),
      eggPointsEstimatesByWeek: Object.entries(eggPointsEstimatesByWeek).map(
        ([week, ranges]) => {
          const pointsGroups = Object.entries(ranges).map(
            ([rangeName, points]) => ({
              name: `Positivas - ${rangeName}`,
              points: points,
            })
          );

          return {
            label: week,
            pointsGroups: pointsGroups,
          };
        }
      ),
      exuviaPointsEstimatesByWeek: Object.entries(
        exuviaPointsEstimatesByWeek
      ).map(([week, ranges]) => {
        const pointsGroups = Object.entries(ranges).map(
          ([rangeName, points]) => ({
            name: `Positivas - ${rangeName}`,
            points: points,
          })
        );

        return {
          label: week,
          pointsGroups: pointsGroups,
        };
      }),
      larvaOrPupaPointsEstimatesByWeek: Object.entries(
        larvaOrPupaPointsEstimatesByWeek
      ).map(([week, ranges]) => {
        const pointsGroups = Object.entries(ranges).map(
          ([rangeName, points]) => ({
            name: `Positivas - ${rangeName}`,
            points: points,
          })
        );

        return {
          label: week,
          pointsGroups: pointsGroups,
        };
      }),
    };
  };

  const fillInPointsEstimatesByWeek = (
    estimateTypeName,
    forWich,
    objectToFill,
    trapsList,
    label
  ) => {
    if(trapsList[forWich].length === 0) {
      if(forWich === "forLarvaOrPupa" || forWich === "forExuvia") {
        if (!objectToFill[label]) objectToFill[label] = {};

        objectToFill[label]["1 a 20"] = [];
        objectToFill[label]["21 a 100"] = [];
        objectToFill[label]["Mais de 100"] = [];

        return;
      }

      if(forWich === "forEgg") {
        if (!objectToFill[label]) objectToFill[label] = {};

        objectToFill[label]["1 a 100"] = [];
        objectToFill[label]["101 a 200"] = [];
        objectToFill[label]["Mais de 200"] = [];

        return;
      }

      if(forWich === "forAedesAegypti") {
        if (!objectToFill[label]) objectToFill[label] = {};

        objectToFill[label]["1"] = [];
        objectToFill[label]["2 a 3"] = [];
        objectToFill[label]["Mais de 3"] = [];

        return;
      }
    }

    trapsList[forWich].forEach(
      ({ estimatives, latitude, longitude, number }) => {
        if (!objectToFill[label]) objectToFill[label] = {};

        const esimate = estimatives.find(
          ({ type }) => type === estimateTypeName
        );

        if (!objectToFill[label][esimate.name])
          objectToFill[label][esimate.name] = [];

        const trapPoint = {
          number,
          latitude,
          longitude,
          icon: Leaflet.icon({
            iconUrl:
              ARMADILHA_INFO[appliedFilters.trapType.trapTypeName].icons[
                "green"
              ],
            iconSize: [16, 16],
          }),
        };

        objectToFill[label][esimate.name].push(trapPoint);
      }
    );
  };

  return {
    positivityIndexActualWeekArrayIndex,
    positivityIndexGroupPointsList,
  };
};

const MapPointsLayersControl = ({ estimateObjectName }) => {
  const {
    positivityIndexActualWeekArrayIndex,
    positivityIndexGroupPointsList,
  } = useMapPointsLayersControl();

  if (
    !positivityIndexGroupPointsList ||
    positivityIndexGroupPointsList.length === 0 ||
    !positivityIndexGroupPointsList[estimateObjectName] ||
    !positivityIndexGroupPointsList[estimateObjectName][
      positivityIndexActualWeekArrayIndex
    ] ||
    !positivityIndexGroupPointsList[estimateObjectName][
      positivityIndexActualWeekArrayIndex
    ].pointsGroups
  )
    return null;

  return (
    <LayersControl position="topright">
      <BaseLayer checked name="Armadilhas">
        <TileLayer attribution="" url="" />
      </BaseLayer>
      {positivityIndexGroupPointsList[estimateObjectName][
        positivityIndexActualWeekArrayIndex
      ].pointsGroups.map(({ name, points }) => {
        return (
          <Overlay key={`${name}-overlay`} name={name}>
            <MarkerClusterGroup
              key={`${name}-cluster`}
              removeOutsideVisibleBounds={true}
            >
              {points &&
                points.length > 0 &&
                points.map(({ latitude, longitude, number, icon }, index) => {
                  return (
                    <Marker
                      key={`${number}-${index}-marker`}
                      position={[latitude, longitude]}
                      icon={icon}
                    >
                      <Popup>
                        <span>
                          <span className="h4">
                            Armadilha Nº:
                          </span>{" "}
                          {number}
                        </span>
                      </Popup>
                    </Marker>
                  );
                })}
            </MarkerClusterGroup>
          </Overlay>
        );
      })}
    </LayersControl>
  );
};

const Legend = () => {
  const { appliedFilters } = useAppliedFilters();
  const { fieldResultsList } = useGraphicsData();
  const { positivityIndexActualWeekArrayIndex } = usePositivityIndexMap();

  if (!fieldResultsList || fieldResultsList.length === 0) return null;

  const weekNumber = fieldResultsList[
    positivityIndexActualWeekArrayIndex
  ].period.label.replace("Semana ", "");

  return (
    <Control position={"bottomright"}>
      <div
        style={{
          maxWidth: 250,
          maxHeight: 300,
          backgroundColor: "#fff",
          paddingTop: 5,
          paddingBottom: 5,
          paddingLeft: 10,
          paddingRight: 10,
        }}
      >
        <div style={{ width: "100%", textAlign: "center" }}>
          <span className="h4">
            Dados da semana
            <br />
            {weekNumber}/{appliedFilters.year.value}
          </span>
        </div>
      </div>
    </Control>
  );
};

const MapPolygonsLayersControl = () => {
  const [typeLayersPolygons, setTypeLayersPolygons] = React.useState([]);

  const { territorializationsList } = useTerritorializationsList();

  React.useEffect(() => {
    if (!territorializationsList || territorializationsList.length === 0)
      return;

    const typeLayersPolygonsGroup = [];

    territorializationsList.forEach(({ name, territorializations }) => {
      const typeLayerData = {
        name,
        polygons: [],
      };

      territorializations.forEach((territorialization) => {
        const turfPolygon = polygon([
          territorialization.coordinates.map(({ x, y }) => [x, y]),
        ]);

        turfPolygon.name = territorialization.name;
        turfPolygon.territorialization = territorialization;

        typeLayerData.polygons.push(turfPolygon);
      });

      typeLayersPolygonsGroup.push(typeLayerData);
    });

    setTypeLayersPolygons(typeLayersPolygonsGroup);
  }, [territorializationsList]);

  return (
    <LayersControl position="topright">
      <BaseLayer checked name="Delimitadores de Áreas">
        <TileLayer attribution="" url="" />
      </BaseLayer>
      {typeLayersPolygons &&
        typeLayersPolygons.length > 0 &&
        typeLayersPolygons.map(({ name, polygons }, index) => {
          return (
            <Overlay name={name} key={`${name}-overlay-${index}`}>
              <FeatureGroup
                fillOpacity={0.3}
                name={name}
                key={`${name}-feature-${index}`}
              >
                {polygons &&
                  polygons.length > 0 &&
                  polygons.map((polygon, index) => {
                    const { geometry, name } = polygon;

                    const coordinates = geometry.coordinates[0].map(
                      ([latitude, longitude]) => [longitude, latitude]
                    );

                    return (
                      <Polygon
                        key={`${name}-polygon-${index}`}
                        color={"#000000"}
                        fillColor={"transparent"}
                        opacity={1}
                        positions={coordinates}
                      >
                        {name ? <Tooltip sticky>{name}</Tooltip> : null}
                      </Polygon>
                    );
                  })}
              </FeatureGroup>
            </Overlay>
          );
        })}
    </LayersControl>
  );
};

export default PositivityIndexMap;
