import React from "react";

import { Col, Row } from "reactstrap";
import { Bar, Line, Rectangle } from "recharts";
import PieChartGraphic from "../../../components/Graphics/Pie/PieChartGraphic";
import BarChartGraphic from "../../../components/Graphics/Bar/BarChartGraphic";
import moment from "moment";
import VerticalChartGraphic from "../../../components/Graphics/Composed/VerticalChartGraphic";
import LineChartGraphic from "../../../components/Graphics/Line/LineChartGraphic";
import { getUserData } from "../../../services/utils/auth";

const ManagementPageGraphicsWrapper = ({ data, filtersValues }) => {
  const [formatedDataForGraphics, setFormatedDataForGraphics] = React.useState(
    []
  );
  const [formatedDataForHistoricSerie, setFormatedDataForHistoricSerie] =
    React.useState([]);

  React.useEffect(() => {
    if (!data || data.length === 0) return;

    const formatedData = data.map((report) => {
      return {
        "Armadilhas Instaladas": report.activeTraps,
        "Área em KM²": report.squareKilometre,
        "Área de Intervenção": report.territorializationName,
        "Densidade Armadilhas/KM²": report.trapsDensityBySquareKilometre,
        "Área de Risco": report.trapsNotProtectingAreas,
        "Área de Risco (%)": report.trapsNotProtectingAreasPercentage,
        "Armadilhas 1º Período": report.trapsOnFirstPeriod,
        "Armadilhas 1º Período (%)": report.trapsOnFirstPeriodPercentage,
        "Armadilhas 2º Período": report.trapsOnSecondPeriod,
        "Armadilhas 2º Período (%)": report.trapsOnSecondPeriodPercentage,
        "Armadilhas 3º Período": report.trapsOnThirdPeriod,
        "Armadilhas 3º Período (%)": report.trapsOnThirdPeriodPercentage,
        "Área Protegida": report.trapsProtectingAreas,
        "Área Protegida (%)": report.trapsProtectingAreasPercentage,
      };
    });

    const dataForHistoricGraphic = data.map(
      ({ territorializationName, trapsSituationsByPeriods }) => {
        return {
          territorializationName,
          periods: trapsSituationsByPeriods.map(
            ({ green, yellow, red, period }) => ({
              Verde: green,
              Amarelo: yellow,
              Vermelho: red,
              "Data início": moment(period.beginDate).format("DD/MM/YYYY"),
              "Data fim": moment(period.finalDate).format("DD/MM/YYYY"),
              Label: period.label,
            })
          ),
        };
      }
    );

    const dataForHistoricSerie = new Map();

    dataForHistoricGraphic.forEach(({ territorializationName, periods }) => {
      periods.forEach(({ Vermelho, Label }) => {
        if (!dataForHistoricSerie.has(Label))
          dataForHistoricSerie.set(Label, { Label });

        let actualWeek = dataForHistoricSerie.get(Label);

        actualWeek = { ...actualWeek, [territorializationName]: Vermelho };

        dataForHistoricSerie.set(Label, actualWeek);
      });
    });

    const dataForHistoricSerieResult = Array.from(
      dataForHistoricSerie.values()
    );

    setFormatedDataForHistoricSerie(dataForHistoricSerieResult);
    setFormatedDataForGraphics(formatedData);
  }, [data]);

  const getColorByIndex = (index) => {
    const colors = [
      "#0088FE",
      "#00C49F",
      "#FFBB28",
      "#FF8042",
      "#a19e9c",
      "#865838",
      "#6d6966",
      "#8a8703",
      "#ff0000",
      "#f700ff",
      "#4400ff",
      "#00ccff",
      "#064f58",
      "#212525",
      "#9CF071",
      "#960921",
      "#301D14",
      "#13CC37",
      "#D50563",
      "#E10522",
      "#0EEB05",
      "#E8F023",
      "#4C82D8",
      "#36FEA1",
      "#FBF31D",
      "#69CB66",
      "#E690B6",
      "#A6F0D7",
      "#A36B13",
      "#1D0AEF",
      "#7D5567",
      "#0D9224",
      "#82DA12",
      "#3E6AEC",
      "#06B79C",
      "#4C465E",
      "#5D04B1",
      "#BC6F7F",
      "#5D535F",
      "#25822A",
      "#D07F05",
      "#E5598D",
      "#AB6B0C",
      "#227479",
      "#339224",
      "#8FA4A4",
      "#B052E3",
      "#B573E8",
      "#51DA47",
      "#45E717",
    ];

    return colors[index % colors.length]; // Gera cores em loop
  };

  const beginDateFormated =
    filtersValues &&
    filtersValues.beginDate &&
    moment(filtersValues.beginDate).format("DD/MM/YYYY");
  const finalDateFormated =
    filtersValues &&
    filtersValues.finalDate &&
    moment(filtersValues.finalDate).format("DD/MM/YYYY");

  return formatedDataForGraphics && formatedDataForGraphics.length > 0 ? (
    <>
      <Row className="mt-12 mb-12" style={{ paddingBottom: "1.5rem" }}>
        <Col className="mb-6 mb-xl-0" xl="6">
          <PieChartGraphic
            data={formatedDataForGraphics}
            dataKey="Armadilhas Instaladas"
            chartElementsLimit={10}
            customTooltip={<InstalledTrapsCustomTooltip />}
            headerText={
              <span>
                Armadilhas Instaladas ({filtersValues.trapType.label})<br /> em{" "}
                {beginDateFormated} a {finalDateFormated}
              </span>
            }
            legendsKey="Área de Intervenção"
            propertyToOrderBy="Armadilhas Instaladas"
          />
        </Col>
        <Col className="mb-6 mb-xl-0" xl="6">
          <PieChartGraphic
            data={formatedDataForGraphics}
            dataKey="Área em KM²"
            chartElementsLimit={10}
            customTooltip={<SquareKilometersCustomTooltip />}
            headerText={
              <span>
                Áreas em KM² ({filtersValues.trapType.label})<br /> em{" "}
                {beginDateFormated} a {finalDateFormated}
              </span>
            }
            legendsKey="Área de Intervenção"
            propertyToOrderBy="Área em KM²"
          />
        </Col>
      </Row>
      <Row className="mt-12 mb-12" style={{ paddingBottom: "1.5rem" }}>
        <Col className="mb-6 mb-xl-0" xl="6">
          <BarChartGraphic
            bars={[
              <Bar
                dataKey="Densidade Armadilhas/KM²"
                fill="#8884d8"
                activeBar={<Rectangle fill="pink" stroke="blue" />}
              />,
            ]}
            data={formatedDataForGraphics}
            headerText={
              <span>
                Densidade de Armadilhas por KM² ({filtersValues.trapType.label})
                <br />
                em {beginDateFormated} a {finalDateFormated}
              </span>
            }
            keyToConsiderOnDomain={"Densidade Armadilhas/KM²"}
            xAxisDataKey="Área de Intervenção"
          />
        </Col>
        <Col className="mb-6 mb-xl-0" xl="6">
          <BarChartGraphic
            bars={[
              <Bar
                dataKey="Armadilhas 1º Período"
                fill="#0dd649"
                activeBar={<Rectangle fill="pink" stroke="blue" />}
              />,
              <Bar
                dataKey="Armadilhas 2º Período"
                fill="#dcac00"
                activeBar={<Rectangle fill="pink" stroke="blue" />}
              />,
              <Bar
                dataKey="Armadilhas 3º Período"
                fill="#f10a0a"
                activeBar={<Rectangle fill="pink" stroke="blue" />}
              />,
            ]}
            data={formatedDataForGraphics}
            headerText={
              <span>
                Status da Manutenção em Valores Absolutos (
                {filtersValues.trapType.label})<br /> em {beginDateFormated} a
                {finalDateFormated}
              </span>
            }
            xAxisDataKey="Área de Intervenção"
          />
        </Col>
      </Row>
      <Row className="mt-12 mb-12" style={{ paddingBottom: "1.5rem" }}>
        <Col className="mb-6 mb-xl-0" xl="6">
          <VerticalChartGraphic
            chartElements={[
              <Bar
                dataKey="Armadilhas 1º Período (%)"
                fill="#0dd649"
                activeBar={<Rectangle fill="pink" stroke="blue" />}
                stackId="a"
                barSize={20}
              />,
              <Bar
                dataKey="Armadilhas 2º Período (%)"
                fill="#dcac00"
                activeBar={<Rectangle fill="pink" stroke="blue" />}
                stackId="a"
                barSize={20}
              />,
              <Bar
                dataKey="Armadilhas 3º Período (%)"
                fill="#f10a0a"
                activeBar={<Rectangle fill="pink" stroke="blue" />}
                stackId="a"
                barSize={20}
              />,
            ]}
            chartElementsLimit={7}
            data={formatedDataForGraphics}
            headerText={
              <span>
                Status da Manutenção em Percentual (
                {filtersValues.trapType.label})<br /> em {beginDateFormated} a{" "}
                {finalDateFormated}
              </span>
            }
            propertyToOrderBy="Armadilhas 1º Período (%)"
            xAxisTickFormatter="%"
            xAxisDomain={[0, 100]}
            yAxisDataKey="Área de Intervenção"
          />
        </Col>
        <Col className="mb-6 mb-xl-0" xl="6">
          <BarChartGraphic
            bars={[
              <Bar
                dataKey="Área Protegida (%)"
                fill="#0dd649"
                activeBar={<Rectangle fill="pink" stroke="blue" />}
              />,
              <Bar
                dataKey="Área de Risco (%)"
                fill="#f10a0a"
                activeBar={<Rectangle fill="pink" stroke="blue" />}
              />,
            ]}
            data={formatedDataForGraphics}
            headerText={
              <span>
                Áreas de Risco x Áreas Protegidas (
                {filtersValues.trapType.label})<br /> em {beginDateFormated} a{" "}
                {finalDateFormated}
              </span>
            }
            xAxisDataKey="Área de Intervenção"
          />
        </Col>
      </Row>
      {getUserData("organizationId") ===
      "559a16ab-5da8-41ba-96b8-6e9f55feddd8" ? null : (
        <Row className="mt-12 mb-12" style={{ paddingBottom: "1.5rem" }}>
          <Col className="mb-6 mb-xl-0" xl="12">
            <LineChartGraphic
              brushBar={true}
              data={formatedDataForHistoricSerie}
              headerText={
                <span>
                  Série histórica das armadilhas em vermelho (
                  {filtersValues.trapType.label}) por semanas <br /> em{" "}
                  {moment().year()}
                </span>
              }
              lines={
                formatedDataForHistoricSerie &&
                formatedDataForHistoricSerie.length > 0 &&
                Object.keys(formatedDataForHistoricSerie[0])
                  .filter((key) => key !== "Label")
                  .map((name, index) => (
                    <Line
                      key={name}
                      type="monotone"
                      dataKey={name}
                      stroke={getColorByIndex(index)}
                    />
                  ))
              }
              xAxisDataKey="Label"
            />
          </Col>
        </Row>
      )}
    </>
  ) : null;
};

const InstalledTrapsCustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;

    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#fff",
          padding: "10px",
          border: "1px solid #ccc",
        }}
      >
        <p className="label" style={{ fontWeight: "bold" }}>
          {data["Área de Intervenção"]}: {data["Armadilhas Instaladas"]} ADI's
        </p>
      </div>
    );
  }

  return null;
};

const SquareKilometersCustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;

    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#fff",
          padding: "10px",
          border: "1px solid #ccc",
        }}
      >
        <p className="label" style={{ fontWeight: "bold" }}>
          {data["Área de Intervenção"]}: {data["Área em KM²"]}KM²
        </p>
      </div>
    );
  }

  return null;
};

export default ManagementPageGraphicsWrapper;
