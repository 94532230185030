import React from "react";

import LineChartGraphic from "../../../../../components/Graphics/Line/LineChartGraphic";
import { Button } from "reactstrap";
import ReactTooltip from "react-tooltip";
import { Line } from "recharts";
import { getColorByIndex } from "../../../../../services/utils/globalFunctions";

export const PositivityIndexLinePercentageEstimatesByWeekGraphic = ({
  data,
  headerText,
  estimatesCategoriesList,
  setGraphicType,
}) => {
  return (
    <LineChartGraphic
      brushBar={true}
      data={data}
      headerContent={
        <>
          <Button
            onClick={() => setGraphicType("bar")}
            color="primary"
            data-tip
            data-for="exchangeGraphicType1"
            style={{ width: "55px", marginRight: "0px" }}
          >
            <i>
              <img
                src={require("../../../../../assets/img/icons/interface/exchange-alt.png")}
                style={{ width: "13px", height: "13px" }}
                alt="icone"
              />
            </i>
          </Button>
          <ReactTooltip
            id="exchangeGraphicType1"
            type="info"
            place="bottom"
            effect="solid"
          >
            Trocar visualização
          </ReactTooltip>
        </>
      }
      headerText={headerText}
      hideDownloadButton={true}
      lines={estimatesCategoriesList.map((category, index) => (
        <Line
          key={category}
          type="monotone"
          dataKey={category}
          stroke={getColorByIndex(index)}
        />
      ))}
      xAxisDataKey={"week"}
    />
  );
};
