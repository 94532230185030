import React from "react";
import getWeeks from "../../services/utils/epidemiologicalWeek";
import { Select } from "../Wrappers/SelectAll";
import { selectComponentStyles } from "../../services/utils/globalFunctions";

const EpidemiologicWeeksPeriodsFilter = ({
  epidemiologicalWeekBegin,
  epidemiologicalWeekEnd,
  filters,
  handleFiltersChange,
}) => {
  const [epidemiologicalWeeksOptionsList, setEpidemiologicalWeeksOptionsList] =
    React.useState([]);

  React.useEffect(() => {
    if (filters.year.value === "") return;

    const epidemiologicalWeeksFromYear = getWeeks().find(
      ({ year }) => year === filters.year.label
    );

    const epidemiologicalWeeksList = epidemiologicalWeeksFromYear.weeks.map(
      ({ beginDate, endDate, label }) => ({
        beginDate,
        endDate,
        label,
        value: endDate,
      })
    );

    setEpidemiologicalWeeksOptionsList(epidemiologicalWeeksList);
  }, [filters.year]);

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "2rem" }}>
      <div style={{ width: "50%" }}>
        <span className="h4 text-white">Semana início</span>
        <Select
          styles={selectComponentStyles}
          placeholder={"Selecione"}
          isMulti={false}
          isClearable={true}
          value={epidemiologicalWeekBegin}
          options={epidemiologicalWeeksOptionsList}
          onChange={(e) =>
            handleFiltersChange(
              "epidemiologicalWeekBegin",
              !e
                ? {
                    label: "Selecione",
                    value: "",
                  }
                : e
            )
          }
          isDisabled={filters.year.value === ""}
        />
      </div>
      <div style={{ width: "50%" }}>
        <span className="h4 text-white">Semana fim</span>
        <Select
          styles={selectComponentStyles}
          placeholder={"Selecione"}
          isMulti={false}
          isClearable={true}
          value={epidemiologicalWeekEnd}
          options={epidemiologicalWeeksOptionsList}
          onChange={(e) =>
            handleFiltersChange(
              "epidemiologicalWeekEnd",
              !e
                ? {
                    label: "Selecione",
                    value: "",
                  }
                : e
            )
          }
          isDisabled={filters.year.value === "" || filters.epidemiologicalWeekBegin.value === ""}
        />
      </div>
    </div>
  );
};

export default EpidemiologicWeeksPeriodsFilter;
