import React from "react";

import DataTable from "react-data-table-component";
import { Col, Row } from "reactstrap";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "../../../../components/Cards/Card";

const ManagementTable = ({ data, headerText }) => {
  const [dataToExport, setDataToExport] = React.useState([]);

  React.useEffect(() => {
    if (!data || data.length === 0) return;

    const formatedDataToExport = data.map((report) => {
      return {
        AREA_INTERVENCAO: report.territorializationName,
        ADIS_INSTALADAS: report.activeTraps,
        KM2: report.squareKilometre,
        "DENSIDADE_ARMADILHAS/KM2": report.trapsDensityBySquareKilometre,
        ATE_30_DIAS: report.trapsOnFirstPeriod,
        "ATE_30_DIAS(%)": report.trapsOnFirstPeriodPercentage,
        "31_A_60_DIAS": report.trapsOnSecondPeriod,
        "31_A_60_DIAS(%)": report.trapsOnSecondPeriodPercentage,
        ACIMA_60_DIAS: report.trapsOnThirdPeriod,
        "ACIMA_60_DIAS(%)": report.trapsOnThirdPeriodPercentage,
        AREA_PROTEGIDA: report.trapsProtectingAreas,
        "AREA_PROTEGIDA(%)": report.trapsProtectingAreasPercentage,
        AREA_RISCO: report.trapsNotProtectingAreas,
        "AREA_RISCO(%)": report.trapsNotProtectingAreasPercentage,
      };
    });

    setDataToExport(formatedDataToExport);
  }, [data]);

  const defaultColumnStyles = {
    fontSize: "16px",
  };

  const tableColumns = [
    {
      name: "Área de Intervenção",
      selector: "territorializationName",
      sortable: true,
      grow: 2,
      style: defaultColumnStyles,
    },
    {
      name: "ADI's Instaladas",
      selector: "activeTraps",
      sortable: true,
      style: defaultColumnStyles,
      center: true,
    },
    {
      name: "KM²",
      selector: "squareKilometre",
      sortable: true,
      style: defaultColumnStyles,
      center: true,
    },
    {
      name: (
        <span>
          Densidade <br></br>Armadilhas/KM²
        </span>
      ),
      selector: "trapsDensityBySquareKilometre",
      sortable: true,
      style: defaultColumnStyles,
      center: true,
    },
    {
      name: "Até 30 Dias",
      selector: "trapsOnFirstPeriod",
      sortable: true,
      style: defaultColumnStyles,
      center: true,
    },
    {
      name: "Até 30 Dias (%)",
      selector: "trapsOnFirstPeriodPercentage",
      sortable: true,
      style: defaultColumnStyles,
      center: true,
    },
    {
      name: "De 31 Até 60 Dias",
      selector: "trapsOnSecondPeriod",
      sortable: true,
      style: defaultColumnStyles,
      center: true,
    },
    {
      name: "De 31 Até 60 Dias (%)",
      selector: "trapsOnSecondPeriodPercentage",
      sortable: true,
      style: defaultColumnStyles,
      center: true,
    },
    {
      name: "Acima de 60 Dias",
      selector: "trapsOnThirdPeriod",
      sortable: true,
      style: defaultColumnStyles,
      center: true,
    },
    {
      name: "Acima de 60 Dias (%)",
      selector: "trapsOnThirdPeriodPercentage",
      sortable: true,
      style: defaultColumnStyles,
      center: true,
    },
    {
      name: "Área Protegida",
      selector: "trapsProtectingAreas",
      sortable: true,
      style: defaultColumnStyles,
      center: true,
    },
    {
      name: "Área Protegida (%)",
      selector: "trapsProtectingAreasPercentage",
      sortable: true,
      style: defaultColumnStyles,
      center: true,
    },
    {
      name: "Área de Risco",
      selector: "trapsNotProtectingAreas",
      sortable: true,
      style: defaultColumnStyles,
      center: true,
    },
    {
      name: "Área de Risco (%)",
      selector: "trapsNotProtectingAreasPercentage",
      sortable: true,
      style: defaultColumnStyles,
      center: true,
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        fontSize: "13px",
        fontWeight: "bold",
        textAlign: "center",
      },
    },
  };

  const styles = {
    col: {
      marginTop: "0.7rem",
    },
    cardFooter: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      gap: "16px",
    },
  };

  return data && data.length > 0 ? (
    <Card style={{ marginBottom: "1.5rem" }}>
      <CardHeader
        dataToDownload={dataToExport}
        headerText={headerText}
        showExpandButton={false}
        showButtonsTooltips={true}
      />
      <CardContent>
        <Row>
          <Col style={styles.col}>
            <DataTable
              noHeader
              defaultSortField="name"
              defaultSortAsc={true}
              pagination
              highlightOnHover
              responsive
              customStyles={customStyles}
              columns={tableColumns}
              data={data}
              noDataComponent={"Nenhum registro encontrado."}
            />
          </Col>
        </Row>
      </CardContent>
      <CardFooter style={styles.cardFooter}>
        <div></div>
        <img
          style={{ maxWidth: "100px", opacity: 0.5 }}
          src="/static/media/vitec.9bd71d52.png"
          alt="Logo do Vitec"
        />
      </CardFooter>
    </Card>
  ) : null;
};

export default ManagementTable;
