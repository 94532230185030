import React, { useEffect, useState, useRef } from "react";
import Chart from "chart.js";
// reactstrap components
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
} from "variables/charts.jsx";

import EntomologicoFilterPresentational from "../components/Presentational/Entomologico/EntomologicoFilterPresentational";
import ArmadilhasInstaladasContainer from "../components/Containers/Entomologico/Cards/ArmadilhasInstaladasContainer";
import { getNotificationsGeoRef } from "../services/api/Sinan";
import { getTrapEggNumbersFromLab, getTrapStatusNumbers, getTrapsToMap, getTrapsTypes, getActiveTrapsToMap, getActiveTraps } from "../services/api/Trap";
import VistoriasArmadilhasContainer from "../components/Containers/Entomologico/Cards/VistoriasArmadilhasContainer";
import { getUserData, setOrganization } from "../services/utils/auth";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import IDOChartContainer from "../components/Containers/Entomologico/Charts/Line/IDOChartContainer";
import IMAChartContainer from "../components/Containers/Entomologico/Charts/Line/IMAChartContainer";
import IMOChartContainer from "../components/Containers/Entomologico/Charts/Line/IMOChartContainer";
import IPOChartContainer from "../components/Containers/Entomologico/Charts/Line/IPOCharContainer";
import OvosLineContainer from "../components/Containers/Entomologico/Charts/Line/OvosLineContainer";
import TotalOvosColetadosContainer from "../components/Containers/Entomologico/Cards/TotalOvosColetadosContainer";
import ArmadilhasPositivasContainer from "../components/Containers/Entomologico/Cards/ArmadilhasPositivasContainer";
import MediaOvosContainer from "../components/Containers/Entomologico/Cards/MediaOvosContainer";
import PalhetasExaminadasContainer from "../components/Containers/Entomologico/Cards/PalhetasExaminadasContainer";
import { buildEpidemiologicalLabels } from "../services/utils/Entomologico/Labels";
import { getEpidemiologicalPeriod } from "../services/utils/Entomologico/Period";
import PositivesMapContainer from "../components/Containers/Entomologico/Maps/PositivesMapContainer";
import IDOMapContainer from "../components/Containers/Entomologico/Maps/IDOMapContainer";
import ActiveMapContainer from "../components/Containers/Entomologico/Maps/ActiveMapContainer";
import MainPeriodComponent from "../components/Inputs/MainPeriodComponent";
import PendentsVistoriedContainer from "../components/Containers/Entomologico/Charts/Line/PendentsVistoriedContainer";
import PendentsVistoriedMapContainer from "../components/Containers/Entomologico/Maps/PendentsVistoriedMapContainer";
import getWeeks from "../services/utils/epidemiologicalWeek";
import ArmadilhasVistoriadasContainer from "../components/Containers/Entomologico/Cards/ArmadilhasVistoriadasContainer";
import LoadingSpin from "react-loading-spin";
import VisitOperacionalFilterPresentational from "../components/Presentational/Operacional/VisitOperacionalFilterPresentational";
import GeographicFilterPresentational from "../components/Presentational/Filters/GeographicFilterPresentational";
import { ARMADILHA_INFO } from "../constants/ArmadilhaConstant";
import { getVersion } from "../constants/VersionConstant";
import { fetchAnimalGenres, fetchAnimalSpecies } from "../services/api/AnimalGroups";
import "./DashboardEntomologico.css";
import IPOChartContainerAdultrap from "../components/Containers/Entomologico/Charts/Line/IPOCharContainerAdult";
import IDOChartContainerAdult from "../components/Containers/Entomologico/Charts/Line/IDOChartContainerAdult";
import IMOMapContainer from "../components/Containers/Entomologico/Maps/IMOMapContainer";
import { getTodayEpidemiologicalWeek } from "../services/utils/todayEpidemiologicalWeek";
import TodayEpidemiologicalWeekComponent from "../components/Containers/Entomologico/todayEpidemiologicalWeek/TodayEpidemiologicalWeekComponent";
import { Select } from "../components/Wrappers/SelectAll";
import { getProjects } from "../services/api/project";

const DashboardEntomologico = ({ demandList, employeeList, teamList, territorializationList }) => {
  const [activeNav, setActiveNav] = useState(1);
  const [project, setProject] = React.useState(null);
  const [projectOptions, setProjectOptions] = React.useState("");
  const [isTrapStatusLoading, setTrapStatusLoading] = useState(false);
  const [isTrapMapLoading, setTrapMapLoading] = useState(false);
  const [isActiveTrapMapLoading, setActiveTrapMapLoading] = useState(false);
  const [isTrapLabLoading, setTrapLabLoading] = useState(false);
  const [trapStatusNumbers, setTrapStatusNumbers] = useState([]);
  const [dataGeoRef, setDataGeoRef] = useState([]);
  const [dataGeoRefLoading, setDataGeoRefLoading] = useState([]);
  const [trapSampleNumbers, setTrapSampleNumbers] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [organizationId, setOrganizationId] = useState(null);
  const [webVersion, setWebVersion] = useState("normal_version");
  const [endDate, setEndDate] = useState("");
  const [trapTypeAUX, setTrapTypeAUX] = useState({ trapTypeId: "00000000-0000-0000-0000-000000000000", trapTypeName: "Ovitrampa", trapName: "armadilhaOvos" });
  const [trapType, setTrapType] = useState({ trapTypeId: "00000000-0000-0000-0000-000000000000", trapTypeName: "Ovitrampa", trapName: "armadilhaOvos" });
  const [trapTypes, setTrapTypes] = useState([{ trapTypeId: "00000000-0000-0000-0000-000000000000", trapTypeName: "Ovitrampa", trapName: "armadilhaOvos" }]);
  const [trapTypeOptions, setTrapTypeOptions] = useState([]);
  const [speciesOptions, setSpeciesOptions] = useState([]);
  const [selectedSpecie, setSelectedSpecie] = useState(null)
  const [TrapMapInfo, setTrapMapInfo] = useState(null);
  const [ActiveTrapMapInfo, setActiveTrapMapInfo] = useState(null);
  const [PreviousTrapMapInfo, setPreviousTrapMapInfo] = useState(null);
  const [chartLabels, setChartLabels] = useState([]);
  const [chartPeriods, setChartPeriods] = useState([]);
  const [isFilterVisible, setFilterVisibility] = useState(true);
  const [months, setFilterMonths] = useState([]);
  const [year, setFilterYear] = useState([]);
  const [weeks, setFilterWeeks] = useState([]);
  const [weekValue1, setWeekValue1] = useState([]);
  const [weekValue2, setWeekValue2] = useState([]);
  const [demand, setFilterDemand] = useState("00000000-0000-0000-0000-000000000000");
  const [employee, setFilterEmployee] = useState("00000000-0000-0000-0000-000000000000");
  const [team, setFilterTeam] = useState("00000000-0000-0000-0000-000000000000");
  const [selectedTerritorializations, setSelectedTerritorializations] = useState([]);
  const teamEmployees = useRef([]);
  const [todayEpidemiologicalWeek, setTodayEpidemiologicalWeek] = useState("1");

  // ChartDatasets
  const [maxDomainImo, setMaxDomainImo] = useState();
  const [chartDataSetsPendentsVistoried, setChartDataSetsPendentsVistoried] = useState();
  const [chartDataSetsImo, setChartDataSetsImo] = useState();
  const [chartDataSetsIdo, setChartDataSetsIdo] = useState();
  const [chartDataSetsIpo, setChartDataSetsIpo] = useState();
  const [chartDataSetsIpa, setChartDataSetsIpa] = useState();
  const [isGraphsLoading, setIsGraphsLoading] = useState(false);

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const getStartDate = () => {
    const date = new Date();

    date.setMonth(date.getMonth() - 1);

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    return year + "-" + month + "-" + day;
  }

  const getEndDate = () => {
    const date = new Date();
    const endDateString = date.getFullYear() + "-" + (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-" + (date.getDate() < 10 ? "0" + date.getDate() : date.getDate());

    return endDateString;
  }

  useEffect(() => {
    var orgId = getUserData("organizationId");
    setOrganizationId(orgId);

    var version = getVersion(orgId);
    setWebVersion(version);

    setStartDate(getStartDate());

    setTodayEpidemiologicalWeek(getTodayEpidemiologicalWeek());

    setEndDate(getEndDate());

    var projects = getProjects();

    projects.then(response => {
      var projectOptionsFormated = [];
      if(response.data) {
        response.data.map((project) => {
          projectOptionsFormated.push({
            value: project.id,
            label: project.name
          })
        })
      }
      setProjectOptions(projectOptionsFormated);
    })    

    const reqTrapTypes = getTrapsTypes();
    reqTrapTypes.then(response => {
      setTrapTypeOptions(response.data);
      if (response.data.length > 0) {
        let index;

        if (getUserData("organizationId") === "24d125ef-15e6-4bd9-9111-c0b3d50a8009")
          index = response.data.findIndex(tpType => tpType.name === 'armadilhaDisseminadoraInseticida');
        else 
          index = response.data.findIndex(tpType => tpType.name === 'armadilhaOvos');

        var trap = { trapTypeId: response.data[index].id, trapTypeName: ARMADILHA_INFO[response.data[index].name].apelido, trapName: response.data[index].name };
        setTrapType(trap)

        let traps = [];
        response.data.map((trap) => {
          if(trap.name){
            traps.push({trapTypeId: trap.id, trapTypeName: ARMADILHA_INFO[trap.name].apelido, trapName: trap.name })
          }
        })
        setTrapTypes(traps)
        
      }

      applyFilter();
    });

    

    const species = [];
    fetchAnimalGenres("178886c4-a41c-4d15-ac3c-a58806bb8df9").then(async (response) => {
      for (const genre of response.data) {
        let speciesRequest = await fetchAnimalSpecies(genre.id);
        for (const specie of speciesRequest.data) {
          species.push({
            label: specie.name,
            value: specie.id
          })
        }
      }

      setSpeciesOptions(species)
    })

  }, [])

  useEffect(() => {
    if (trapSampleNumbers.length > 0 && trapStatusNumbers.length > 0 && !isTrapLabLoading && !isTrapStatusLoading) {
      const datasetImo = [];
      const datasetIdo = [];
      const datasetIpo = [];
      const datasetIpa = [];
      const datasetPendentsVistoried = [];
      var maiorValor = 100;

      for (const index in chartLabels) {
        const dataSetObjectImo = {
          label: chartLabels[index]
        }
        const dataSetObjectIdo = {
          label: chartLabels[index]
        }
        const dataSetObjectIpo = {
          label: chartLabels[index]
        }
        const dataSetObjectIpa = {
          label: chartLabels[index]
        }
        const dataSetObjectPendentsVistoried = {
          label: chartLabels[index]
        }
        for (let ano in trapSampleNumbers) {
          if (trapSampleNumbers[ano][index] && trapStatusNumbers[ano][index]) {
            let eggs = trapSampleNumbers[ano][index].ovos;
            let positives = trapSampleNumbers[ano][index].positive;
            let examinated = trapSampleNumbers[ano][index].examinated;
            const { actives, inspections, inspected, vistories } = trapStatusNumbers[ano][index];

            const vistoryToChart = actives > 0 ? parseFloat(((vistories / actives) * 100).toFixed(1)) : parseFloat("0.0");
            dataSetObjectPendentsVistoried["vistories"] = vistories;
            dataSetObjectPendentsVistoried["actives"] = actives;
            
            const ipo = examinated != 0 ? ((positives / examinated) * 100).toFixed(1) : 0; //indice de positividade de ovos;
            dataSetObjectIpo["positives"] = positives;
            dataSetObjectIpo["examinated"] = examinated;

            const ipa = vistories != 0 ? ((positives / vistories) * 100).toFixed(1) : 0; //indice de positividade de ovos;
            dataSetObjectIpa["positives"] = positives;
            dataSetObjectIpa["vistories"] = vistories;


            const ido = positives != 0 ? (eggs / positives).toFixed(1) : 0; //indice densidade de ovos;
            dataSetObjectIdo["eggs"] = eggs;
            dataSetObjectIdo["positives"] = positives;

            // (Número de ovos/total de palheta lida no laboratório)
            const imo = inspected != 0 ? (eggs / inspected).toFixed(1) : 0; //indice médio de ovos;
            dataSetObjectImo["inspected"] = inspected;
            dataSetObjectImo["eggs"] = eggs;

            if (parseFloat(imo) > parseFloat(maiorValor)) {
              maiorValor = imo;
            }

            if (trapSampleNumbers[ano].label) {
              dataSetObjectImo[trapSampleNumbers[ano].label] = imo;
              dataSetObjectIdo[trapSampleNumbers[ano].label] = parseFloat(ido);
              dataSetObjectIpo[trapSampleNumbers[ano].label] = ipo;
              dataSetObjectIpa[trapSampleNumbers[ano].label] = ipa;
            } else {
              dataSetObjectImo["IM"] = imo;
              dataSetObjectIdo["ID"] = parseFloat(ido);
              dataSetObjectIpo["IP"] = ipo;
              dataSetObjectIpa["IPA"] = ipa;
            }

            if (trapStatusNumbers[ano].label) {
              dataSetObjectPendentsVistoried[trapStatusNumbers[ano].label] = vistoryToChart;
            } else {
              dataSetObjectPendentsVistoried["Vistoriadas"] = vistoryToChart;
            }
          }
        }
        datasetPendentsVistoried.push(dataSetObjectPendentsVistoried);
        datasetIdo.push(dataSetObjectIdo);
        datasetImo.push(dataSetObjectImo);
        datasetIpo.push(dataSetObjectIpo); 
        datasetIpa.push(dataSetObjectIpa); 
      }
      maiorValor = parseInt(maiorValor);
      setMaxDomainImo(maiorValor);
      setChartDataSetsImo(datasetImo);
      setChartDataSetsPendentsVistoried(datasetPendentsVistoried);
      setChartDataSetsIdo(datasetIdo);
      setChartDataSetsIpo(datasetIpo);
      setChartDataSetsIpa(datasetIpa);
      setIsGraphsLoading(false);
    }
  }, [isTrapLabLoading, isTrapStatusLoading])

  useEffect(() => {
    if (chartLabels.length > 0) {
      fetchData();
    }
  }, [chartLabels]);


  async function applyFilter() {
    if (year.length > 0) {
      setTrapLabLoading(true);
      setTrapStatusLoading(true);
      setTrapMapLoading(true);
      setActiveTrapMapLoading(true);
      buildYearsLabelsAndPeriods(year);
    } else {
      setTrapLabLoading(true);
      setTrapStatusLoading(true);
      setTrapMapLoading(true);
      setActiveTrapMapLoading(true);
      var startDateAux = getStartDate();
      var endDateAux = getEndDate();
      buildLabelsAndPeriods(startDateAux, endDateAux);
    }
    
    
  }

  function changePeriod(e, field) {
    switch (field) {
      case 'Year':
        if (e === null) {
          setFilterYear([]);
          setFilterMonths([]);
          setFilterWeeks([]);
          break;
        } else {
          e = e.sort((a, b) => parseInt(a.value) - parseInt(b.value));
        }

        setFilterYear(e !== null ? e : []);
        break;

      case 'Month':
        if (e !== null) {
          setFilterWeeks([]);
          e = e.sort((a, b) => parseInt(a.value) - parseInt(b.value));
        }

        setFilterMonths(e !== null ? e : []);
        break;

      case 'EpiWeek':
        if (e !== null) {
          setFilterMonths([]);
          e = e.sort((a, b) => parseInt(a.value) - parseInt(b.value));
        }

        setFilterWeeks(e !== null ? e : []);
        break;

      case 'Week1':
        if (e !== null) {
          setFilterMonths([]);
        }
        setWeekValue1(e !== null ? e : []);
        break;

      case 'Week2':
        if (e !== null) {
          setFilterMonths([]);
        }
        setWeekValue2(e !== null ? e : []);
        break;
    }
  }

  function handleChangeFilter(field, value) {
    switch (field) {
      case "startDate":
        if (moment(value).isAfter(endDate)) {
          toast.error("Período de data inválido!");
        } else {
          setStartDate(value);
        }
        break;
      case "endDate":
        if (moment(value).isBefore(startDate)) {
          toast.error("Período de data inválido!");
        } else {
          setEndDate(value);
        }
        break;
      case "trapType":
        const selectedTrapType = trapTypeOptions.filter(tp => tp.id == value);

        //setando a especie para aedes aegypti se marcar adultrap
        if (ARMADILHA_INFO[selectedTrapType[0].name].apelido == "Adultrap" || ARMADILHA_INFO[selectedTrapType[0].name].apelido == "BG-GAT") {
          setSelectedSpecie('3ce3a48f-9555-4b58-8bb9-18c8a4c7549c')
        } else {
          setSelectedSpecie(null)
        }

        setTrapTypeAUX({ trapTypeId: selectedTrapType[0].id, trapTypeName: ARMADILHA_INFO[selectedTrapType[0].name].apelido, trapName: selectedTrapType[0].name });
        toast.info("Tipo de armadilha alterado! Clique no botão para filtrar novamente.");
        break;
      case "demandId":
        if (value) {
          setFilterDemand(value.value);
        } else {
          setFilterDemand("00000000-0000-0000-0000-000000000000")
        }
        break;
      case "teamId":
        if (value) {
          let employeeUserIds = new Array();
          for (const member of value.members) {
            if (member.userId) {
              employeeUserIds.push(member.userId);
            }
          }
          teamEmployees.current = employeeUserIds;
        } else {
          teamEmployees.current = new Array();
        }

        setFilterTeam(value);
        break;
      case "employeeId":
        if (value) {
          setFilterEmployee(value.value);
        } else {
          setFilterEmployee("00000000-0000-0000-0000-000000000000")
        }
        break;
      case "territorialization":
        if (value !== null) {
          //colocando as territorializações para ficarem selecionadas no filtro.
          setSelectedTerritorializations(value);
        } else {
          setSelectedTerritorializations([]);
        }
        break;
      case "specie":
        setSelectedSpecie(value)
        break;
    }
  }

  async function fetchTrapToMap(employees){
    const organizationId = await getUserData("organizationId");
    let filterPeriod = null;
    let previousFilterPeriod = null;

    const currentDate = moment();

    var arrayProjects = await getProjectsId();

    for (let i = chartPeriods.length - 1; i >= 0; i--) {
      if (filterPeriod === null) {
        const periods = chartPeriods[i].periods;
        for (let j = periods.length - 1; j >= 0; j--) {
          if (currentDate.isBetween(moment(periods[j].beginDate), moment(periods[j].finalDate)) || currentDate.isSameOrAfter(moment(periods[j].finalDate))) {
            filterPeriod = periods[j];
            previousFilterPeriod = periods[j - 1];
            break;
          }
        }
      } else {
        break;
      }

      if (filterPeriod === null && i === 0) {
        filterPeriod = { beginDate: currentDate.format("YYYY-MM-DD"), finalDate: currentDate.format("YYYY-MM-DD") }
        break;
      }
    }

    const previousFilterTrapToMap = {
      ProjectsId: arrayProjects,
      organizationId,
      period: previousFilterPeriod,
      trapTypeId: trapType.trapTypeId,
      usersIds: employee && employee != "00000000-0000-0000-0000-000000000000" ? [employee] : employees,
      demandId: demand,
      territorializations: selectedTerritorializations.map(({ value }) => value),
      specieId: selectedSpecie && selectedSpecie != "" ? selectedSpecie : null,
    }

    const filterTrapToMap = {
      ProjectsId: arrayProjects,
      organizationId,
      period: filterPeriod,
      trapTypeId: trapType.trapTypeId,
      usersIds: employee && employee != "00000000-0000-0000-0000-000000000000" ? [employee] : employees,
      demandId: demand,
      territorializations: selectedTerritorializations.map(({ value }) => value),
      specieId: selectedSpecie && selectedSpecie != "" ? selectedSpecie : null,
    }

    const [trapsToMap, previousTrapsToMap] = await Promise.all([
      getTrapsToMap(filterTrapToMap), 
      getTrapsToMap(previousFilterTrapToMap),
    ]);

    setTrapMapInfo(trapsToMap.data);
    setPreviousTrapMapInfo(previousTrapsToMap.data);
  
    return true;
  }

  async function fetchActiveTrapToMap(employees) {
    let filterPeriod = null;
    let previousFilterPeriod = null;

    const currentDate = moment();

    for (let i = chartPeriods.length - 1; i >= 0; i--) {
      if (filterPeriod === null) {
        const periods = chartPeriods[i].periods;
        for (let j = periods.length - 1; j >= 0; j--) {
          if (currentDate.isBetween(moment(periods[j].beginDate), moment(periods[j].finalDate)) || currentDate.isSameOrAfter(moment(periods[j].finalDate))) {
            filterPeriod = periods[j];
            previousFilterPeriod = periods[j - 1];
            break;
          }
        }
      } else {
        break;
      }

      if (filterPeriod === null && i === 0) {
        filterPeriod = { beginDate: currentDate.format("YYYY-MM-DD"), finalDate: currentDate.format("YYYY-MM-DD") }
        break;
      }
    }


    const activeTrapPromises = trapTypes.map(async (thisTrapType) => {
      let arrayProjects = await getProjectsId();

      const filterMap = {
        ProjectsId: arrayProjects,
        organizationId,
        period: filterPeriod,
        trapTypeId: thisTrapType.trapTypeId,
        usersIds: employees,
        demandId: demand,
        territorializations: selectedTerritorializations.map(({ value }) => value),
      };
  
      try {
        const response = await getActiveTrapsToMap(filterMap);
        return {data: response.data, trapType: thisTrapType.trapTypeName};
      } catch (error) {
        console.error(error);
        throw error;
      }
    });
  
    const activeTrap = await Promise.all(activeTrapPromises);

    setActiveTrapMapInfo(activeTrap);
    setActiveTrapMapLoading(false);

    // let objActiveTraps = {};

    // activeTrap.forEach((value, index) => {
    //   if (index > 0) {
    //     objActiveTraps.data.actives = objActiveTraps.actives.concat(value.data.actives);
    //   } else {
    //     objActiveTraps = value;
    //   }
    // });
  }

  async function getProjectsId() {
    var arrayProjects = [];

    if(project && project.length > 0) {
      project.map((objProject) => {
        arrayProjects.push(objProject.value)
      })
    }

    return arrayProjects;
  }

  async function fetchTrapEggNumbers(employees) {
    let arrayProjects = await getProjectsId();    

    const { trapTypeId } = trapType;
    for (const chartPeriod of chartPeriods) {
      chartPeriod.ProjectsId = arrayProjects;
      chartPeriod.organizationId = organizationId;
      chartPeriod.trapTypeId = trapTypeId;
      chartPeriod.usersIds = employee && employee != "00000000-0000-0000-0000-000000000000" ? [employee] : employees;
      chartPeriod.demandId = demand;
      chartPeriod.territorializations = selectedTerritorializations.map(({ value }) => value);
    }

    const trapSampleRequest = await getTrapEggNumbersFromLab(chartPeriods);
    const trapSampleNumbers = trapSampleRequest.data;

    for (const index in trapSampleNumbers) {
      trapSampleNumbers[index].label = chartPeriods[index].year;
    }

    return trapSampleNumbers;
  }

  async function fetchDataGeoRef(){
    const filter = [
      {
      "year":"",
      "periods": [
        {
          "finalDate": endDate,
          "beginDate": startDate
        }
      ],
      "years" : [
        "2023"
      ],
      "typeDateFilter": "DT_SIN_PRI",
      "organizationId": organizationId,
      "desease": "DENGUE",
      "ageInterval": 5,
      "ageMaximum": 85,
      "territorializations": selectedTerritorializations.map(({ value }) => value)
    }];

    const dataGeoRequest = await getNotificationsGeoRef(filter)

    return dataGeoRequest;
  }

  async function fetchTrapStatusNumbers(employees) {
    var arrayProjects = await getProjectsId();

    for (const chartPeriod of chartPeriods) {
      chartPeriod.ProjectsId = arrayProjects;
      chartPeriod.organizationId = organizationId;
      chartPeriod.trapTypeId = trapType.trapTypeId;
      chartPeriod.usersIds = employee && employee != "00000000-0000-0000-0000-000000000000" ? [employee] : employees;
      chartPeriod.demandId = demand;
      chartPeriod.territorializations = selectedTerritorializations.map(({ value }) => value);
      chartPeriod.specieId = selectedSpecie
    }

    console.log("chartPeriods", chartPeriods, "project", project);

    const trapStatusRequest = await getTrapStatusNumbers(chartPeriods)

    const trapStatusNumbers = trapStatusRequest.data;

    for (const index in trapStatusNumbers) {
      trapStatusNumbers[index].label = chartPeriods[index].year;
    }

    return trapStatusNumbers;
  }
  
  async function fetchData() {
    const employees = teamEmployees.current;
    
    if (employee != "00000000-0000-0000-0000-000000000000" && !employees.includes(employee)) {
      employees.push(employee)
    }

    try {
      await fetchActiveTrapToMap(employees);
    
      const fetchTrapEggNumbersPromise = fetchTrapEggNumbers(employees);
      const fetchTrapStatusNumbersPromise = fetchTrapStatusNumbers(employees);
      const fetchDataGeoRefPromise = fetchDataGeoRef();
      const fetchTrapToMapPromise = webVersion != "samp_version" ? fetchTrapToMap(employees) : null;

      const results = await Promise.allSettled([
        fetchTrapEggNumbersPromise,
        fetchTrapStatusNumbersPromise,
        fetchDataGeoRefPromise,
        fetchTrapToMapPromise
      ]);

      results.forEach((result, index) => {
        if (result.status === 'fulfilled') {
          const value = result.value;
  
          switch (index) {
            case 0:
              setTrapSampleNumbers(value);
              setIsGraphsLoading(true);
              setTrapLabLoading(false);
              break;
            case 1:
              setTrapStatusNumbers(value);
              setTrapStatusLoading(false);
              break;
            case 2:
              setDataGeoRef(value.data);
              setDataGeoRefLoading(false);
              break;
            case 3:
              // TrapsToMap
              setTrapMapLoading(false);
              break;
            default:
              break;
          }
        } else {
          const reason = result.reason;
          console.error(`A Promise ${index} foi rejeitada por:`, reason);
        }
      });

    } catch (error) {
      console.error(error);
    }
  }

  async function buildLabelsAndPeriods(startDate,endDate) {
    const labels = await buildEpidemiologicalLabels({ startDate, endDate }); //semana epidemiológica
    const periods = new Array({ year: "", periods: [] });
    let labelsToCharts = new Array();
    for (let i = 0; i < labels.length; i++) {
      for (let labelIndex = 0; labelIndex < labels[i].labels.length; labelIndex++) {
        const { finalDate, beginDate } = await getEpidemiologicalPeriod({ labels: labels[i].labels, labelIndex, startDate, endDate, year: labels[i].year, lastYear: labels[labels.length - 1].year, firstYear: labels[0].year }); //semana epidemiológica
        labelsToCharts.push(labels[i].labels[labelIndex] + "/" + labels[i].year);
        periods[0].periods.push({ finalDate, beginDate });
      }
    }

    setChartPeriods(periods);
    setChartLabels(labelsToCharts);
  }

  async function buildYearsLabelsAndPeriods(years = new Array()) {
    const labels = new Array();
    const periods = new Array();

    if (months.length === 0 && weeks.length === 0) {
      for (let i = 1; i <= 53; i++) {
        labels.push(i);
      }

      for (let i = 0; i < years.length; i++) {
        const epidemiologicalWeek = getWeeks().filter(ew => ew.year === years[i].value);
        periods.push({ year: years[i].value, periods: epidemiologicalWeek[0].weeks.map((ew) => { return { finalDate: moment(ew.endDate, "DD/MM/YYYY").format("YYYY-MM-DD"), beginDate: moment(ew.beginDate, "DD/MM/YYYY").format("YYYY-MM-DD") } }) });
      }
    } else if (weeks.length === 0) {
      months.forEach(month => {
        labels.push(month.label);
      })

      for (let i = 0; i < years.length; i++) {
        const monthPeriods = [];

        for (let j = 0; j < months.length; j++) {
          const beginDate = moment(new Date(years[i].value, months[j].value, 1)).format("YYYY-MM-DD");
          const finalDate = moment(beginDate).add(1, 'month').subtract(1, 'day').format("YYYY-MM-DD");
          monthPeriods.push({ beginDate, finalDate });
        }

        periods.push({ year: years[i].value, periods: monthPeriods });
      }
    } else {
      weeks.forEach(weeks => {
        labels.push(weeks.label);
      })

      for (let i = 0; i < years.length; i++) {
        const weeksPeriods = [];
        const epiWeeks = getWeeks().filter(ew => ew.year === years[i].value);

        for (let j = 0; j < weeks.length; j++) {
          if (epiWeeks[0].weeks[weeks[j].value] !== undefined) {
            const beginDate = moment(epiWeeks[0].weeks[weeks[j].value].beginDate, "DD/MM/YYYY").format("YYYY-MM-DD");
            const finalDate = moment(epiWeeks[0].weeks[weeks[j].value].endDate, "DD/MM/YYYY").format("YYYY-MM-DD");
            weeksPeriods.push({ beginDate, finalDate });
          }
        }
        periods.push({ year: years[i].value, periods: weeksPeriods });
      }
    }

    setChartPeriods(periods);
    setChartLabels(labels);
  }

  const styles = {
    grid: {
      paddingLeft: 0,
      paddingRight: 0
    },
    row: {
      marginLeft: 0,
      marginRight: 0
    },
    col: {
      paddingLeft: 0,
      paddingRight: "0.2vw"
    }
  };

  return (
    <>
      {/* Page content */}

      <ToastContainer />
      <Container fluid className="bg-gradient-info pt-5 pb-6 pt-md-8 mb-5">
        <Container className="filter-container">

          <button className="btn btn-primary hide-btn" onClick={() => { setFilterVisibility(!isFilterVisible) }}>{isFilterVisible ? "Esconder Filtro" : "Mostrar Filtro"}</button>

          {isFilterVisible ?
            <button className="btn btn-primary filter-btn" onClick={() => {
              if (trapTypeAUX.trapTypeId != "00000000-0000-0000-0000-000000000000") {
                setTrapType(trapTypeAUX);
                applyFilter();
              }
              else {
                applyFilter()
              }
            }}>

              Filtrar
            </button>
            : null}
        </Container>

        {isFilterVisible ?
          <>
            <VisitOperacionalFilterPresentational
              handleChangeFilter={handleChangeFilter}
              employeeId={employee}
              employeeIdList={employeeList}
              demandId={demand}
              demandIdList={demandList}
              teamId={team}
              teamIdList={teamList}
            />
            <Row style={{ display: "flex", gapTop: "5px" }}>
              <MainPeriodComponent
                months={months}
                years={year}
                weeks={weeks}
                weekValue1={weekValue1}
                weekValue2={weekValue2}
                showWeekToWeek
                changePeriod={changePeriod}
              />

              <EntomologicoFilterPresentational
                startDate={startDate}
                endDate={endDate}
                trapTypeId={trapType.trapTypeId}
                trapTypeIdAUX={trapTypeAUX.trapTypeId}
                trapTypeOptions={trapTypeOptions}
                handleChangeFilter={handleChangeFilter}
                speciesOptions={speciesOptions}
                selectedSpecie={selectedSpecie}
                applyFilter={applyFilter}
              />

              <GeographicFilterPresentational
                Territorializations={territorializationList}
                handleChangeFilter={handleChangeFilter}
                SelectedTerritorializations={selectedTerritorializations}
              />
              <Col className="linha-form col-md-4 geo-responsive-filter project-filter"> 
                <span className="h4 text-white">Atividade: </span>
                <Select
                    styles={{
                        control: styles => ({ ...styles, minHeight: "2.90rem", borderRadius: "0.370rem" }),
                        option: styles => ({ ...styles, fontSize: "0.875rem" }),
                        valueContainer: styles => ({ ...styles, fontSize: "0.875rem", color: "#8898aa" }),
                        singleValue: styles => ({ ...styles, fontSize: "0.875rem", color: "#8898aa" }),
                        placeholder: styles => ({ ...styles, fontSize: "0.875rem", color: "#8898aa" }),
                        menuPortal: styles => ({ ...styles, zIndex: 9999 }),
                    }}
                    value={project}
                    isMulti={true}
                    placeholder={"Selecione"}
                    isClearable={true}
                    blurInputOnSelect={false}
                    closeMenuOnSelect={false}
                    onChange={(e) => setProject(e)}
                    options={projectOptions}
                />
              </Col>
            </Row>
            <Row>
                <Col className="col-4 col-md-4 col">
                </Col>
                <TodayEpidemiologicalWeekComponent 
                    todayEpidemiologicalWeek={todayEpidemiologicalWeek}
                />
                <Col className="col-4 col-md-4 col">
                </Col>
            </Row>
            <br />
          </>
          : null}
      </Container>
      <Container fluid className="mt--7">
        {/* Aqui 1 */}
        <Row className="mb-5" >
          <Col xl="12" className="mb-5 mb-xl-0">
            {isActiveTrapMapLoading ?
              <div className="mb-4" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <LoadingSpin primaryColor="#fff" size={28} />
              </div>
              :
              <ActiveMapContainer
                trapMapInfo={ActiveTrapMapInfo}
                territorializationsList={territorializationList}
                territorializationFiltered={selectedTerritorializations}
                trapTypeName={trapType.trapTypeName}
              />
            }
          </Col>
        </Row>
        {(trapType.trapTypeName === 'Ovitrampa' || trapType.trapTypeName === 'Adultrap' || trapType.trapTypeName === 'BG-GAT' || trapType.trapTypeName === 'Monitor') && webVersion != "samp_version" ?
          <>
            <Row className="mb-5" >
              <Col xl="6" className="mb-5 mb-xl-0">
                {isGraphsLoading ?
                  <div className="mb-4" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                    <LoadingSpin primaryColor="#000" size={28} />
                  </div>
                  :
                  <>
                    {trapType.trapName !== 'armadilhaMosquitoAdulto' &&  trapType.trapName !== 'armadilhaMosquitoAdulto2'?
                      <IPOChartContainer
                        chartDataSets={chartDataSetsIpo}
                        data={trapSampleNumbers}
                      /> : <></>}

                    {(trapType.trapName === 'armadilhaMosquitoAdulto' || trapType.trapName === 'armadilhaMosquitoAdulto2') && isTrapStatusLoading == false ?
                      <IPOChartContainerAdultrap
                        chartDataSets={chartDataSetsIpa}
                        data={trapSampleNumbers}
                        dataTrapStatusNumbers={trapStatusNumbers}
                      /> : <></>}
                  </>
                }
              </Col>
              <Col xl="6" className="mb-5 mb-xl-0">
                {isTrapMapLoading || dataGeoRefLoading ?
                  <div className="mb-4" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                    <LoadingSpin primaryColor="#000" size={28} />
                  </div>
                  :
                  <PositivesMapContainer
                    data={trapSampleNumbers}
                    dataGeoRef={dataGeoRef}
                    dataGeoRefConfirmed={dataGeoRef.filter(t => t.confirmed == true)}
                    labels={chartLabels}
                    trapMapInfo={TrapMapInfo}
                    previousTrapMapInfo={PreviousTrapMapInfo}
                    territorializationList={territorializationList}
                    territorializationFiltered={selectedTerritorializations}
                    trapTypeName={trapType.trapTypeName}
                  />
                }
              </Col>
            </Row>
            <Row className="mb-5">
              <Col xl="6" className="mb-5 mb-xl-0">
                {isGraphsLoading ?
                  <div className="mb-4" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                    <LoadingSpin primaryColor="#000" size={28} />
                  </div>
                  :
                  <>
                    {trapType.trapName !== 'armadilhaMosquitoAdulto' && trapType.trapName !== 'armadilhaMosquitoAdulto2' ?
                      <IDOChartContainer
                        data={trapSampleNumbers}
                        chartDataSets={chartDataSetsIdo}
                      />
                      :
                      <>
                        {isTrapStatusLoading ?
                          <div className="mb-4" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                            <LoadingSpin primaryColor="#000" size={28} />
                          </div>
                          :
                          <IDOChartContainerAdult
                            data={trapSampleNumbers}
                            chartDataSets={chartDataSetsIdo}
                          />
                        }
                      </>
                    }
                  </>
                }
              </Col>
              <Col xl="6" className="mb-5 mb-xl-0">
                {isTrapMapLoading ?
                  <div className="mb-4" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                    <LoadingSpin primaryColor="#000" size={28} />
                  </div>
                  :
                  <IDOMapContainer
                    data={trapSampleNumbers}
                    labels={chartLabels}
                    trapMapInfo={TrapMapInfo}
                    previousTrapMapInfo={PreviousTrapMapInfo}
                    territorializationList={territorializationList}
                    trapTypeName={trapType.trapTypeName}
                  />
                }
              </Col>
            </Row>
            <Row className="mb-5">
              <Col xl="6" className="mb-5 mb-xl-0">
                {isGraphsLoading ?
                  <div className="mb-4" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                    <LoadingSpin primaryColor="#000" size={28} />
                  </div>
                  :
                  <>
                    {trapType.trapName !== 'armadilhaMosquitoAdulto' && trapType.trapName !== 'armadilhaMosquitoAdulto2' ?
                      <IMOChartContainer
                        chartDataSets={chartDataSetsImo}
                        maxDomain={maxDomainImo}
                        data={trapSampleNumbers}
                      />
                      :
                      <>
                        {isTrapStatusLoading ?
                          <div className="mb-4" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                            <LoadingSpin primaryColor="#000" size={28} />
                          </div>
                          :
                          <IMAChartContainer
                            chartDataSets={chartDataSetsImo}
                            maxDomain={maxDomainImo}
                            data={trapSampleNumbers}
                          />
                        }
                      </>
                    }
                  </>
                }
              </Col>
              <Col xl="6" className="mb-5 mb-xl-0">
                {isTrapMapLoading ?
                  <div className="mb-4" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                    <LoadingSpin primaryColor="#000" size={28} />
                  </div>
                  :
                  <IMOMapContainer
                    data={trapSampleNumbers}
                    labels={chartLabels}
                    trapMapInfo={TrapMapInfo}
                    trapData={trapSampleNumbers}
                    previousTrapMapInfo={PreviousTrapMapInfo}
                    territorializationList={territorializationList}
                    territorializationFiltered={selectedTerritorializations}
                  />
                }
              </Col>
            </Row>
          </>
          : null}
        {/* Aqui 2 */}
        <Row className="mb-5" >
          <Col xl="6" className="mb-5 mb-xl-0">
            {isGraphsLoading ?
              <div className="mb-4" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                <LoadingSpin primaryColor="#000" size={28} />
              </div>
              :
                webVersion != "samp_version"
                ?
                <PendentsVistoriedContainer
                  chartDataSets={chartDataSetsPendentsVistoried}
                  data={trapStatusNumbers}
                />
                :
                <></>
            }
          </Col>
          <Col xl="6" className="mb-5 mb-xl-0">
            {isTrapMapLoading ?
              <div className="mb-4" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                <LoadingSpin primaryColor="#000" size={28} />
              </div>
              :
                webVersion != "samp_version"
                ?
                <PendentsVistoriedMapContainer
                  data={trapSampleNumbers}
                  labels={chartLabels}
                  trapMapInfo={TrapMapInfo}
                  trapData={trapSampleNumbers}
                  previousTrapMapInfo={PreviousTrapMapInfo}
                  territorializationList={territorializationList}
                  territorializationFiltered={selectedTerritorializations}
                />
                :
                <></>
            }
          </Col>
        </Row>

        {trapType.trapTypeName === 'Ovitrampa' || trapType.trapTypeName === 'Adultrap'  || trapType.trapTypeName === 'BG-GAT' || trapType.trapTypeName === 'Monitor' ?
          <Row className="mb-5" >
            <Col xl="12" className="mb-5 mb-xl-0">
              {isTrapLabLoading ?
                <div className="mb-4" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                  <LoadingSpin primaryColor="#000" size={28} />
                </div>
                :
                  webVersion != "samp_version"
                  ?
                  <OvosLineContainer
                    data={trapSampleNumbers}
                    trapTypeName={trapType.trapName}
                    labels={chartLabels}
                  />
                  :
                  <></>
              }
            </Col>
          </Row>
          : null}
      </Container>
    </>
  );
};

export default DashboardEntomologico;
