import { useContextSelector } from "use-context-selector";
import { OrganizationContext } from "../contexts/organizationContext";

const useTrapsList = () => {
  const trapsList = useContextSelector(
    OrganizationContext,
    (context) => context.trapsList
  );
  const setTrapsList = useContextSelector(
    OrganizationContext,
    (context) => context.setTrapsList
  );

  return { trapsList, setTrapsList };
};

export default useTrapsList;
