import React from "react";
import GoalByDayComposedChartGraphic from "./Composed/GoalByDayComposedChartGraphic";
import moment from "moment";

const GoalByDayGraphicWrapper = ({ goalsData, productivityFilters }) => {
  const [formatedGoalsByDay, setFormatedGoalsByDay] = React.useState([]);
  const [formatedGoalsByUser, setFormatedGoalsByUser] = React.useState(null);

  React.useEffect(() => {
    if (!goalsData || goalsData.lenght === 0) return;

    const goalsByDay = formatGoalsByDays(goalsData);
    const goalsByUsers = formatGoalsByUsers(goalsData);

    setFormatedGoalsByDay(goalsByDay);
    setFormatedGoalsByUser(goalsByUsers);
  }, [goalsData]);

  const calculateGoalConcludedPercentual = (total, concluded) => {
    return total ? ((concluded / total) * 100).toFixed(2) : 0;
  };

  const formatGoalsByDays = (goalsData) => {
    if (!goalsData || goalsData.length === 0) return;

    const result = goalsData.map(({ dailyGoals, day }) => {
      let installedtrapsGoalByDay = 0;
      let maintenancetrapsGoalByDay = 0;
      let uninstalledtrapsGoalByDay = 0;
      let visitsGoalByDay = 0;
      let concludedInstalledTrapsByDay = 0;
      let concludedMaintenanceTrapsByDay = 0;
      let concludedUninstalledTrapsByDay = 0;
      let concludedVisitsByDay = 0;

      dailyGoals.forEach(({ goals, results }) => {
        concludedInstalledTrapsByDay += results.installedTraps;
        concludedMaintenanceTrapsByDay += results.readedTraps;
        concludedUninstalledTrapsByDay += results.removedTraps;
        concludedVisitsByDay += results.openVisits;

        goals.forEach(({ type, value }) => {
          if (type.name === "Armadilhas Instaladas")
            installedtrapsGoalByDay += value;
          if (type.name === "Armadilhas Manutenção")
            maintenancetrapsGoalByDay += value;
          if (type.name === "Armadilhas Desinstalação")
            uninstalledtrapsGoalByDay += value;
          if (type.name === "Visitas") visitsGoalByDay += value;
        });
      });

      const totalConcludedGoals =
        concludedInstalledTrapsByDay +
        concludedMaintenanceTrapsByDay +
        concludedUninstalledTrapsByDay +
        concludedVisitsByDay;

      const totalGoals =
        installedtrapsGoalByDay +
        maintenancetrapsGoalByDay +
        uninstalledtrapsGoalByDay +
        visitsGoalByDay;

      return {
        date: moment(day).format("DD/MM/YYYY"),
        Meta: 100,
        "Meta - Armadilhas Instaladas": installedtrapsGoalByDay,
        "Meta - Armadilhas Manutenção": maintenancetrapsGoalByDay,
        "Meta - Armadilhas Desinstalação": uninstalledtrapsGoalByDay,
        "Meta - Visitas": visitsGoalByDay,
        "Meta - Total": totalGoals,
        "Realizado - Armadilhas Instaladas": concludedInstalledTrapsByDay,
        "Realizado - Armadilhas Manutenção": concludedMaintenanceTrapsByDay,
        "Realizado - Armadilhas Desinstalação": concludedUninstalledTrapsByDay,
        "Realizado - Visitas": concludedVisitsByDay,
        "Realizado - Total": totalConcludedGoals,
        "Realizado - Armadilhas Instaladas (%)":
          calculateGoalConcludedPercentual(
            installedtrapsGoalByDay,
            concludedInstalledTrapsByDay
          ),
        "Realizado - Armadilhas Manutenção (%)":
          calculateGoalConcludedPercentual(
            maintenancetrapsGoalByDay,
            concludedMaintenanceTrapsByDay
          ),
        "Realizado - Armadilhas Desinstalação (%)":
          calculateGoalConcludedPercentual(
            uninstalledtrapsGoalByDay,
            concludedUninstalledTrapsByDay
          ),
        "Realizado - Visitas (%)": calculateGoalConcludedPercentual(
          visitsGoalByDay,
          concludedVisitsByDay
        ),
        "Realizado - Total (%)": calculateGoalConcludedPercentual(
          totalGoals,
          totalConcludedGoals
        ),
      };
    });

    return result;
  };

  const formatGoalsByUsers = (goalsData) => {
    if (!goalsData || goalsData.length === 0) return;

    const finalResult = {};

    goalsData.forEach(({ day, dailyGoals }) => {
      dailyGoals.forEach(({ goals, name, results }) => {
        const actualDate = moment(day).format("DD/MM/YYYY");
        let visitsGoal = 0;
        let installTrapsGoal = 0;
        let maintenanceTrapsGoal = 0;
        let uninstallTrapsGoal = 0;

        goals.forEach(({ value, type }) => {
          if (type.name === "Armadilhas Instaladas") installTrapsGoal += value;
          if (type.name === "Armadilhas Manutenção")
            maintenanceTrapsGoal += value;
          if (type.name === "Armadilhas Desinstalação")
            uninstallTrapsGoal += value;
          if (type.name === "Visitas") visitsGoal += value;
        });

        const totalGoals =
          installTrapsGoal +
          maintenanceTrapsGoal +
          uninstallTrapsGoal +
          visitsGoal;
        const totalConcludedGoals =
          results.installedTraps +
          results.readedTraps +
          results.removedTraps +
          results.openVisits;

        const result = {
          firstName: name.split(" ")[0],
          fullName: name,
          data: actualDate,
          "Meta - Armadilhas Instaladas": installTrapsGoal,
          "Meta - Armadilhas Manutenção": maintenanceTrapsGoal,
          "Meta - Armadilhas Desinstalação": uninstallTrapsGoal,
          "Meta - Visitas": visitsGoal,
          "Meta - Total": totalGoals,
          "Realizado - Armadilhas Instaladas (%)":
            calculateGoalConcludedPercentual(
              installTrapsGoal,
              results.installedTraps
            ),
          "Realizado - Armadilhas Manutenção (%)":
            calculateGoalConcludedPercentual(
              maintenanceTrapsGoal,
              results.readedTraps
            ),
          "Realizado - Armadilhas Desinstalação (%)":
            calculateGoalConcludedPercentual(
              uninstallTrapsGoal,
              results.removedTraps
            ),
          "Realizado - Visitas (%)": calculateGoalConcludedPercentual(
            visitsGoal,
            results.openVisits
          ),
          "Realizado - Total (%)": calculateGoalConcludedPercentual(
            totalGoals,
            totalConcludedGoals
          ),
          "Realizado - Armadilhas Instaladas": results.installedTraps,
          "Realizado - Armadilhas Manutenção": results.readedTraps,
          "Realizado - Armadilhas Desinstalação": results.removedTraps,
          "Realizado - Visitas": results.openVisits,
          "Realizado - Total": totalConcludedGoals,
          Meta: 100,
        };

        if (!finalResult[actualDate]) finalResult[actualDate] = [];

        const previousResultValues = finalResult[actualDate];

        finalResult[actualDate] = [...previousResultValues, result];
      });
    });

    return finalResult;
  };

  return (
    <GoalByDayComposedChartGraphic
      headerText={`Metas por dia em ${moment(
        productivityFilters.beginDate
      ).format("DD/MM/YYYY")} a ${moment(productivityFilters.finalDate).format(
        "DD/MM/YYYY"
      )}`}
      goalsByDayData={formatedGoalsByDay}
      goalsByUserData={formatedGoalsByUser}
      productivityFilters={productivityFilters}
    />
  );
};

export default GoalByDayGraphicWrapper;
