import React from "react";

import {
  CartesianGrid,
  ComposedChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { Card, CardContent, CardFooter, CardHeader } from "../../Cards/Card";

const ComposedChartGraphic = ({
  barsAndLines,
  customTooltip,
  customXAxisTick,
  data,
  headerText,
  xAxisDataKey,
  yAxisTickFormatter,
}) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [screenHeight, setScreenHeight] = React.useState(window.innerHeight);

  React.useEffect(() => {
    const handleResize = () => {
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleModal = () => {
    setIsModalOpen((previousValue) => !previousValue);
  };

  const styles = {
    modalCol: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "0.7rem",
      height: `${screenHeight - 300}px`,
    },
    col: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "25rem",
      marginTop: "0.7rem",
    },
    fullScreenStyle: {
      maxWidth: "100%",
      width: "100vw",
      height: "100vh",
      margin: "0",
      top: "0",
    },
    cardFooter: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      gap: "16px",
    },
  };

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        toggle={() => toggleModal()}
        style={styles.fullScreenStyle}
      >
        <ModalHeader toggle={() => toggleModal()}>
          Visualização em tela cheia
        </ModalHeader>
        <ModalBody style={{ height: `${screenHeight - 150}px` }}>
          <Card>
            <CardHeader
              dataToDownload={data}
              headerText={headerText}
              showExpandButton={true}
              showButtonsTooltips={false}
              handleExpandButtonClick={toggleModal}
            />
            <CardContent>
              <Row>
                <Col style={styles.modalCol}>
                  <ComposedGraphic
                    chartElements={barsAndLines}
                    customTooltip={customTooltip}
                    customXAxisTick={customXAxisTick}
                    data={data}
                    xAxisDataKey={xAxisDataKey}
                    yAxisTickFormatter={yAxisTickFormatter}
                  />
                </Col>
              </Row>
            </CardContent>
            <CardFooter style={styles.cardFooter}>
              <div></div>
              <img
                style={{ maxWidth: "100px", opacity: 0.5 }}
                src="/static/media/vitec.9bd71d52.png"
                alt="Logo do Vitec"
              />
            </CardFooter>
          </Card>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => toggleModal()}>
            Fechar
          </Button>
        </ModalFooter>
      </Modal>
      <Card>
        <CardHeader
          dataToDownload={data}
          headerText={headerText}
          showExpandButton={true}
          showButtonsTooltips={true}
          handleExpandButtonClick={toggleModal}
        />
        <CardContent>
          <Row>
            <Col style={styles.col}>
              <ComposedGraphic
                chartElements={barsAndLines}
                customTooltip={customTooltip}
                customXAxisTick={customXAxisTick}
                data={data}
                xAxisDataKey={xAxisDataKey}
                yAxisTickFormatter={yAxisTickFormatter}
              />
            </Col>
          </Row>
        </CardContent>
        <CardFooter style={styles.cardFooter}>
          <div></div>
          <img
            style={{ maxWidth: "100px", opacity: 0.5 }}
            src="/static/media/vitec.9bd71d52.png"
            alt="Logo do Vitec"
          />
        </CardFooter>
      </Card>
    </>
  );
};

const ComposedGraphic = ({
  chartElements,
  customTooltip,
  customXAxisTick,
  data,
  xAxisDataKey,
  yAxisTickFormatter,
}) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={xAxisDataKey} tick={customXAxisTick} />
        <YAxis tickFormatter={(value) => `${value}${yAxisTickFormatter}`} />
        <Tooltip content={customTooltip} />
        <Legend />
        {!chartElements || chartElements.length === 0
          ? null
          : chartElements.map((chart) => chart)}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default ComposedChartGraphic;
