import { useContextSelector } from "use-context-selector";
import { TrapsPageContext } from "../Contexts/trapsPageContext";

const useTrapsPage = () => {
  const isEditTrapModalOpen = useContextSelector(
    TrapsPageContext,
    (context) => context.isEditTrapModalOpen
  );
  const selectedTrapNumber = useContextSelector(
    TrapsPageContext,
    (context) => context.selectedTrapNumber
  );
  const setSelectedTrapNumber = useContextSelector(
    TrapsPageContext,
    (context) => context.setSelectedTrapNumber
  );
  const toggleEditTrapModal = useContextSelector(
    TrapsPageContext,
    (context) => context.toggleEditTrapModal
  );

  return {
    isEditTrapModalOpen,
    selectedTrapNumber,
    setSelectedTrapNumber,
    toggleEditTrapModal,
  };
};

export default useTrapsPage;
