import React from "react";

import {
  FeatureGroup,
  LayersControl,
  Map,
  Polygon,
  TileLayer,
  Tooltip,
  ScaleControl,
} from "react-leaflet";
import Control from "react-leaflet-control";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import Cookies from "js-cookie";
import { GOOGLE_MAPS_API_KEY } from "../../constants/WebFormConstants";
import { polygon } from "@turf/turf";
import { EditControl } from "react-leaflet-draw";
import L from "leaflet";
import FullscreenControl from "react-leaflet-fullscreen";

const { BaseLayer, Overlay } = LayersControl;

const MapWrapper = ({ children, ...props }) => {
  const [initalZoom, setInitalZoom] = React.useState(12);
  const [isMapLoaded, setIsMapLoaded] = React.useState(false);
  const [mapInitialCenterPosition, setMapInitialCenterPosition] =
    React.useState([-15.814951, -47.903183]);

  React.useEffect(() => {
    if (!Cookies.get("Admin-Organization")) return;

    const organizationCookies = JSON.parse(Cookies.get("Admin-Organization"));

    if (!organizationCookies.stateSub) return;

    const [organizationLatitude, organizationLongitude] =
      organizationCookies.stateSub.replace(" ", "").split(",");

    if (!validadeLatitudeLongitude(organizationLatitude, organizationLongitude))
      return;

    if (organizationCookies.countySub && organizationCookies.countySub !== "")
      setInitalZoom(Number(organizationCookies.countySub));

    setMapInitialCenterPosition([organizationLatitude, organizationLongitude]);
  }, []);

  const validadeLatitudeLongitude = (latitude, longitude) => {
    if (!latitude || !longitude) return false;

    const latitudeInNumber = +latitude;
    const longitudeInNumber = +longitude;

    if (!(latitudeInNumber >= -90 && latitudeInNumber <= 90)) return false;
    if (!(longitudeInNumber >= -180 && longitudeInNumber <= 180)) return false;

    return true;
  };

  return (
    <Map
      center={mapInitialCenterPosition}
      zoom={initalZoom}
      style={{ height: "47rem" }}
      whenReady={() => setIsMapLoaded(true)}
      {...props}
    >
      {isMapLoaded && (
        <>
          <WindRoseControl />
          <FullscreenControl
            position="topleft"
            title="Tela Cheia"
            titleCancel="Sair da Tela Cheia"
          />
          <MapDrawTools />
          <MapVisualizationLayersControl />
          {children}
          <ScaleControl
            position="bottomleft"
            imperial={false}
            updateWhenIdle={false}
          />
        </>
      )}
    </Map>
  );
};

const MapVisualizationLayersControl = () => {
  return (
    <LayersControl>
      <BaseLayer checked name="Normal">
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
      </BaseLayer>
      <BaseLayer name="Satélite">
        <ReactLeafletGoogleLayer
          googleMapsLoaderConf={{ KEY: GOOGLE_MAPS_API_KEY }}
          type={"hybrid"}
        />
      </BaseLayer>
    </LayersControl>
  );
};

const MapPolygonsLayersControl = ({
  data,
  layerTitle,
  defaultSelectedTypeLayerName = "",
}) => {
  const [typeLayersPolygons, setTypeLayersPolygons] = React.useState([]);

  React.useEffect(() => {
    if (!data || data.length === 0) return;

    const typeLayersPolygonsGroup = [];

    data.forEach(({ name, territorializations }) => {
      const typeLayerData = {
        name,
        polygons: [],
      };

      territorializations.forEach((territorialization) => {
        const turfPolygon = polygon([
          territorialization.coordinates.map(({ x, y }) => [x, y]),
        ]);

        turfPolygon.name = territorialization.name;
        turfPolygon.territorialization = territorialization;

        typeLayerData.polygons.push(turfPolygon);
      });

      typeLayersPolygonsGroup.push(typeLayerData);
    });

    setTypeLayersPolygons(typeLayersPolygonsGroup);
  }, [data]);

  return (
    <LayersControl position="topright">
      {layerTitle !== "" && (
        <BaseLayer checked name={layerTitle}>
          <TileLayer attribution="" url="" />
        </BaseLayer>
      )}
      {typeLayersPolygons &&
        typeLayersPolygons.length > 0 &&
        typeLayersPolygons.map(({ name, polygons }, index) => {
          return (
            <Overlay
              checked={defaultSelectedTypeLayerName === name}
              name={name}
              key={`${name}-overlay-${index}`}
            >
              <FeatureGroup
                fillOpacity={0.3}
                name={name}
                key={`${name}-featureGroup-${index}`}
              >
                {polygons &&
                  polygons.length > 0 &&
                  polygons.map(({ geometry, name }, index) => {
                    const coordinates = geometry.coordinates[0].map(
                      ([latitude, longitude]) => [longitude, latitude]
                    );

                    return (
                      <Polygon
                        key={index}
                        color={"#000000"}
                        fillColor={"#03a1fc"}
                        opacity={1}
                        positions={coordinates}
                      >
                        {name ? <Tooltip sticky>{name}</Tooltip> : null}
                      </Polygon>
                    );
                  })}
              </FeatureGroup>
            </Overlay>
          );
        })}
    </LayersControl>
  );
};

const MapDrawTools = ({
  handleActiveDrawedPolygonsChange,
  handleDeleteAllDrawedPolygonsData,
}) => {
  const [polygonColorIndex, setPolygonColorIndex] = React.useState(0);

  const polygonColors = [
    "yellow",
    "red",
    "blue",
    "orange",
    "green",
    "purple",
    "gray",
    "pink",
    "black",
    "white",
    "aqua",
    "olive",
  ];

  /* const onPolygonCreated = (e) => {
    const layer = e.layer;
    if (layer instanceof L.Polygon) {
      const geojson = layer.toGeoJSON();
      const polygonArea = turf.area(geojson);
      const polygonAreaInSquareMeteres = polygonArea;
      const polygonAreaInSquareKilometers = polygonArea / 1000000;
      const polygonAreaInEquit = polygonArea / 10000;

      handleActiveDrawedPolygonsChange({
        areaInSquareKilometers: polygonAreaInSquareKilometers,
        areaInSquareMeters: polygonAreaInSquareMeteres,
        areaInEquit: polygonAreaInEquit,
        color: polygonColors[polygonColorIndex],
      });

      const popupContent = `
        <div>
          Área do polígono:
          <br />
          <br />
          ・${polygonAreaInSquareMeteres.toFixed(2)} m²
          <br />
          ・${polygonAreaInSquareKilometers.toFixed(2)} km²
          <br />
          ・${polygonAreaInEquit.toFixed(2)} ha
        </div>
      `;

      layer.bindPopup(popupContent).openPopup();
      setPolygonColorIndex((previousValue) => previousValue + 1);
    }
  }; */

  const onPolygonsDeleted = () => {
    handleDeleteAllDrawedPolygonsData();
  };

  return (
    <FeatureGroup>
      <EditControl
        position="topleft"
        /* onCreated={onPolygonCreated}
        onDeleted={onPolygonsDeleted} */
        draw={{
          polyline: {
            icon: new L.DivIcon({
              iconSize: new L.Point(8, 8),
              className: "leaflet-div-icon leaflet-editing-icon",
            }),
            shapeOptions: {
              guidelineDistance: 10,
              color: "navy",
              weight: 3,
            },
          },
          marker: false,
          rectangle: false,
          circlemarker: false,
          circle: false,
          polygon: {
            shapeOptions: {
              color: polygonColors[polygonColorIndex],
              weight: 7,
              fillColor: "transparent",
              fillOpacity: 0,
            },
          },
        }}
      />
    </FeatureGroup>
  );
};

const WindRoseControl = () => {
  return (
    <Control position="topleft">
      <div className="leaflet-control-scale leaflet-control">
        <img
          width={60}
          src={require("../../assets/img/control/compass_rose_2.png")}
          alt="Rosa dos Ventos"
        />
      </div>
    </Control>
  );
};

export {
  MapWrapper as Map,
  MapVisualizationLayersControl,
  MapPolygonsLayersControl,
  MapDrawTools,
  WindRoseControl,
};
