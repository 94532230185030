import React from "react";

import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip } from "recharts";
import { Card, CardContent, CardFooter, CardHeader } from "../../Cards/Card";

const PieChartGraphic = ({
  data,
  chartElementsLimit = 0,
  customTooltip,
  dataKey,
  headerText,
  legendsKey,
  propertyToOrderBy,
}) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [screenHeight, setScreenHeight] = React.useState(window.innerHeight);
  const [dataToRenderOnDefaultView, setDataToRenderOnDefaultView] =
    React.useState(data);
  const [dataToRenderOnExpandedView, setDataToRenderOnExpandedView] =
    React.useState(data);

  React.useEffect(() => {
    if (!data || data.length === 0) return;

    let newDataToRender = [...data];

    if (propertyToOrderBy && propertyToOrderBy !== "")
      newDataToRender = newDataToRender.sort((a, b) => {
        return b[propertyToOrderBy] - a[propertyToOrderBy];
      });

    setDataToRenderOnExpandedView(newDataToRender);

    if (chartElementsLimit <= 0) {
      setDataToRenderOnDefaultView(newDataToRender);

      return;
    }

    const dataWithinElementsLimit = [];

    for (let i = 0; i < chartElementsLimit; i++) {
      if (!newDataToRender[i]) break;

      dataWithinElementsLimit.push(newDataToRender[i]);
    }

    setDataToRenderOnDefaultView(dataWithinElementsLimit);
  }, [data]);

  React.useEffect(() => {
    const handleResize = () => {
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleModal = () => {
    setIsModalOpen((previousValue) => !previousValue);
  };

  const calculatePiePercentage = (pieQuantity, data) => {
    const total = data.reduce((sum, object) => sum + object[dataKey], 0);

    return ((pieQuantity / total) * 100).toFixed(2);
  };

  const COLORS = [
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#FF8042",
    "#a19e9c",
    "#865838",
    "#6d6966",
    "#8a8703",
    "#ff0000",
    "#f700ff",
    "#4400ff",
    "#00ccff",
    "#064f58",
    "#212525",
    "#9CF071",
    "#960921",
    "#301D14",
    "#13CC37",
    "#D50563",
    "#E10522",
    "#0EEB05",
    "#E8F023",
    "#4C82D8",
    "#36FEA1",
    "#FBF31D",
    "#69CB66",
    "#E690B6",
    "#A6F0D7",
    "#A36B13",
    "#1D0AEF",
    "#7D5567",
    "#0D9224",
    "#82DA12",
    "#3E6AEC",
    "#06B79C",
    "#4C465E",
    "#5D04B1",
    "#BC6F7F",
    "#5D535F",
    "#25822A",
    "#D07F05",
    "#E5598D",
    "#AB6B0C",
    "#227479",
    "#339224",
    "#8FA4A4",
    "#B052E3",
    "#B573E8",
    "#51DA47",
    "#45E717",
  ];

  const styles = {
    modalCol: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "0.7rem",
      height: `${screenHeight - 300}px`,
    },
    col: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "25rem",
      marginTop: "0.7rem",
    },
    fullScreenStyle: {
      maxWidth: "100%",
      width: "100vw",
      height: "100vh",
      margin: "0",
      top: "0",
    },
    cardFooter: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      gap: "16px",
    },
  };

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        toggle={() => toggleModal()}
        style={styles.fullScreenStyle}
      >
        <ModalHeader toggle={() => toggleModal()}>
          Visualização em tela cheia
        </ModalHeader>
        <ModalBody style={{ height: `${screenHeight - 150}px` }}>
          <Card>
            <CardHeader
              dataToDownload={data}
              headerText={headerText}
              showExpandButton={true}
              showButtonsTooltips={false}
              handleExpandButtonClick={toggleModal}
            />
            <CardContent>
              <Row>
                <Col style={styles.modalCol}>
                  <section>
                    <ul>
                      {dataToRenderOnExpandedView &&
                        dataToRenderOnExpandedView.length > 0 &&
                        dataToRenderOnExpandedView.map((paramData, index) => {
                          return (
                            <li
                              key={index}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                              }}
                            >
                              <div
                                style={{
                                  width: "14px",
                                  height: "10px",
                                  backgroundColor:
                                    COLORS[index % COLORS.length],
                                }}
                              ></div>
                              <span
                                style={{ color: COLORS[index % COLORS.length] }}
                              >
                                {paramData[legendsKey]}:{" "}
                                <span style={{ fontWeight: "bold" }}>
                                  ({paramData[dataKey]}){" "}
                                  {calculatePiePercentage(
                                    paramData[dataKey],
                                    data
                                  )}
                                  %
                                </span>
                              </span>
                            </li>
                          );
                        })}
                    </ul>
                  </section>
                </Col>
                <Col style={styles.modalCol}>
                  <PieGraphic
                    colors={COLORS}
                    customTooltip={customTooltip}
                    data={dataToRenderOnExpandedView}
                    dataKey={dataKey}
                    outerRadius={200}
                  />
                </Col>
              </Row>
            </CardContent>
            <CardFooter style={styles.cardFooter}>
              <div></div>
              <img
                style={{ maxWidth: "100px", opacity: 0.5 }}
                src="/static/media/vitec.9bd71d52.png"
                alt="Logo do Vitec"
              />
            </CardFooter>
          </Card>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => toggleModal()}>
            Fechar
          </Button>
        </ModalFooter>
      </Modal>
      <Card>
        <CardHeader
          dataToDownload={data}
          headerText={headerText}
          showExpandButton={true}
          showButtonsTooltips={true}
          handleExpandButtonClick={toggleModal}
        />
        <CardContent>
          <Row>
            <Col style={styles.col}>
              <section>
                <ul>
                  {dataToRenderOnDefaultView &&
                    dataToRenderOnDefaultView.length > 0 &&
                    dataToRenderOnDefaultView.map((paramData, index) => {
                      return (
                        <li
                          key={index}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <div
                            style={{
                              width: "14px",
                              height: "10px",
                              backgroundColor: COLORS[index % COLORS.length],
                            }}
                          ></div>
                          <span
                            style={{ color: COLORS[index % COLORS.length] }}
                          >
                            {paramData[legendsKey]}:{" "}
                            <span style={{ fontWeight: "bold" }}>
                              ({paramData[dataKey]}){" "}
                              {calculatePiePercentage(paramData[dataKey], data)}
                              %
                            </span>
                          </span>
                        </li>
                      );
                    })}
                </ul>
              </section>
            </Col>
            <Col style={styles.col}>
              <PieGraphic
                colors={COLORS}
                chartElementsLimit={chartElementsLimit}
                customTooltip={customTooltip}
                data={dataToRenderOnDefaultView}
                dataKey={dataKey}
              />
            </Col>
          </Row>
        </CardContent>
        <CardFooter style={styles.cardFooter}>
          <div>
            {data &&
            data.length > 0 &&
            chartElementsLimit > 0 &&
            data.length > chartElementsLimit ? (
              <>
                <i>
                  <img
                    src={require("../../../assets/img/icons/interface/triangle-warning.png")}
                    style={{ width: "13px", height: "13px" }}
                    alt="icone"
                  />
                </i>{" "}
                <span style={{ fontSize: "12px" }}>
                  Os resultados foram limitados para não prejudicar a
                  visualização. Clique no botão "Expandir" para visualizar o
                  gráfico completo.
                </span>
              </>
            ) : null}
          </div>
          <img
            style={{ maxWidth: "100px", opacity: 0.5 }}
            src="/static/media/vitec.9bd71d52.png"
            alt="Logo do Vitec"
          />
        </CardFooter>
      </Card>
    </>
  );
};

const PieGraphic = ({
  colors,
  customTooltip,
  data,
  dataKey,
  outerRadius = 100,
}) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart width={900} height={900}>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          labelLine={false}
          outerRadius={outerRadius}
          fill="#8884d8"
          dataKey={dataKey}
        >
          {data &&
            data.length > 0 &&
            data.map((_, index) => (
              <Cell
                key={`cell-${index}`}
                fill={colors[index % colors.length]}
              />
            ))}
        </Pie>
        <Tooltip content={customTooltip} />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default PieChartGraphic;
