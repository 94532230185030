import React from "react";
import DataTable from "react-data-table-component"; 

const SincronizationPage = ({ organizations, sincronizationsProcessed, sincronizationsWithError, getTableColumns, filterInformations }) => {
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [selectedOrganization, setSelectedOrganization] = React.useState(null);

  return (
    <div>
      <div className="row linha-form my-2">
        <div className="col-3 responsive-field-form-full">
          <span style={{ fontWeight: "bold" }}>Organização</span>
          <select 
            className="form-control"
            onChange={(e) => setSelectedOrganization(e.target.value)}
            value={selectedOrganization ? selectedOrganization : null}
          >
            {
              organizations && organizations.map((organization) => (
                <option value={organization.id}>{organization.name}</option>
              ))
            }
          </select>
        </div>
        <div className="col-3 responsive-field-form-full">
          <span style={{ fontWeight: "bold" }}>Data inicial</span>
          <input onChange={(e) => {setStartDate(e.target.value)}} value={startDate} type="date" className="form-control" />
        </div>
        <div className="col-3 responsive-field-form-full">
          <span style={{ fontWeight: "bold" }}>Data Final</span>
          <input onChange={(e) => {setEndDate(e.target.value)}} value={endDate} type="date" className="form-control" />
        </div>
        <div className="col-3 d-flex align-items-end justify-content-end">
          <button className="float-right btn btn-primary" onClick={() => filterInformations(selectedOrganization, startDate, endDate)}>
            Filtrar
          </button>
        </div>
      </div>
      {/* Seção de Sincronizações Processadas */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingRight: "1rem",
        }}
      >
        <span style={{ fontWeight: "bold" }}>Sincronizações Processadas:</span>
        <i className="fa fa-arrow-down" aria-hidden="true"></i>
      </div>
      <DataTable
        noHeader
        defaultSortField="name"
        defaultSortAsc={true}
        highlightOnHover
        pagination
        paginationPerPage={5}
        paginationRowsPerPageOptions={[5, 10, 15]}
        columns={getTableColumns("Processed")}
        data={sincronizationsProcessed}
        noDataComponent={"Nenhum registro encontrado."}
      />

      {/* Seção de Sincronizações com Erro */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingRight: "1rem",
          marginTop: "1rem",
        }}
      >
        <span style={{ fontWeight: "bold" }}>Sincronizações com erro:</span>
        <i className="fa fa-arrow-down" aria-hidden="true"></i>
      </div>
      <DataTable
        noHeader
        defaultSortField="name"
        defaultSortAsc={true}
        highlightOnHover
        pagination
        paginationPerPage={5}
        paginationRowsPerPageOptions={[5, 10, 15]}
        columns={getTableColumns("Error")}
        data={sincronizationsWithError}
        noDataComponent={"Nenhum registro encontrado."}
      />
    </div>
  );
};

export default SincronizationPage;
