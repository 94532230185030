import React from "react";
import { Select } from "../Wrappers/SelectAll";
import { selectComponentStyles } from "../../services/utils/globalFunctions";
import getWeeks from "../../services/utils/epidemiologicalWeek";

const ManualEpidemiologicWeeksFilter = ({ filters, handleFiltersChange }) => {
  const [epidemiologicalWeeksOptionsList, setEpidemiologicalWeeksOptionsList] =
    React.useState([]);

  React.useEffect(() => {
    if (filters.year.value === "")
      return;
 
    const epidemiologicalWeeksFromYear = getWeeks().find(
      ({ year }) => year === filters.year.label
    );

    const epidemiologicalWeeksList = epidemiologicalWeeksFromYear.weeks.map(
      ({beginDate, endDate, label}) => ({
        beginDate,
        endDate,
        label,
        value: endDate,
      })
    );

    setEpidemiologicalWeeksOptionsList(epidemiologicalWeeksList)

  }, [filters.year]);

  return (
    <div>
      <span className="h4 text-white">Semanas (manual)</span>
      <Select
        styles={selectComponentStyles}
        placeholder={"Selecione"}
        isMulti={true}
        blurInputOnSelect={false}
        closeMenuOnSelect={false}
        isClearable={true}
        value={filters.epidemiologicalWeeks}
        options={epidemiologicalWeeksOptionsList}
        onChange={(e) =>
          handleFiltersChange(
            "epidemiologicalWeeks",
            !e || e.length === 0 ? [] : e
          )
        }
        isDisabled={filters.year.value === ""}
      />
    </div>
  );
};

export default ManualEpidemiologicWeeksFilter;
