import React from "react";

import { PositiveBarPercentageEstimatesGraphic } from "../Bar/PositiveBarPercentageEstimatesGraphic";
import { PositivityIndexLineByWeekGraphic } from "../Line/PositivityIndexLineByWeekGraphic";
import { PositivityIndexLinePercentageEstimatesByWeekGraphic } from "../Line/PositivityIndexLinePercentageEstimatesByWeekGraphic";
import { ARMADILHA_INFO } from "../../../../../constants/ArmadilhaConstant";

const useIPOGraphic = () => {
  const [graphicType, setGraphicType] = React.useState("default");

  return {
    graphicType,
    setGraphicType,
  };
};

export const IPOGraphic = ({
  estimatesList,
  lineData,
  positivityIndex,
  trapTypeName,
  verticalBarData,
}) => {
  const { graphicType, setGraphicType } = useIPOGraphic();

  if (
    !estimatesList ||
    estimatesList.length === 0 ||
    !lineData ||
    lineData.length === 0 ||
    !verticalBarData ||
    verticalBarData.length === 0
  )
    return null;

  return (
    <div>
      {graphicType === "default" && (
        <PositivityIndexLineByWeekGraphic
          data={positivityIndex}
          headerText={"Índice de Positividade de Ovos"}
          positiveFor="ovos"
          positivityIndexName="IPO"
          setGraphicType={setGraphicType}
          tooltipPositivityPropertyName="positiveForEgg"
          trapTypeName={trapTypeName}
        />
      )}
      {graphicType === "line" && (
        <PositivityIndexLinePercentageEstimatesByWeekGraphic
          data={lineData}
          headerText={`Estimativas de ${ARMADILHA_INFO[trapTypeName].apelido}'s positivas para ovos`}
          estimatesCategoriesList={estimatesList}
          setGraphicType={setGraphicType}
        />
      )}
      {graphicType === "bar" && (
        <PositiveBarPercentageEstimatesGraphic
          data={verticalBarData}
          estimatesList={estimatesList}
          positiveFor="ovos"
          setGraphicType={setGraphicType}
          trapTypeName={trapTypeName}
        />
      )}
    </div>
  );
};
