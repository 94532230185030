import React from "react";

import { Container } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import ManagementFilters from "./Filters/ManagementFilters";
import ManagementTable from "./Tables/ManagementTable";
import moment from "moment";
import {
  fetchTerritorializations,
  getAllTypeTerritorializations,
} from "../../../services/api/territorialization";
import {
  fetchActiveTrapsByPeriods,
  fetchTerritorializationReport,
  getTrapsTypes,
} from "../../../services/api/Trap";
import { getUserData } from "../../../services/utils/auth";
import { ARMADILHA_INFO } from "../../../constants/ArmadilhaConstant";
import {
  polygon as createPolygon,
  area as calculatePolygonArea,
} from "@turf/turf";
import ManagementPageGraphicsWrapper from "./ManagementPageGraphicsWrapper";
import { getTrapColorStatus } from "../../../services/utils/TrapStatus/trapStatus";
import getWeeks from "../../../services/utils/epidemiologicalWeek";
import { getTrapRange } from "../../../constants/RangeTrapConstant";

const ManagementPage = () => {
  const [progress, setProgess] = React.useState(0);
  const [managementFilters, setManagementFilters] = React.useState({
    trapType: {
      label: "Selecione",
      value: "",
    },
    beginDate: moment().subtract(30, "days").format("YYYY-MM-DD"),
    finalDate: moment().format("YYYY-MM-DD"),
    territorializations: [],
    typeLayers: [],
  });
  const [territorializationsReportData, setTerritorializationsReportData] =
    React.useState([]);
  const [
    isTerritorializationsReportDataLoading,
    setIsTerritorializationsReportDataLoading,
  ] = React.useState(false);
  const [territorializationsData, setTerritorializationsData] = React.useState(
    []
  );
  /* const [trapsConditionsHistories, setTrapsConditionsHistories] =
    React.useState([]);
  const [
    isTrapsConditionsHistoriesLoading,
    setIsTrapsConditionsHistoriesLoading,
  ] = React.useState(false); */
  const [trapTypesData, setTrapTypesData] = React.useState([]);

  React.useEffect(() => {
    fetchTerritorializationsData();
    fetchTrapTypes();
  }, []);

  const fetchAllComponentsData = async () => {
    await Promise.all([fetchTerritorializationsReportData()]);
  };

  const fetchTrapTypes = async () => {
    try {
      const { data, status } = await getTrapsTypes();

      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao buscar pelos dados de tipos de armadilhas. Verifique sua conexão com internet e tente novamente. Caso o problema persista, entre em contato com nossa equipe."
        );

      /* const formatedTrapTypesOptions = data.map(({ id, name }) => ({
        label: ARMADILHA_INFO[name].apelido,
        value: id,
      })); */

      const adiTrapInformations = data.find(
        ({ name }) => name === "armadilhaDisseminadoraInseticida"
      );

      const formatedTrapTypesOptions = [
        {
          label: ARMADILHA_INFO[adiTrapInformations.name].apelido,
          value: adiTrapInformations.id,
        },
      ];

      setTrapTypesData(formatedTrapTypesOptions);
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
        toast.error(error.message);
      }
    }
  };

  const fetchTerritorializationsData = async () => {
    try {
      const { data: typeTerritorializationsData, status } =
        await getAllTypeTerritorializations();
      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao buscar pelos dados de territorializações. Verifique sua conexão com internet e tente novamente. Caso o problema persista, entre em contato com nossa equipe."
        );

      const territorializations = [];

      for (const typeTerritorialization of typeTerritorializationsData) {
        if (typeTerritorialization.typeGeometry === "Polygon") {
          const { data } = await fetchTerritorializations(
            typeTerritorialization.id
          );

          territorializations.push({
            name: typeTerritorialization.name,
            label: typeTerritorialization.name,
            territorializations: data,
            options: data.map((territorialization) => {
              return {
                label: territorialization.name,
                value: territorialization.id,
              };
            }),
          });
        }
      }

      setTerritorializationsData(territorializations);
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
        toast.error(error.message);
      }
    }
  };

  const fetchTerritorializationsReportData = async () => {
    let counter = 0;
    let progress = 0;

    if (
      !managementFilters.finalDate ||
      managementFilters.finalDate === "" ||
      managementFilters.trapType.label === "Selecione" ||
      managementFilters.territorializations.length === 0
    ) {
      toast.error(
        "É necessário informar o Tipo de Armadilha, Data e ao menos uma Territorialização para gerar o relatório"
      );

      return;
    }

    const filtersToSend = {
      territorializationsIds: managementFilters.territorializations.map(
        ({ value }) => value
      ),
      organizationId: getUserData("organizationId"),
      periods: {
        beginDate: managementFilters.beginDate,
        finalDate: managementFilters.finalDate,
      },
      trapTypeId: managementFilters.trapType.value,
    };

    const weeksFromActualYear = getWeeks()
      .find((week) => week.year === String(moment().year()))
      .weeks.map(({ beginDate, endDate }, index) => ({
        beginDate: moment(beginDate, "DD/MM/YYYY")
          .utc()
          .set({ hour: 0, minute: 0, second: 0 })
          .toISOString(),
        finalDate: moment(endDate, "DD/MM/YYYY")
          .utc()
          .set({ hour: 23, minute: 59, second: 59 })
          .toISOString(),
      }));

    const actualDate = moment();
    const actualWeek = weeksFromActualYear.find(({ beginDate, finalDate }) =>
      actualDate.isBetween(moment(beginDate), moment(finalDate), null, "[]")
    );

    const filteredWeeks = weeksFromActualYear.filter(({ finalDate }) =>
      moment(finalDate).isBefore(actualDate)
    );

    if (actualWeek) {
      const actualWeekFormated = { ...actualWeek };

      actualWeekFormated.finalDate = actualDate
        .add(1, "day")
        .utc()
        .set({ hour: 23, minute: 59, second: 59 })
        .toISOString();

      filteredWeeks.push(actualWeekFormated);
    }

    const trapConditionHistoriesFiltersToSend = {
      organizationId: getUserData("organizationId"),
      showRemovedTraps: false,
      usersIds: [],
      demandId: "00000000-0000-0000-0000-000000000000",
      territorializations: managementFilters.territorializations.map(
        ({ value }) => value
      ),
      trapTypeId: managementFilters.trapType.value,
      typeLayerId: "00000000-0000-0000-0000-000000000000",
      typeLayerSubtypeId: "00000000-0000-0000-0000-000000000000",
      typeLayerSubtypesIds: [],
      filterForReadTraps: true,
      webTraps: true,
      periods: filteredWeeks,
      projectsId: [],
    };

    try {
      setIsTerritorializationsReportDataLoading(true);

      const totalTerritorializations =
        filtersToSend.territorializationsIds.length;

      const reportResults = [];

      for (let id of filtersToSend.territorializationsIds) {
        const requestFilters = {
          ...filtersToSend,
          territorializationsIds: [id],
        };

        const requestActiveTrapsByPeriodsFilters = {
          ...trapConditionHistoriesFiltersToSend,
          territorializations: [id],
        };

        const adiTrapRange = getTrapRange("armadilhaDisseminadoraInseticida");

        const organizationId = getUserData("organizationId");

        const { data } = await fetchTerritorializationReport(requestFilters);

        let activeTrapByPeriodsData = [];

        if (organizationId !== "559a16ab-5da8-41ba-96b8-6e9f55feddd8") {
          const { data: activeTrapByPeriodsDataResponse } =
            await fetchActiveTrapsByPeriods(requestActiveTrapsByPeriodsFilters);

          activeTrapByPeriodsData = activeTrapByPeriodsDataResponse;
        }

        const trapsSituationsByPeriods = activeTrapByPeriodsData.map(
          ({ periods, traps }, index) => {
            const period = { ...periods, label: `Semana ${index + 1}` };

            return traps.reduce(
              (acc, trap) => {
                if (trap.daysSinceLastActivity <= adiTrapRange.data.middle)
                  acc.green += 1;
                else if (trap.daysSinceLastActivity <= adiTrapRange.data.end)
                  acc.yellow += 1;
                else acc.red += 1;

                return acc;
              },
              { period, green: 0, yellow: 0, red: 0 }
            );
          }
        );

        data.territorializationsReports.forEach((report) => {
          const reportPolygon = createPolygon([
            report.polygon.coordinates.map(({ x, y }) => [x, y]),
          ]);

          const reportPolygonArea = +(
            calculatePolygonArea(reportPolygon) / 1000000
          ).toFixed(2);

          let greenSituationTrapsNumber = 0;
          let yellowSituationTrapsNumber = 0;
          let redSituationTrapsNumber = 0;

          report.traps.forEach(
            ({
              trapTypeName,
              lastInstallHistoryStatusDate,
              lastReadHistoryStatusDate,
            }) => {
              const trapColorStatus = getTrapColorStatus(
                trapTypeName,
                lastInstallHistoryStatusDate,
                lastReadHistoryStatusDate
              );

              if (trapColorStatus === "green") greenSituationTrapsNumber++;
              if (trapColorStatus === "yellow") yellowSituationTrapsNumber++;
              if (trapColorStatus === "red") redSituationTrapsNumber++;
            }
          );

          const trapsNotProtectingAreasPercentage =
            (redSituationTrapsNumber / report.traps.length) * 100;

          const trapsOnFirstPeriodPercentage =
            (greenSituationTrapsNumber / report.traps.length) * 100;

          const trapsOnSecondPeriodPercentage =
            (yellowSituationTrapsNumber / report.traps.length) * 100;

          const trapsOnThirdPeriodPercentage =
            (redSituationTrapsNumber / report.traps.length) * 100;

          const trapsProtectingAreasPercentage =
            ((greenSituationTrapsNumber + yellowSituationTrapsNumber) /
              report.traps.length) *
            100;

          const result = {
            activeTraps: report.traps.length,
            squareKilometre: reportPolygonArea,
            territorializationId: report.territorializationId,
            territorializationName: report.territorializationName,
            trapsDensityBySquareKilometre: +(
              report.traps.length / reportPolygonArea
            ).toFixed(2),
            trapsNotProtectingAreas: redSituationTrapsNumber,
            trapsNotProtectingAreasPercentage:
              !trapsNotProtectingAreasPercentage
                ? "0.00"
                : +trapsNotProtectingAreasPercentage.toFixed(2),
            trapsOnFirstPeriod: greenSituationTrapsNumber,
            trapsOnFirstPeriodPercentage: !trapsOnFirstPeriodPercentage
              ? "0.00"
              : +trapsOnFirstPeriodPercentage.toFixed(2),
            trapsOnSecondPeriod: yellowSituationTrapsNumber,
            trapsOnSecondPeriodPercentage: !trapsOnSecondPeriodPercentage
              ? "0.00"
              : +trapsOnSecondPeriodPercentage.toFixed(2),
            trapsOnThirdPeriod: redSituationTrapsNumber,
            trapsOnThirdPeriodPercentage: !trapsOnThirdPeriodPercentage
              ? "0.00"
              : +trapsOnThirdPeriodPercentage.toFixed(2),
            trapsProtectingAreas:
              greenSituationTrapsNumber + yellowSituationTrapsNumber,
            trapsProtectingAreasPercentage: !trapsProtectingAreasPercentage
              ? "0.00"
              : +trapsProtectingAreasPercentage.toFixed(2),
            trapsSituationsByPeriods,
          };

          reportResults.push(result);
        });

        counter++;
        progress = (counter / totalTerritorializations) * 100;

        setProgess(progress);
      }
      setTerritorializationsReportData(reportResults);
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
        toast.error(error.message);
      }
    } finally {
      setIsTerritorializationsReportDataLoading(false);
      setProgess(0);
    }
  };

  const handleFiltersChange = (filterName, newValue) => {
    setManagementFilters((previousValues) => {
      const newFiltersValues = { ...previousValues };

      newFiltersValues[filterName] = newValue;

      return newFiltersValues;
    });
  };

  const beginDateFormated =
    managementFilters &&
    managementFilters.beginDate &&
    moment(managementFilters.beginDate).format("DD/MM/YYYY");
  const finalDateFormated =
    managementFilters &&
    managementFilters.finalDate &&
    moment(managementFilters.finalDate).format("DD/MM/YYYY");

  return (
    <main>
      <ToastContainer />
      <Container fluid className="bg-gradient-info pt-5 pb-2 mb-5">
        <div style={{ paddingTop: "10px !important" }}>&nbsp;</div>
        <ManagementFilters
          fetchData={fetchAllComponentsData}
          filtersValues={managementFilters}
          handleFiltersChange={handleFiltersChange}
          territorializations={territorializationsData}
          trapTypes={trapTypesData}
          isTerritorializationsReportDataLoading={
            isTerritorializationsReportDataLoading
          }
          loadingProgress={progress}
        />
      </Container>
      <Container fluid className="pb-2 mt-2">
        <ManagementTable
          headerText={`Situações por Áreas de Intervenção (${managementFilters.trapType.label}) em ${beginDateFormated} a ${finalDateFormated}`}
          data={territorializationsReportData}
          filtersValues={managementFilters}
        />
        <ManagementPageGraphicsWrapper
          data={territorializationsReportData}
          filtersValues={managementFilters}
        />
      </Container>
    </main>
  );
};

export default ManagementPage;
