import React from "react";

import TrapsPage from "./TrapsPage";
import { TrapsPageContextProvider } from "./Contexts/trapsPageContext";
import useEmployeesList from "../../../hooks/useEmployeesList";
import useTeamsList from "../../../hooks/useTeamsList";
import useTerritorializationsList from "../../../hooks/useTerritorializationsList";
import useDemandsList from "../../../hooks/useDemandsList";
import useTrapTypesList from "../../../hooks/useTrapTypesList";
import { fetchDemandsData, fetchEmployeesAndTeamsData, fetchTerritorializationsData, fetchTrapTypesData } from "../../../services/utils/globalFunctions";
import useTypeLayersList from "../../../hooks/useTypeLayersList";

const useTrapsPageWrapper = () => {
  const { demandsList, setDemandsList } = useDemandsList();
  const { employeesList, setEmployeesList } = useEmployeesList();
  const { teamsList, setTeamsList } = useTeamsList();
  const { territorializationsList, setTerritorializationsList } = useTerritorializationsList();
  const { trapTypesList, setTrapTypesList } = useTrapTypesList();
  const { typeLayersList, setTypeLayersList } = useTypeLayersList();

  React.useEffect(() => {
    if(demandsList.length === 0)
      fetchDemandsData(setDemandsList);

    if(territorializationsList.length === 0 || typeLayersList.length === 0)
      fetchTerritorializationsData(setTerritorializationsList, setTypeLayersList);

    if(trapTypesList.length === 0)
      fetchTrapTypesData(setTrapTypesList);

    if(teamsList.length === 0 || employeesList.length === 0)
      fetchEmployeesAndTeamsData(setEmployeesList, setTeamsList);
  }, []);

  return {};
};

const TrapsPageWrapper = () => {
  useTrapsPageWrapper();

  return (
    <TrapsPageContextProvider>
      <TrapsPage />
    </TrapsPageContextProvider>
  );
};

export default TrapsPageWrapper;
