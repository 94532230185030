import React from 'react'
import { Select } from '../Wrappers/SelectAll'
import { selectComponentStyles } from '../../services/utils/globalFunctions'

const TypeLayersFilter = ({ selectedTypeLayers, handleTypeLayerChange, typeLayersList }) => {
  return (
    <div>
      <span className="h4 text-white">Delimitador de Área</span>
          <Select
            styles={selectComponentStyles}
            placeholder={"Selecione"}
            isClearable={true}
            options={typeLayersList}
            value={selectedTypeLayers}
            isMulti={true}
            blurInputOnSelect={false}
            closeMenuOnSelect={false}
            onChange={(e) => handleTypeLayerChange(e)}
            menuPortalTarget={document.body}
            isDisabled={typeLayersList.length === 0}
          />
    </div>
  )
}

export default TypeLayersFilter