import React from "react";

import { getUserData } from "../services/utils/auth";

const useUserPreferences = () => {
  const [preferences, setPreferences] = React.useState(null);

  const addPreferences = (pageName, preferences) => {
    if (!pageName || pageName === "" || !preferences) return false;

    const organizationId = getUserData("organizationId");

    try {
      const allUserPreferences = localStorage.getItem("preferences");

      if (!allUserPreferences) {
        const preferencesToAdd = {
          [organizationId]: {
            [pageName]: preferences,
          },
        };

        setPreferencesOnLocalStorage(preferencesToAdd);

        return preferencesToAdd;
      }

      const parsedAllUserPreferences = JSON.parse(allUserPreferences);

      const newUserPreferences = {
        ...parsedAllUserPreferences,
        [organizationId]: {
          ...parsedAllUserPreferences[organizationId],
          [pageName]: {
            ...parsedAllUserPreferences[organizationId][pageName],
            ...preferences,
          },
        },
      };

      setPreferencesOnLocalStorage(newUserPreferences);
      setPreferences(newUserPreferences);

      return newUserPreferences[organizationId][pageName];
    } catch (error) {
      if (error instanceof Error) {
        console.error(
          `Ocorreu um erro ao setar as preferências do usuário: ${error.message}`
        );
        console.error(error);
      }

      return false;
    }
  };

  const setPreferencesOnLocalStorage = (preferences) => {
    localStorage.setItem("preferences", JSON.stringify(preferences));
  };

  React.useEffect(() => {
    const organizationId = getUserData("organizationId");
    const allUserPreferences = localStorage.getItem("preferences")
      ? JSON.parse(localStorage.getItem("preferences"))
      : null;

    if (!allUserPreferences) {
      const emptyPreferences = {
        [organizationId]: {},
      };

      setPreferences(emptyPreferences[organizationId]);
      setPreferencesOnLocalStorage(emptyPreferences);

      return;
    }

    if (!allUserPreferences[organizationId]) {
      const newUserPreferences = {
        ...allUserPreferences,
        [organizationId]: {},
      };

      setPreferences(newUserPreferences[organizationId]);
      setPreferencesOnLocalStorage(newUserPreferences);

      return;
    }

    setPreferences(allUserPreferences[organizationId]);
  }, []);

  return { addPreferences, preferences };
};

export default useUserPreferences;
