import React from "react";
import { createContext } from "use-context-selector";
import useAppliedFilters from "../Hooks/useAppliedFilters";

const PositivityIndexMapContext = createContext(null);

const PositivityIndexMapContextProvider = ({ children }) => {
  const [
    positivityIndexActualWeekArrayIndex,
    setPositivityIndexActualWeekArrayIndex,
  ] = React.useState(0);
  const [positivityIndexGroupPointsList, setPositivityIndexGroupPointsList] =
    React.useState([]);
  const { appliedFilters } = useAppliedFilters();

  const handlePositivityWeekArrayIndexChange = React.useCallback(() => {
    setPositivityIndexActualWeekArrayIndex((previousValue) => {
      if (
        previousValue + 1 === appliedFilters.epidemiologicalWeeks.length ||
        previousValue + 1 > appliedFilters.epidemiologicalWeeks.length
      )
        return 0;

      return previousValue + 1;
    });
  }, [appliedFilters]);

  return (
    <PositivityIndexMapContext.Provider
      value={{
        handlePositivityWeekArrayIndexChange,
        positivityIndexActualWeekArrayIndex,
        positivityIndexGroupPointsList,
        setPositivityIndexActualWeekArrayIndex,
        setPositivityIndexGroupPointsList,
      }}
    >
      {children}
    </PositivityIndexMapContext.Provider>
  );
};

export { PositivityIndexMapContext, PositivityIndexMapContextProvider };
