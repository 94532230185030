import React from "react";
import { Button, Col, Container, Row } from "reactstrap";
import SamplesExportTable from "./SamplesExportTable";
import TableLoadingSkeleton from "../../../../components/ui/Loading/TableLoadingSkeleton";
import { getUserData } from "../../../../services/utils/auth";
import moment from "moment";
import {
  fetchSamples,
  fetchSamplesAnalysis,
} from "../../../../services/api/Sample";
import { toast } from "react-toastify";
import { getUserEmployeeDataByOrganization } from "../../../../services/api/Users";
import { fetchDemands } from "../../../../services/api/Demand";
import { getAllLaboratories } from "../../../../services/api/Laboratory";
import {
  getDiagnosticsExam,
  getIndentificationsExam,
} from "../../../../services/api/Exam";
import { fetchIdentifications } from "../../../../services/api/Identification";
import { getTechniques } from "../../../../services/api/Technique";
import { getConservationTypes } from "../../../../services/api/ConservationType";
import { getJustificationDisposalTypes } from "../../../../services/api/JustificationDispositionType";
import {
  fetchAllAnimalGenres,
  fetchAllAnimalSpecies,
  fetchAnimalGenres,
  fetchAnimalTypeGroups,
} from "../../../../services/api/AnimalGroups";
import { getActiveTraps } from "../../../../services/api/Trap";
import { ARMADILHA_INFO } from "../../../../constants/ArmadilhaConstant";
import {
  fetchTerritorializations,
  getAllTypeTerritorializations,
} from "../../../../services/api/territorialization";
import { Select } from "../../../../components/Wrappers/SelectAll";
import { getProjects } from "../../../../services/api/project";

const SamplesExport = () => {
  const [samplesData, setSamplesData] = React.useState(null);
  const [samplesAnalysisData, setSamplesAnalysisData] = React.useState(null);
  const [samplesIds, setSamplesIds] = React.useState([]);
  const [samplesToExport, setSamplesToExport] = React.useState(null);
  const [employeesData, setEmployeesData] = React.useState(null);
  const [demandsData, setDemandsData] = React.useState(null);
  const [filteredSamplesData, setFilteredSamplesData] = React.useState(null);
  const [isSamplesDataLoading, setIsSamplesDataLoading] = React.useState(false);
  const [isSamplesAnalysisDataLoading, setIsSamplesAnalysisDataLoading] =
    React.useState(false);
  const [samplesRelatedData, setSamplesRelatedData] = React.useState(null);
  const [filters, setFilters] = React.useState({
    territorializations: [],
    projects: [],
  });
  const [territorializationsList, setTerritorializationsList] = React.useState(
    []
  );
  const [projectsList, setProjectsList] = React.useState([]);

  React.useEffect(() => {
    fetchEmployeesData();
    fetchDemandsData();
    fetchTerritorializationsData();
    fetchAllSamplesRelatedData();
    fetchProjectsData();
  }, []);

  React.useEffect(() => {
    if (
      !samplesData ||
      !demandsData ||
      !employeesData ||
      !samplesRelatedData ||
      filteredSamplesData
    )
      return;

    const samplesIds = [];

    const filteredSamples = samplesData.map((sample) => {
      samplesIds.push(sample.id);

      const conservationMethod =
        sample.conservationTypeId === "00000000-0000-0000-0000-000000000000" ||
        !sample.conservationTypeId
          ? "Não informado"
          : samplesRelatedData.conservations.find(
              (conservation) => conservation.id === sample.conservationTypeId
            ).name;

      const animalGroup =
        sample.typeAnimalGroupIdentified ===
          "00000000-0000-0000-0000-000000000000" ||
        !sample.typeAnimalGroupIdentified
          ? "Não informado"
          : samplesRelatedData.animalGroups.find(
              (animalGroup) =>
                animalGroup.id === sample.typeAnimalGroupIdentified
            ).name;

      const animalGenre =
        sample.genderIdentified === "00000000-0000-0000-0000-000000000000" ||
        !sample.genderIdentified
          ? "Não informado"
          : samplesRelatedData.animalGenres.find(
              (animalGroup) => animalGroup.id === sample.genderIdentified
            ).name;

      const animalSpecie =
        sample.speciesIdentified === "00000000-0000-0000-0000-000000000000" ||
        !sample.speciesIdentified
          ? "Não informado"
          : samplesRelatedData.animalSpecies.find(
              (animalGroup) => animalGroup.id === sample.speciesIdentified
            ).name;

      return {
        ID_SAMPLE: sample.id,
        ID_VISITA: sample.visitId,
        NUMERO_ARMADILHA: !sample.trap ? "Não encontrado" : sample.trap.number,
        LATITUDE: !sample.trap ? "Não encontrado" : sample.trap.latitude,
        LONGITUDE: !sample.trap ? "Não encontrado" : sample.trap.longitude,
        TIPO_ARMADILHA: !sample.trap
          ? "Não encontrado"
          : ARMADILHA_INFO[sample.trap.trapType.name]
          ? ARMADILHA_INFO[sample.trap.trapType.name].apelido
          : "Tipo não encontrado",
        REGIAO1: !sample.trap || !sample.trap.insideTerritorialization || sample.trap.insideTerritorialization === "" ? "Não encontrado" : sample.trap.insideTerritorialization,
        REGIAO2: !sample.trap || !sample.trap.insideTerritorialization2 || sample.trap.insideTerritorialization2 === "" ? "Não encontrado" : sample.trap.insideTerritorialization2,
        ENDERECO_ARMADILHA: !sample.trap
          ? "Não encontrado"
          : `${sample.trap.territorialization.subpremisse}, ${sample.trap.territorialization.number}`,
        DATA_COLETA: formatDateToSaoPauloTimezone(sample.dateCollected),
        NOME_COLETOR: getEmployeeName(sample.collectedBy),
        NUMERO_DEMADA: demandsData.find(
          (demand) => demand.id === sample.demandId
        ).serviceOrder,
        NUMERO_AMOSTRA:
          sample.number === null || sample.number === ""
            ? "Não informado"
            : sample.number,
        TIPO_MATERIAL: sample.typeMaterial,
        SITUACAO_AMOSTRA:
          sample.status === "Created"
            ? "Criada"
            : sample.status === "Analyzed"
            ? "Analisada"
            : sample.status,
        QTD_TOTAL_MATERIAL_CAMPO:
          sample.quantity === 0 || !sample.quantity
            ? "Não informado"
            : sample.quantity,
        QTD_MATERIAL_VIVO_CAMPO:
          sample.quantityLiveMaterial === 0 || !sample.quantityLiveMaterial
            ? "Não informado"
            : sample.quantityLiveMaterial,
        QTD_MATERIAL_MORTO_CAMPO:
          sample.quantityDeadMaterial === 0 || !sample.quantityDeadMaterial
            ? "Não informado"
            : sample.quantityDeadMaterial,
        TIPO_CONSERVACAO: conservationMethod,
        GRUPO_ANIMAIS: animalGroup,
        ANALISE_CLASSIFICACAO_GENERO: animalGenre,
        ANALISE_CLASSIFICACAO_ESPECIE: animalSpecie,
      };
    });

    setSamplesIds(samplesIds);

    setFilteredSamplesData(filteredSamples);
  }, [samplesData, demandsData, employeesData, samplesRelatedData]);

  React.useEffect(() => {
    if (!samplesIds || samplesIds.length === 0 || samplesAnalysisData) return;

    const dividedGuids = divideArray(samplesIds, 250);

    fetchSamplesAnalysisData(dividedGuids);
  }, [samplesIds]);

  React.useEffect(() => {
    if (!samplesAnalysisData || !filteredSamplesData) return;

    const samplesToExport = filteredSamplesData.map((sample) => {
      const sampleAnalysis = samplesAnalysisData.find(
        (sampleAnalysis) => sampleAnalysis.sampleId === sample.ID_SAMPLE
      );
      const sampleAnalysisInfos =
        !sampleAnalysis || sampleAnalysis.analysis.length === 0
          ? "Não informado"
          : sampleAnalysis.analysis[0];

      if (typeof sampleAnalysisInfos === "string")
        return {
          ...sample,
          ANALISE_IDENTIFICACAO_FASE_LABORATORIO: "Não informado",
          ANALISE_IDENTIFICACAO_SEXO_LABORATORIO: "Não informado",
          ANALISE_IDENTIFICACAO_QTD_VIVOS_LABORATORIO: "Não informado",
          ANALISE_IDENTIFICACAO_QTD_MORTOS_LABORATORIO: "Não informado",
          ANALISE_IDENTIFICACAO_QTD_TOTAL_LABORATORIO: "Não informado",
        };

      return {
        ...sample,
        ANALISE_IDENTIFICACAO_FASE_LABORATORIO: sampleAnalysisInfos.stage,
        ANALISE_IDENTIFICACAO_SEXO_LABORATORIO: sampleAnalysisInfos.gender,
        ANALISE_IDENTIFICACAO_QTD_VIVOS_LABORATORIO:
          sampleAnalysisInfos.quantityAlive,
        ANALISE_IDENTIFICACAO_QTD_MORTOS_LABORATORIO:
          sampleAnalysisInfos.quantityDead,
        ANALISE_IDENTIFICACAO_QTD_TOTAL_LABORATORIO:
          sampleAnalysisInfos.quantity,
      };
    });

    setSamplesToExport(samplesToExport);
  }, [samplesAnalysisData, filteredSamplesData]);

  const handleFiltersChange = (filterName, newValue) => {
    const newFiltersValues = { ...filters };

    newFiltersValues[filterName] = newValue;

    setFilters(newFiltersValues);
  };

  const fetchAllSamplesRelatedData = async () => {
    const responses = await Promise.all([
      fetchLaboratoriesData(),
      fetchExamsData(),
      fetchDiagnosticsExamsData(),
      fetchIdentificationsData(),
      fetchTechniquesData(),
      fetchConservationTypesData(),
      fetchJustificationDisposalTypesData(),
      fetchAnimalTypeGroupsData(),
      fetchAnimalSpeciesData(),
      fetchAnimalGenresData(),
    ]);

    setSamplesRelatedData({
      laboratories: responses[0],
      exams: responses[1],
      diagnosticsExams: responses[2],
      identifications: responses[3],
      techniques: responses[4],
      conservations: responses[5],
      justifications: responses[6],
      animalGroups: responses[7],
      animalSpecies: responses[8],
      animalGenres: responses[9],
    });
  };

  const fetchAllComponentsData = async () => {
    await Promise.all([fetchSamplesData()]);
  };

  const fetchProjectsData = async () => {
    try {
      const { data, status } = await getProjects();
      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao buscar pelos dados dos projetos. Verifique sua conexão com a internet e tente novamente. Caso o erro persista, entre em contato com a nossa equipe"
        );

      const formatedProjectsList = data.map(({ id, name }) => ({
        label: name,
        value: id,
      }));

      setProjectsList(formatedProjectsList);
    } catch (error) {
      console.error(error);
      toast.error(error);
    }
  };

  const fetchSamplesData = async () => {
    setIsSamplesDataLoading(true);

    const filterRequest = {
      organizationId: getUserData("organizationId"),
      endDate: moment().format("YYYY-MM-DD"),
      startDate: moment().subtract(3, "months").format("YYYY-MM-DD"),
      employeeId: "",
      userId: "00000000-0000-0000-0000-000000000000",
      sampleNumber: "",
      status: "all",
      demandId: "00000000-0000-0000-0000-000000000000",
      programId: "00000000-0000-0000-0000-000000000000",
      territorializationsIds: filters.territorializations.map(
        ({ value }) => value
      ),
      projectsIds: filters.projects.map(({ value }) => value),
    };

    try {
      const { data, status } = await fetchSamples(filterRequest);
      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao buscar pelos dados das AMOSTRAS. Verifique sua conexão com a internet e tente novamente. Caso o erro persista, entre em contato com a nossa equipe"
        );

      setSamplesData(data);
    } catch (error) {
      console.error(error);
      toast.error(error);
    } finally {
      setIsSamplesDataLoading(false);
    }
  };

  const fetchSamplesAnalysisData = async (samplesIds) => {
    setIsSamplesAnalysisDataLoading(true);

    try {
      const promisses = samplesIds.map((samples) => {
        const filters = {
          samplesIds: samples,
        };

        return fetchSamplesAnalysis(filters);
      });

      const responses = await Promise.all(promisses);

      const samplesAnalysis = [];

      responses.forEach((response) => samplesAnalysis.push(...response.data));

      setSamplesAnalysisData(samplesAnalysis);
    } catch (error) {
      console.error(error);
      toast.error(
        "Ocorreu um erro ao buscar pelos dados das ANÁLISES DAS AMOSTRAS. Verifique sua conexão com a internet e tente novamente. Caso o erro persista, entre em contato com a nossa equipe"
      );
    } finally {
      setIsSamplesAnalysisDataLoading(false);
    }
  };

  const fetchEmployeesData = async () => {
    try {
      const { data, status } = await getUserEmployeeDataByOrganization();
      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao buscar pelos dados dos FUNCIONÁRIOS. Verifique sua conexão com a internet e tente novamente. Caso o erro persista, entre em contato com a nossa equipe"
        );

      setEmployeesData(data);
    } catch (error) {
      console.error(error);
      toast.error(error);
    }
  };

  const fetchDemandsData = async () => {
    try {
      const { data, status } = await fetchDemands();
      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao buscar pelos dados das demandas. Verifique sua conexão com a internet e tente novamente. Caso o erro persista, entre em contato com a nossa equipe"
        );

      setDemandsData(data);
    } catch (error) {
      console.error(error);
      toast.error(error);
    }
  };

  const fetchLaboratoriesData = async () => {
    try {
      const { data, status } = await getAllLaboratories();
      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao buscar pelos dados dos laboratórios. Verifique sua conexão com a internet e tente novamente. Caso o erro persista, entre em contato com a nossa equipe"
        );

      return data;
    } catch (error) {
      console.error(error);
      toast.error(error);
    }
  };

  const fetchExamsData = async () => {
    try {
      const { data, status } = await getIndentificationsExam();
      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao buscar pelos dados dos exames. Verifique sua conexão com a internet e tente novamente. Caso o erro persista, entre em contato com a nossa equipe"
        );

      return data;
    } catch (error) {
      console.error(error);
      toast.error(error);
    }
  };

  const fetchAnimalSpeciesData = async () => {
    try {
      const { data, status } = await fetchAllAnimalSpecies();
      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao buscar pelos dados dos gêneros. Verifique sua conexão com a internet e tente novamente. Caso o erro persista, entre em contato com a nossa equipe"
        );

      return data;
    } catch (error) {
      console.error(error);
      toast.error(error);
    }
  };

  const fetchAnimalGenresData = async () => {
    try {
      const { data, status } = await fetchAllAnimalGenres();
      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao buscar pelos dados dos gêneros. Verifique sua conexão com a internet e tente novamente. Caso o erro persista, entre em contato com a nossa equipe"
        );

      return data;
    } catch (error) {
      console.error(error);
      toast.error(error);
    }
  };

  const fetchDiagnosticsExamsData = async () => {
    try {
      const { data, status } = await getDiagnosticsExam();
      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao buscar pelos dados dos diagnósticos de exames. Verifique sua conexão com a internet e tente novamente. Caso o erro persista, entre em contato com a nossa equipe"
        );

      return data;
    } catch (error) {
      console.error(error);
      toast.error(error);
    }
  };

  const fetchIdentificationsData = async () => {
    try {
      const { data, status } = await fetchIdentifications();
      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao buscar pelos dados das identificações. Verifique sua conexão com a internet e tente novamente. Caso o erro persista, entre em contato com a nossa equipe"
        );

      return data;
    } catch (error) {
      console.error(error);
      toast.error(error);
    }
  };

  const fetchTechniquesData = async () => {
    try {
      const { data, status } = await getTechniques();
      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao buscar pelos dados das identificações. Verifique sua conexão com a internet e tente novamente. Caso o erro persista, entre em contato com a nossa equipe"
        );

      return data;
    } catch (error) {
      console.error(error);
      toast.error(error);
    }
  };

  const fetchConservationTypesData = async () => {
    try {
      const { data, status } = await getConservationTypes();
      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao buscar pelos dados das conversações. Verifique sua conexão com a internet e tente novamente. Caso o erro persista, entre em contato com a nossa equipe"
        );

      return data;
    } catch (error) {
      console.error(error);
      toast.error(error);
    }
  };

  const fetchJustificationDisposalTypesData = async () => {
    try {
      const { data, status } = await getJustificationDisposalTypes();
      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao buscar pelos dados das conversações. Verifique sua conexão com a internet e tente novamente. Caso o erro persista, entre em contato com a nossa equipe"
        );

      return data;
    } catch (error) {
      console.error(error);
      toast.error(error);
    }
  };

  const fetchAnimalTypeGroupsData = async () => {
    try {
      const { data, status } = await fetchAnimalTypeGroups();
      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao buscar pelos dados das conversações. Verifique sua conexão com a internet e tente novamente. Caso o erro persista, entre em contato com a nossa equipe"
        );

      return data;
    } catch (error) {
      console.error(error);
      toast.error(error);
    }
  };

  const getEmployeeName = (id) => {
    if (!id || !employeesData) return;

    const responsableByUserIdData = employeesData.find(
      (employee) => employee.userId === id
    );
    if (responsableByUserIdData) return responsableByUserIdData.employeeName;

    const responsableByEmployeeIdData = employeesData.find(
      (employee) => employee.employeeId === id
    );
    if (responsableByEmployeeIdData)
      return responsableByEmployeeIdData.employeeName;

    return "Funcionário não encontrado";
  };

  const formatDateToSaoPauloTimezone = (dateToFormat, displayHours) => {
    if (!dateToFormat) return;

    let dateFormatOptions;

    if (displayHours)
      dateFormatOptions = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
        timeZone: "America/Sao_Paulo",
      };
    else
      dateFormatOptions = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour12: false,
        timeZone: "America/Sao_Paulo",
      };

    const date = new Date(dateToFormat);
    const dateFormated = date.toLocaleString("pt-BR", dateFormatOptions);

    return dateFormated;
  };

  const divideArray = (array, chunkSize) => {
    const dividedArrays = [];

    for (let i = 0; i < array.length; i += chunkSize) {
      dividedArrays.push(array.slice(i, i + chunkSize));
    }

    return dividedArrays;
  };

  const fetchTerritorializationsData = async () => {
    try {
      const { data: typeTerritorializationsData, status } =
        await getAllTypeTerritorializations();
      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao buscar pelos dados de territorializações. Verifique sua conexão com internet e tente novamente. Caso o problema persista, entre em contato com nossa equipe."
        );

      const territorializations = [];

      for (const typeTerritorialization of typeTerritorializationsData) {
        if (typeTerritorialization.typeGeometry === "Polygon") {
          const { data } = await fetchTerritorializations(
            typeTerritorialization.id
          );

          territorializations.push({
            name: typeTerritorialization.name,
            label: typeTerritorialization.name,
            territorializations: data,
            options: data.map((territorialization) => {
              return {
                label: territorialization.name,
                value: territorialization.id,
              };
            }),
          });
        }
      }

      setTerritorializationsList(territorializations);
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
        toast.error(error.message);
      }
    }
  };

  const selectStyles = {
    control: (styles) => ({
      ...styles,
      minHeight: "2.90rem",
      borderRadius: "0.370rem",
    }),
    option: (styles) => ({ ...styles, fontSize: "0.875rem" }),
    valueContainer: (styles) => ({
      ...styles,
      fontSize: "0.875rem",
      color: "#8898aa",
    }),
    singleValue: (styles) => ({
      ...styles,
      fontSize: "0.875rem",
      color: "#8898aa",
    }),
    placeholder: (styles) => ({
      ...styles,
      fontSize: "0.875rem",
      color: "#8898aa",
    }),
  };

  return (
    <>
      <Container fluid style={{ marginTop: "2rem" }}>
        <Row className="mb-5">
          <Col className="col-md-4 visit-responsive-filter">
            <span className="h4 text-white">Territorialização</span>
            <Select
              styles={selectStyles}
              placeholder={"Selecione"}
              isClearable={true}
              options={territorializationsList}
              value={filters.territorializations}
              isMulti={true}
              blurInputOnSelect={false}
              closeMenuOnSelect={false}
              onChange={(e) =>
                handleFiltersChange(
                  "territorializations",
                  !e || e.length === 0 ? [] : e
                )
              }
              menuPortalTarget={document.body}
            />
          </Col>
          <Col className="col-md-4 visit-responsive-filter">
            <span className="h4 text-white">Projeto da Armadilha</span>
            <Select
              styles={selectStyles}
              placeholder={"Selecione"}
              isClearable={true}
              options={projectsList}
              value={filters.projects}
              isMulti={true}
              blurInputOnSelect={false}
              closeMenuOnSelect={false}
              onChange={(e) =>
                handleFiltersChange("projects", !e || e.length === 0 ? [] : e)
              }
              menuPortalTarget={document.body}
            />
          </Col>
          <Col className="col-md-4 visit-responsive-filter"></Col>
        </Row>
        <Row className="mb-5">
          <Col className="col-md-4 visit-responsive-filter"></Col>
          <Col className="col-md-4 visit-responsive-filter">
            <Button color="primary" onClick={() => fetchAllComponentsData()}>
              Filtrar
            </Button>
          </Col>
          <Col className="col-md-4 visit-responsive-filter"></Col>
        </Row>

        {isSamplesDataLoading || !filteredSamplesData
          ? null
          : filteredSamplesData && (
              <SamplesExportTable
                samplesToTable={filteredSamplesData}
                samplesToExport={samplesToExport}
                isSamplesAnalysisDataLoading={isSamplesAnalysisDataLoading}
              />
            )}
      </Container>
    </>
  );
};

export default SamplesExport;
