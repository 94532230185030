import React from "react";
import { Card, CardBody, CardHeader, Collapse } from "reactstrap";

const Accordion = ({ children, ...props }) => {
  return <Card {...props}>{children}</Card>;
};

const AccordionItem = ({ children, ...props }) => {
  return <CardBody {...props}>{children}</CardBody>;
};

const AccordionHeader = ({ children, isOpen, ...props }) => {
  return (
    <CardHeader
      {...props}
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "1rem 1.5rem",
        cursor: "pointer"
      }}
    >
      {children}
      {isOpen ? (
        <i className="fa fa-angle-up"></i>
      ) : (
        <i className="fa fa-angle-down"></i>
      )}
    </CardHeader>
  );
};

const AccordionContent = ({ children, ...props }) => {
  return <Collapse {...props}>{children}</Collapse>;
};

export { Accordion, AccordionItem, AccordionHeader, AccordionContent };
