import { getUserData } from "../services/utils/auth";
import { ARMADILHA_INFO } from "./ArmadilhaConstant";

const defaultRangesByTrapTypeName = {
  //ADI
  armadilhaDisseminadoraInseticida: {
    beggining: 0,
    middle: 30,
    end: 60,
  },
  //Adultrap
  armadilhaMosquitoAdulto: {
    beggining: 0,
    end: 5,
  },
  //BG-GAT
  armadilhaMosquitoAdulto2: {
    beggining: 0,
    end: 5,
  },
  //Estação Disseminadora
  estacaoDisseminadoraLarvicida: {
    beggining: 0,
    middle: 15,
    end: 30,
  },
  //Larvitrampa
  armadilhaLarvas: {
    beggining: 0,
    end: 5,
  },
  //Ovitrampa
  armadilhaOvos: {
    beggining: 0,
    end: 5,
  },
};

const rangesByOrganization = {
  //Foz do Iguaçu
  "8e34767e-3673-4606-9e47-dd67edd2677d": {
    //Adultrap
    armadilhaMosquitoAdulto: {
      beggining: 0,
      end: 7,
    },
    //BG-GAT
    armadilhaMosquitoAdulto2: {
      beggining: 0,
      end: 7,
    },
    //Ovitrampa
    armadilhaOvos: {
      beggining: 0,
      end: 7,
    },
  },
  //Paulínia
  "24a480de-51af-45a0-93c9-12499d329310": {
    //BG-GAT
    armadilhaMosquitoAdulto2: {
      beggining: 0,
      end: 15,
    },
  },
};

export const getTrapRange = (trapTypeName) => {
  if (!ARMADILHA_INFO[trapTypeName]) return;

  const organizationId = getUserData("organizationId");

  if (
    !rangesByOrganization[organizationId] ||
    (rangesByOrganization[organizationId] &&
      !rangesByOrganization[organizationId][trapTypeName])
  )
    return {
      quantityRange: Object.keys(defaultRangesByTrapTypeName[trapTypeName])
        .length,
      data: defaultRangesByTrapTypeName[trapTypeName],
    };

  return {
    quantityRange: Object.keys(
      rangesByOrganization[organizationId][trapTypeName]
    ).length,
    data: rangesByOrganization[organizationId][trapTypeName],
  };
};
