import React from "react";

import { Select } from "../Wrappers/SelectAll";
import { selectComponentStyles } from "../../services/utils/globalFunctions";

const DemandsFilter = ({
  demandsList,
  handleFiltersChange,
  selectedDemands,
}) => {
  return (
    <div>
      <span className="h4 text-white">Demanda</span>
      <Select
        styles={selectComponentStyles}
        placeholder={"Selecione"}
        isClearable={true}
        value={selectedDemands}
        options={demandsList}
        onChange={(e) =>
          handleFiltersChange(
            "demand",
            !e
              ? {
                  label: "Todas",
                  value: "",
                }
              : e
          )
        }
        isDisabled={demandsList.length === 0}
      />
    </div>
  );
};

export default DemandsFilter;
