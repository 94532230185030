import React, { useState } from "react";
import { toast } from "react-toastify";
import CsvDownloadButton from "react-json-to-csv";
import {
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  Row,
  Col,
  Button,
  Popover,
  PopoverHeader,
  PopoverBody,
  UncontrolledPopover,
} from "reactstrap";
import DataTable from "react-data-table-component";
import { getPermissionsToken, getUserData } from "../../services/utils/auth";
import {
  deleteTrap,
  getTrapByNumber,
  postTrapHistory,
  putTrapByNumber,
} from "../../services/api/Trap";
import {
  daysDifferenceBetweenTodayAndDate,
  getTrapColorStatus,
} from "../../services/utils/TrapStatus/trapStatus";
import UpdateTrapPositionModal from "../Modal/UpdateTrapPositionModal";
import { ARMADILHA_INFO } from "../../constants/ArmadilhaConstant";
import moment from "moment";
import { getVersion } from "../../constants/VersionConstant";
import ReactTooltip from "react-tooltip";
import { Select } from "../Wrappers/SelectAll";
import ConfirmGeoreferenceTrapsModal from "../Modal/TrapsTable/ConfirmGeoreferenceTrapsModal";
import { getAddressInformations } from "../../services/utils/WebForms/validations";
import TrapHistoryModal from "../Modal/TrapHistory/TrapHistoryModal";
import { formatTodayDateBody } from "../../services/utils/format";
import { checkPermissionComponent } from "../../services/utils/permission";

import styles from "./DashboardTrapsTable.module.css";
import { getTrapRange } from "../../constants/RangeTrapConstant";

const DashboardTrapsTable = ({
  traps,
  trapTypeOptions,
  selectedTrapType,
  employeeList,
  employeesId,
  setUpdatedData,
  updatedData,
  setTrapToCenterOnMapPosition,
  setTrapToCenterNumber,
  consolidatedTrapTypeOptions,
  territorializationsList,
  setModal2,
  modal2,
  setTrapToUpdateData,
  trapToUpdateData,
}) => {
  const [activeTableColumns, setActiveTableColumns] = React.useState({
    "Tipo de armadilha": true,
    Número: true,
    Endereço: true,
    "Funcionário que instalou (campo)": true,
    "Data de instalação (campo)": true,
    "Hora de instalação (campo)": true,
    "Funcionário que realizou a manutenção (campo)": true,
    "Data da manutenção (campo)": true,
    "Hora da manutenção (campo)": true,
    "Funcionário que realizou a desinstalação (campo)": false,
    "Data da desinstalação (campo)": false,
    "Hora da desinstalação (campo)": false,
    "Funcionário que digitou a instalação (Web)": true,
    "Data de digitação da instalação (Web)": true,
    "Hora de digitação da instalação (Web)": true,
    "Funcionário que digitou a manutenção (Web)": true,
    "Data de digitação da manutenção (Web)": true,
    "Hora de digitação da manutenção (Web)": true,
    "Situação da armadilha": true,
  });
  const [selectedTrapId, setSelectedTrapId] = useState(null);
  const [selectedTrap, setSelectedTrap] = useState(null);
  const [selectedTrapData, setSelectedTrapData] = useState(null);
  const [filteredTraps, setFilteredTraps] = useState(traps);
  const [modal, setModal] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [
    isConfirmGeoreferenceTrapsModalOpen,
    setIsConfirmGeoreferenceTrapsModalOpen,
  ] = React.useState(false);
  const [
    isGeoreferenceTrapsByAdressLoading,
    setIsGeoreferenceTrapsByAdressLoading,
  ] = React.useState(false);
  const [formatedTrapsToExport, setFormatedTrapsToExport] = React.useState([]);
  const [insideTerritorializationOptions, setInsideTerritorializationOptions] =
    React.useState([]);
  const [trapsFilters, setTrapsFilters] = React.useState({
    number: "",
    address: "",
    status: {
      label: "Instalada",
      value: "instaled",
    },
    statusByColor: {
      label: "Todos",
      value: "all",
    },
    type: {
      label: "Todos",
      value: "all",
    },
    typistId: {
      label: "Todos",
      value: "all",
    },
    beginTypingDate: "",
    endTypingDate: "",
    installerId: {
      label: "Todos",
      value: "all",
    },
    readerId: {
      label: "Todos",
      value: "all",
    },
    beginInstallDate: "",
    endInstallDate: "",
    beginReadDate: "",
    endReadDate: "",
    beginUninstallDate: "",
    endUninstallDate: "",
    uvis: "",
    insideTerritorialization: {
      label: "Todos",
      value: "all",
    },
  });

  const organizationId = getUserData("organizationId");

  React.useEffect(() => {
    if (
      getUserData("organizationId") !==
        "559a16ab-5da8-41ba-96b8-6e9f55feddd8" ||
      !territorializationsList ||
      territorializationsList.length === 0
    )
      return;

    const uvisTerritorializations = territorializationsList.find(
      ({ name }) => name === "UVIS"
    );

    if (uvisTerritorializations)
      setInsideTerritorializationOptions(uvisTerritorializations.options);
  }, [territorializationsList]);

  React.useEffect(() => {
    if (updatedData) {
      if (
        employeesId &&
        employeesId.value &&
        employeesId.value !== "00000000-0000-0000-0000-000000000000"
      )
        handleFiltersChange("type", {
          label: selectedTrapType.trapTypeName,
          value: selectedTrapType.trapTypeName,
        });

      handleFiltersChange("type", {
        label: selectedTrapType.trapTypeName,
        value: selectedTrapType.trapTypeName,
      });

      setUpdatedData(false);
    }
  }, [updatedData]);

  React.useEffect(() => {
    const filteredTrapsForTable = filterTraps();
    const formatedTrapsToExport = formatTrapsToExport(filteredTrapsForTable);

    setFilteredTraps(filteredTrapsForTable);
    setFormatedTrapsToExport(formatedTrapsToExport);
  }, [trapsFilters]);

  React.useEffect(() => {
    const userPreferences = localStorage.getItem("userPreferences");

    if (!userPreferences) return;

    const { tableColumns } = JSON.parse(userPreferences);

    const preferencesToSet = {};

    for (const [key, value] of Object.entries(tableColumns.dashboardTrapsTable))
      preferencesToSet[key] = value;

    setActiveTableColumns(preferencesToSet);
  }, []);

  const handleFiltersChange = (filterName, newValue) => {
    const newFiltersValues = { ...trapsFilters };

    newFiltersValues[filterName] = newValue;

    setTrapsFilters(newFiltersValues);
  };

  const handleActiveColumnsChange = (columnName) => {
    setActiveTableColumns((previousValues) => {
      const newValues = { ...previousValues };

      newValues[columnName] = !newValues[columnName];

      const preferences = {
        tableColumns: {
          dashboardTrapsTable: { ...newValues },
        },
      };

      localStorage.setItem("userPreferences", JSON.stringify(preferences));

      return newValues;
    });
  };

  const handleGeoreferenceTrapsWithError = async () => {
    if (filteredTraps.length === 0) {
      toast.info("Não existem armadilhas sem coordenadas.");

      return;
    }

    const organizationId = getUserData("organizationId");
    let errorOcurred = false;

    setIsGeoreferenceTrapsByAdressLoading(true);

    for (const { realty, number } of filteredTraps) {
      try {
        const { geometry } = await getAddressInformations(
          realty.subpremise,
          realty.number
        );

        const newTrapLatitude =
          geometry && geometry.location && geometry.location.lat;
        const newTrapLongitude =
          geometry && geometry.location && geometry.location.lng;

        if (newTrapLatitude && newTrapLongitude) {
          const trapToUpdateData = {
            OrganizationId: organizationId,
            TrapNumber: number,
            Latitude: newTrapLatitude,
            Longitude: newTrapLongitude,
            GeoreferencedByAddress: true,
          };

          await putTrapByNumber(trapToUpdateData);
        }
      } catch (error) {
        errorOcurred = true;
      } finally {
        setIsGeoreferenceTrapsByAdressLoading(false);
      }
    }

    if (errorOcurred) {
      toast.error(
        "Ocorreu um erro ao georreferenciar pelo menos uma armadilha por endereço. Algumas das armadilhas podem ter sido georreferenciadas, portanto, recarregue a página, volte a filtrar pelas armadilhas com o erro de georreferenciamento, clique no botão 'Baixar CSV das armadilhas' e envie o arquivo para nossa equipe."
      );

      return;
    }

    toast.success(
      "Armadilhas georreferenciadas com sucesso. Recarregue a página para ver as atualizações."
    );
  };

  const confirmDelete = (trapName, trapId) => {
    setSelectedTrapId(trapId);
    setSelectedTrap(trapName);
    toggle(false);
  };

  const trapDetails = (trapName, trapNumber, trapId) => {
    setSelectedTrapId(trapId);
    setSelectedTrap(trapName);
    setModalType("details");
    toggle(false);
    getTrapDetails(trapNumber);
  };

  const getTrapDetails = async (trapNumber) => {
    try {
      const { data, status } = await getTrapByNumber(trapNumber);
      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao buscar pelos detalhes da armadilha"
        );

      setSelectedTrapData(data);
    } catch (error) {
      console.error(error);
    }
  };

  const toggle = async (willDeleteTrap) => {
    setModal(!modal);

    if (modal) {
      setModalType(null);
      setSelectedTrapData(null);
    }

    if (willDeleteTrap) {
      let temp = await getTrapByNumber(selectedTrap.number);
      selectedTrap.histories = temp.data.histories;
      var lastHistory = selectedTrap.histories.sort(
        (a, b) => new Date(a.statusDate) - new Date(b.statusDate)
      );

      if (lastHistory && lastHistory.length > 0) {
        lastHistory = lastHistory[lastHistory.length - 1];
      } else {
        return;
      }

      var today = new Date();

      var statusDateBody = formatTodayDateBody(today);

      var body = {
        demandId: lastHistory.demandId,
        trapId: lastHistory.trapId,
        userId: lastHistory.userId,
        trap: null,
        samples: null,
        statusDate: statusDateBody,
        organizationId: organizationId,
        wasUnableToRead: false,
        trapWasInstalled: false,
        trapWasRead: false,
        trapWasRemoved: true,
        trapNumber: selectedTrap.number,
        wasCollectedSample: false,
        wasInviable: false,
        manuallyChanged: false,
        latitude: lastHistory.latitude,
        longitude: lastHistory.longitude,
        visitId: lastHistory.visitId,
        typistId: null,
        georeferencedByAddress: null,
      };

      postTrapHistory(body).then((response) => {
        if (response) {
          toast.success("Armadilha desinstalada com sucesso!");
        }
      });
    }
  };

  const toggleUpdateTrapPositionModal = () => {
    setModal2(!modal2);
  };

  const openUpdateTrapPositionModal = (trap) => {
    toggleUpdateTrapPositionModal();
    getTrapByNumber(trap.number).then((response) => {
      setTrapToUpdateData(response.data);
    });
  };

  const handleTrapPositionToCenterChange = (latitude, longitude) => {
    setTrapToCenterOnMapPosition([latitude, longitude]);
  };

  const handleTrapToCenterNumber = (trapNumber) => {
    setTrapToCenterNumber(trapNumber);
  };

  const buildButtons = (trap) => {
    const userPermissions = getPermissionsToken();
    return (
      <>
        {userPermissions["ba56119d-ac43-4ec1-858e-31f2e12235e8"] && (
          <>
            <button
              data-tip
              data-for="trapHistory"
              className="btn btn-primary"
              style={{
                width: "40px",
                margin: "5px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              id="tooltip204269497"
              onClick={() => trapDetails(trap.name, trap.number, trap.id)}
              type="button"
            >
              <i className="fa fa-search" aria-hidden="true"></i>
            </button>
            <ReactTooltip effect="solid" type="info" id="trapHistory">
              Histórico da armadilha
            </ReactTooltip>
            <button
              data-tip
              data-for="centerTrapOnMap"
              className="btn btn-primary"
              style={{
                width: "40px",
                margin: "5px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              id="tooltip204269497"
              onClick={() => {
                handleTrapPositionToCenterChange(trap.latitude, trap.longitude);
                handleTrapToCenterNumber(trap.number);
              }}
              type="button"
            >
              <i className="fa fa-map" aria-hidden="true"></i>
            </button>
            <ReactTooltip effect="solid" type="info" id="centerTrapOnMap">
              Centralizar armadilha no mapa
            </ReactTooltip>
            {checkPermissionComponent(
              "bb5b6faa-ba61-4e67-87d9-bb30dfe5c8ab",
              "update"
            ) ? (
              <>
                <button
                  data-tip
                  data-for="editTrap"
                  className="btn btn-primary"
                  style={{
                    width: "40px",
                    margin: "5px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  id="tooltip204269497"
                  onClick={() => openUpdateTrapPositionModal(trap)}
                  type="button"
                >
                  <i className="fa fa-pen" aria-hidden="true"></i>
                </button>
                <ReactTooltip effect="solid" type="info" id="editTrap">
                  Editar armadilha
                </ReactTooltip>
              </>
            ) : null}
            {checkPermissionComponent(
              "bb5b6faa-ba61-4e67-87d9-bb30dfe5c8ab",
              "delete"
            ) ? (
              <>
                <button
                  data-tip
                  data-for="deleteTrap"
                  className="btn btn-warning"
                  style={{
                    width: "40px",
                    margin: "5px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  data-clipboard-text=" ni ni-fat-remove"
                  id="tooltip204269497"
                  onClick={() => confirmDelete(trap, trap.id)}
                  type="button"
                >
                  <i className=" fa fa-trash" />
                </button>
                <ReactTooltip effect="solid" type="error" id="deleteTrap">
                  Desinstalar armadilha
                </ReactTooltip>
              </>
            ) : null}
          </>
        )}
      </>
    );
  };

  const buildTrapSituationButton = (row) => {
    if (row.trapWasRemoved) return null;

    const lastReadDate = row.lastReadDate ? new Date(row.lastReadDate) : null;
    const installDate = new Date(row.installDate);

    let daysDifference;

    const daysDifferenceBetweenTodayAndInstallDate =
      daysDifferenceBetweenTodayAndDate(installDate);
    const daysDifferenceBetweenTodayAndLastReadDate =
      daysDifferenceBetweenTodayAndDate(lastReadDate);

    if (daysDifferenceBetweenTodayAndLastReadDate === null)
      daysDifference = daysDifferenceBetweenTodayAndInstallDate;
    else
      daysDifference = Math.min(
        daysDifferenceBetweenTodayAndInstallDate,
        daysDifferenceBetweenTodayAndLastReadDate
      );

    const trapTypeRange = getTrapRange(row.trapTypeName);

    let trapStatusColor = "white";

    if (trapTypeRange.quantityRange === 2) {
      if (daysDifference <= trapTypeRange.data.end) trapStatusColor = "#76ea79";
      else trapStatusColor = "#ff0000";
    }

    if (trapTypeRange.quantityRange === 3) {
      if (daysDifference <= trapTypeRange.data.middle)
        trapStatusColor = "#76ea79";
      else if (daysDifference <= trapTypeRange.data.end)
        trapStatusColor = "#fff200";
      else trapStatusColor = "#ff0000";
    }

    const statusStyles = {
      width: "60px",
      margin: "5px",
      backgroundColor: trapStatusColor,
    };

    return (
      <button className="btn" style={statusStyles}>
        {daysDifference}
      </button>
    );
  };

  const getTrapInstallerOrTyperName = (
    installerId,
    invalidInstallerIdMessage
  ) => {
    if (
      installerId === "00000000-0000-0000-0000-000000000000" ||
      installerId === null ||
      installerId === undefined
    )
      return invalidInstallerIdMessage;

    let employeeObject;

    employeeObject = employeeList.filter(
      (employee) => employee.userId === installerId
    )[0];
    if (employeeObject) return employeeObject.label;

    employeeObject = employeeList.filter(
      (employee) => employee.employeeId === installerId
    )[0];
    if (employeeObject) return employeeObject.label;

    return invalidInstallerIdMessage;
  };

  const formatTrapDateAndTime = (date, isIsoFormat) => {
    let installDate;

    if (isIsoFormat)
      installDate = moment(date)
        .subtract(3, "hours")
        .format("DD/MM/YYYY HH:mm");
    else installDate = moment(date).format("DD/MM/YYYY HH:mm");

    const splitedDate = installDate.split(" ");

    if (splitedDate[1].includes("00:00")) installDate = splitedDate[0];
    else installDate = `${splitedDate[0]} às ${splitedDate[1]}`;

    return installDate;
  };

  const formatDateFromIsoDate = (isoDate) => {
    return isoDate ? moment(isoDate).format("DD/MM/YYYY") : "-";
  };

  const formatTimeFromIsoDate = (isoDate, subtractHours) => {
    if (!isoDate) return "-";

    let time = moment(isoDate);

    return subtractHours
      ? time.subtract(3, "hours").format("HH:mm")
      : time.format("HH:mm");
  };

  const formatTrapsToExport = (trapsToExport) => {
    if (!trapsToExport || trapsToExport.length === 0) return [];

    const formatedTrapsToExport = trapsToExport.map((trap) => {
      let daysDifference;

      const daysDifferenceBetweenTodayAndInstallDate =
        daysDifferenceBetweenTodayAndDate(trap.installDate);
      const daysDifferenceBetweenTodayAndLastReadDate =
        daysDifferenceBetweenTodayAndDate(trap.lastReadDate);

      !daysDifferenceBetweenTodayAndLastReadDate
        ? (daysDifference = daysDifferenceBetweenTodayAndInstallDate)
        : daysDifferenceBetweenTodayAndLastReadDate <
          daysDifferenceBetweenTodayAndInstallDate
        ? (daysDifference = daysDifferenceBetweenTodayAndLastReadDate)
        : (daysDifference = daysDifferenceBetweenTodayAndInstallDate);

      return {
        NUMERO_ARMADILHA: trap.number,
        TIPO_ARMADILHA: trap.name,
        REGIAO1: trap.insideTerritorialization,
        REGIAO2: trap.insideTerritorialization2,
        ENDERECO: trap.address,
        LATITUDE: trap.latitude,
        LONGITUDE: trap.longitude,
        QUEM_INSTALOU_CAMPO: getTrapInstallerOrTyperName(
          trap.whoInstallId,
          "Não encontrado"
        ),
        DATA_INSTALACAO_CAMPO: formatDateFromIsoDate(trap.installDate),
        HORA_INSTALACAO_CAMPO: formatTimeFromIsoDate(trap.installTime),
        QUEM_REALIZOU_MANUTENCAO_CAMPO: getTrapInstallerOrTyperName(
          trap.whoLastReadId,
          "Não encontrado"
        ),
        DATA_MANUTENCAO_CAMPO: formatDateFromIsoDate(trap.lastReadDate),
        HORA_MANUTENCAO_CAMPO: formatTimeFromIsoDate(trap.lastReadDate),
        QUEM_REALIZOU_DESINSTALACAO_CAMPO: getTrapInstallerOrTyperName(
          trap.whoLastUninstalledId,
          "Não encontrado"
        ),
        DATA_DESINSTALACAO_CAMPO: formatDateFromIsoDate(trap.lastUninstallDate),
        HORA_DESINSTALACAO_CAMPO: formatTimeFromIsoDate(trap.lastUninstallDate),
        QUEM_INSTALOU_WEB: getTrapInstallerOrTyperName(
          trap.typistId,
          "Não encontrado"
        ),
        DATA_INSTALACAO_WEB: formatDateFromIsoDate(trap.installTypingDate),
        HORA_INSTALACAO_WEB: formatTimeFromIsoDate(trap.installTypingTime),
        QUEM_REALIZOU_MANUTENCAO_WEB: getTrapInstallerOrTyperName(
          trap.lastReadTypistId,
          "Não encontrado"
        ),
        DATA_MANUTENCAO_WEB: formatDateFromIsoDate(trap.lastReadTypingDate),
        HORA_MANUTENCAO_WEB: formatTimeFromIsoDate(trap.lastReadTypingTime),
        DIFERENCA_DIAS_ENTRE_INSTALACAO_OU_MANUTENCAO_ATE_HOJE: daysDifference,
        PROJETOS:
          !trap.projects || trap.projects.length === 0
            ? []
            : trap.projects.map(({ name }) => name).join(", "),
      };
    });

    return formatedTrapsToExport;
  };

  const filterTraps = () => {
    const TRAP_NOT_GEOREFERENCED_LATITUDE = -15.798784;
    const TRAP_NOT_GEOREFERENCED_LONGITUDE = -47.8638;

    const beginInstallDate = moment(trapsFilters.beginInstallDate);
    const endInstallDate = moment(trapsFilters.endInstallDate);
    const beginReadDate = moment(trapsFilters.beginReadDate);
    const endReadDate = moment(trapsFilters.endReadDate);
    const beginUninstallDate = moment(trapsFilters.beginUninstallDate);
    const endUninstallDate = moment(trapsFilters.endUninstallDate);
    const beginTypingDate = moment(trapsFilters.beginTypingDate);
    const endTypingDate = moment(trapsFilters.endTypingDate);

    const trapsFiltered = traps.filter((trap) => {
      if (
        trapsFilters.number !== "" &&
        !trap.number.includes(trapsFilters.number)
      )
        return false;

      if (
        trapsFilters.statusByColor.value !== "all" &&
        getTrapColorStatus(
          trap.trapName,
          trap.installDate,
          trap.lastReadDate
        ) !== trapsFilters.statusByColor.value
      )
        return false;

      if (
        trapsFilters.type.value !== "all" &&
        trap.name !== trapsFilters.type.value
      )
        return false;

      if (
        trapsFilters.typistId.value !== "all" &&
        trap.typistId !== trapsFilters.typistId.value &&
        trap.lastReadTypistId !== trapsFilters.typistId.value
      )
        return false;

      if (
        trapsFilters.beginInstallDate !== "" &&
        trapsFilters.endInstallDate !== "" &&
        !moment(trap.installDate).isBetween(
          beginInstallDate,
          endInstallDate,
          null,
          "[]"
        )
      )
        return false;

      if (
        trapsFilters.beginReadDate !== "" &&
        trapsFilters.endReadDate !== "" &&
        !moment(trap.lastReadDate).isBetween(
          beginReadDate,
          endReadDate,
          null,
          "[]"
        )
      )
        return false;

      if (
        trapsFilters.beginUninstallDate !== "" &&
        trapsFilters.endUninstallDate !== "" &&
        !moment(trap.lastUninstallDate).isBetween(
          beginUninstallDate,
          endUninstallDate,
          null,
          "[]"
        )
      )
        return false;

      if (
        trapsFilters.installerId.label !== "Todos" &&
        getTrapInstallerOrTyperName(trap.whoInstallId, "Não encontrado") !==
          trapsFilters.installerId.label
      )
        return false;

      if (
        trapsFilters.readerId.label !== "Todos" &&
        getTrapInstallerOrTyperName(trap.whoLastReadId, "Não encontrado") !==
          trapsFilters.readerId.label
      )
        return false;

      if (
        trapsFilters.address !== "" &&
        !trap.address
          .toLowerCase()
          .includes(trapsFilters.address.toLocaleLowerCase())
      )
        return false;

      if (
        trapsFilters.insideTerritorialization.label !== "Todos" &&
        trap.insideTerritorialization !==
          trapsFilters.insideTerritorialization.label
      )
        return false;

      if (
        trapsFilters.beginTypingDate !== "" &&
        trapsFilters.endTypingDate !== "" &&
        !moment(trap.typingDate).isBetween(
          beginTypingDate,
          endTypingDate,
          null,
          "[]"
        )
      )
        return false;

      if (trapsFilters.status.value === "instaled" && trap.trapWasRemoved)
        return false;

      if (trapsFilters.status.value === "removed" && !trap.trapWasRemoved)
        return false;

      if (
        trapsFilters.status.value === "notGeoreferenced" &&
        trap.latitude !== TRAP_NOT_GEOREFERENCED_LATITUDE &&
        trap.longitude !== TRAP_NOT_GEOREFERENCED_LONGITUDE
      )
        return false;

      if (
        trapsFilters.status.value === "georeferencedByAddress" &&
        !trap.georeferencedByAddress
      )
        return false;

      return true;
    });

    return trapsFiltered;
  };

  const displayTrapNumber = (number, trapWasGeoreferencedByAddress) => {
    if (!trapWasGeoreferencedByAddress) return number;

    return (
      <>
        <div>
          {number}{" "}
          <i
            className="fa fa-exclamation-triangle"
            data-tip
            data-for={`trapWarning${number}`}
          ></i>
        </div>
        <ReactTooltip effect="solid" type="info" id={`trapWarning${number}`}>
          Georreferenciada por endereço
        </ReactTooltip>
      </>
    );
  };

  const columns = [
    {
      name: "Tipo de armadilha",
      selector: "name",
      sortable: true,
      width: "100px",
      omit: !activeTableColumns["Tipo de armadilha"] ? true : false,
    },
    {
      name: "Número",
      selector: "number",
      sortable: true,
      sortFunction: (a, b) => {
        const valorA = a.number.replace(/\D/g, "");
        const valorB = b.number.replace(/\D/g, "");

        return valorA - valorB;
      },
      cell: (row) => displayTrapNumber(row.number, row.georeferencedByAddress),
      omit: !activeTableColumns["Número"] ? true : false,
    },
    {
      name: "Endereço",
      selector: "address",
      sortable: true,
      cell: (row) => `${row.address}`,
      omit: !activeTableColumns["Endereço"] ? true : false,
    },
    {
      name: "Funcionário que instalou (campo)",
      selector: "whoInstallId",
      sortable: true,
      cell: (row) =>
        getTrapInstallerOrTyperName(
          row.whoInstallId,
          "Funcionário não encontrado"
        ),
      omit: !activeTableColumns["Funcionário que instalou (campo)"]
        ? true
        : false,
    },
    {
      name: "Data de instalação (campo)",
      selector: "installDate",
      sortable: true,
      cell: (row) => formatDateFromIsoDate(row.installDate),
      omit: !activeTableColumns["Data de instalação (campo)"] ? true : false,
    },
    {
      name: "Hora de instalação (campo)",
      selector: "installTime",
      sortable: true,
      cell: (row) => formatTimeFromIsoDate(row.installTime),
      omit: !activeTableColumns["Hora de instalação (campo)"] ? true : false,
    },
    {
      name: "Funcionário que realizou a manutenção (campo)",
      selector: "lastReadDate",
      sortable: true,
      cell: (row) =>
        getTrapInstallerOrTyperName(
          row.whoLastReadId,
          "Funcionário não encontrado"
        ),
      omit: !activeTableColumns["Funcionário que realizou a manutenção (campo)"]
        ? true
        : false,
    },
    {
      name: "Data da manutenção (campo)",
      selector: "lastReadDate",
      sortable: true,
      cell: (row) => formatDateFromIsoDate(row.lastReadDate),
      omit: !activeTableColumns["Data da manutenção (campo)"] ? true : false,
    },
    {
      name: "Hora da manutenção (campo)",
      selector: "lastReadTime",
      sortable: true,
      cell: (row) => formatTimeFromIsoDate(row.lastReadTime),
      omit: !activeTableColumns["Hora da manutenção (campo)"] ? true : false,
    },
    {
      name: "Funcionário que realizou a desinstalação (campo)",
      sortable: true,
      cell: (row) =>
        getTrapInstallerOrTyperName(
          row.WhoLastUninstalledId,
          "Funcionário não encontrado"
        ),
      omit: !activeTableColumns[
        "Funcionário que realizou a desinstalação (campo)"
      ]
        ? true
        : false,
    },
    {
      name: "Data da desinstalação (campo)",
      selector: "lastUninstallDate",
      sortable: true,
      cell: (row) => formatDateFromIsoDate(row.lastUninstallDate),
      omit: !activeTableColumns["Data da desinstalação (campo)"] ? true : false,
    },
    {
      name: "Hora da desinstalação (campo)",
      selector: "lastReadTime",
      sortable: true,
      cell: (row) => formatTimeFromIsoDate(row.lastReadTime),
      omit: !activeTableColumns["Hora da desinstalação (campo)"] ? true : false,
    },
    {
      name: "Funcionário que digitou a instalação (Web)",
      selector: "typistId",
      sortable: true,
      cell: (row) =>
        getTrapInstallerOrTyperName(
          row.typistId,
          "Não inserida pelo formulário"
        ),
      omit: !activeTableColumns["Funcionário que digitou a instalação (Web)"]
        ? true
        : false,
    },
    {
      name: "Data de digitação da instalação (Web)",
      selector: "installTypingDate",
      sortable: true,
      cell: (row) => formatDateFromIsoDate(row.installTypingDate),
      omit: !activeTableColumns["Data de digitação da instalação (Web)"]
        ? true
        : false,
    },
    {
      name: "Hora de digitação da instalação (Web)",
      selector: "installTypingTime",
      sortable: true,
      cell: (row) => formatTimeFromIsoDate(row.installTypingTime, true),
      omit: !activeTableColumns["Hora de digitação da instalação (Web)"]
        ? true
        : false,
    },
    {
      name: "Funcionário que digitou a manutenção (Web)",
      selector: "typistId",
      sortable: true,
      cell: (row) =>
        getTrapInstallerOrTyperName(
          row.lastReadTypistId,
          "Não inserida pelo formulário"
        ),
      omit: !activeTableColumns["Funcionário que digitou a manutenção (Web)"]
        ? true
        : false,
    },
    {
      name: "Data de digitação da manutenção (Web)",
      selector: "lastReadTypingDate",
      sortable: true,
      cell: (row) => formatDateFromIsoDate(row.lastReadTypingDate),
      omit: !activeTableColumns["Data de digitação da manutenção (Web)"]
        ? true
        : false,
    },
    {
      name: "Hora de digitação da manutenção (Web)",
      selector: "lastReadTypingTime",
      sortable: true,
      cell: (row) => formatTimeFromIsoDate(row.lastReadTypingTime, true),
      omit: !activeTableColumns["Hora de digitação da manutenção (Web)"]
        ? true
        : false,
    },
    {
      name: "Situação da armadilha",
      sortable: true,
      selector: "lastReadDate",
      width: "100px",
      cell: (row) => buildTrapSituationButton(row),
      omit: !activeTableColumns["Situação da armadilha"] ? true : false,
    },
    {
      cell: (row) => buildButtons(row),
      ignoreRowClick: true,
    },
  ];

  const columnsByOrganization = {
    "559a16ab-5da8-41ba-96b8-6e9f55feddd8": [
      {
        name: "Tipo de armadilha",
        selector: "name",
        sortable: true,
        width: "100px",
        omit: !activeTableColumns["Tipo de armadilha"] ? true : false,
      },
      {
        name: "Quadra SISCOZ",
        selector: "insideTerritorialization",
        sortable: true,
      },
      {
        name: "Número",
        selector: "number",
        sortable: true,
        sortFunction: (a, b) => {
          const valorA = a.number.replace(/\D/g, "");
          const valorB = b.number.replace(/\D/g, "");

          return valorA - valorB;
        },
        omit: !activeTableColumns["Número"] ? true : false,
      },
      {
        name: "Endereço",
        selector: "address",
        sortable: true,
        cell: (row) => `${row.address}`,
        omit: !activeTableColumns["Endereço"] ? true : false,
      },

      {
        name: "Funcionário que instalou (campo)",
        selector: "whoInstallId",
        sortable: true,
        cell: (row) =>
          getTrapInstallerOrTyperName(
            row.whoInstallId,
            "Funcionário não encontrado"
          ),
        omit: !activeTableColumns["Funcionário que instalou (campo)"]
          ? true
          : false,
      },
      {
        name: "Data de instalação (campo)",
        selector: "installDate",
        sortable: true,
        cell: (row) => formatDateFromIsoDate(row.installDate),
        omit: !activeTableColumns["Data de instalação (campo)"] ? true : false,
      },
      {
        name: "Hora de instalação (campo)",
        selector: "installTime",
        sortable: true,
        cell: (row) => formatTimeFromIsoDate(row.installTime),
        omit: !activeTableColumns["Hora de instalação (campo)"] ? true : false,
      },
      {
        name: "Funcionário que realizou a manutenção (campo)",
        selector: "lastReadDate",
        sortable: true,
        cell: (row) =>
          getTrapInstallerOrTyperName(
            row.whoLastReadId,
            "Funcionário não encontrado"
          ),
        omit: !activeTableColumns[
          "Funcionário que realizou a manutenção (campo)"
        ]
          ? true
          : false,
      },
      {
        name: "Data da manutenção (campo)",
        selector: "lastReadDate",
        sortable: true,
        cell: (row) => formatDateFromIsoDate(row.lastReadDate),
        omit: !activeTableColumns["Data da manutenção (campo)"] ? true : false,
      },
      {
        name: "Hora da manutenção (campo)",
        selector: "lastReadTime",
        sortable: true,
        cell: (row) => formatTimeFromIsoDate(row.lastReadTime),
        omit: !activeTableColumns["Hora da manutenção (campo)"] ? true : false,
      },
      {
        name: "Funcionário que realizou a desinstalação (campo)",
        sortable: true,
        cell: (row) =>
          getTrapInstallerOrTyperName(
            row.WhoLastUninstalledId,
            "Funcionário não encontrado"
          ),
        omit: !activeTableColumns[
          "Funcionário que realizou a desinstalação (campo)"
        ]
          ? true
          : false,
      },
      {
        name: "Data da desinstalação (campo)",
        selector: "lastUninstallDate",
        sortable: true,
        cell: (row) => formatDateFromIsoDate(row.lastUninstallDate),
        omit: !activeTableColumns["Data da desinstalação (campo)"]
          ? true
          : false,
      },
      {
        name: "Hora da desinstalação (campo)",
        selector: "lastReadTime",
        sortable: true,
        cell: (row) => formatTimeFromIsoDate(row.lastReadTime),
        omit: !activeTableColumns["Hora da desinstalação (campo)"]
          ? true
          : false,
      },
      {
        name: "Funcionário que digitou a instalação (Web)",
        selector: "typistId",
        sortable: true,
        cell: (row) =>
          getTrapInstallerOrTyperName(
            row.typistId,
            "Não inserida pelo formulário"
          ),
        omit: !activeTableColumns["Funcionário que digitou a instalação (Web)"]
          ? true
          : false,
      },
      {
        name: "Data de digitação da instalação (Web)",
        selector: "installTypingDate",
        sortable: true,
        cell: (row) => formatDateFromIsoDate(row.installTypingDate),
        omit: !activeTableColumns["Data de digitação da instalação (Web)"]
          ? true
          : false,
      },
      {
        name: "Hora de digitação da instalação (Web)",
        selector: "installTypingTime",
        sortable: true,
        cell: (row) => formatTimeFromIsoDate(row.installTypingTime),
        omit: !activeTableColumns["Hora de digitação da instalação (Web)"]
          ? true
          : false,
      },
      {
        name: "Funcionário que digitou a manutenção (Web)",
        selector: "typistId",
        sortable: true,
        cell: (row) =>
          getTrapInstallerOrTyperName(
            row.lastReadTypistId,
            "Não inserida pelo formulário"
          ),
        omit: !activeTableColumns["Funcionário que digitou a manutenção (Web)"]
          ? true
          : false,
      },
      {
        name: "Data de digitação da manutenção (Web)",
        selector: "lastReadTypingDate",
        sortable: true,
        cell: (row) => formatDateFromIsoDate(row.lastReadTypingDate),
        omit: !activeTableColumns["Data de digitação da manutenção (Web)"]
          ? true
          : false,
      },
      {
        name: "Hora de digitação da manutenção (Web)",
        selector: "lastReadTypingTime",
        sortable: true,
        cell: (row) => formatTimeFromIsoDate(row.lastReadTypingTime),
        omit: !activeTableColumns["Hora de digitação da manutenção (Web)"]
          ? true
          : false,
      },
      /* {
        name: "UVIS",
        selector: "insideTerritorialization",
        sortable: true,
      }, */
      {
        name: "Situação da armadilha",
        sortable: true,
        selector: "lastReadDate",
        width: "100px",
        cell: (row) => buildTrapSituationButton(row),
        omit: !activeTableColumns["Situação da armadilha"] ? true : false,
      },
      {
        cell: (row) => buildButtons(row),
        ignoreRowClick: true,
      },
    ],
    "24a480de-51af-45a0-93c9-12499d329310": [
      {
        name: "Tipo de armadilha",
        selector: "name",
        sortable: true,
        width: "100px",
        omit: !activeTableColumns["Tipo de armadilha"] ? true : false,
      },
      {
        name: "Número",
        selector: "number",
        sortable: true,
        sortFunction: (a, b) => {
          const valorA = a.number.replace(/\D/g, "");
          const valorB = b.number.replace(/\D/g, "");

          return valorA - valorB;
        },
        cell: (row) =>
          displayTrapNumber(row.number, row.georeferencedByAddress),
        omit: !activeTableColumns["Número"] ? true : false,
      },
      {
        name: "Macro Região",
        selector: "insideTerritorialization",
        sortable: true,
      },
      {
        name: "Endereço",
        selector: "address",
        sortable: true,
        cell: (row) => `${row.address}`,
        omit: !activeTableColumns["Endereço"] ? true : false,
      },
      {
        name: "Funcionário que instalou (campo)",
        selector: "whoInstallId",
        sortable: true,
        cell: (row) =>
          getTrapInstallerOrTyperName(
            row.whoInstallId,
            "Funcionário não encontrado"
          ),
        omit: !activeTableColumns["Funcionário que instalou (campo)"]
          ? true
          : false,
      },
      {
        name: "Data de instalação (campo)",
        selector: "installDate",
        sortable: true,
        cell: (row) => formatDateFromIsoDate(row.installDate),
        omit: !activeTableColumns["Data de instalação (campo)"] ? true : false,
      },
      {
        name: "Hora de instalação (campo)",
        selector: "installTime",
        sortable: true,
        cell: (row) => formatTimeFromIsoDate(row.installTime),
        omit: !activeTableColumns["Hora de instalação (campo)"] ? true : false,
      },
      {
        name: "Funcionário que realizou a manutenção (campo)",
        selector: "lastReadDate",
        sortable: true,
        cell: (row) =>
          getTrapInstallerOrTyperName(
            row.whoLastReadId,
            "Funcionário não encontrado"
          ),
        omit: !activeTableColumns[
          "Funcionário que realizou a manutenção (campo)"
        ]
          ? true
          : false,
      },
      {
        name: "Data da manutenção (campo)",
        selector: "lastReadDate",
        sortable: true,
        cell: (row) => formatDateFromIsoDate(row.lastReadDate),
        omit: !activeTableColumns["Data da manutenção (campo)"] ? true : false,
      },
      {
        name: "Hora da manutenção (campo)",
        selector: "lastReadTime",
        sortable: true,
        cell: (row) => formatTimeFromIsoDate(row.lastReadTime),
        omit: !activeTableColumns["Hora da manutenção (campo)"] ? true : false,
      },
      {
        name: "Funcionário que realizou a desinstalação (campo)",
        sortable: true,
        cell: (row) =>
          getTrapInstallerOrTyperName(
            row.WhoLastUninstalledId,
            "Funcionário não encontrado"
          ),
        omit: !activeTableColumns[
          "Funcionário que realizou a desinstalação (campo)"
        ]
          ? true
          : false,
      },
      {
        name: "Data da desinstalação (campo)",
        selector: "lastUninstallDate",
        sortable: true,
        cell: (row) => formatDateFromIsoDate(row.lastUninstallDate),
        omit: !activeTableColumns["Data da desinstalação (campo)"]
          ? true
          : false,
      },
      {
        name: "Hora da desinstalação (campo)",
        selector: "lastReadTime",
        sortable: true,
        cell: (row) => formatTimeFromIsoDate(row.lastReadTime),
        omit: !activeTableColumns["Hora da desinstalação (campo)"]
          ? true
          : false,
      },
      {
        name: "Funcionário que digitou a instalação (Web)",
        selector: "typistId",
        sortable: true,
        cell: (row) =>
          getTrapInstallerOrTyperName(
            row.typistId,
            "Não inserida pelo formulário"
          ),
        omit: !activeTableColumns["Funcionário que digitou a instalação (Web)"]
          ? true
          : false,
      },
      {
        name: "Data de digitação da instalação (Web)",
        selector: "installTypingDate",
        sortable: true,
        cell: (row) => formatDateFromIsoDate(row.installTypingDate),
        omit: !activeTableColumns["Data de digitação da instalação (Web)"]
          ? true
          : false,
      },
      {
        name: "Hora de digitação da instalação (Web)",
        selector: "installTypingTime",
        sortable: true,
        cell: (row) => formatTimeFromIsoDate(row.installTypingTime, true),
        omit: !activeTableColumns["Hora de digitação da instalação (Web)"]
          ? true
          : false,
      },
      {
        name: "Funcionário que digitou a manutenção (Web)",
        selector: "typistId",
        sortable: true,
        cell: (row) =>
          getTrapInstallerOrTyperName(
            row.lastReadTypistId,
            "Não inserida pelo formulário"
          ),
        omit: !activeTableColumns["Funcionário que digitou a manutenção (Web)"]
          ? true
          : false,
      },
      {
        name: "Data de digitação da manutenção (Web)",
        selector: "lastReadTypingDate",
        sortable: true,
        cell: (row) => formatDateFromIsoDate(row.lastReadTypingDate),
        omit: !activeTableColumns["Data de digitação da manutenção (Web)"]
          ? true
          : false,
      },
      {
        name: "Hora de digitação da manutenção (Web)",
        selector: "lastReadTypingTime",
        sortable: true,
        cell: (row) => formatTimeFromIsoDate(row.lastReadTypingTime, true),
        omit: !activeTableColumns["Hora de digitação da manutenção (Web)"]
          ? true
          : false,
      },
      {
        name: "Situação da armadilha",
        sortable: true,
        selector: "lastReadDate",
        width: "100px",
        cell: (row) => buildTrapSituationButton(row),
        omit: !activeTableColumns["Situação da armadilha"] ? true : false,
      },
      {
        cell: (row) => buildButtons(row),
        ignoreRowClick: true,
      },
    ],
  };

  const trapColorStatusOptions = [
    {
      label: "Todos",
      value: "all",
    },
    {
      label: "Verde",
      value: "green",
    },
    {
      label: "Amarelo",
      value: "yellow",
    },
    {
      label: "Vermelho",
      value: "red",
    },
  ];

  const trapStatusOptions = [
    {
      label: "Todas",
      value: "all",
    },
    {
      label: "Instalada",
      value: "instaled",
    },
    {
      label: "Desinstalada",
      value: "removed",
    },
    {
      label: "Sem coordenadas",
      value: "notGeoreferenced",
    },
    {
      label: "Georreferenciada por endereço",
      value: "georeferencedByAddress",
    },
  ];

  const formatedTrapTypeOptions =
    trapTypeOptions &&
    trapTypeOptions.map((trapType) => {
      return {
        label: ARMADILHA_INFO[trapType.name].apelido,
        value: ARMADILHA_INFO[trapType.name].apelido,
      };
    });

  const formatedEmployeesOptions =
    employeeList &&
    employeeList.map(({ employeeName, userId }) => {
      return {
        label: employeeName,
        value: userId,
      };
    });

  const getTrapTypeSurname = {
    armadilhaDisseminadoraInseticida: "ADI",
    armadilhaOvos: "OVITRAMPA",
    armadilhaMosquitoAdulto2: "BG-GAT",
  };

  const dateFormatOptions = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
    timeZone: "America/Sao_Paulo",
  };

  const selectComponentStyles = {
    control: (styles) => ({
      ...styles,
      minHeight: "2.90rem",
      borderRadius: "0.370rem",
    }),
    option: (styles) => ({ ...styles, fontSize: "0.875rem" }),
    valueContainer: (styles) => ({
      ...styles,
      fontSize: "0.875rem",
      color: "#8898aa",
    }),
    singleValue: (styles) => ({
      ...styles,
      fontSize: "0.875rem",
      color: "#8898aa",
    }),
    placeholder: (styles) => ({
      ...styles,
      fontSize: "0.875rem",
      color: "#8898aa",
    }),
  };

  const toggleGeoreferenceTrapsModal = () => {
    setIsConfirmGeoreferenceTrapsModalOpen(
      !isConfirmGeoreferenceTrapsModalOpen
    );
  };

  const handleModalClose = () => {
    setTrapToUpdateData(null);
    toggleUpdateTrapPositionModal(false);
  };

  return (
    <>
      {modal ? (
        <TrapHistoryModal
          modalType={modalType}
          modal={modal}
          toggle={toggle}
          selectedTrapData={selectedTrapData}
          setSelectedTrapData={setSelectedTrapData}
          dateFormatOptions={dateFormatOptions}
          getTrapTypeSurname={getTrapTypeSurname}
          consolidatedTrapTypeOptions={consolidatedTrapTypeOptions}
        />
      ) : (
        <></>
      )}

      <Modal size="lg" fade isOpen={modal2} toggle={() => handleModalClose()}>
        <ModalHeader>
          <span>Alterar posição da armadilha</span>
        </ModalHeader>
        <ModalBody>
          {trapToUpdateData && (
            <UpdateTrapPositionModal
              employeeList={employeeList}
              trapTypeOptions={trapTypeOptions}
              trap={trapToUpdateData}
              modalOpened={modal2}
              position={[trapToUpdateData.latitude, trapToUpdateData.longitude]}
              number={trapToUpdateData.number}
              showEmployeeFields={true}
              territorializationsList={territorializationsList}
            />
          )}
        </ModalBody>
        <ModalFooter>
          <div className="row">
            <div className="col-6" style={{ maxWidth: "100%" }}>
              <button
                className="form-control"
                onClick={() => {
                  handleModalClose();
                }}
                style={{ cursor: "pointer" }}
              >
                FECHAR
              </button>
            </div>
          </div>
        </ModalFooter>
      </Modal>
      <ConfirmGeoreferenceTrapsModal
        isModalOpen={isConfirmGeoreferenceTrapsModalOpen}
        toggle={toggleGeoreferenceTrapsModal}
        handleGeoreferenceTrapsWithError={handleGeoreferenceTrapsWithError}
        isGeoreferenceTrapsByAdressLoading={isGeoreferenceTrapsByAdressLoading}
      />
      <Row className="mt-12">
        <Col className="mb-12 mb-xl-0" xl="12">
          <Card className="shadow">
            <CardHeader className="border-0" style={{ paddingBottom: "0px" }}>
              <Row className="align-items-center">
                <div className="col">
                  <h2 className="mb-0">Tabela de armadilhas</h2>
                  <br />
                </div>
              </Row>
            </CardHeader>
            <Row
              className="align-items-center"
              style={{
                marginBottom: "1rem",
                padding: "0px 1.5rem",
              }}
            >
              <Col xl="4">
                <span style={{ fontWeight: "bold" }}>Número</span>
                <input
                  type="text"
                  className="form-control"
                  value={trapsFilters.number}
                  onChange={({ target }) =>
                    handleFiltersChange("number", target.value)
                  }
                  placeholder="Digite o número da armadilha"
                />
              </Col>
              <Col xl="4">
                <span style={{ fontWeight: "bold" }}>Status por cor</span>
                <Select
                  styles={selectComponentStyles}
                  placeholder={"Selecione"}
                  isClearable={false}
                  options={trapColorStatusOptions}
                  value={trapsFilters.statusByColor}
                  onChange={(selectedOptionObject) =>
                    handleFiltersChange("statusByColor", selectedOptionObject)
                  }
                />
              </Col>
              <Col xl="4">
                <span style={{ fontWeight: "bold" }}>Tipo</span>
                <Select
                  styles={selectComponentStyles}
                  placeholder={"Selecione"}
                  isClearable={false}
                  options={[
                    {
                      label: "Todos",
                      value: "all",
                    },
                    ...formatedTrapTypeOptions,
                  ]}
                  value={trapsFilters.type}
                  onChange={(selectedOptionObject) =>
                    handleFiltersChange("type", selectedOptionObject)
                  }
                />
              </Col>
            </Row>
            <Row
              className="align-items-center"
              style={{ marginBottom: "1rem", padding: "0px 1.5rem" }}
            >
              <Col xl="4">
                <span style={{ fontWeight: "bold" }}>Quem instalou</span>
                <Select
                  styles={selectComponentStyles}
                  placeholder={"Selecione"}
                  isClearable={false}
                  options={[
                    {
                      label: "Todos",
                      value: "all",
                    },
                    ...formatedEmployeesOptions,
                  ]}
                  value={trapsFilters.installerId}
                  onChange={(selectedOptionObject) =>
                    handleFiltersChange("installerId", selectedOptionObject)
                  }
                />
              </Col>
              <Col xl="4">
                <span style={{ fontWeight: "bold" }}>Quem leu</span>
                <Select
                  styles={selectComponentStyles}
                  placeholder={"Selecione"}
                  isClearable={false}
                  options={[
                    {
                      label: "Todos",
                      value: "all",
                    },
                    ...formatedEmployeesOptions,
                  ]}
                  value={trapsFilters.readerId}
                  onChange={(selectedOptionObject) =>
                    handleFiltersChange("readerId", selectedOptionObject)
                  }
                />
              </Col>
              <Col xl="4">
                <span style={{ fontWeight: "bold" }}>Quem digitou</span>
                <Select
                  styles={selectComponentStyles}
                  placeholder={"Selecione"}
                  isClearable={false}
                  options={[
                    {
                      label: "Todos",
                      value: "all",
                    },
                    ...formatedEmployeesOptions,
                  ]}
                  value={trapsFilters.typistId}
                  onChange={(selectedOptionObject) =>
                    handleFiltersChange("typistId", selectedOptionObject)
                  }
                />
              </Col>
            </Row>
            <Row
              className="align-items-center"
              style={{ marginBottom: "1rem", padding: "0px 1.5rem" }}
            >
              <Col xl="4">
                <span style={{ fontWeight: "bold" }}>Rua</span>
                <input
                  type="text"
                  className="form-control"
                  value={trapsFilters.address}
                  onChange={({ target }) =>
                    handleFiltersChange("address", target.value)
                  }
                  placeholder="Digite o nome da rua"
                />
              </Col>
              <Col xl="4">
                <span style={{ fontWeight: "bold" }}>Data de digitação</span>
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      gap: "5%",
                    }}
                  >
                    <div style={{ width: "45%" }}>
                      <input
                        type="date"
                        value={trapsFilters.beginTypingDate}
                        onChange={({ target }) =>
                          handleFiltersChange("beginTypingDate", target.value)
                        }
                        className="form-control"
                        name="beginTypingDate"
                        id="beginTypingDate"
                      />
                    </div>
                    <div
                      className="h4 text-white"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <span style={{ fontWeight: "bold", color: "#525f7f" }}>
                        Até
                      </span>
                    </div>
                    <div style={{ width: "45%" }}>
                      <input
                        type="date"
                        value={trapsFilters.endTypingDate}
                        onChange={({ target }) =>
                          handleFiltersChange("endTypingDate", target.value)
                        }
                        className="form-control"
                        name="endTypingDate"
                        id="endTypingDate"
                      />
                    </div>
                  </div>
                </div>
              </Col>
              <Col xl="4">
                <span style={{ fontWeight: "bold" }}>Exibir armadilhas</span>
                <Select
                  styles={selectComponentStyles}
                  placeholder={"Selecione"}
                  isClearable={false}
                  options={trapStatusOptions}
                  value={trapsFilters.status}
                  onChange={(selectedOptionObject) =>
                    handleFiltersChange("status", selectedOptionObject)
                  }
                />
              </Col>
            </Row>
            <Row
              className="align-items-center"
              style={{ marginBottom: "1rem", padding: "0px 1.5rem" }}
            >
              <Col xl="4">
                <span style={{ fontWeight: "bold" }}>Data de instalação</span>
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      gap: "5%",
                    }}
                  >
                    <div style={{ width: "45%" }}>
                      <input
                        type="date"
                        value={trapsFilters.beginInstallDate}
                        onChange={({ target }) =>
                          handleFiltersChange("beginInstallDate", target.value)
                        }
                        className="form-control"
                        name="beginInstallDate"
                        id="beginInstallDate"
                      />
                    </div>
                    <div
                      className="h4 text-white"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <span style={{ fontWeight: "bold", color: "#525f7f" }}>
                        Até
                      </span>
                    </div>
                    <div style={{ width: "45%" }}>
                      <input
                        type="date"
                        value={trapsFilters.endInstallDate}
                        onChange={({ target }) =>
                          handleFiltersChange("endInstallDate", target.value)
                        }
                        className="form-control"
                        name="endInstallDate"
                        id="endInstallDate"
                      />
                    </div>
                  </div>
                </div>
              </Col>
              <Col xl="4">
                <span style={{ fontWeight: "bold" }}>Data de manutenção</span>
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      gap: "5%",
                    }}
                  >
                    <div style={{ width: "45%" }}>
                      <input
                        type="date"
                        value={trapsFilters.beginReadDate}
                        onChange={({ target }) =>
                          handleFiltersChange("beginReadDate", target.value)
                        }
                        className="form-control"
                        name="beginReadDate"
                        id="beginReadDate"
                      />
                    </div>
                    <div
                      className="h4 text-white"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <span style={{ fontWeight: "bold", color: "#525f7f" }}>
                        Até
                      </span>
                    </div>
                    <div style={{ width: "45%" }}>
                      <input
                        type="date"
                        value={trapsFilters.endReadDate}
                        onChange={({ target }) =>
                          handleFiltersChange("endReadDate", target.value)
                        }
                        className="form-control"
                        name="endReadDate"
                        id="endReadDate"
                      />
                    </div>
                  </div>
                </div>
              </Col>
              <Col xl="4">
                <span style={{ fontWeight: "bold" }}>
                  Data de desinstalação
                </span>
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      gap: "5%",
                    }}
                  >
                    <div style={{ width: "45%" }}>
                      <input
                        type="date"
                        value={trapsFilters.beginUninstallDate}
                        onChange={({ target }) =>
                          handleFiltersChange(
                            "beginUninstallDate",
                            target.value
                          )
                        }
                        className="form-control"
                        name="beginUninstallDate"
                        id="beginUninstallDate"
                      />
                    </div>
                    <div
                      className="h4 text-white"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <span style={{ fontWeight: "bold", color: "#525f7f" }}>
                        Até
                      </span>
                    </div>
                    <div style={{ width: "45%" }}>
                      <input
                        type="date"
                        value={trapsFilters.endUninstallDate}
                        onChange={({ target }) =>
                          handleFiltersChange("endUninstallDate", target.value)
                        }
                        className="form-control"
                        name="endUninstallDate"
                        id="endUninstallDate"
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row
              className="align-items-center"
              style={{ marginBottom: "2rem", padding: "0px 1.5rem" }}
            >
              {/* {organizationId === "559a16ab-5da8-41ba-96b8-6e9f55feddd8" && (
                <Col xl="4">
                  <span style={{ fontWeight: "bold" }}>UVIS</span>
                  <Select
                    styles={selectComponentStyles}
                    placeholder={"Selecione"}
                    isClearable={false}
                    options={[
                      {
                        label: "Todos",
                        value: "all",
                      },
                      ...insideTerritorializationOptions,
                    ]}
                    value={trapsFilters.insideTerritorialization}
                    onChange={(selectedOptionObject) =>
                      handleFiltersChange(
                        "insideTerritorialization",
                        selectedOptionObject
                      )
                    }
                  />
                </Col>
              )} */}
            </Row>
            <Row
              style={{
                textAlign: "center",
                marginBottom: "2rem",
                justifyContent: "center",
                gap: "4rem",
              }}
            >
              <Col xl={2}>
                <CsvDownloadButton
                  className="btn btn-primary"
                  data={formatedTrapsToExport}
                  filename={`armadilhas${moment().format("YYYY-MM-DD")}`}
                  style={{ width: "330px" }}
                >
                  <i className="fa fa-download" /> Baixar CSV das armadilhas
                </CsvDownloadButton>
              </Col>
              <Col xl={2}>
                {trapsFilters.status.value !== "notGeoreferenced" ? (
                  <>
                    <Button
                      disabled={true}
                      color="primary"
                      style={{ width: "330px" }}
                      data-tip
                      data-for="georeferenceTrapsButton"
                    >
                      <i className="fa fa-map-pin"></i> Corrigir armadilhas sem
                      coordenadas
                    </Button>
                    <ReactTooltip
                      effect="solid"
                      type="info"
                      id="georeferenceTrapsButton"
                    >
                      Exiba as armadilhas sem coordenadas antes
                    </ReactTooltip>
                  </>
                ) : (
                  <>
                    <Button
                      color="primary"
                      style={{ width: "330px" }}
                      onClick={() => toggleGeoreferenceTrapsModal()}
                      data-tip
                      data-for="georeferenceTrapsButton"
                    >
                      <i className="fa fa-map-pin"></i> Corrigir armadilhas sem
                      coordenadas
                    </Button>
                  </>
                )}
              </Col>
            </Row>
            <Row style={{ marginBottom: "1rem" }}>
              <Col xl="2"></Col>
              <Col xl="2"></Col>
              <Col xl="2"></Col>
              <Col xl="2"></Col>
              <Col xl="2"></Col>
              <Col
                xl="2"
                style={{
                  paddingRight: "40px",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <div>
                  <Button
                    id="Popover1"
                    color="primary"
                    type="button"
                    style={{ width: "130px" }}
                  >
                    Colunas <i className="fa fa-arrow-down"></i>
                  </Button>
                  <UncontrolledPopover flip placement="left" target="Popover1">
                    <PopoverBody>
                      <div>
                        {Object.keys(activeTableColumns).map(
                          (columnName, index) => {
                            return (
                              <button
                                className={styles.tableButtonPopover}
                                key={index}
                                onClick={() =>
                                  handleActiveColumnsChange(columnName)
                                }
                              >
                                {activeTableColumns[columnName] ? (
                                  <i class="fa fa-check"></i>
                                ) : (
                                  <div
                                    style={{
                                      width: "13.28px",
                                      height: "13.28px",
                                    }}
                                  ></div>
                                )}
                                <span style={{ textAlign: "left" }}>
                                  {columnName}
                                </span>
                              </button>
                            );
                          }
                        )}
                      </div>
                      {/* <Button
                        color="primary"
                        type="button"
                        onClick={() => console.log("Hello World")}
                      ></Button> */}
                    </PopoverBody>
                  </UncontrolledPopover>
                </div>
              </Col>
            </Row>
            <DataTable
              noHeader
              defaultSortField="name"
              defaultSortAsc={true}
              pagination
              highlightOnHover
              responsive
              columns={
                columnsByOrganization[organizationId]
                  ? columnsByOrganization[organizationId]
                  : columns
              }
              data={filteredTraps}
              noDataComponent={"Nenhum registro encontrado."}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default DashboardTrapsTable;
