import React from "react";
import { Col, Row } from "reactstrap";
import { Select } from "../../Wrappers/SelectAll";
import "./GeographicFilter.css";
import { getUserData } from "../../../services/utils/auth";
import { getVersion } from "../../../constants/VersionConstant";
import {
  NOT_PARTICIPANTS_SCHOOLS_DATA,
  PARTICIPANTS_SCHOOLS_DATA,
} from "../../../constants/SchoolsConstant";

// reactstrap components

const GeographicFilterPresentational = (props) => {
  var organizationId = getUserData("organizationId");
  const [territorializations, SelectedTerritorializations] = React.useState([]);

  const changeTerritorializationSamp = (partOfTerritorialization) => {
    var copyTerritorializations = territorializations
      ? JSON.parse(JSON.stringify(territorializations))
      : [];

    if (partOfTerritorialization && partOfTerritorialization.length > 0) {
      var partOfTerritorializationToPush = partOfTerritorialization.filter(
        (pt) => {
          return !territorializations.some((terr) => terr.value === pt.value);
        }
      );

      copyTerritorializations.push(...partOfTerritorializationToPush);
    } else {
      if (partOfTerritorialization.length > 0) {
        copyTerritorializations.push(partOfTerritorialization[0]);
      }
    }

    SelectedTerritorializations(copyTerritorializations);

    props.handleChangeFilter("territorialization", copyTerritorializations);
  };

  const selectStyles = {
    control: (styles) => ({
      ...styles,
      minHeight: "2.90rem",
      borderRadius: "0.370rem",
    }),
    option: (styles) => ({ ...styles, fontSize: "0.875rem" }),
    valueContainer: (styles) => ({
      ...styles,
      fontSize: "0.875rem",
      color: "#8898aa",
    }),
    singleValue: (styles) => ({
      ...styles,
      fontSize: "0.875rem",
      color: "#8898aa",
    }),
    placeholder: (styles) => ({
      ...styles,
      fontSize: "0.875rem",
      color: "#8898aa",
    }),
    menuPortal: (styles) => ({ ...styles, zIndex: 9999 }),
  };

  const schoolTypesOptions = [
    { label: "Participantes", value: "eb3d927c-2ef0-41ab-91fd-7917f32486e8" },
    {
      label: "Não Participantes",
      value: "79d665fe-ac45-4016-a709-b0a31b3019c5",
    },
  ];

  return (
    <>
      {organizationId === "559a16ab-5da8-41ba-96b8-6e9f55feddd8" &&
      props.trapsPageFilters != null ? (
        <>
          {
            <>
              <Col className="linha-form col-md-4 geo-responsive-filter">
                <span className="h4 text-white">Territorialização</span>
                <Select
                  styles={selectStyles}
                  placeholder={"Selecione"}
                  isClearable={true}
                  options={props.Territorializations}
                  value={props.SelectedTerritorializations}
                  isMulti={true}
                  blurInputOnSelect={false}
                  closeMenuOnSelect={false}
                  onChange={(e) => {
                    props.handleChangeFilter("territorialization", e);
                  }}
                  menuPortalTarget={document.body}
                />
              </Col>
              {/* <Col className="linha-form col-md-4 geo-responsive-filter">
                <span className="h4 text-white">Áreas Projeto</span>
                <Select
                  styles={selectStyles}
                  placeholder={"Selecione"}
                  isClearable={true}
                  options={props.Territorializations.filter((t) =>
                    t.name.includes("Áreas Selecionadas")
                  )}
                  isMulti={true}
                  blurInputOnSelect={false}
                  closeMenuOnSelect={false}
                  onChange={(e) => {
                    props.handleChangeFilter("territorialization", e);
                  }}
                  menuPortalTarget={document.body}
                />
              </Col>
              <Col className="linha-form col-md-4 geo-responsive-filter">
                <span className="h4 text-white">Escola</span>
                <Select
                  styles={selectStyles}
                  placeholder={"Selecione"}
                  isClearable={true}
                  options={schoolTypesOptions}
                  isMulti={false}
                  blurInputOnSelect={false}
                  closeMenuOnSelect={false}
                  onChange={(e) =>
                    props.handleFiltersChange(
                      "selectedSchoolType",
                      !e || e.length === 0
                        ? {
                            label: "Selecione",
                            value: "00000000-0000-0000-0000-000000000000",
                          }
                        : e
                    )
                  }
                  menuPortalTarget={document.body}
                />
              </Col>
              <Col className="linha-form col-md-4 geo-responsive-filter">
                <span className="h4 text-white">Tipo de Escola</span>
                <Select
                  styles={selectStyles}
                  placeholder={"Selecione"}
                  isClearable={true}
                  options={
                    props.trapsPageFilters.selectedSchoolType.label ===
                    "Participantes"
                      ? PARTICIPANTS_SCHOOLS_DATA
                      : NOT_PARTICIPANTS_SCHOOLS_DATA
                  }
                  isMulti={false}
                  blurInputOnSelect={false}
                  closeMenuOnSelect={false}
                  onChange={(e) =>
                    props.handleFiltersChange(
                      "selectedTypeLayerSubtypeOption",
                      !e || e.length === 0
                        ? {
                            label: "Selecione",
                            value: "00000000-0000-0000-0000-000000000000",
                          }
                        : e
                    )
                  }
                  menuPortalTarget={document.body}
                />
              </Col> */}
              <Col className="linha-form col-md-3 geo-responsive-filter">
                <span className="h4 text-white">CRS</span>
                <Select
                  styles={selectStyles}
                  placeholder={"Selecione"}
                  isClearable={true}
                  options={
                    props.Territorializations &&
                    props.Territorializations.length > 0
                      ? props.Territorializations.find(
                          ({ name }) => name === "CRS"
                        ).options
                      : []
                  }
                  isMulti={true}
                  blurInputOnSelect={false}
                  closeMenuOnSelect={false}
                  onChange={(e) => {
                    props.handleChangeFilter("territorialization", e);
                  }}
                  menuPortalTarget={document.body}
                />
              </Col>
              <Col className="linha-form col-md-3 geo-responsive-filter">
                <span className="h4 text-white">UVIS</span>
                <Select
                  styles={selectStyles}
                  placeholder={"Selecione"}
                  isClearable={true}
                  options={
                    props.Territorializations &&
                    props.Territorializations.length > 0
                      ? props.Territorializations.find(
                          ({ name }) => name === "UVIS"
                        ).options
                      : []
                  }
                  isMulti={true}
                  blurInputOnSelect={false}
                  closeMenuOnSelect={false}
                  onChange={(e) => {
                    props.handleChangeFilter("territorialization", e);
                  }}
                  menuPortalTarget={document.body}
                />
              </Col>
              <Col className="linha-form col-md-3 geo-responsive-filter">
                <span className="h4 text-white">
                  Áreas Selecionadas Etapa 1 Ca
                </span>
                <Select
                  styles={selectStyles}
                  placeholder={"Selecione"}
                  isClearable={true}
                  options={
                    props.Territorializations &&
                    props.Territorializations.length > 0
                      ? props.Territorializations.find(
                          ({ name }) => name === "Áreas Selecionadas Etapa 1 Ca"
                        ).options
                      : []
                  }
                  isMulti={true}
                  blurInputOnSelect={false}
                  closeMenuOnSelect={false}
                  onChange={(e) => {
                    props.handleChangeFilter("territorialization", e);
                  }}
                  menuPortalTarget={document.body}
                />
              </Col>
              <Col className="linha-form col-md-3 geo-responsive-filter">
                <span className="h4 text-white">
                  Áreas Selecionadas Etapa 1 Co
                </span>
                <Select
                  styles={selectStyles}
                  placeholder={"Selecione"}
                  isClearable={true}
                  options={
                    props.Territorializations &&
                    props.Territorializations.length > 0
                      ? props.Territorializations.find(
                          ({ name }) => name === "Áreas Selecionadas Etapa 1 Co"
                        ).options
                      : []
                  }
                  isMulti={true}
                  blurInputOnSelect={false}
                  closeMenuOnSelect={false}
                  onChange={(e) => {
                    props.handleChangeFilter("territorialization", e);
                  }}
                  menuPortalTarget={document.body}
                />
              </Col>
              <Col className="linha-form col-md-3 geo-responsive-filter">
                <span className="h4 text-white">
                  Áreas Selecionadas Etapa 2
                </span>
                <Select
                  styles={selectStyles}
                  placeholder={"Selecione"}
                  isClearable={true}
                  options={
                    props.Territorializations &&
                    props.Territorializations.length > 0
                      ? props.Territorializations.find(
                          ({ name }) => name === "Áreas Selecionadas Etapa 2"
                        ).options
                      : []
                  }
                  isMulti={true}
                  blurInputOnSelect={false}
                  closeMenuOnSelect={false}
                  onChange={(e) => {
                    props.handleChangeFilter("territorialization", e);
                  }}
                  menuPortalTarget={document.body}
                />
              </Col>
              <Col className="linha-form col-md-3 geo-responsive-filter">
                <span className="h4 text-white">Escolas Participantes</span>
                <Select
                  styles={selectStyles}
                  placeholder={"Selecione"}
                  isClearable={true}
                  options={PARTICIPANTS_SCHOOLS_DATA}
                  isMulti={true}
                  blurInputOnSelect={false}
                  closeMenuOnSelect={false}
                  onChange={(e) => {
                    props.handleChangeFilter("territorialization", e);
                  }}
                  menuPortalTarget={document.body}
                />
              </Col>
              <Col className="linha-form col-md-3 geo-responsive-filter">
                <span className="h4 text-white">Escolas Não Participantes</span>
                <Select
                  styles={selectStyles}
                  placeholder={"Selecione"}
                  isClearable={true}
                  options={NOT_PARTICIPANTS_SCHOOLS_DATA}
                  isMulti={true}
                  blurInputOnSelect={false}
                  closeMenuOnSelect={false}
                  onChange={(e) => {
                    props.handleChangeFilter("territorialization", e);
                  }}
                  menuPortalTarget={document.body}
                />
              </Col>
              {/* <Col className="linha-form col-md-3 geo-responsive-filter">
                <span className="h4 text-white">Município</span>
                <Select
                  styles={selectStyles}
                  placeholder={"Selecione"}
                  isClearable={true}
                  options={
                    props.Territorializations && props.Territorializations.length > 0
                      ? props.Territorializations.find(
                          ({ name }) => name === "Município"
                        ).options
                      : []
                  }
                  isMulti={true}
                  blurInputOnSelect={false}
                  closeMenuOnSelect={false}
                  onChange={(e) => {
                    props.handleChangeFilter("territorialization", e);
                  }}
                  menuPortalTarget={document.body}
                />
              </Col> */}
              <Col className="linha-form col-md-3 geo-responsive-filter">
                <span className="h4 text-white">Setor Ambiental</span>
                <Select
                  styles={selectStyles}
                  placeholder={"Selecione"}
                  isClearable={true}
                  options={
                    props.Territorializations &&
                    props.Territorializations.length > 0
                      ? props.Territorializations.find(
                          ({ name }) => name === "Setor Ambiental"
                        ).options
                      : []
                  }
                  isMulti={true}
                  blurInputOnSelect={false}
                  closeMenuOnSelect={false}
                  onChange={(e) => {
                    props.handleChangeFilter("territorialization", e);
                  }}
                  menuPortalTarget={document.body}
                />
              </Col>
              {/*  
                {
                  props.Territorializations.map((typeTerritorialization) => {
                      if(typeTerritorialization.name == "CRS" || 
                          typeTerritorialization.name == "UVIS" ||
                          typeTerritorialization.name == "Quadra" ||
                          typeTerritorialization.name == "Setor Ambiental"
                      ) {
                          return <Col className="linha-form col-md-4 geo-responsive-filter">
                              <span className="h4 text-white">{typeTerritorialization.name}</span>
                              <Select 
                                  styles={selectStyles}
                                  placeholder={"Selecione"}
                                  isClearable={true}
                                  options={typeTerritorialization.options}
                                  // value={props.SelectedTerritorializations}
                                  isMulti={true}
                                  blurInputOnSelect={false}
                                  closeMenuOnSelect={false}
                                  onChange={(e) => {changeTerritorializationSamp(e)}}
                                  menuPortalTarget={document.body}
                              />
                          </Col>;
                      } 
                  })
                } */}
            </>
          }
        </>
      ) : (
        <>
          <Col className="linha-form col-md-4 geo-responsive-filter">
            <span className="h4 text-white">Territorialização</span>
            <Select
              styles={selectStyles}
              placeholder={"Selecione"}
              isClearable={true}
              options={props.Territorializations}
              value={props.SelectedTerritorializations}
              isMulti={true}
              blurInputOnSelect={false}
              closeMenuOnSelect={false}
              onChange={(e) => {
                props.handleChangeFilter("territorialization", e);
              }}
              menuPortalTarget={document.body}
            />
          </Col>
          {getUserData("organizationId") ===
            "8e34767e-3673-4606-9e47-dd67edd2677d" && props.trapsPageFilters && props.trapsPageFilters.selectedProjects && (
            <Col className="linha-form col-md-4 geo-responsive-filter">
              <span className="h4 text-white">Atividades</span>
              <Select
                styles={selectStyles}
                placeholder={"Selecione"}
                isClearable={true}
                options={props.projectsList}
                value={props.trapsPageFilters.selectedProjects}
                isMulti={true}
                blurInputOnSelect={false}
                closeMenuOnSelect={false}
                onChange={(e) => {
                  props.handleFiltersChange(
                    "selectedProjects",
                    !e || e.length === 0 ? [] : e
                  );
                }}
                menuPortalTarget={document.body}
              />
            </Col>
          )}
        </>
      )}
    </>
  );
};

export default GeographicFilterPresentational;
