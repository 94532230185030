import { useContextSelector } from "use-context-selector";
import { OrganizationContext } from "../contexts/organizationContext";

const useTeamsList = () => {
  const teamsList = useContextSelector(
    OrganizationContext,
    (context) => context.teamsList
  );
  const setTeamsList = useContextSelector(
    OrganizationContext,
    (context) => context.setTeamsList
  );

  return { teamsList, setTeamsList };
};

export default useTeamsList;
