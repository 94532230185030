import React from "react";

import { Col, Row } from "reactstrap";

import VisitsMap from "./VisitsMap";

import BasicLineChartGraphicWrapper from "./Line/BasicLineChartGraphicWrapper";
import GoalByDayGraphicWrapper from "./GoalByDayGraphicWrapper";
import BarChartGraphic from "../../../../components/Graphics/Bar/BarChartGraphic";
import { Bar, Rectangle } from "recharts";
import PieChartGraphic from "../../../../components/Graphics/Pie/PieChartGraphic";
import moment from "moment";

const ProductivityGraphicsWrapper = ({
  fieldProductivityData,
  typingProductivityData,
  visitsByStatusData,
  consolidatedVisits,
  productivityFilters,
  employeesList,
  territorializationsData,
  goalsData,
}) => {
  const [formatedProductivityData, setFormatedProductivityData] =
    React.useState({
      fieldProductivity: [],
      typingProductivity: [],
    });
  const [formatedVisitsData, setFormatedVisitsData] = React.useState([]);
  const [formatedDataByFieldAndTyping, setFormatedDataByFieldAndTyping] =
    React.useState(null);

  React.useEffect(() => {
    if (!fieldProductivityData || !employeesList || employeesList.length === 0)
      return;

    const { productivitiesByUser: fieldProductivityByUsers } =
      fieldProductivityData;
    const { productivitiesByUser: typingProductivityByUsers } =
      typingProductivityData;

    let fieldProductivityFormatedData = [];
    let typingProductivityFormatedData = [];

    for (let i = 0; i < 10; i++) {
      const fieldUser = fieldProductivityByUsers[i];
      const typingUser = typingProductivityByUsers[i];

      const fieldUserFullName = getUserName(
        fieldUser ? fieldUser.userId : null
      );
      const typingUserFullName = getUserName(
        typingUser ? typingUser.userId : null
      );

      const userFieldResult = {
        firstName: capitalizeString(fieldUserFullName.split(" ")[0]),
        userFullName: fieldUserFullName,
        Instalações: fieldUser ? fieldUser.totalInstalls : 0,
        Manutenções: fieldUser ? fieldUser.totalMaintenances : 0,
        Desinstalações: fieldUser ? fieldUser.totalUninstalls : 0,
        "Não lidas": fieldUser ? fieldUser.totalNotRead : 0,
        amt: fieldUser ? fieldUser.amount : 0,
      };

      const typingProductivityResult = {
        firstName: capitalizeString(typingUserFullName.split(" ")[0]),
        userFullName: typingUserFullName,
        Instalações: typingUser ? typingUser.totalInstalls : 0,
        Manutenções: typingUser ? typingUser.totalMaintenances : 0,
        Desinstalações: typingUser ? typingUser.totalUninstalls : 0,
        "Não lidas": typingUser ? typingUser.totalNotRead : 0,
        amt: typingUser ? typingUser.amount : 0,
      };

      fieldProductivityFormatedData.push(userFieldResult);
      typingProductivityFormatedData.push(typingProductivityResult);
    }

    setFormatedProductivityData({
      fieldProductivity: fieldProductivityFormatedData,
      typingProductivity: typingProductivityFormatedData,
    });
  }, [fieldProductivityData, typingProductivityData, employeesList]);

  React.useEffect(() => {
    if (!visitsByStatusData || visitsByStatusData.length === 0) return;

    const formatedVisitsResult = {};

    visitsByStatusData.forEach(({ situation, visitsData }) => {
      visitsData.forEach((visit) => {
        if (!formatedVisitsResult[visit.collectorId]) {
          formatedVisitsResult[visit.collectorId] = {
            collectorId: visit.collectorId,
            name: getUserName(visit.collectorId),
            visits: [],
            situationsCount: {
              Aberto: 0,
              Fechado: 0,
              "Endereço Não Encontrado": 0,
              Recusa: 0,
              Orientação: 0,
            },
          };
        }

        formatedVisitsResult[visit.collectorId].visits.push({
          situation,
          ...visit,
        });

        if (formatedVisitsResult[visit.collectorId])
          formatedVisitsResult[visit.collectorId].situationsCount[
            situation
          ] += 1;
      });
    });

    const result = Object.values(formatedVisitsResult);

    result.sort(
      (a, b) =>
        getTotalSituationCount(b.situationsCount) -
        getTotalSituationCount(a.situationsCount)
    );

    const firstTenResults = [];

    for (let i = 0; i < 10; i++) {
      const actualUser = result[i];
      const actualUserSituationsCount =
        actualUser && actualUser.situationsCount
          ? actualUser.situationsCount
          : {
              Aberto: 0,
              Fechado: 0,
              "Endereço Não Encontrado": 0,
              Recusa: 0,
              Orientação: 0,
            };

      firstTenResults.push({
        collectorId:
          actualUser && actualUser.collectorId ? actualUser.collectorId : "",
        firstName:
          actualUser && actualUser.name ? actualUser.name.split(" ")[0] : "",
        userFullName: actualUser && actualUser.name ? actualUser.name : "",
        ...actualUserSituationsCount,
      });
    }

    setFormatedVisitsData(firstTenResults);
  }, [visitsByStatusData]);

  React.useEffect(() => {
    if (!fieldProductivityData || !typingProductivityData) return;

    const result = {};

    const fieldInstalls = {
      situation: "Aplicativo",
      quantity: fieldProductivityData.totalInstalledTrapsOnField
        ? fieldProductivityData.totalInstalledTrapsOnField
        : 0,
    };
    const fieldMaintenances = {
      situation: "Aplicativo",
      quantity: fieldProductivityData.totalMaintenancesTrapsOnField
        ? fieldProductivityData.totalMaintenancesTrapsOnField
        : 0,
    };
    const fieldUninstalls = {
      situation: "Aplicativo",
      quantity: fieldProductivityData.totalUninstalledTrapsOnField
        ? fieldProductivityData.totalUninstalledTrapsOnField
        : 0,
    };

    const typingInstalls = {
      situation: "Digitação",
      quantity: typingProductivityData.totalInstalls
        ? typingProductivityData.totalInstalls
        : 0,
    };
    const typingMaintenances = {
      situation: "Digitação",
      quantity: typingProductivityData.totalMaintenances
        ? typingProductivityData.totalMaintenances
        : 0,
    };
    const typingUninstalls = {
      situation: "Digitação",
      quantity: typingProductivityData.totalUninstalls
        ? typingProductivityData.totalUninstalls
        : 0,
    };

    result["fieldInstalls"] = fieldInstalls;
    result["fieldMaintenances"] = fieldMaintenances;
    result["fieldUninstalls"] = fieldUninstalls;

    result["typingInstalls"] = typingInstalls;
    result["typingMaintenances"] = typingMaintenances;
    result["typingUninstalls"] = typingUninstalls;

    setFormatedDataByFieldAndTyping(result);
  }, [fieldProductivityData, typingProductivityData]);

  const capitalizeString = (string) => {
    if (!string || string === "") return "";

    const loweCaseString = string.toLowerCase();

    return loweCaseString[0].toUpperCase() + loweCaseString.slice(1);
  };

  const getUserName = (id) => {
    if (
      id === "00000000-0000-0000-0000-000000000000" ||
      id === null ||
      id === undefined
    )
      return "";

    if (id === "5a868c02-7a8d-47a0-b1b1-bb55383ad55d") return "Não informado";

    const userData = employeesList.find(
      (employee) => employee.employeeId === id || employee.userId === id
    );

    return userData ? userData.employeeName : "";
  };

  const getTotalSituationCount = (situationCount) => {
    return Object.values(situationCount).reduce((sum, count) => sum + count, 0);
  };

  const beginDateFormated =
    productivityFilters &&
    productivityFilters.beginDate &&
    moment(productivityFilters.beginDate).format("DD/MM/YYYY");
  const finalDateFormated =
    productivityFilters &&
    productivityFilters.finalDate &&
    moment(productivityFilters.finalDate).format("DD/MM/YYYY");

  return (
    <>
      <Row>
        <Col className="col-6 mb-4 responsive-field-sinan-medium">
          <BarChartGraphic
            bars={[
              <Bar
                dataKey="Instalações"
                fill="#8884d8"
                activeBar={<Rectangle fill="pink" stroke="blue" />}
              />,
              <Bar
                dataKey="Manutenções"
                fill="#82ca9d"
                activeBar={<Rectangle fill="gold" stroke="purple" />}
              />,
              <Bar
                dataKey="Desinstalações"
                fill="#4181f7"
                activeBar={<Rectangle fill="gold" stroke="purple" />}
              />,
              <Bar
                dataKey="Não lidas"
                fill="#fa4c35"
                activeBar={<Rectangle fill="gold" stroke="purple" />}
              />,
            ]}
            customTooltip={<CustomTooltip />}
            data={formatedProductivityData.fieldProductivity}
            headerText={`Usuários que mais realizaram atividades no período (em campo) em ${beginDateFormated} a ${finalDateFormated}`}
            xAxisDataKey="firstName"
          />
        </Col>
        <Col className="col-6 mb-4 responsive-field-sinan-medium">
          <BarChartGraphic
            bars={[
              <Bar
                dataKey="Instalações"
                fill="#8884d8"
                activeBar={<Rectangle fill="pink" stroke="blue" />}
              />,
              <Bar
                dataKey="Manutenções"
                fill="#82ca9d"
                activeBar={<Rectangle fill="gold" stroke="purple" />}
              />,
              <Bar
                dataKey="Desinstalações"
                fill="#4181f7"
                activeBar={<Rectangle fill="gold" stroke="purple" />}
              />,
              <Bar
                dataKey="Não lidas"
                fill="#fa4c35"
                activeBar={<Rectangle fill="gold" stroke="purple" />}
              />,
            ]}
            customTooltip={<CustomTooltip />}
            data={formatedProductivityData.typingProductivity}
            headerText={`Usuários que mais digitaram armadilhas no período (pela WEB) em ${beginDateFormated} a ${finalDateFormated}`}
            xAxisDataKey="firstName"
          />
        </Col>
      </Row>
      <Row style={{ justifyContent: "center" }}>
        <Col className="col-6 mb-4 responsive-field-sinan-medium">
          <PieChartGraphic
            data={visitsByStatusData}
            dataKey="quantity"
            headerText={`Situação das visitas no período em ${beginDateFormated} a ${finalDateFormated}`}
            legendsKey="situation"
          />
        </Col>
        <Col className="col-6 mb-4 responsive-field-sinan-medium">
          <VisitsMap
            headerText={`Pontos das visitas no período em ${beginDateFormated} a ${finalDateFormated}`}
            data={visitsByStatusData}
            employees={employeesList}
            territorializations={territorializationsData}
          />
        </Col>
      </Row>
      <Row>
        <Col className="col-4 mb-4 responsive-field-sinan-medium">
          <PieChartGraphic
            data={
              formatedDataByFieldAndTyping !== null
                ? [
                    { ...formatedDataByFieldAndTyping.fieldInstalls },
                    { ...formatedDataByFieldAndTyping.typingInstalls },
                  ]
                : []
            }
            dataKey="quantity"
            headerText={`Instalação Aplicativo X Digitação em ${beginDateFormated} a ${finalDateFormated}`}
            legendsKey="situation"
          />
        </Col>
        <Col className="col-4 mb-4 responsive-field-sinan-medium">
          <PieChartGraphic
            data={
              formatedDataByFieldAndTyping !== null
                ? [
                    { ...formatedDataByFieldAndTyping.fieldMaintenances },
                    { ...formatedDataByFieldAndTyping.typingMaintenances },
                  ]
                : []
            }
            dataKey="quantity"
            headerText={`Manutenção Aplicativo X Digitação em ${beginDateFormated} a ${finalDateFormated}`}
            legendsKey="situation"
          />
        </Col>
        <Col className="col-4 mb-4 responsive-field-sinan-medium">
          <PieChartGraphic
            data={
              formatedDataByFieldAndTyping !== null
                ? [
                    { ...formatedDataByFieldAndTyping.fieldUninstalls },
                    { ...formatedDataByFieldAndTyping.typingUninstalls },
                  ]
                : []
            }
            dataKey="quantity"
            headerText={`Desinstalação Aplicativo X Digitação em ${beginDateFormated} a ${finalDateFormated}`}
            legendsKey="situation"
          />
        </Col>
      </Row>
      <Row>
        <Col className="col-12 mb-4 responsive-field-sinan-medium">
          <BasicLineChartGraphicWrapper
            data={consolidatedVisits}
            employees={employeesList}
            productivityFilters={productivityFilters}
            headerText={`Número de atividades por dia no período em ${beginDateFormated} a ${finalDateFormated}`}
          />
        </Col>
      </Row>
      <Row style={{ justifyContent: "center" }}>
        <Col className="col-12 mb-4 responsive-field-sinan-medium">
          <BarChartGraphic
            bars={[
              <Bar
                dataKey="Aberto"
                fill="#0088FE"
                activeBar={<Rectangle fill="pink" stroke="blue" />}
              />,
              <Bar
                dataKey="Fechado"
                fill="#00C49F"
                activeBar={<Rectangle fill="gold" stroke="purple" />}
              />,
              <Bar
                dataKey="Endereço Não Encontrado"
                fill="#FFBB28"
                activeBar={<Rectangle fill="gold" stroke="purple" />}
              />,
              <Bar
                dataKey="Recusa"
                fill="#FF8042"
                activeBar={<Rectangle fill="gold" stroke="purple" />}
              />,
              <Bar
                dataKey="Orientação"
                fill="#A19E9C"
                activeBar={<Rectangle fill="gold" stroke="purple" />}
              />,
            ]}
            data={formatedVisitsData}
            headerText={`Usuários que mais realizaram visitas no período (em campo) em ${beginDateFormated} a ${finalDateFormated}`}
            xAxisDataKey="firstName"
            customTooltip={<CustomTooltip />}
          />
        </Col>
      </Row>
      <Row>
        <Col className="col-12 mb-4 responsive-field-sinan-medium">
          <GoalByDayGraphicWrapper
            goalsData={goalsData}
            productivityFilters={productivityFilters}
          />
        </Col>
      </Row>
    </>
  );
};

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const fullName = payload[0].payload.userFullName;

    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#fff",
          border: "1px solid #ccc",
          padding: "10px",
        }}
      >
        <p className="label">{fullName}</p>
        {payload.map((entry, index) => (
          <p
            key={`item-${index}`}
            style={{ color: entry.color }}
          >{`${entry.name}: ${entry.value}`}</p>
        ))}
      </div>
    );
  }

  return null;
};

export default ProductivityGraphicsWrapper;
