import React from "react";

import { Card, CardBody, CardText, Col, Row } from "reactstrap";
import { getTrapRange } from "../../../../constants/RangeTrapConstant";
import {
  GREEN_TRAP_STATUS,
  INACTIVE_TRAP_STATUS,
  RED_TRAP_STATUS,
  YELLOW_TRAP_STATUS,
} from "../../../../services/utils/globalFunctions";
import useAppliedFilters from "../Hooks/useAppliedFilters";
import useTrapsList from "../../../../hooks/useTrapsList";

const styles = {
  cardBody: {
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  cardText: { fontWeight: "bold", marginBottom: "0px" },
};

const useTrapsSituationByColorCardsWrapper = () => {
  const [trapTypeRange, setTrapTypeRange] = React.useState({
    quantityRange: 3,
    data: {
      middle: -1,
      end: -1,
    },
  });
  const [trapsSituationsByColors, setTrapsSituationsByColors] = React.useState({
    green: 0,
    yellow: 0,
    red: 0,
    white: 0,
  });
  const { appliedFilters } = useAppliedFilters();
  const { trapsList } = useTrapsList();

  React.useEffect(() => {
    if (
      !trapsList ||
      trapsList.length === 0 ||
      !appliedFilters ||
      !appliedFilters.trapType ||
      appliedFilters.trapType.value === ""
    )
      return;

    const situationsByColors2 = {
      green: 0,
      yellow: 0,
      red: 0,
      white: 0,
    };

    for (let i = 0; i < trapsList.length; i++) {
      const {
        status,
        colorStatus,
        trapType: { name },
      } = trapsList[i];

      if (
        status === INACTIVE_TRAP_STATUS ||
        name !== appliedFilters.trapType.trapTypeName
      )
        continue;

      if (colorStatus === GREEN_TRAP_STATUS) {
        situationsByColors2.green += 1;
        continue;
      }

      if (colorStatus === YELLOW_TRAP_STATUS) {
        situationsByColors2.yellow += 1;
        continue;
      }

      if (colorStatus === RED_TRAP_STATUS) {
        situationsByColors2.red += 1;
        continue;
      }
    }

    const trapRange = getTrapRange(appliedFilters.trapType.trapTypeName);

    setTrapsSituationsByColors(situationsByColors2);
    setTrapTypeRange(trapRange);
  }, [trapsList, appliedFilters]);

  return {
    appliedFilters,
    trapsSituationsByColors,
    trapTypeRange,
  };
};

const TrapsSituationByColorCardsWrapper = () => {
  const {
    appliedFilters,
    trapTypeRange,
    trapsSituationsByColors,
  } = useTrapsSituationByColorCardsWrapper();

  return (
    <Row className="mb-4" style={{ justifyContent: "center" }}>
      {trapTypeRange && trapTypeRange.quantityRange === 2 ? (
        <>
          {appliedFilters.colorStatuses.find(
            ({ value }) => value === GREEN_TRAP_STATUS
          ) && (
            <Col className="col-md-3 visit-responsive-filter">
              <GreenStatusCard
                trapTypeRange={trapTypeRange}
                value={trapsSituationsByColors.green}
              />
            </Col>
          )}
          {appliedFilters.colorStatuses.find(
            ({ value }) => value === RED_TRAP_STATUS
          ) && (
            <Col className="col-md-3 visit-responsive-filter">
              <RedStatusCard
                trapTypeRange={trapTypeRange}
                value={trapsSituationsByColors.red}
              />
            </Col>
          )}
        </>
      ) : trapTypeRange && trapTypeRange.quantityRange === 3 ? (
        <>
          {appliedFilters.colorStatuses.find(
            ({ value }) => value === GREEN_TRAP_STATUS
          ) && (
            <Col className="col-md-3 visit-responsive-filter">
              <GreenStatusCard
                trapTypeRange={trapTypeRange}
                value={trapsSituationsByColors.green}
              />
            </Col>
          )}
          {appliedFilters.colorStatuses.find(
            ({ value }) => value === YELLOW_TRAP_STATUS
          ) && (
            <Col className="col-md-3 visit-responsive-filter">
              <YellowStatusCard
                trapTypeRange={trapTypeRange}
                value={trapsSituationsByColors.yellow}
              />
            </Col>
          )}
          {appliedFilters.colorStatuses.find(
            ({ value }) => value === RED_TRAP_STATUS
          ) && (
            <Col className="col-md-3 visit-responsive-filter">
              <RedStatusCard
                trapTypeRange={trapTypeRange}
                value={trapsSituationsByColors.red}
              />
            </Col>
          )}
        </>
      ) : null}
    </Row>
  );
};

const GreenStatusCard = ({ trapTypeRange, value }) => {
  return (
    <Card className="card-stats" style={{ height: "8rem" }}>
      <CardBody style={styles.cardBody}>
        <CardText style={styles.cardText}>{value}</CardText>
        <CardText style={{ fontSize: ".9rem" }}>
          <span>
            Instalação ou manutenção recém realizada
            <br />
            (até{" "}
            {trapTypeRange && trapTypeRange.quantityRange === 2
              ? trapTypeRange.data.end
              : trapTypeRange.data.middle}{" "}
            dias)
            <span
              className="badge badge-success"
              style={{
                width: "50px",
                marginLeft: "5px",
                backgroundColor: "#76ea79",
              }}
            >
              &nbsp;
            </span>
          </span>
        </CardText>
      </CardBody>
    </Card>
  );
};

const YellowStatusCard = ({ trapTypeRange, value }) => {
  return (
    <Card className="card-stats" style={{ height: "8rem" }}>
      <CardBody style={styles.cardBody}>
        <CardText style={styles.cardText}>{value}</CardText>
        <CardText style={{ fontSize: ".9rem" }}>
          <span>
            Atenção para próxima manutenção
            <br />({trapTypeRange && trapTypeRange.data.middle + 1} a{" "}
            {trapTypeRange && trapTypeRange.data.end} dias)
            <span
              className="badge badge-success"
              style={{
                width: "50px",
                marginLeft: "5px",
                backgroundColor: "#fff200",
              }}
            >
              &nbsp;
            </span>
          </span>
        </CardText>
      </CardBody>
    </Card>
  );
};

const RedStatusCard = ({ trapTypeRange, value }) => {
  return (
    <Card className="card-stats" style={{ height: "8rem" }}>
      <CardBody style={styles.cardBody}>
        <CardText style={styles.cardText}>{value}</CardText>
        <CardText style={{ fontSize: ".9rem" }}>
          <span>
            Ultrapassou o prazo de manutenção
            <br />
            (acima de {trapTypeRange && trapTypeRange.data.end} dias)
            <span
              className="badge badge-danger"
              style={{
                width: "50px",
                marginLeft: "5px",
                backgroundColor: "#ff0000",
              }}
            >
              &nbsp;
            </span>
          </span>
        </CardText>
      </CardBody>
    </Card>
  );
};

export default TrapsSituationByColorCardsWrapper;
