import { useContextSelector } from "use-context-selector";
import { TrapsPageContext } from "../Contexts/trapsPageContext";

const useAppliedFilters = () => {
  const appliedFilters = useContextSelector(
    TrapsPageContext,
    (context) => context.appliedFilters
  );
  const setAppliedFilters = useContextSelector(
    TrapsPageContext,
    (context) => context.setAppliedFilters
  );

  return {
    appliedFilters,
    setAppliedFilters,
  };
};

export default useAppliedFilters;
