import React from "react";

import { Bar, Line, Rectangle } from "recharts";
import GoalByUsersModal from "../Modal/Goal/GoalByUsersModal";
import ComposedChartGraphic from "../../../../../components/Graphics/Composed/ComposedChartGraphic";
import VerticalChartGraphic from "../../../../../components/Graphics/Composed/VerticalChartGraphic";

const GoalByDayComposedChartGraphic = ({
  goalsByDayData,
  goalsByUserData,
  headerText,
}) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [selectedDayData, setSelectedDayData] = React.useState(null);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const openModal = (dayData) => {
    setIsModalOpen(true);
    setSelectedDayData(dayData);
  };

  const CustomTick = ({ x, y, payload }) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          style={{ cursor: "pointer" }}
          onClick={() => openModal(payload.value)}
          x={0}
          y={0}
          dy={16}
          textAnchor="middle"
          fill="#666"
        >
          {payload.value}
        </text>
      </g>
    );
  };

  return (
    <>
      <GoalByUsersModal
        goalsByUsersData={goalsByUserData}
        isOpen={isModalOpen}
        selectedDay={selectedDayData}
        toggle={toggleModal}
      />
      <ComposedChartGraphic
        barsAndLines={[
          <Bar dataKey="Realizado - Total (%)" fill="#82CA9D" />,
          <Line type="" dataKey="Meta" stroke="#FF0000" />,
        ]}
        customTooltip={<CustomTooltip />}
        customXAxisTick={<CustomTick />}
        data={goalsByDayData}
        headerText={headerText}
        xAxisDataKey="date"
        yAxisTickFormatter="%"
      />
    </>
  );
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#fff",
          padding: "10px",
          border: "1px solid #ccc",
        }}
      >
        <p
          className="label"
          style={{ fontWeight: "bold" }}
        >{`Data: ${label}`}</p>
        <p
          style={{ color: "#82CA9D" }}
        >{`Total de Metas: ${data["Meta - Total"]}`}</p>
        <p
          style={{ color: "#82CA9D" }}
        >{`Realizado: ${data["Realizado - Total"]}`}</p>
        <p
          style={{ color: "#82CA9D" }}
        >{`Conclusão: ${data["Realizado - Total (%)"]}%`}</p>
      </div>
    );
  }

  return null;
};

export default GoalByDayComposedChartGraphic;
