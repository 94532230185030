import React from "react";
import { Col, Row } from "reactstrap";
import InstalledTrapsCard from "../../../Containers/Entomologico/Cards/DashboardTraps/InstalledTrapsCard";
import TrapsBeingInstalledCard from "../../../Containers/Entomologico/Cards/DashboardTraps/TrapsBeingInstalledCard";
import TotalTrapsInstalledCard from "../../../Containers/Entomologico/Cards/DashboardTraps/TotalTrapsInstalledCard";
import moment from "moment";
import { fetchTrapsDensity } from "../../../../services/api/Trap";
import { toast } from "react-toastify";
import CardLoadingSkeleton from "../../../ui/Loading/CardLoadingSkeleton";

const TotalTrapsNumberCardsWrapper = ({ selectedTrapTypeData, traps, organizationId }) => {
  const [totalInstalledTrapsNumber, setTotalInstalledTrapsNumber] =
    React.useState(0);
  const [secondStageTrapsNumber, setSecondStageTrapsNumber] =
    React.useState(0);
  const [
    isTotalInstalledTrapsNumberLoading,
    setIsTotalInstalledTrapsNumberLoading,
  ] = React.useState(false);
  const [isSecondStageTrapsNumberLoading, setIsSecondStageTrapsNumberLoading] =
    React.useState(false);

  React.useEffect(() => {
    if(organizationId == "559a16ab-5da8-41ba-96b8-6e9f55feddd8")
      return;

    console.log("passed through calculate");
    fetchTotalInstalledTrapsNumber();

    // if (organizationId === "559a16ab-5da8-41ba-96b8-6e9f55feddd8")
    //   fetchSecondStageTrapsNumber();
  }, [selectedTrapTypeData, traps]);

  const fetchTotalInstalledTrapsNumber = async () => {
    try {
      setIsTotalInstalledTrapsNumberLoading(true);

      // const filter = getTrapDensityFilter();

      // const { data, status } = await fetchTrapsDensity(filter);
      // if (status !== 200)
      //   throw new Error(
      //     "Ocorreu um erro ao pegar os números de armadilhas para montar os cards. Tente novamente"
      //   );

      if(traps !== null)
      {
        const actualTrap = traps.find((trap) => trap.trapType == selectedTrapTypeData.trapTypeName);
        const totalTrapsNumber = actualTrap.data.actives.length;

        setTotalInstalledTrapsNumber(totalTrapsNumber);
      }
      
    } catch (error) {
      console.error(error);
      // toast.error(error);
    } finally {
      if(selectedTrapTypeData && traps !== null)
        setIsTotalInstalledTrapsNumberLoading(false);
    }
  };

  const fetchSecondStageTrapsNumber = async () => {
    try {
      setIsSecondStageTrapsNumberLoading(true);

      const filter = getTrapDensityFilter(true);

      const { data, status } = await fetchTrapsDensity(filter);
      if (status !== 200)
        throw new Error(
          "Ocorreu um erro ao pegar os números de armadilhas da Segunda Etapa. Tente novamente"
        );

      const totalSecondStageTrapsNumbers = data.reduce(
        (previousValue, currentValue) => {
          return previousValue + currentValue.quantity;
        },
        0
      );

      setSecondStageTrapsNumber(totalSecondStageTrapsNumbers);
    } catch (error) {
      console.error(error);
      // toast.error(error);
    } finally {
      setIsSecondStageTrapsNumberLoading(false);
    }
  };

  const getTrapDensityFilter = (filterForSecondStage) => {
    const SECOND_STAGE_TERRITORIALIZATION_ID =
      "fd70d5b1-37e9-4bc5-a246-aa189ff48efc";
    const ONE_MONTH_BEHING = moment().subtract(30, "days").format("YYYY-MM-DD");
    const ONE_DAY_FOWARD = moment().add(1, "day").format("YYYY-MM-DD");

    let filter;

    filterForSecondStage
      ? (filter = {
          organizationId: organizationId,
          TrapTypeId: "b935d0a1-9dbb-4e08-9672-f7205605530f",
          UsersIds: [],
          DemandId: "00000000-0000-0000-0000-000000000000",
          period: {
            beginDate: ONE_MONTH_BEHING,
            finalDate: ONE_DAY_FOWARD,
          },
          TypeLayerId: SECOND_STAGE_TERRITORIALIZATION_ID,
        })
      : (filter = {
          organizationId: organizationId,
          TrapTypeId: selectedTrapTypeData.trapTypeId,
          UsersIds: [],
          DemandId: "00000000-0000-0000-0000-000000000000",
          period: {
            beginDate: ONE_MONTH_BEHING,
            finalDate: ONE_DAY_FOWARD,
          },
          TypeLayerId: "00000000-0000-0000-0000-000000000000",
        });

    return filter;
  };

  const getInstalledTrapsCardToRender = {
    //São Paulo
    "559a16ab-5da8-41ba-96b8-6e9f55feddd8": (
      <InstalledTrapsCard
        cardCustomText={`Total de ADI georreferenciada no período (Etapa 1)`}
        cardCustomInstalledTrapsNumber={totalInstalledTrapsNumber}
        cardCustomNumber={secondStageTrapsNumber}
        reduceCustomNumberFromInstalledTraps={true}
      />
    ),
    //Cotia
    "24d125ef-15e6-4bd9-9111-c0b3d50a8009": (
      <InstalledTrapsCard
        cardCustomText={`Total de ${selectedTrapTypeData.trapTypeName} ativa`}
        cardCustomInstalledTrapsNumber={totalInstalledTrapsNumber}
      />
    ),
    "secondStage": (
      <InstalledTrapsCard
        cardCustomText={`Total de ADI georreferenciada no período (Etapa 2)`}
        cardCustomNumber={secondStageTrapsNumber}
      />
    ),
    "default": (
      <InstalledTrapsCard
        cardCustomText={`Total de ${selectedTrapTypeData.trapTypeName} instaladas`}
        cardCustomInstalledTrapsNumber={totalInstalledTrapsNumber}
      />
    )
  };

  const getBeingInstalledTrapsCardToRender = {
    //São Paulo
    "559a16ab-5da8-41ba-96b8-6e9f55feddd8": (
      <TrapsBeingInstalledCard
        totalInstalledTrapNumber={20000}
        cardCustomText="Total de ADI em processo de georreferencimento (Etapa 1)"
        cardCustomInstalledTrapsNumber={totalInstalledTrapsNumber}
        cardCustomNumber={secondStageTrapsNumber}
        reduceCardCustomNumberFromInstalledTraps={true}
      />
    ),
    //Cotia
    "24d125ef-15e6-4bd9-9111-c0b3d50a8009": (
      <TrapsBeingInstalledCard
        totalInstalledTrapNumber={1000}
        cardCustomText="Total de ADI em processo de instalação"
        cardCustomNumber={totalInstalledTrapsNumber}
      />
    ),
    "b2e9a6f4-8a10-4b38-b80c-95cf0aeeed30": (
      <TrapsBeingInstalledCard
        totalInstalledTrapNumber={10800}
        cardCustomText="Total de ADI em processo de instalação"
        cardCustomNumber={totalInstalledTrapsNumber}
      />
    ),
    "secondStage": (
      <TrapsBeingInstalledCard
        totalInstalledTrapNumber={20000}
        cardCustomText="Total de ADI a ser instalada (Etapa 2)"
        cardCustomInstalledTrapsNumber={totalInstalledTrapsNumber}
        cardCustomNumber={secondStageTrapsNumber}
      />
    ),
    "default": (
      <TrapsBeingInstalledCard
        totalInstalledTrapNumber={0}
        cardCustomText={`Total de ${selectedTrapTypeData.trapTypeName} em processo de instalação`}
        cardCustomNumber={0}
      />
    ),
  };

  const getTotalTrapsToInstallCardToRender = {
    //São Paulo
    "559a16ab-5da8-41ba-96b8-6e9f55feddd8": (
      <TotalTrapsInstalledCard
        totalInstalledTrapsNumber={20000}
        cardCustomText={"Total de ADI instaladas (Etapa 1)"}
      />
    ),
    //Cotia
    "24d125ef-15e6-4bd9-9111-c0b3d50a8009": (
      <TotalTrapsInstalledCard
        totalInstalledTrapsNumber={1000}
        cardCustomText={"Total de ADI disponíveis para instalação"}
      />
    ),
    "b2e9a6f4-8a10-4b38-b80c-95cf0aeeed30": (
      <TotalTrapsInstalledCard
        totalInstalledTrapsNumber={10800}
        cardCustomText={"Total de ADI disponíveis para instalação"}
      />
    ),
    "secondStage": (
      <TotalTrapsInstalledCard
        totalInstalledTrapsNumber={20000}
        cardCustomText={
          "Total de ADI disponíveis para instalação (Etapa 2)"
        }
      />
    ),
    "default": (
      <TotalTrapsInstalledCard
        totalInstalledTrapsNumber={0}
        cardCustomText={`Total de ${selectedTrapTypeData.trapTypeName} disponíveis para instalação`}
      />
    ),
  };

  const styles = {
    row: {
      marginLeft: 0,
      marginRight: 0,
    },
    col: {
      paddingLeft: 0,
      paddingRight: "0.2vw",
      width: "100%",
    },
  };

  if (organizationId == '559a16ab-5da8-41ba-96b8-6e9f55feddd8') return <></>;

  return (
    <>
      <Row style={styles.row}>
        {isTotalInstalledTrapsNumberLoading || isSecondStageTrapsNumberLoading ? (
          <>
            <Col style={styles.col} xl={4}>
              <CardLoadingSkeleton />
            </Col>
            <Col style={styles.col} xl={4}>
              <CardLoadingSkeleton />
            </Col>
            <Col style={styles.col} xl={4}>
              <CardLoadingSkeleton />
            </Col>
          </>
        ) : (
          <>
            <Col style={styles.col} xl={4}>
              {getInstalledTrapsCardToRender[organizationId]
                ? getInstalledTrapsCardToRender[organizationId]
                : getInstalledTrapsCardToRender["default"]}
            </Col>
            <Col style={styles.col} xl={4}>
              {getBeingInstalledTrapsCardToRender[organizationId]
                ? getBeingInstalledTrapsCardToRender[organizationId]
                : getBeingInstalledTrapsCardToRender["default"]}
            </Col>
            <Col style={styles.col} xl={4}>
              {getTotalTrapsToInstallCardToRender[organizationId]
                ? getTotalTrapsToInstallCardToRender[organizationId]
                : getTotalTrapsToInstallCardToRender["default"]}
            </Col>
          </>
        )}
      </Row>
      {/* {organizationId === "559a16ab-5da8-41ba-96b8-6e9f55feddd8" &&
        <Row style={styles.row}>
          {isTotalInstalledTrapsNumberLoading || isSecondStageTrapsNumberLoading ? (
            <>
              <Col style={styles.col} xl={4}>
                <CardLoadingSkeleton />
              </Col>
              <Col style={styles.col} xl={4}>
                <CardLoadingSkeleton />
              </Col>
              <Col style={styles.col} xl={4}>
                <CardLoadingSkeleton />
              </Col>
            </>
          ) : (
            <>
              <Col style={styles.col} xl={4}>
                {getInstalledTrapsCardToRender["secondStage"]}
              </Col>
              <Col style={styles.col} xl={4}>
                {getBeingInstalledTrapsCardToRender["secondStage"]}
              </Col>
              <Col style={styles.col} xl={4}>
                {getTotalTrapsToInstallCardToRender["secondStage"]}
              </Col>
            </>
          )}
        </Row>
      } */}
    </>
  );
};

export default TotalTrapsNumberCardsWrapper;
